import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { BoolField, CellColumnNumber } from "types/fieldComponentTypes";



type Props = {
  field: BoolField;
  value:string;
  xs: CellColumnNumber;
  sm: CellColumnNumber;
  md: CellColumnNumber;
  disabled: boolean;
  onBlur: (value:boolean) => void;
  error: string | null;
}

const BoolFieldControl:React.FC<Props> = ({ field, value, xs, sm, md, onBlur, disabled, error }) => {
  //console.log(field.id, error)
  const strValue = value === null ? (field.defaultValue !== undefined ? field.defaultValue : "") : value;
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <TextField
        label={field.label}
        select
        variant="filled"
        fullWidth
        value={strValue}
        onChange={e => onBlur(e.target.value === 'true')}
        multiline={field.multiline}
        disabled={disabled}
        helperText={error || (field.isRequired ? 'Required' : null)}
        error={!!error}
      >
        <MenuItem value={'true'}>{field.trueLabel || 'Yes'}</MenuItem>
        <MenuItem value={'false'}>{field.falseLabel || 'No'}</MenuItem>
      </TextField>
    </Grid>
  );
};
export default BoolFieldControl;
