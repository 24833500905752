import React from 'react';

import styled from 'styled-components';


const ContributorMembershipTable = () => {

    return (
        <Container>
        <Table>
          <thead>
            <tr>
              <Th style={{minWidth:140}}>Role</Th>
              <Th>Membership</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td><b>Principal</b></Td>
              <Td>Limited search access by bartering listing data</Td>
            </tr>
            <tr>
              <Td><b>Staff Member</b></Td>
              <Td>Limited search access by bartering listing data</Td>
            </tr>

          </tbody>
        </Table></Container>
      );
};

export default ContributorMembershipTable;
const Container = styled.div`
padding: 8px 8px -1px 8px;
border: 1px solid #ccc;
font-size:10pt;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Th = styled.th`

  padding: 8px;
  text-align: left;
`;

const Td = styled.td`
  border-top: 1px solid #ccc;
  border-left: 0;
  border-right: 0;
  padding: 8px;
  text-align: left;
`;
