import {
  AppBar,
  Dialog,
  IconButton,
  LinearProgress, Slide,
  Toolbar,
  Typography
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";
import { navigate } from "@reach/router";
import { AppContext } from "AppContext";
import { getFilteredDocPages } from "common/newHelperFunctions";
import PageLink, { ClickLink, ExternalLink } from "components/PageLink";
import DocSideCategory, {
  LinkProps
} from "pages/documentation/DocSideCategory";
import {
  PageCategory,
  PageCollection
} from "pages/documentation/DocumentationTypes";
import React, { useContext, useEffect, useState } from "react";
import YouTube from "react-youtube";
import useDueDiligenceStore from "store/useDueDiligenceStore";
import styled from "styled-components";
type ExtraFeaturesPopupProps = {
  isOpen: boolean;
  handleClose(): void;
};
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const query = `
{
  documentationPageCollection{
    items{
      title
      slug
      index
      showToProspects
      showToContributors
      showToSponsors
      category{
        name
        index
      }

    }
  }
}
`;

export default function ExtraFeaturesPopup({
  isOpen,
  handleClose,
}: ExtraFeaturesPopupProps) {
  const [collection, setCollection] = useState<PageCollection | null>(null);
  const [videoOpenId, setVideoOpenId] = useState<string | null>(null);
  const { docs } = useDueDiligenceStore(null);
  const publishedDocs = docs?.filter((d) => d.status === "published");
  const { organizationType, webSiteBaseUrl } = useContext(AppContext);
  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/m36bcpspkzf4/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer RtsHzuFecKvMzRCeDAFxaOMTYThYFZaQJ-gOaZA7gKM",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setCollection(data.documentationPageCollection);
      });
  }, []);

  if (!collection || !publishedDocs) {
    return <LinearProgress />;
  }
  const filteredDocPages = getFilteredDocPages(collection, organizationType);

  const links: LinkProps[] = filteredDocPages
    .map((l) => ({
      slug: l.slug,
      title: l.title,
      isCurrent: false,
      index: l.index,
      categoryName: l.category.name,
    }))
    .sort((a, b) => (a.index || 0) - (b.index || 0));

  const categories = filteredDocPages
    .map((i) => i.category)
    .reduce(function (pV: PageCategory[], cV, cI) {
      const isIn = !!pV.find((c) => c.name === cV.name);
      if (!isIn) {
        pV.push(cV);
      }
      return pV;
    }, [])
    .sort((a, b) => (a.index || 0) - (b.index || 0));

  function getChildDocs(parentId: string) {
    const childDocs = publishedDocs
      ?.filter((d) => d.parentDoc === parentId)
      .sort((a, b) => a.title.localeCompare(b.title));

    if (childDocs?.length === 0) {
      return <></>;
    }
    return (
      <>
        {childDocs?.map((d, n) => (
          <PageLink
            pathPrefix="duediligence"
            slug={d.slug}
            title={d.title}
            indented={true}
            key={`page_${n}`}
          />
        ))}
      </>
    );
  }
  return (
    <Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
      <StyledAppBar>
        <Toolbar>
          <StyledTitle variant="h6">Extra Elements</StyledTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      <Row>
        <Column id="extra-elements-listings">
          <H2>Listings</H2>
          <ClickLink
            title="Search"
            onClick={() => {
              navigate(`/`);
              handleClose();
            }}
            showIcon={false}
          />
          <ClickLink
            title="Posting Editor"
            onClick={() => {
              navigate(`/editor`);
              handleClose();
            }}
            showIcon={false}
          />
                   
        </Column>
        <Column  id="extra-elements-useful-resources">
          <H2>Useful Resources</H2>
          {publishedDocs
            .filter((d) => !d.parentDoc)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((d, n) => (
              <div key={`page_${n}`}>
                <ClickLink
                  title={d.title}
                  onClick={() => {
                    navigate(`/duediligence/${d.slug}`);
                    handleClose();
                  }}
                  showIcon={false}
                />
                {getChildDocs(d.id)}
              </div>
            ))}
        </Column>
        <Column  id="extra-elements-documentation">
          <H2>Additional Programs & Services</H2>
          {categories.map((c, n) => {
            return (
              <DocSideCategory
                key={`cat_${n}`}
                title={c.name}
                links={links.filter((l) => l.categoryName === c.name)}
                hideTitle={c.name.startsWith("_")}
                onClick={() => {
                  handleClose();
                }}
                noMargin
              />
            );
          })}
        </Column>
        <Column  id="extra-elements-explore">
          <H2>Explore the GeX</H2>
          {getGexLinks(webSiteBaseUrl).map((link, n) => (
            <ExternalLink key={`{link_${n}}`} {...link} />
          ))}
           <ClickLink
            title="Tour"
            onClick={() => {
              navigate(`/tour`);
              handleClose();
            }}
            showIcon={false}
          />
        </Column>
        <Column  id="extra-elements-videos">
          <H2>Videos</H2>
          <ClickLink
            title="About Us"
            onClick={() => setVideoOpenId("jFCOXlV9Lcs")}
          />
          <ClickLink
            title="Founder's Corner"
            onClick={() => setVideoOpenId("XjaXJD4W7M8")}
          />
          <ClickLink
            title="Search"
            onClick={() => setVideoOpenId("nF9Rgg-co_s")}
          />
          <ClickLink
            title="Data Inputting"
            onClick={() => setVideoOpenId("uYpN22kWYq4")}
          />

        </Column>
      </Row>
      <Modal
        open={!!videoOpenId}
        onClose={() => setVideoOpenId(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <VideoHolder>
          <IconHolder onClick={() => setVideoOpenId(null)}>
            <StyledCloseIcon fontSize="large" />
          </IconHolder>

          {videoOpenId && (
            <div>
              <YouTube
                videoId={videoOpenId}
                opts={{
                  width: "800",
                  height: "450",
                  playerVars: {
                    autoplay: 1,
                  },
                }}

                // onReady={this._onReady}
              />
            </div>
          )}
        </VideoHolder>
      </Modal>
    </Dialog>
  );
}

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: auto;
  margin-top: 70px;
  justify-content: center;
`;

const Column = styled.div`
  width: 295px;
  margin: 16px;
`;
const H2 = styled.h2`
  padding-left: 16px;
  font-size: 18px;
`;

const VideoHolder = styled.div`
  width: 800px;

  margin: 100px auto;
`;
const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  color: #fff;
  cursor: pointer;
`;

const IconHolder = styled.div`
  position: relative;
  height: 40px;
`;

const StyledAppBar = styled(AppBar)`
  position: relative;
  color: #0079ff !important;
  background-color: #fff !important;
`;
const StyledTitle = styled(Typography)`
  margin-left: 32px;
  flex: 1;
`;

const getGexLinks = (
  webSiteBaseUrl: string
): { title: string; url: string }[] => [
  {
    title: "Landing Page",
    url: `${webSiteBaseUrl}`,
  },
  {
    title: "GeX Capabilities & Limitations",
    url: `${webSiteBaseUrl}/capabilities`,
  },
  {
    title: "Quality Control & Security",
    url: `${webSiteBaseUrl}/quality-control`,
  },
  {
    title: "Rollout Schedule",
    url: `${webSiteBaseUrl}/roll-out-schedule`,
  },
  {
    title: "Referral Program",
    url: `${webSiteBaseUrl}/gex-referral-program`,
  },
  {
    title: "PSX Affordable Housing Initiative",
    url: `${webSiteBaseUrl}/psx-affordable-housing`,
  },
  {
    title: "Prospect Data Inputting Services",
    url: `${webSiteBaseUrl}/data-inputing-services`,
  },
  {
    title: "Useful Resources",
    url: `${webSiteBaseUrl}/useful-resources`,
  },
  // {
  //   title: "Limited Due Diligence",
  //   url: `${webSiteBaseUrl}/due-diligence/`,
  // },
];
