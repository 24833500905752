import { Link } from "@material-ui/core";
import FileDownloadLink from "components/controls/FileDownloadLink";
import React from "react";
import { FileField } from "types/fieldComponentTypes";
import { Light } from "./ListingPageStyledComponents";

type Props = {
  value: string | undefined;
  field: FileField;
  listingId: string;
};

export default function FileFieldLabel({ field, value, listingId }: Props) {
  if (!value) {
    return null;
  }

  const valueIsLink =
    !!value &&
    (value.indexOf("http://") === 0 || value.indexOf("https://") === 0);
  if (valueIsLink) {
    return (
      <p>
        <Light>{field.label}</Light>

        <Link variant="body2" href={value} target="_blank" style={{paddingLeft:20}}>
          link to {field.label}
        </Link>
      </p>
    );
  }

  return (
    <div>
      <Light>{field.label}</Light>
      <FileDownloadLink fileName={value} listingId={listingId} />
    </div>
  );
}
