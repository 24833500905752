import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EmailRole } from 'types/Types';
import EmailTable from './EmailTable';
import SponsorMembershipTable from './SponsorMembershipTable';


type OrganizationalSetupControlsProps =  {
    children?: React.ReactNode;
    onSubmit: (emailRoles: EmailRole[]) => void;
    roles: string[];
}

const OrganizationalSetupControls = ({onSubmit, children, roles } : OrganizationalSetupControlsProps ) => {

    const [showErrors, setShowErrors] = useState(false);
    const emailRow = {
        email: "",
        role: "",
      }
      function addRow() {
        setEmailRoles([...emailRoles, emailRow]);
      }
      function updateRow(ordinal: number, email: string, role: string) {
        const newEmailRoles = emailRoles.map((er, index) => {
          if (index === ordinal) {
            return { email, role };
          }
          return er;
        });
        setEmailRoles(newEmailRoles);
      }
      function deleteRow(ordinal: number) {
        const newEmailRoles = emailRoles.filter((er, index) => {
          return index !== ordinal;
        });
        setEmailRoles(newEmailRoles);
      }
    
      const [emailRoles, setEmailRoles] = useState<EmailRole[]>([emailRow]);
      const isButtonDisabled = false;
      function submitForm() {
        if(emailRoles.length === 0) {
          alert("Please add at least one email");
          return;
        }

        if(!validate()) {
          alert("Please fill out all fields");
          setShowErrors(true);
          return;
        }
        onSubmit(emailRoles);
      }
      function validate() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRoles.every((er) => {
          return emailRegex.test(er.email) && er.role !== "";
        });
      }
    return (
        <div>
            <h2>Organizational Setup</h2>
            {children}
            <p>Please ensure that all individuals within your organization are added to their designated roles. Upon completion of the organization's setup, each user will receive an email with instructions on accessing the site.</p>
            <EmailTable roles={roles} emailRoleArray={emailRoles} addRow={addRow} updateRow={updateRow} deleteRow={deleteRow} showErrors={showErrors} />
            <ButtonHolder>
              <Button
                color="primary"
                variant="contained"
                disabled={isButtonDisabled}
                onClick={submitForm}
              >
                Submit
              </Button>
             
            </ButtonHolder>
        </div>
    );
};
const ButtonHolder = styled.div`
  padding-top: 20px;
`;
export default OrganizationalSetupControls;
