import React, { useContext, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "@reach/router";
import { Button } from "@material-ui/core";
import ContributorTerms from "./ContributorTerms";
import useUser from "store/useUser";
import { User } from "types/AdminTypes";
import { AppContext } from "AppContext";
import { firestore } from "firebase";
import OrganizationalSetupControls from "./sponsorContributorEmailTable/OrganizationalSetupControls";
import Template from "./Template";
import InputField from "./sponsorContributorEmailTable/InputField";
import { EmailRole } from "types/Types";
import ContributorMembershipTable from "./sponsorContributorEmailTable/ContributorMembershipTable";

type ContributorRegistrationPageProps = {} & RouteComponentProps;

type StateType = {
  firstName: string;
  surname: string;
  title: string;
  email: string;
  registrationType: string;
  phoneNumber: string;
  organization: string;
  businessAddress: string;
  businessPhoneNumber: string;

}

export default function ContributorRegistrationPage({}: ContributorRegistrationPageProps) {
  const { user } = useContext(AppContext);
  const { addNew } = useUser("gex");
  const firstName = user?.displayName?.split(" ")[0];
  const lastName = user?.displayName?.split(" ")[1];

  const [state, setState] = useState< StateType>({
    registrationType: "Contributor",
    firstName: firstName || "",
    surname: lastName || "",
    title: "",
    phoneNumber: "",
    email: user?.email || "",
    organization: "",
    businessAddress: "",
    businessPhoneNumber: "",
  });
  const [formState, setFormState] = useState({
    isValidated: false,
    isLoading: false,
    isSubmitted: false,
    termsAgreed: false,
    privacyAgreed: false,
    pageTwo: false,
  });

  type FieldType = {
    id: string;
    label: string;
    isRequired?: boolean;
    value: string;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  };

  const errors: string[] = (function () {
    const e: string[] = [];
    if (state.firstName.length < 2) {
      e.push("firstName");
    }
    if (state.surname.length < 2) {
      e.push("surname");
    }
    if (state.email.length < 2) {
      e.push("email");
    }
    if (state.phoneNumber.length < 2) {
      e.push("phoneNumber");
    }
    if (state.organization.length < 2) {
      e.push("organization");
    }
    return e;
  })();

  const fields: FieldType[] = [
    {
      id: "firstName",
      label: "First Name",
      value: state.firstName,
      isRequired: true,
      onChange: (e) => setState({ ...state, firstName: e.target.value }),
    },

    {
      id: "surname",
      label: "Surname",
      isRequired: true,
      value: state.surname,
      onChange: (e) => setState({ ...state, surname: e.target.value }),
    },
    {
      id: "title",
      label: "Title",
      value: state.title,
      onChange: (e) => setState({ ...state, title: e.target.value }),
    },
    {
      id: "organization",
      label: "Organization",
      value: state.organization,
      onChange: (e) => setState({ ...state, organization: e.target.value }),
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      value: state.phoneNumber,
      isRequired: true,
      onChange: (e) => setState({ ...state, phoneNumber: e.target.value }),
    },
    {
      id: "email",
      label: "Email Address",
      value: state.email,
      isRequired: true,
      onChange: (e) => setState({ ...state, email: e.target.value }),
    },
  ];

  function submitFormPage1() {
   
      setFormState({ ...formState, isValidated: true, pageTwo: errors.length === 0 });
      return;
    
  }

  async function submitForm(emailRoles: EmailRole[]) {

    setFormState({ ...formState, isLoading: true });
    if (!user) return;
    const newUser: User = {
      ...user,
      orgId: "gex",
      roles: [],
      marketSegments: ["IL", "CL", "OS", "RS", "IS"],
      registrationInfo: {
        registrationType: "contributor",
        firstName: state.firstName,
        surname: state.surname,
        title: state.title,
        organization: state.organization,
        phoneNumber: state.phoneNumber,
        email: state.email,
        phoneOrEmail: `Phone: ${state.phoneNumber} Email: ${state.email}`,
        businessAddress: state.businessAddress,
        businessPhoneNumber: state.businessPhoneNumber,
        registrationDate: firestore.Timestamp.fromDate(new Date()),
      },
      organizationSetup: {
        emailRoles: emailRoles,
    }
  };


    console.log("STATE", state, newUser)
    const t = await addNew(newUser);
    window.location.href = "/tour";
  }

  const isButtonDisabled =
    !formState.termsAgreed || !formState.privacyAgreed || formState.isLoading;
  return (
    <Template>
      {!formState.pageTwo ? (<>
      <h2>Contributor Registration Form</h2>
      <p>
        Fill out the Form. Agree to the Terms and Conditions and Privacy Policy
      </p>
      {fields.map((f) => (
        <InputField
          key={`tf_${f.id}`}
          {...f}
          disabled={formState.isLoading}
          isError={formState.isValidated && errors.includes(f.id)}
        />
      ))}
      <ContributorTerms
        setTermsChecked={(isChecked) =>
          setFormState({ ...formState, termsAgreed: isChecked })
        }
        setPrivacyChecked={(isChecked) =>
          setFormState({ ...formState, privacyAgreed: isChecked })
        }
        isDisabled={formState.isLoading}
      />
      {formState.isValidated && errors.length > 0 && (
        <div style={{ color: "red" }}>
          Enter required info:{" "}
          {errors
            .map((id) => fields.find((f) => f.id === id)?.label)
            .join(", ")}
        </div>
      )}
      <ButtonHolder>
        <Button
          color="primary"
          variant="contained"
          disabled={isButtonDisabled}
          onClick={submitFormPage1}
        >
          Next
        </Button>
      </ButtonHolder></>) : (<OrganizationalSetupControls onSubmit={(emailRoles) => submitForm(emailRoles)} roles={
        ["Principal", "Staff Member"]
      }>
        <ContributorMembershipTable/>
      </OrganizationalSetupControls>)}
      
      
    </Template>
  );
}

const ButtonHolder = styled.div`
  padding-top: 20px;
`;
