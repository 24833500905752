import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import themes, { Themes } from "themes";
import {
  LISTING_STATUSES,
  MapListingInfo,
  OrdinalMarkerPolygon,
  Point,
  VersionDoc,
} from "types/Types";
import MarkerInfoWindow from "./MarkerInfoWindow";
import { getLetterForOrdinal } from "common/helperFunctions";
type Props = {
  mapInfos: MapListingInfo[];
  defaultCenter: { lat: number; lng: number };
  defaultZoom: number;
  markerClick: (id: string) => void;
  greedy?: boolean;
  omps?: OrdinalMarkerPolygon[];
  selectedMarkerId: string | null;
  hoverMarkerId?: string | null;
  currentPage?: VersionDoc;
  hideMap: boolean;
  isPublic: boolean;
  isRealtor: boolean;
  isEditor?: boolean;
};

export default function ListingMap(props: Props) {
  const {
    mapInfos,
    defaultCenter,
    defaultZoom,
    markerClick,
    greedy,
    omps,
    selectedMarkerId,
    hoverMarkerId,
    currentPage,
    hideMap,
    isPublic,
    isRealtor,
    isEditor = true,
  } = props;

  const theme = useTheme();
  const strokeColor = "#fff";
  const selectedStrokeColor = "#fff";// theme.palette.primary.dark; //  "#9fd080"; //#0F6A37
  const fillColor = "#fff";
  const fillOpacity = 0.4;
  const selectedStrokeWeight = 4;
  const strokeWeight = 2;
  const hideSelected = !!omps;

  const mapCenter = defaultCenter;

  //

  const mapIsReady = !!mapInfos;

  const selectedMapInfo =
    !!selectedMarkerId && mapInfos.find((m) => m.id === selectedMarkerId);

  const SVG_MARKER = {
    path: "M12.3606 0.0210949C12.2718 0.0281262 12.0019 0.0527356 11.7568 0.0703137C7.43057 0.44297 3.44177 3.12539 1.44204 7.01016C0.596687 8.64844 0.152698 10.2762 0.0354846 12.1746C-0.220253 16.2527 2.08849 23.3578 6.96171 33.4863C7.6863 34.984 9.42319 38.4082 10.2934 40.043C11.4087 42.1418 12.9609 44.9613 13 44.9648C13.0177 44.9648 13.9235 43.3617 14.4776 42.3457C19.9901 32.2383 23.9079 23.2488 25.3251 17.4586C25.7904 15.5496 25.9787 14.2207 25.9787 12.832C25.9787 10.8316 25.5737 9.08086 24.7035 7.28789C22.7429 3.25195 18.8038 0.527345 14.2787 0.0703137C13.8808 0.0316418 12.627 -0.00351443 12.3606 0.0210949Z",
    fillColor: "#304ffe",
    strokeColor: "white",
    fillOpacity: 1,
    rotation: 0,
    scale: 1,
    anchor: new google.maps.Point(14, 45),
    labelOrigin: new google.maps.Point(13, 15),
    labelColor: "white",
  };

  

  return (
    <>
      {!hideMap && (
        <GoogleMap
          id="parcel-map"
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          zoom={defaultZoom}
          center={mapCenter}
          options={{
            gestureHandling: greedy ? "greedy" : "cooperative",
            mapTypeId: "hybrid",
          }}
          //onLoad={map => MapLoad(map)}
        >
          {/**************** MARKERS ***************/}
          {mapIsReady &&
            mapInfos
              .filter(
                (i: MapListingInfo) =>
                  i.id !== currentPage?.listingId &&
                  !!i.mapInfo?.marker &&
                  i.id !== selectedMarkerId
              )
              .map((place: MapListingInfo, n: number) => {
                if (!place.mapInfo.marker) {
                  throw new Error("no lat long");
                }
                return (
                  <Marker
                    key={`mrk_${n}`}
                    position={place.mapInfo.marker}
                    icon={getIcon(place.status, place.orgId, isPublic, theme)}
                    //onLoad={(marker) => markerLoadHandler(marker, place.id)}
                    onClick={() => markerClick(place.id)}
                    animation={
                      hoverMarkerId === place.id
                        ? window.google.maps.Animation.BOUNCE
                        : undefined
                    }
                  />
                );
              })}
          {/**************** SELECTED MARKER ***************/}
          {!!selectedMapInfo && selectedMapInfo.mapInfo.marker && (
            <Marker
              key={`mrk_selected`}
              position={selectedMapInfo.mapInfo.marker}
              icon={getIcon(
                selectedMapInfo.status,
                selectedMapInfo.orgId,
                isPublic,
                theme
              )}
              onClick={() => markerClick(selectedMapInfo.id)}
            >
              <MarkerInfoWindow
                land={selectedMapInfo}
                isPublic={isPublic}
                isEditor={isEditor}
                isRealtor={isRealtor}
                markerClose={() => markerClick(selectedMapInfo.id)}
                key={`infowindow`}
              />
            </Marker>
          )}

          {/**************** POLYGONS ***************/}
          {mapIsReady &&
            mapInfos
              .filter(
                (i: MapListingInfo) =>
                  (!hideSelected || i.id !== currentPage?.listingId) &&
                  !!i.mapInfo.polygon
              )
              .map((place: MapListingInfo, n: number) => {

                if (place.id === currentPage?.listingId) {
          

                  return ([
                    currentPage?.mapInfo.polygon &&<Polygon
                      key={`plyg_${n}`}
                      paths={currentPage?.mapInfo.polygon}
                      options={{
                        strokeColor: selectedStrokeColor,
                        fillColor: selectedStrokeColor,
                        fillOpacity,
                        strokeWeight: selectedStrokeWeight,
                      }}
                      onClick={() => markerClick(currentPage?.listingId)}
                    />,
                     currentPage?.mapInfo.secondaryPolygon && (
                      <Polygon
                      key={`plyg_s${n}`}
                      paths={currentPage?.mapInfo.secondaryPolygon}
                      options={{
                        strokeColor: selectedStrokeColor,
                        fillColor: selectedStrokeColor,
                        fillOpacity,
                        strokeWeight: selectedStrokeWeight,
                      }}
                      onClick={() => markerClick(currentPage?.listingId)}
                    />
                    )
                    ]
                  );
                }
                return (
                  [<Polygon
                    key={`plyg_${n}`}
                    paths={place.mapInfo.polygon as [Point]}
                    options={{
                      strokeColor,
                      fillColor,
                      fillOpacity,
                      strokeWeight,
                    }}
                    onClick={() => markerClick(place.id)}
                  />,
                  place.mapInfo.secondaryPolygon && ( <Polygon
                    key={`plyg_s${n}`}
                    paths={place.mapInfo.secondaryPolygon as [Point]}
                    options={{
                      strokeColor,
                      fillColor,
                      fillOpacity,
                      strokeWeight,
                    }}
                    onClick={() => markerClick(place.id)}
                  />)

                  ]


                );
              })}

          {/**************** SPACE LOTS ***************/}
          {!!omps &&
            omps.map((l: OrdinalMarkerPolygon, n: number) => {
              if (!l.marker?.lat || !l.marker?.lng) {
                return null;
              }
              return (
                <Marker
                  key={`SPACE_${n}`}
                  position={
                    new window.google.maps.LatLng(l.marker?.lat, l.marker?.lng)
                  }
                  icon={SVG_MARKER}
                  label={{ text: `${getLetterForOrdinal(n)}`, color: "white" }}
                />
              );
            })}
           {!!omps  &&
            omps.map((l: OrdinalMarkerPolygon, n: number) => {
              if (!l.polygon) {
                return null;
              }
              return (
                <Polygon
                  key={`SPACE_p_${n}`}
                  paths={l.polygon as [Point]}
                  options={{
                    strokeColor: selectedStrokeColor,
                    fillColor: selectedStrokeColor,
                    fillOpacity,
                    strokeWeight: selectedStrokeWeight,
                  }}
                />
              );
            })} 
        </GoogleMap>
      )}
    </>
  );
}

function getIcon(
  status: LISTING_STATUSES,
  orgId: keyof Themes,
  isPublic: boolean,
  theme: any
): google.maps.Icon {
  const common = {
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(12, 42),
    scaledSize: new window.google.maps.Size(30, 42),
  };

  const blueFlag = {
    url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAApCAYAAAArpDnNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+lJREFUeNq8V21IU1EYfnfd5tZ255RSc6hZww/wY2QkjWpGRUQUakEEQUI/I9O/QfQv6E+BBX38DITq1+xXUNCkGm1hyCQtXCq1VVrug2Xze53zunO3u3avlldfOJxz7u59n/O87/O+9w5gg0wl92MikbCRqZmMcjJsycvNMo9EyBhga5VK1SrnfBsZN8kIJ9ZokowoAJk6M8H9U7MQjM7BzELq2de+7ziXFhnBmMtBKPob97xODQdrt4AlX0+3BwgrF12o00BesLBEp+fgqfszPHzuBzdxeOq8A7S5ajF6LjqCocgiTE5F4d37gPCT91MYbrc3QDLcKSAC0s5AHj3zw5V7XgRjNvbxG1TVl0qGmzfkivYfvsbYsoEtuOR8joF03HglAqE2+uGbrKL0Og0OZsFwHGLxBUgTkAC0HPfB71kdhX/GcMiZyaAT7YeXWdlItMwIdLGjw+x2uxH56J4ySUcrseKN4vC9HQ2zpY3lqKW/v99st9sRiKroy8SvvxwFxn5A495KmJtdgMgUZfgL19SmY3EotRbB7obUQefVAjDNvQvFMOL3QzweB71ej2D3nUNigeVvAk2hCXqfvIPpQDgrIwq6/2i9sA/NCaXgYDlyMjBqpw9bhZtzSILNVcVgsOQDp8kBLa+TDN3kV/EBYikgVDN3q7sb28agz4dXa7cXYPgoC1PFZgQQqjuHw+tSjKZjM2KZT/xmNdrMVNfLGFE75qiATcV56DjTNLxektVEUMwqGJ0XWLFyd4ZCoavBYBAsFgucOVIJj4dCWZ1pTTo8QGJxCff2+mKMQBkZhSX5UF5uBkueFuLzS6DXCAd1CL2OyDzc1tZmbnZg7uDQtVdYeJm21aiBfWU8HGm0IMhqLb2BOUme2hkQbYwPXn4WAVw4vANO2ktX5ZhECAc1q9XqSgfqI3lqZ5vWXSUC0ImGIrjcWg15Bq24q5O80twGAwEsj/Q8Z1hXOhB2Wd/gINTX1UF1CY+tfqfFCNfPpuqD5tHlcoHH6/2XF2yLoF2vxxNpampqMfF8cU1NDV4z5Kjg0vEq4W7q/M7duwgmY/TA4xkjos68iTCyEVHgJjMfPT09mQ77knOE1OPAasWA9UQS2EmTWFBQIPVN0EWFkyz0/zcq8xcuV9ZvANrp/9cvly3G/pGRrDevhUU2oF6qPKWNk1ANKA3GZQkPleOAVPiUZMRkviFAvem9at2ASPiwCJVkxcm1EiXzJAfUJ9ONFQVy0tbvVwhMEigp83Ff8qNlPRkhq5H1ZsTyRN89Sspcrpsn3ng8f/2DU5oR+2hZ99ApJvNVMVJC5isCMZkPDQ+v6dX9R4ABAFFIAQvac3YrAAAAAElFTkSuQmCC",
    ...common,
  };
  const grayFlag = {
    url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAApCAYAAAArpDnNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA8dJREFUeNq8V11LG1EQnSQaTDQhasEYkQQViw3RgIpUH4ygiKXSVHyrtf6BivoH+mp9KViw/gHtm8RHfXEFkUYQYwIVTLBRjJ+QRBQNaEjvHXO3uzG7pro6MNx7N+ucnTlnZleAZzKV3I+pVMpJFhdxK3Fn+rJL5k/ixH1sr1Kp3ssFtxH/RjyWeqRJZkQByDJy5zHjcTg/P4ebmxv+2ubmJq5msxny87UQi0XxXFCgA7v9FRQXF9NjB8mKo5s8AcgSK8vFxQWsrq7CwsICBhwbGyMBCkTgRqMR18vLSzg5CcPGho//bWdnBwYHP0K63P+ACMgQA1lcXISpqSkEYxYIBKC5uVmy3AaDQXQ+ODhg2wa2UafXTwxkYmJCBELN7/fLKkqn06Ezi8VicHWVAIGAeCBR3TPt+PgYXc4yszo8xKycpFomBPo8PGwifCBya2urZKD7sjIaxUCUp7Q5GUfu9fV1EwVpa2tDFR0dHd0JtL29DV1dXZBIJAj5J5gh3VM7OzuDuro6EY86nR4EfcehGIKhEKnpFdaZAs7NzYlASktfgMVigdnZnxAO/8maEQXt7+/nz8kk3wrtjCMPA6PW3d3N36zX68HhqAebzQZarRZMJpNk6fb29kRnQc+hmtXfJydxbATSHFRXV2P5aBa1tS8RgJlGo8HrUhnREgrt9PSU9aiLqW6eZYTt3NEBlZWVGDjT5LLa3d0VnQVt4mKTwRONRr9EIhGoqKiAnp43hPxg1mAUiD5AMpm87ciGBqxAWVkZWK1WqKqqQqlfX1+T0ZTP88TPOiLzWF9fn8nVjtzB+PhXbLxMKywsJA9jgaamJgTJ1fIEew/haYgB2e12WFlZEQF0dnYSVb7OKTCpEDq1mpoaTgi0THgaYofGxkYeyOFwgNv9DoqKikTBQoRXym1kfx/bQ8hzho0KgXDK+skArSeBLZZyHPXl5WYYGPjA30R55DgOvGtr//OCdfOyWvN64y0tLW6jwWCmXY7aV2ugt/ctfzcN/mN6GsFkjD5wOMPjeZk3kYycRBRwO/vEfMzMzGQGXE6vcdKPvlzFgP1ECByhJJaUlEh9E4xS4aQb/eFGZb7EcVm/Aeikf2hcdbYah4LZm/UxWWQDmqfKU9rUEqoBpcHUWcpD5eiTKp+SGTGZPwvQvHBWPRkQKR82oZJZqeVGiZI8yQEty0xjRYE8dPSHFAKTBErLPHzfh6MSGWFWwafOiPFE3z1Kylxumqd+eb13/oNTOiP20fLkpVNM5jllpITM7wViMv+9tfWoV/dfAQYAfHkBnr+RjP8AAAAASUVORK5CYII=",
    ...common,
  };

  if (status === LISTING_STATUSES.ARCHIVED) {
    return grayFlag;
  }

  if (status !== LISTING_STATUSES.PUBLISHED) {
    return blueFlag;
  }

  if(themes[orgId].company.markerIsFile) {
    console.log("Using file marker",`/themes/${themes[orgId].company.id}/pin.svg`);
    return {
      url: `/themes/${themes[orgId].company.id}/pin.svg`,
      ...common,
    };
  }
  return {
    url: themes[orgId].company.marker,
    ...common,
  };
}
