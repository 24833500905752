import { withTheme } from '@material-ui/core/styles';
import React from 'react';
import styled from 'styled-components';
import { getFriendlyAcres } from 'common/newHelperFunctions';
import tapeMeasure from "data/tapeMeasure.png";
import LandSizeSlider from './LandSizeSlider';
import { Heading } from "components/searchPopup/styledComponents";


type Props = {
    state: number;
    setState(v: number): void;
    distribution: { ordinal: number, value: string, min: number, max: number }[],
}

const SizeForm: React.FC<Props> = ({state, setState, distribution}) => {
    return (<Container>
        <TapeMeasureImage  src={tapeMeasure} alt="tap measure" />
        <Heading>Select Size:</Heading>
            <AcresText>{getFriendlyAcres(state)}</AcresText>
            {/* <RangeText>{getFriendlyRange(ordinal)}</RangeText> */}
        <SliderHolder>
            <LandSizeSlider distribution={distribution} values={[state+1]} setValues={ (v:number[]) =>  setState(v[0]-1)} />
        </SliderHolder>
        
    </Container>);
};

const TapeMeasureImage = styled.img`
    bottom:60px;
    right:10px;
    position:absolute;


    z-index:100;
`;

const Container = styled.div`
    position: relative;
    height:100%;
    padding:0 80px;
   
`
const SliderHolder = styled.div`
    margin-top:30px;
    width:500px;

`;

const AcresText = withTheme(styled.div`
 font-size: 25px;
 color:${props =>  props.theme.palette.primary[600]};
 font-weight:600;
 margin-top:30px;
`);

export default SizeForm;