import { withTheme } from "@material-ui/core";
import {
  getAreasForPieces,
  getJurisdictionLongNameById,
  getListingTypeFriendlyText,
  getNumberOfPieces,
  getShortAddress,
  getTermsConditionsHelperValues,
  isRetailOrOfficeSpace,
  isSpaceMarketSegment,
} from "common/newHelperFunctions";
import ImgViewer300 from "components/controls/ImgViewer300";
import {
  Box,
  Line,
  Title,
} from "components/preview/ListingPageStyledComponents";
import NumberFieldLabel from "components/preview/NumberFieldLabel";
import StringFieldLabel from "components/preview/StringFieldLabel";
import { settings } from "config/firebase";
import AvailabilitySection from "pages/components/AvailabilitySection";
import AvailableSpacesTable from "pages/components/AvailableSpacesTable";
import ConfigTable from "pages/components/ConfigTable";
import ListingMap from "pages/components/ListingMap";
import LotTable from "pages/components/LotTable";
import React, { useCallback, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import YouTube from "react-youtube";
import useListingStore from "store/useListingStore";
import styled from "styled-components";
import {
  Listing,
  LISTING_TYPE,
  TermsConditions,
  VersionDoc,
} from "types/Types";
import * as fields from "../../types/fieldDefinitions";

import BasicRentFixedRangeFieldLabel from "./BasicRentFixedRangeFieldLabel";
import BooleanFieldLabel from "./BooleanFieldLabel";
import CranesListingSection from "./CranesListingSection";
import FileFieldLabel from "./FileFieldLabel";
import LandSurveyorDescriptionFieldLabel from "./LandSurveyorDescriptionFieldLabel";
import LegalDescriptionFieldLabel from "./LegalDescriptionFieldLabel";
import MezzanineFieldLabel from "./MezzanineFieldLabel";
import PiecesCombosListingRenderTable from "./PiecesCombosListingRenderTable";
import PiecesTable from "./PiecesTable";
import SpaceSizeFieldLabel from "./SpaceSizeFieldLabel";
import { TermsConditionSection } from "./TermsConditionSection";
import TrafficFlowInfoSection from "./TrafficFlowInfoSection";

type ListingRendererProps = {
  versionDoc: VersionDoc;
  allListings: Listing[] | null;
  lookups: any;
  //orgId: keyof Themes;
  isPublic: boolean;
  isRealtor: boolean;
};
export default function ListingRenderer({
  versionDoc,
  allListings,
  lookups,
  //orgId,
  isPublic = false,
  isRealtor = false,
}: ListingRendererProps) {
  const [indexOfOpenImage, setIndexOfOpenImage] = useState<number | null>(null);
  const { jurisdictionLookups } = useListingStore();
  const openLightbox = useCallback((n: number) => {
    setIndexOfOpenImage(n);
  }, []);
  const { mapRegion } = versionDoc;
  const { projectId } = settings.firebaseConfig;

  const isSpaceListing = isSpaceMarketSegment(versionDoc.listingType);

  const showSpaceSection =
    isSpaceListing ;

  const showTrafficFlows =
    !!versionDoc?.trafficFlow &&
    versionDoc.trafficFlow.showTrafficFlow &&
    !!versionDoc.trafficFlow.trafficFlowInfoYears &&
    versionDoc.trafficFlow.trafficFlowInfoYears.length > 0;

  const showParking =
    isRetailOrOfficeSpace(versionDoc.listingType) && !!versionDoc?.parking;

  const showMaterialHandlingSection: boolean =
    versionDoc?.cranes?.exists === true;
  const showCraneFields: boolean = versionDoc?.cranes?.exists === true;

  const isSpaceDeveloped = versionDoc?.location?.isDeveloped;
  const isMezzanineIncluded = !!versionDoc?.space?.mezzanineIncluded;
  const isNotOfficeSpace = versionDoc.listingType !== LISTING_TYPE.OFFICE_SPACE;

  const numOfPieces = versionDoc.pieces
    ? getNumberOfPieces(versionDoc.pieces, isSpaceListing)
    : 0;

  const  showSpacesMap = versionDoc.pieces?.showMap && numOfPieces > 0;

  const showPiecesTable = numOfPieces > 1;
  const showComboTable =
    showPiecesTable && !!versionDoc?.pieces?.canPiecesBeCombined;

  const showCellTowerSection =
    !!versionDoc?.cellTower && !!versionDoc?.cellTower?.cellTowerSiteIsOffered;

  function closeLightbox() {
    setIndexOfOpenImage(null);
  }
  const images2 =
    versionDoc.images &&
    versionDoc.images.map((i) => ({
      //src: `/blob/${i.fullImageName}_2400.jpg`,
      source: `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${versionDoc.listingId}%2F${i.fullImageName}?alt=media`,
    }));

  return (
    <Page>
      <Wrapper>
        {mapRegion && (
          <Box gridColumn="col / span 1">
            <LocusImage mapRegion={mapRegion} />
          </Box>
        )}
        <Box gridColumn="col 2 / span 4">
          <ListingTypeLabel>
            {getListingTypeFriendlyText(versionDoc.listingType)}
          </ListingTypeLabel>
          <Heading>{getShortAddress(versionDoc)}</Heading>
          <HeadingTwo>
            {versionDoc.location.jurisdiction &&
              jurisdictionLookups &&
              getJurisdictionLongNameById(
                versionDoc.location.jurisdiction,
                jurisdictionLookups
              )}
          </HeadingTwo>
          <HeadingThree>{versionDoc.location.subdivision}</HeadingThree>
        </Box>
        <Line gridColumn="col 2 / span 4"></Line>
        {/*****************************************/}
        {/***** LAND ******************************/}
        {/*****************************************/}
        <Box gridColumn="col 1 / span 1">
          <Title>Land</Title>
        </Box>
        <Box gridColumn="col 2 / span 4">
          <StringFieldLabel
            value={versionDoc?.location?.park}
            field={fields.park}
          />
          <StringFieldLabel
            value={versionDoc?.location?.locus}
            field={fields.locus}
          />
          <StringFieldLabel
            value={versionDoc?.space?.projectName}
            field={fields.projectName}
          />
          <StringFieldLabel
            value={versionDoc.districts}
            field={fields.zoning}
          />
          <BooleanFieldLabel
            value={versionDoc.lotIsContiguous}
            field={fields.lotIsContiguous}
            hideIfTrue
          />
          <LandSurveyorDescriptionFieldLabel
            value={versionDoc.landSurveyorDescription}
            field={fields.landSurveyorDescription}
          />
          {/* {!showAvailableSpaces && (
            <>
              {!!versionDoc?.location?.isUrban && (
                <LegalDescriptionFieldLabel
                  value={versionDoc.legalDescription}
                  field={fields.legalDescription}
                />
              )}

              <StringFieldLabel
                value={versionDoc.availability?.linc}
                field={fields.linc}
              />
              <BooleanFieldLabel
                value={versionDoc.rezoningApplicationPending}
                field={fields.rezoningApplicationPending}
                hideIfFalse
              />
              <StringFieldLabel
                value={versionDoc.proposedLandUseDistrict}
                field={fields.proposedLandUseDistrict}
              />
            </>
          )} */}
          <StringFieldLabel
            value={versionDoc.taxRollNumber}
            field={fields.taxRollNumber}
          />

          <StringFieldLabel
            value={versionDoc.location?.locationRemarks}
            field={fields.locationRemarks}
            isMarkdown
            hideLabel
          />
        </Box>
        <Line gridColumn="col 2 / span 4"></Line>
        {/******************************************/}
        {/******** BUILDING ************************/}
        {/******************************************/}
        {!!versionDoc.structuresOnSite &&
          !!versionDoc.numberOfBuildings &&
          versionDoc.numberOfBuildings >= 1 && (
            <>
              <Box gridColumn="col 1 / span 1">
                <Title>Building</Title>
              </Box>
              <Box gridColumn="col 2 / span 4">
                <NumberFieldLabel
                  value={versionDoc.majorBuildingsAreaSum}
                  field={fields.majorBuildingsAreaSum}
                  isBuilding
                />
              </Box>
              <Line gridColumn="col 2 / span 4"></Line>
            </>
          )}
        {/******************************************/}
        {/******** AVAILABLITY *********************/}
        {/******************************************/}
        {!!versionDoc.availability && (
          <AvailabilitySection
            availability={versionDoc.availability}
            pieces={versionDoc.pieces || { mapInfo: null, image: null }}
            hideContactInfo={isRealtor}
            showTitle={true}
            hideTitle={true} //TODO: Fix this
            listingId={versionDoc.listingId}
            lookups={lookups}
            isSpaceListing={isSpaceListing}
          />
        )}
        {/******************************************/}
        {/******** CELL TOWER *********************/}
        {/******************************************/}
        {showCellTowerSection && (
          <>
            <Box gridColumn="col 1 / span 1">
              <Title>Cell Tower</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <BooleanFieldLabel
                value={versionDoc?.cellTower?.cellTowerSiteIsOffered}
                field={fields.cellTowerSiteIsOffered}
              />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}

        {/******************************************/}
        {/******** SPACE ***************************/}
        {/******************************************/}

        {showSpaceSection && (
          <>
            <Box gridColumn="col 1 /span 1">
              <Title>Space</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <StringFieldLabel
                label="Space Size"
                value={versionDoc.pieces ? getAreasForPieces(versionDoc.pieces, isSpaceListing) : ""}

              />
              <NumberFieldLabel
                value={versionDoc?.space?.numberOfFloors}
                field={fields.numberOfFloors}
              />
              {!isSpaceDeveloped && (
                <NumberFieldLabel
                  value={versionDoc?.space?.budgetedOfficeArea}
                  field={fields.budgetedOfficeArea}
                  isPercent
                />
              )}
              {isSpaceDeveloped && (
                <NumberFieldLabel
                  value={versionDoc?.space?.existingOfficeArea}
                  field={fields.existingOfficeArea}
                  isBuilding
                />
              )}

              <NumberFieldLabel
                value={versionDoc?.space?.tenantImprovementAllowance}
                field={fields.tenantImprovementAllowance}
                isCurrencyRate
              />

              <StringFieldLabel
                value={versionDoc?.space?.buildingClass}
                field={fields.buildingClass}
              />

              {isMezzanineIncluded && (
                <MezzanineFieldLabel
                  size={versionDoc.space?.officeStorageArea}
                  purpose={versionDoc?.space?.mezzanineFunction}
                />
              )}

              {isNotOfficeSpace && (
                <NumberFieldLabel
                  value={versionDoc?.space?.baySize}
                  field={fields.baySize}
                  isBuilding
                />
              )}

              <NumberFieldLabel
                value={versionDoc?.space?.ceilingClearanceUnderJoist}
                field={fields.ceilingClearanceUnderJoist}
                isHeight
              />
              <BooleanFieldLabel
                value={versionDoc?.space?.fireSuppressionSystem}
                field={fields.fireSuppressionSystem}
              />
              <StringFieldLabel
                value={versionDoc?.space?.driveThrough}
                field={fields.driveThrough}
              />
              <StringFieldLabel
                value={versionDoc?.space?.loadingDock}
                field={fields.loadingDock}
              />
              <FileFieldLabel
                value={versionDoc?.space?.siteFloorPlans}
                field={fields.siteFloorPlans}
                listingId={versionDoc.listingId}
              />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}
        {/******************************************/}
        {/******** TERMS & CONDITIONS **************/}
        {/******************************************/}
        <TermsConditionSection
          tradingCategory={versionDoc.availability?.tradingCategory || []}
          termsConditions={versionDoc?.termsConditions}
          isMezzanineIncluded={isMezzanineIncluded}
        />
        {/******************************************/}
        {/******** Material Handling **************/}
        {/******************************************/}

        {showMaterialHandlingSection && (
          <>
            <Box gridColumn="col 1 /span 1">
              <Title>Material Handling</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <BooleanFieldLabel
                value={versionDoc?.cranes?.exists}
                field={fields.craneExists}
              />
            </Box>
            {showCraneFields && (
              <CranesListingSection cranes={versionDoc?.cranes} />
            )}
          </>
        )}
        {/******************************************/}
        {/******** Parking ************************/}
        {/******************************************/}
        {showParking && (
          <>
            <Box gridColumn="col 1 /span 1">
              <Title>Parking</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <NumberFieldLabel
                value={versionDoc?.parking?.numberOfStalls}
                field={fields.parkingNumStalls}
              />
              <NumberFieldLabel
                value={versionDoc?.parking?.stallsPer1000}
                field={fields.parkingStallsPer1000}
              />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}
        {/******************************************/}
        {/******** Traffic Flows ************************/}
        {/******************************************/}
        {showTrafficFlows && (
          <>
            <Box gridColumn="col 1 /span 1">
              <Title>Traffic Flow</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <TrafficFlowInfoSection
                trafficFlowInfoYears={
                  versionDoc?.trafficFlow?.trafficFlowInfoYears
                }
              />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}
        <Anchor name="map"></Anchor>
      </Wrapper>
      {/******************************************/}
      {/******** Map ************************/}
      {/******************************************/}
      <ListingMap
        isEditor={false}
        land={versionDoc}
        listings={allListings?.map((l) => ({
          ...l.listingInfo,
          listingType: l.listingType,
          orgId: l.orgId,
          status: l.status,
          lastSaveUserId: l.lastSave.uid,
        }))}
        currentPage={versionDoc}
        isPiecesMap={false}
      />

      <PhotoWrapper>
        <MediaHeader>Photos</MediaHeader>
        {versionDoc.images &&
          versionDoc.images.map((img, n) => {
            return (
              <ImgViewer300
                listingId={versionDoc.listingId}
                fullImageName={img.fullImageName}
                onClick={() => openLightbox(n)}
                key={`img_${n}`}
              />
            );
          })}

        {!versionDoc.images && <div>No images taken.</div>}

        {!!versionDoc.video1 && (
          <>
            <Anchor name="videos"></Anchor>
            <MediaHeader>Videos</MediaHeader>
            <YouTube
              videoId={versionDoc.video1}
              opts={{
                height: "390",
                width: "640",
                playerVars: {},
              }}
              // onReady={this._onReady}
            />
          </>
        )}

        <ModalGateway>
          {indexOfOpenImage !== null && !!images2 ? (
            <Modal onClose={closeLightbox}>
              <Carousel views={images2} currentIndex={indexOfOpenImage} />
            </Modal>
          ) : null}
        </ModalGateway>
      </PhotoWrapper>

      <Wrapper>
        {showPiecesTable && versionDoc.pieces && (
          <>
            <Line gridColumn="col 2 / span 4"></Line>
            <Box gridColumn="col 1 / span 1">
              <Title>{isSpaceListing ? "Available Spaces" : "Lots"}</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <PiecesTable
                pieces={versionDoc.pieces}
                isSpaceListing={isSpaceListing}
              />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}

        {showComboTable && versionDoc.pieces &&  (
          <>
            <Box gridColumn="col 1 / span 1">
              <Title>Configurations</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
            <PiecesCombosListingRenderTable
            pieces={versionDoc.pieces}
            isSpaceListing={isSpaceListing}
          />
            </Box>
            <Line gridColumn="col 2 / span 4"></Line>
          </>
        )}
        {versionDoc?.pieces?.image && (
          <>
            <Box gridColumn="col 1 / span 1">
              <Title>Site Map</Title>
            </Box>
            <Box gridColumn="col 2 / span 4">
              <Image
                src={`https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${versionDoc.listingId}%2F${versionDoc.pieces.image}?alt=media`}
              />
            </Box>
          </>
        )}
      </Wrapper>
       {showSpacesMap && (
        <ListingMap
          isEditor={false}
          land={versionDoc}
          listings={allListings?.map((l) => ({
            ...l.listingInfo,
            listingType: l.listingType,
            orgId: l.orgId,
            status: l.status,
            lastSaveUserId: l.lastSave.uid,
          }))}
          currentPage={versionDoc}
          isPiecesMap={true}
        />
      )} 
    </Page>
  );
}
type AnchorProps = { name: string };
const Anchor = styled.a<AnchorProps>`
  position: relative;
  top: -69px;
  visibility: hidden;
`;
const LocusImage = ({ mapRegion }: { mapRegion: string }) => {
  return (
    <img height="150px" src={`/images/${mapRegion}.png`} alt={mapRegion} />
  );
};

const ListingTypeLabel = withTheme(styled.div`
  color: ${(props) => props.theme.palette.primary[600]};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`);
const Heading = styled.h1`
  font-weight: 400;
  font-size: 28px;
  margin-top: 4px;
  margin-bottom: 5px;
`;
const HeadingTwo = withTheme(styled.div`
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary[600]};
`);
const HeadingThree = styled.div`
  font-size: 16px;
  font-weight: lighter;
  margin: 0px;
  line-height: 30px;
`;
const MediaHeader = withTheme(styled.div`
  border-top: 1px solid rgb(235, 235, 235);
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.primary[600]};
`);
const Page = styled.div`
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  padding: 20px 0px 200px 0px;
`;
const PhotoWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  max-width: 1020px;
  margin: auto;
  left: 0;
  right: 0;
`;
const Wrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: grid;
  grid-template-columns: repeat(5, [col] 196px);
  grid-template-rows: repeat(16, [row] auto);
  grid-gap: 5px;
  color: #444;
  max-width: 1020px;
  margin: auto;
  left: 0;
  right: 0;
`;

const Image = styled.img`
  max-width: 100%;
`;
