import HeaderBuilder from "components/HeaderBuilder";
import React from "react";
import styled from "styled-components";
import { Themes } from "themes";

type Props = {
  listOwnerOrgId: keyof Themes
}

const ListingHeader = ({listOwnerOrgId}: Props) => {
  return (
    <HeaderBuilder listOwnerOrgId = {listOwnerOrgId} >
      <RightCell>
        <MenuWrapper>
          <MenuItem href="#overview">Overview</MenuItem>
          <Spacer>•</Spacer>
          <MenuItem href="#map">Map</MenuItem>
          <Spacer>•</Spacer>
          <MenuItem href="#photos">Photos</MenuItem>
          <Spacer>•</Spacer>
          <MenuItem href="#videos">Videos</MenuItem>
        </MenuWrapper>
      </RightCell>
    </HeaderBuilder>
  );


};

const MenuWrapper = styled.div`
  width: 1020px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: auto;
  left: 0;
  right: 0;
  padding-left: 5px;
`;
const RightCell = styled.div`
  flex: 1;
  text-align: right;
`;

const MenuItem = styled.a`
  vertical-align: middle;
  font-weight: 500;
  text-decoration: none;
  color: rgb(72, 72, 72);
`;

const Spacer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;


export default ListingHeader;
