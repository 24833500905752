import { withTheme } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import ListingPanel from "components/ListingPanel";
import React, { useState } from "react";
import styled from "styled-components";
//import allLand from "../data/allLand";
import { createSearchFieldsFromSearchDoc, filterLots, getFriendlyQueryText } from "../common/newHelperFunctions";
import useListingStore from "../store/useListingStore";
import usePublicStore from "../store/usePublicStore";
import ListMapControl from "./components/ListMapControl";
import PublicHeader from "./components/PublicHeader";
import Page404 from "./Page404";

type Props = {
  userId?: string;
  searchId?: string;
  hideContactInfo?: boolean;
} & RouteComponentProps;

const PublicListingPage = ({ userId, searchId, hideContactInfo = true}: Props ) => {

  if(!userId || !searchId){
    throw new Error('invalid props')
  }
  const {searchDoc, isNotFound} = usePublicStore(userId, searchId);
  
  const { state } = useListingStore();
  const [mapState, setMapState] = useState<{selectedMarkerId: string | null}> ({
    selectedMarkerId: null,
  });
  if (isNotFound) {
    return <Page404 />;
  }
  if (!searchDoc || !state) {
    return <h3>loading</h3>;
  }

  const searchFields = createSearchFieldsFromSearchDoc(searchDoc);
 
    const listingInfos = state.listings
    //.map((l) => l.listingInfo)
    .filter((i) => filterLots(i, searchFields))
    .map((l) => ({
      ...l.listingInfo,
      status: l.status,
      listingType: l.listingType,
      orgId: l.orgId,
      lastSaveUserId:l.lastSave.uid,
    }));
    return (
      <ListMapControl
      mode='realtor'
      listings={listingInfos}
      mapState={mapState}
      setMapState={setMapState}
      hideMap={ false}
      canUserEdit={false}
      canUserSearch={false}
    >
       <PublicHeader /> 
       <ListingPanel
         listings={listingInfos}
         selectedItemId={mapState.selectedMarkerId}
         isPublic={true}
         isRealtor={false}
         rowItemClick={(id) => {
          setMapState((s) => ({
             ...s,
            selectedMarkerId: id
           }));
         }}
         rowItemHover={id => {}}
         shouldShowEditButton={(orgId) => false}
         
       >
        <PublicListingHeader
          queryText={getFriendlyQueryText(
            searchFields.marketSegment,
            searchFields.areaSliderOrdinal,
            Object.keys(searchFields.mapRegions).filter(
              (key) => !!searchFields.mapRegions[key]
            ),
            searchFields.isMajorStructures,
            searchFields.isNoMajorStructures,
            true
          )}
        />
       </ListingPanel>
    </ListMapControl>
    )

};
const PublicListingHeader = ({ queryText } : {queryText:string | JSX.Element  }) => {
  return (
    <HeaderContainer>
      <QueryText>{queryText}</QueryText>
    </HeaderContainer>
  );
};
const HeaderContainer = withTheme(styled.div`
  padding: 30px;

`);
const QueryText = styled.div`
  font-size: 18px;
  color: #777;
    line-height: 25px;
    & > strong {
        color: #000;
        font-weight:500;
    }
`;
export default PublicListingPage;
