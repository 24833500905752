import { Paper, Toolbar } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import styled from "styled-components";
import firebase from "../config/firebase";
import PageHeader from "./components/PageHeader";

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

const WelcomePage = (props: RouteComponentProps) => {
  return (
    <>
      <PageHeader />
      <WelcomeBackground>
        <Toolbar />
        <div style={{ marginTop: 100 }}>
          <LoginPaper>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </LoginPaper>
        </div>
      </WelcomeBackground>
    </>
  );
};

export default WelcomePage;

const LoginPaper = styled(Paper)`
  width:400px;
  padding:40px;
  text-align:center;
  @media (min-width: 1400px) {
    margin-left: 70%;
  }
  @media (max-width: 1400px) {
    margin:auto;
  }
`;


const WelcomeBackground = styled.div`
  position: relative;
  overflow: hidden;
  opacity: 0.99;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(https://heuristic-ride-308dde.netlify.com/static/cbcab0bc37f0cb0fa0992a218b7f47bc/93223/airport.webp);
  height: 100%;
  opacity: 1;
`;
// /*
// const WelcomeBanner = () => {
//   const {user} = useContext(AppContext);
//   return (
//     <Container>
//       <h1>Find industrial land in Metro Edmonton.</h1>
//       <div>
//         {!!user ? (<Button to="/">Search</Button>) : (<Button to="/about">Learn More</Button>)}

//       </div>
//     </Container>
//   );
// }; */

// const Container = styled.div`
//   background-color: #fff;
//   height: 200px;
//   width: 500px;
//   margin-top: 160px;
//   margin-left: 80px;
//   border-radius: 4px;
//   padding: 32px;
//   box-shadow: rgba(0, 0, 0, 0.12) 0px 16px 40px !important;
//   font-family: Montserrat;
//   & > h1 {
//     font-size: 30px;
//     font-weight: 700;
//     margin-top: 0;
//   }
//   & > div {
//   }
// `;

// const Page = styled.div`
//   max-width: 1800px;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 0 20px;
// `;

// const Button = styled(Link)`
//   width: 150px;
//   height: 48px;
//   font-weight: 600px;
//   background-color: rgb(255, 90, 95);
//   padding: 0 6px;
//   font-family: inherit;
//   font-weight: 600;
//   font-size: 16px;
//   box-shadow: none;
//   border-radius: 4px;
//   border-style: initial;
//   color: #fff;
//   float: right;
//   text-decoration: none;
//   text-align: center;
//   line-height: 48px;
// `;

// export default WelcomePage;
