import { Toolbar } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "../config/firebase";
import PageHeader from "./components/PageHeader";




const LoginPage = ({redirect = "/"}: {redirect?:string} & RouteComponentProps  ) => {

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: redirect,
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, 
      firebase.auth.EmailAuthProvider.PROVIDER_ID]
  };
  return (
    <>
      <PageHeader  />
      <Toolbar />
      <div style={{ marginTop: 100 }}>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </>
  );
};

export default LoginPage;
