import {
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ShareIcon from "@material-ui/icons/Share";
import { getFriendlyDateWithTime } from "common/helperFunctions";
import { firestore } from "firebase";
import React from "react";
import styled from "styled-components";
type SearchPanelHeaderProps = {
  queryText: string | JSX.Element;
  closeClick(): void;
  refineSearchClick(): void;
  shareSearchClick(): void;
  clientName:string | void;
  clientNameChange(value:string):void;
  clientNameSave():void;
  dateSaved?: firestore.Timestamp;
};

export default function SearchPanelHeader({
  queryText,
  closeClick,
  refineSearchClick,
  shareSearchClick,
  clientName,
  clientNameChange,
  clientNameSave,
  dateSaved
}: SearchPanelHeaderProps) {
  const classes = useStyles();
  return (
    <Container>
      <CloseButton
        aria-label="Close Search"
        edge="end"
        onClick={closeClick}
        color="primary"
      >
        <CloseIcon />
      </CloseButton>
      <FlexDiv>
        <QueryText> {queryText}</QueryText>
        <ClientNameDiv>
          <TextField
            placeholder="Client Name"
            variant="outlined"
            label="Client Name"
            size="small"
            value={clientName || ""}
            onChange={e => clientNameChange(e.target.value) }
            onBlur={() => clientNameSave()}
            fullWidth
          />
        </ClientNameDiv>
      </FlexDiv>
      <ButtonHolder>
        <Button
          size="small"
          className={classes.button}
          startIcon={<EditIcon />}
          onClick={refineSearchClick}
        >
          Refine Search
        </Button>
        <Button
          size="small"
          className={classes.button}
          startIcon={<ShareIcon />}
          onClick={shareSearchClick}
        >
          Share
        </Button>
        <SaveDiv>{dateSaved && getFriendlyDateWithTime(dateSaved.toDate())}</SaveDiv>
      </ButtonHolder>
    </Container>
  );
}
const FlexDiv = styled.div`
  display: flex;
`;
const QueryText = styled.div`
  padding: 24px 12px 12px 24px;
  color: #777;
  line-height: 25px;
  flex:1;
  & > strong {
    color: #000;
    font-weight: 500;
  }
`;
const ClientNameDiv = styled.div`
  width: 280px;
  padding: 24px 50px 12px 12px;
`;

const Container = styled.div`
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 10px;
  top: 0px;
`;
const ButtonHolder = styled.div`
  padding-left: 32px;
  display:flex;
  padding-right:24px;
  align-items:center;
`;

const SaveDiv = styled.div`
  flex:1;
  text-align:right;
  font-size:13px;
  font-style: italic;
  padding-right:24px;
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      color: "#666",
    },
  })
);
