import {  withTheme } from "@material-ui/core";
import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { LISTING_TYPE } from "types/Types";
import AddButtonMenu from "./controls/AddButtonMenu";

type MainTabsProps = {
  selectedTab: "search" | "editor";
  showSearch: boolean;
  showEdit: boolean;
  addNewClick?(listingType: LISTING_TYPE): void;
  overrideIsAddOpen: boolean;
  
};

export default function MainTabs({
  selectedTab,
  showSearch,
  showEdit,
  addNewClick,
  overrideIsAddOpen,
}: MainTabsProps) {
  return (
    <Container>
      <TabHolder>
        {showSearch && (
          <MKTab to="/" selected={selectedTab === "search"} id="main-tabs-search">
            Search
          </MKTab>
        )}
        {showEdit && (
          <MKTab to="/editor" id="main-tabs-posting-editor" selected={selectedTab === "editor"}>
            Posting Editor
          </MKTab>
        )}
      </TabHolder>
      {!!addNewClick && (
        <AddButtonMenu
        onNewClick={addNewClick}
        overideIsOpen={overrideIsAddOpen}
      /> 
        // <StyledButton variant="contained" color="primary" onClick={addNewClick}>
        //   Add
        // </StyledButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  margin-bottom: 32px;
  margin-right: 20px;
  margin-left: 20px;
`;

type TabType = {
  selected: boolean;
  theme: any;
};

const TabHolder = withTheme(styled.div`
  border-bottom: 1px solid #ccc;
  margin-right: 20px;

  display: flex;
  flex: 1;
`);

const MKTab = withTheme(styled(Link)<TabType>`
  color: ${(props) =>
    props.selected ? props.theme.palette.primary[600] : "#777"};
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  margin-right: 32px;
  padding: 6px 12px;
  min-height: 48px;
  text-align: center;
  line-height: 1.75;
  display: inline-block;
  border-bottom: 2px solid
    ${(props) =>
      props.selected ? props.theme.palette.primary[600] : "#f6f7f9"};

  &:hover {
    color: ${(props) => props.theme.palette.primary[600]};
    opacity: 1;
  }
`);
