import { withTheme } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';


export default function IsRequiredText ({isRequired} : {isRequired:boolean | undefined}) {
    if(!isRequired){
        return null;
    }
    return <Container>Required</Container>;
}

const Container = withTheme(styled.div`
    color:${(props) => props.theme.palette.primary.dark};
    font-size:12px;
    padding: 5px 0 0 10px;
`);

