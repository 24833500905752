import { IconButton, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { getFriendlyDateWithTime } from "common/helperFunctions";
import {
  getFriendlyQueryText,
  getMarketSegmentFromText
} from "common/newHelperFunctions";
import React, { useState } from "react";
import styled from "styled-components";
import { createSearchObject } from "../../common/newHelperFunctions";
import Button from "./controls/Button";
import NewSearchButton from "./controls/NewSearchButton";
import Snackbar from "./controls/SnackBar";
const SavedSearchesForm = ({
  closeForm,
  deleteSearch,
  saveSearch,
  savedSearches,
  setSearchFields,
  newSearchClick,
}) => {
  //useLockBodyScroll();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [lastDeleted, setLastDeleted] = useState(null);
  //const {isPrelaunch} = useContext(AppContext);
  //const showPrelaunchMessage = isPrelaunch;


  if (!savedSearches) return null;
  const filteredSavedSearches = savedSearches.filter((doc) => !doc.isDeleted);

  function setSearch(
    searchId,
    clientName,
    marketSegment,
    searchAreaOrdinal,
    mapRegions,
    isMajorStructures,
    isNoMajorStructures,
    dateSaved
  ) {
    const newSearch = createSearchObject(
      searchId,
      clientName,
      getMarketSegmentFromText(marketSegment),
      searchAreaOrdinal,
      mapRegions,
      isMajorStructures,
      isNoMajorStructures,
      dateSaved
    );
    setSearchFields(newSearch);
    closeForm();
  }
  const handleDelete = (doc) => {
    if (window.confirm("Are you sure you want to delete this search?")) {
      deleteSearch(doc);
      setLastDeleted(doc);
      setIsSnackbarOpen(true);
    }
  };
  const undoDelete = () => {
    if (lastDeleted) {
      saveSearch({ ...lastDeleted, isDeleted: false });
      setIsSnackbarOpen(false);
    }
  };

  // if (!!showPrelaunchMessage) {
  //   return (
  //     <PrelaunchMessage  elevation={2}>
    
  //         <h1>Search Functionality Disabled</h1>
  //         <p>During prelaunch, search functionality is disabled. </p>


  //     </PrelaunchMessage>
  //   );
  // }

  if (filteredSavedSearches.length === 0) {
    return (
      <Container  elevation={2}>
        <div style={{ padding: 30 }}>You have no saved searches.</div>
        <div style={{ padding: 30 }}>
          <NewSearchButton onClick={newSearchClick} />
        </div>
      </Container>
    );
  }


  return (
    <Container elevation={2}>
      <HeaderDiv>
        <NewSearchButton onClick={newSearchClick} />
      </HeaderDiv>
      <TableHolder>
        <Table>
          <TableBody>
            {filteredSavedSearches
              .sort((a, b) => b.dateSaved.seconds - a.dateSaved.seconds)
              .map((row, n) => (
                <TableRow key={`row_${n}`}>
                  <TableCell component="th" scope="row">
                    <Button
                      noMargin
                      onClick={() =>
                        setSearch(
                          row.searchId,
                          row.clientName,
                          row.marketSegment,
                          row.area.ordinal,
                          row.mapRegions,
                          row.isMajorStructures,
                          row.isNoMajorStructures,
                          row.dateSaved
                        )
                      }
                    >
                      Open
                    </Button>
                  </TableCell>

                  <TableCell align="left">
                    {getFriendlyQueryText(
                      row.marketSegment,
                      row.area.ordinal,
                      row.mapRegions,
                      row.isMajorStructures,
                      row.isNoMajorStructures,
                      true
                    )}
                  </TableCell>
                  <ClientTableCell >
                  {row.clientName && row.clientName.length > 0 && <ClientNameDiv>{row.clientName}</ClientNameDiv>}
                   <DateSavedDiv>{!!row.dateSaved && getFriendlyDateWithTime(row.dateSaved.toDate())}</DateSavedDiv> 
                    
                  </ClientTableCell>
                  <ButtonTableCell align="right">
                    <IconButton
                      aria-label="delete saved search"
                      color="secondary"
                      onClick={() => handleDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ButtonTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableHolder>
      <Snackbar
        isSnackbarOpen={isSnackbarOpen}
        setIsOpen={(isOpen) => setIsSnackbarOpen(isOpen)}
        variant="info"
        message="Search Deleted"
        handleUndo={() => undoDelete()}
      />
    </Container>
  );
};

export default SavedSearchesForm;
const Container = styled(Paper)`
  margin:0 16px 0 8px;
`;
// const PrelaunchMessage = styled(Paper)`
//   margin:0 16px 0 8px;
//   padding:30px;
//   & > h1{
//     font-size:18px;
//   }
// `;
const HeaderDiv = styled.div`
  background-color: #fafafa;
  text-align: right;
  padding: 16px;
  border-bottom:1px solid #e0e0e0;
`;

const TableHolder = styled.div`
  padding: 16px;
`;


const ClientNameDiv = styled.div`
  min-width:150px;
`;
const DateSavedDiv = styled.div`
  font-size:11px;
`;

const ButtonTableCell = styled(TableCell)`
  padding:0 !important;
`
const ClientTableCell = styled(TableCell)`
  vertical-align:top !important;
`