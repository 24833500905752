import React from "react";
import { Light, Strong } from "./ListingPageStyledComponents";

const ContactRow = ({
  label,
  contactName,
  email,
  phone,
  topMargin = false,
}: {
  label?: string;
  contactName?: string;
  email?: string;
  phone?: string;
  topMargin?: boolean;
}) => {
  if (!contactName) {
    return <></>;
  }
  const noEmail = !email || email.trim().length == 0;
  const noPhone = !phone || phone.trim().length == 0;

  const contactInfoArray = [];
  if (!noPhone) {
    contactInfoArray.push(`${phone}`)
  }
  if (!noEmail) {
    contactInfoArray.push(`${email}`)
  }


  return (<div style={{ marginTop: topMargin ? 16 : 0 }}>
    <Light> {contactName} {label && `(${label})`} </Light>
    <Strong>
        {contactInfoArray.join(" • ")}
    </Strong>
  </div>

  )

  // const contactInfoArray = [ !noEmail && email, !noPhone && phone];
  // console.log(contactInfoArray)

  // if(!noEmail){
  //   return (
  //     <div style={{marginTop: topMargin ? 16 : 0}}>
  //     <Light>{label} </Light>
  //     <Strong>
  //       {contactName} {(email || phone) && "—"} <i>{email || phone}</i>
  //     </Strong>
  //   </div>
  //   )
  // }

  // return (
  //   <div style={{marginTop: topMargin ? 16 : 0}}>
  //     <Light>{label} </Light>
  //     <Strong>
  //       {contactName} {(email || phone) && "—"} <i>{email || phone}</i>
  //     </Strong>
  //   </div>
  // );
};

export default ContactRow;
