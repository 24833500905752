import React from "react";
import { Light, Strong } from "./ListingPageStyledComponents";
import { BoolField } from "types/fieldComponentTypes";


type Props = {
  value: boolean | undefined;
  field: BoolField;
  isBuilding?: boolean;
  hideIfTrue?: boolean;
  hideIfFalse?:boolean;
};

export default function BooleanFieldLabel({ field, value, isBuilding, hideIfTrue, hideIfFalse }: Props) {
  if(value === undefined){
    return null;
  }
  const boolValue = value ? "Yes" : "No";
  if ((hideIfTrue && value) || (hideIfFalse && !value)) return null;
  return (
    <p>
      <Light>{field.label}</Light>
      <Strong>{boolValue}</Strong>
    </p>
  )
}

