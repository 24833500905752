import { RouteComponentProps } from "@reach/router"
import React from "react"
import styled from "styled-components"
import Template from "./Template"
export default function PrivacyPolicy({}: RouteComponentProps) {
  return (

      <Template>
        <p style={{textAlign:"center"}}>
          <h1>Privacy and Data Collection Policy</h1>
          <strong>
            IF YOU DO NOT AGREE WITH THIS PRIVACY AND DATA COLLECTION POLICY,
            <br />
            YOU MUST NOT USE THIS WEBSITE.
          </strong>
        </p>
        <p>
          <strong>
            This Privacy and Data Collection Policy lets you know how GSL
            collects and uses your Personal Information and how you can control
            its use.
          </strong>
        </p>
        <h2>1. General</h2>
        <OL>
          <li>
            <strong>Purpose</strong>. The purpose of this privacy and data
            collection policy (“<strong>Privacy Policy</strong>”) is to provide
            for the collection, use, and disclosure of personal information by
            us in a manner that recognizes both the right of an individual to
            have personal information protected and our need to collect, use, or
            disclose personal information for purposes that are reasonable.
          </li>
          <li>
            <strong>GSL</strong>. GSL processes Personal Information (as defined
            below) in the course of its business, including the Personal
            Information of people using and browsing GSL websites (the “Site”)
            and providing information to GSL via the Site.
          </li>
          <li>
            <strong>Personal Information</strong>. Personal information (“{" "}
            <strong>Personal Information</strong>”) means any information that
            may result in the identification of an individual.{" "}
            <strong>Non-Personal Information</strong> means any information that
            does not result in the identification of an individual.
          </li>
          <li>
            <strong>Online and offline collection</strong>. GSL collects
            Personal Information online and offline (for example, via a business
            card voluntarily given to a GSL employee or director) and this
            Privacy Policy is applicable regardless of the means of collection.
          </li>
          <li>
            <strong>Data controller</strong>. The individual (the “Data
            Controller”) responsible for ensuring GSL’s compliance with our Data
            Policies may be contacted <a href="mailto:samuel@gosololand.com">here</a>
           
            . The Data Controller is responsible for responding to access
            requests to Personal Information and all such requests will be
            referred to him.
            <a>
              On written request and appropriate identification satisfactory to
              GSL, you will be advised of Personal Information about you
              retained in GSL’s records. Where information cannot be disclosed
              (for example the information contains reference to other
              individuals) you will be given reasons for non-disclosure
            </a>.
          </li>
          <li>
            <strong>Service providers</strong>. We may use third-party service
            providers outside of Canada to collect, use, disclosure, or store
            Personal Information on our behalf. Information regarding these
            jurisdictions and the purposes for which the third-party service
            provider has been authorized to collect, use, or disclose Personal
            Information can be found <a href="https://firebase.google.com/support/privacy">here</a>.
          </li>
          <li>
            <strong>Terms of Use</strong>. The Site’s Terms of Use, which can be
            found <a href="/terms">here</a>
 
            , are incorporated by reference into this Privacy Policy. Your use
            of the Sites and any Personal Information You provide to us are
            subject to the terms of this Privacy Policy and those Terms of Use.
          </li>
        </OL>
        <h2>2. Data Collection</h2>
        <OL>
          <li>
            <strong>Personal Information we collect</strong>. We collect your
            Personal Information in order to provide and improve our services.
            We collect the following types of personal information:
          </li>
          <OLLetter>
            <li>
              <strong>Information you give us</strong>: we collect any
              information you provide in relation to our services. You may
              choose not to provide certain information, but then we may not be
              able to provide you with our services.
            </li>
            <li>
              <strong>Automatic information</strong>: we automatically collect
              certain types of information about your use of our Site, such as
              information about your use, including your interaction with
              content and services available through the Site.
            </li>
            <li>
              <strong>Information from other sources</strong>: we may receive
              information about you from other sources, which we may use to
              correct our records.
            </li>
          </OLLetter>
          <li>
            <strong>
              Purpose of collecting and processing Personal Information
            </strong>
            . We process your personal information to provide and improve the
            services that we offer our users, including:
          </li>
          <OLLetter>
            <li>
              <strong>Provide services</strong>. We use your personal
              information to provide services, communicate with you about
              services and promotional offers, and provide information you have
              requested.
            </li>
            <li>
              <strong>Improving functionality and performance</strong>. We use
              your personal information to provide functionality, analyse
              performance, fix errors, and improve usability and effectiveness
              of the Site and our services.
            </li>
            <li>
              <strong>Recommendations and personalisation</strong>. We use your
              personal information to recommend services that might be of
              interest to you, identify your preferences, and personalise your
              experience.
            </li>
            <li>
              <strong>Comply with legal obligations</strong>. In certain cases,
              we collect and use your personal information to comply with laws.
            </li>
            <li>
              <strong>Communicate with you</strong>. We use your personal
              information to communicate with you in relation to our services,
              both through the channels you initiate communication with us and
              other channels (e.g., by phone, email, chat).
            </li>
            <li>
              <strong>Advertising</strong>. We use your personal information to
              display interest-based ads for services that might be of interest
              to you.
            </li>
            <li>
              <strong>Fraud prevention and credit risks</strong>. We process
              personal information to prevent and detect fraud and abuse in
              order to protect the security of our business and our users; we
              may also use scoring methods to assess and manage credit risks.
            </li>
            <li>
              <strong>Purposes for which we seek your consent</strong>.
              
                We may also ask for your consent to process your personal
                information for a specific purpose that we communicate to you.
              
              When you consent to our processing your personal information for a
              specified purpose, you may withdraw your consent at any time and
              we will stop processing of your data for that purpose.
            </li>
          </OLLetter>
          <li>
            <strong>Retention of Personal Information</strong>. We keep your
            Personal Information for as long as it is required in order to
            fulfil the relevant purposes described in this Privacy Policy, as
            may be required by law such as for tax and accounting purposes, or
            as otherwise communicated to you.
          </li>
          <li>
            <strong>Usage Data</strong>. We may also retain usage data for
            internal analysis purposes. Such usage data is generally retained
            for shorter periods, except when this data is used to enhance the
            security or functionality of this Site or when obligated by law.
          </li>
        </OL>
        <h2>3. Data Sharing</h2>
        <OL>
          <li>
            <strong>
              Transfer and hosting of Personal Information outside of Canada
            </strong>
            . Your Personal Information is processed by our operating office and
            in any other places where the parties involved in the processing are
            located. Your Personal Information may be transferred to and
            maintained on servers located outside of Canada where the data
            protection laws may differ.
            <strong>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer
            </strong>
            .
          </li>
          <li>
            <strong>Sharing of Personal Information</strong>. We are not in the
            business of selling our users' personal information to others. We
            may share your personal information only as described below and any
            affiliates that GSL controls that are either subject to this Privacy
            Policy or follow practices at least as protective as those described
            in this Privacy Policy.
          </li>
          <li>
            <strong>Recipients of shared Personal Information</strong>. We may
            share your Personal Information in the following circumstances:
          </li>
          <OLLetter>
            <li>
              <strong>With your consent. </strong>
              <a>
                We may disclose your Personal Information for any other purpose
                with your consent.
              </a>
            </li>
            <li>
              <strong>With members. </strong>If<strong> </strong>you are a GeX
              Member, we may disclose your Personal Information in accordance
              with your membership terms and conditions.
            </li>
            <li>
              <strong>Third party service providers</strong>. We may employ
              other companies and individuals to perform functions on our
              behalf. These third-party service providers have access to
              personal information needed to perform their functions but may not
              use it for other purposes, including providers of data hosting or
              processing services, or payment processors.
              <a>
                Further, they must process the personal information in
                accordance with this Privacy Policy and as permitted by
                applicable data protection laws.
              </a>
            </li>
            <li>
              <strong>Business transfers</strong>. We may sell or buy other
              businesses or services. In such transactions, user information
              generally is one of the transferred business assets but remains
              subject to the promises made in any pre-existing Privacy Policy
              (unless, of course, the user consents otherwise). In the event
              that GSL or substantially all of its assets are acquired, user
              information will be one of the transferred assets.
            </li>
            <li>
              <strong>Affiliates</strong>. We may share your information with
              our affiliates, in which case we will require those affiliates to
              adhere to a privacy and data collection policy that is at least as
              stringent as this Privacy Policy.
            </li>
            <li>
              <strong>Business partners</strong>. We may share your information
              with our business partners to offer you certain products, services
              or promotions.
            </li>
            <li>
              <strong>Protection of GSL and others</strong>. We release Personal
              Information when we believe release is appropriate to comply with
              the law; enforce or apply our Terms of Use and other agreements;
              or protect the rights, property or safety of GSL, our users, or
              others. This includes exchanging information with other companies
              and organizations for fraud protection and credit risk reduction.
            </li>
            <li>
              <strong>Notice in other circumstances</strong>. Other than as set
              out above, you will receive notice when personal information about
              you might be shared with third parties and you will have an
              opportunity to choose not to share the information.
            </li>
          </OLLetter>
        </OL>
        <h2>4. Types of Personal Information Collected</h2>
        <OL>
          <li>
            <strong>Examples of information collected</strong>. Information you
            give us when you use this Site may include the following:
          </li>
          <OLLetter>
            <li> your name;</li>
            <li> your business’s name;</li>
            <li>
              {" "}
              contact information for you and/or your business, including
            </li>
            <OLRoman>
              <li>phone numbers;</li>
              <li> email addresses;</li>
              <li> postal addresses;</li>
              <li> website addresses;</li>
            </OLRoman>
            <li> your business activities;</li>
            <li> the nature of your enquiry;</li>
            <li> information regarding your identity and standing;</li>
            <li> credit card information;</li>
            <li> corporate, financial, and credit history information;</li>
            <OLRoman>
              <li>
                information provided voluntarily by you, including when you
                register for information, make an enquiry or as part of
                accessing our services.
              </li>
            </OLRoman>
          </OLLetter>
          <li>
             <strong>Examples of automatically collected information</strong>
            . Additional to the examples above, Personal Information collected
            from those who visit a Site without entering information may
            include:
          </li>
          <OLLetter>
            <li> searches for services;</li>
            <li>
               information about your use of our site, including details of
              your visits such as pages viewed, the resources that you access;
            </li>
            <li>
               the full Uniform Resource Locators (URL) clicks to, through,
              and from our Site (including date and time), page response times,
              download errors, and length of visits to certain pages;
            </li>
            <li>
               network details including information about your internet
              service provider, traffic data, IP address and other location
              data, and other communication data;
            </li>
            <li> device log files and configurations; and</li>
            <li> version and time zone settings.</li>
          </OLLetter>
          <li>
             <strong>Fraud prevention</strong>. We may also use device
            identifiers and other technologies on devices, applications and our
            web pages to collect browsing, usage or other technical information
            for fraud prevention purposes.
          </li>
          <li>
            <strong>Personal Information collected from other sources</strong>.
            Examples of information we receive from other sources include:
          </li>
          <OLLetter>
            <li>
              information about your interactions with services offered by
              our affiliates;
            </li>
            <li> search results and links, including paid listings; and</li>
            <li> credit history information from credit bureaus.</li>
          </OLLetter>
        </OL>
        <h2>5. Maintaining Security of Personal Information</h2>
        <OL>
          <li>
             <strong>Employees.</strong> All of our employees and contractors
            are informed of our privacy policy and shall only have access to
            Personal Information as necessary to perform their duties.
          </li>
          <li>
             <strong>Security Measures</strong>. We have implemented
            reasonable security measures to safeguard your Personal Information,
            including <a>using encryption </a>
            when sending information over the internet and use passwords to
            protect information stored in the GeX database. While we take
            commercially reasonable measures to maintain a secure website,
            electronic communications and databases are subject to errors and
            break-ins, and we cannot guarantee that such events will not take
            place and will not be liable to you for any such occurrences.
          </li>
        </OL>
        <h2>
          6. <strong>Compliance</strong>
        </h2>
        <OL>
          <li>
            <strong>
              <u>Initiating Complaints.</u>
            </strong>
            <u>
              Any complaints from you concerning the collection, use or
              disclosure of their Personal Information or concerning your
              ability to access your Personal Information shall be referred to
              the Controller described in Subsection 1(5) of this Privacy
              Policy, who will attempt to resolve the complaint to your
              satisfaction.
            </u>
          </li>
          <li>
            <strong>
              <u>Further Concerns.</u>
            </strong>
            <u>
              In the event the complaint cannot be resolved internally to your
              satisfaction, you will be advised of where to{" "}
              <a>direct the complaint.</a>
            </u>

          </li>
        </OL>
        <h2>
          7. <strong>Policy Changes</strong>
        </h2>

        <p>
          Each time you use the Site, the current version of this Privacy Policy
          will apply. We reserve the right to modify this Privacy Policy at any
          time and without notice other than simply posting such modifications.
          Any such modifications will be effective immediately upon posting on
          the Site.
        </p>
      </Template>
  )
}

const Page = styled.div`
  & > ol > li {
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  & > hr {
    margin: 48px 0;
  }
  padding: 8px;
`


const OL = styled.ol`
  counter-reset: list;
  margin: 0;

  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: list;
    content: "(" counter(list, numbers) ")";
    position: absolute;
    left: -2em;
  }
`
const OLLetter = styled.ol`
  counter-reset: letters;
  margin: 0;
  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: letters;
    content: "(" counter(letters, lower-alpha) ")";
    position: absolute;
    left: -2em;
  }
`
const OLRoman = styled.ol`
  counter-reset: roman;
  margin: 0;
  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: roman;
    content: "(" counter(roman, lower-roman) ")";
    position: absolute;
    left: -2em;
  }
`
