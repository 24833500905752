import React from "react";
import styled from "styled-components";

type MajorStructuresOnSiteDivProps = {
  majorStructuresOnSite: boolean;
  majorBuildingsAreaSum?: number;
  numberOfBuildings?: number;
  isMap:boolean;
};
export default function MajorStructuresOnSiteDiv({
  majorStructuresOnSite,
  majorBuildingsAreaSum,
  numberOfBuildings,
  isMap
}: MajorStructuresOnSiteDivProps) {
  if (!!majorStructuresOnSite) {
    // const C = ({ children }) => <div className={className}>{children}</div>;
    // if (numberOfBuildings > 1 && majorBuildingsAreaSum) {
    //   return (
    //     <C>
    //       {`${numberOfBuildings} buildings totalling ${getBuildingArea(
    //         measureUnits.ACRES,
    //         majorBuildingsAreaSum
    //       )}`}{" "}
    //       <UnitLabel measureUnit={measureUnits.ACRES} isBuilding />
    //     </C>
    //   );
    // }
    // if (numberOfBuildings > 1) {
    //   return <C>{`${numberOfBuildings} buildings on site`}</C>;
    // }
    // if (numberOfBuildings === 1 && majorBuildingsAreaSum) {
    //   return (
    //     <C>
    //       {getBuildingArea(measureUnit, majorBuildingsAreaSum)}{" "}
    //       <UnitLabel measureUnit={measureUnits.ACRES} isBuilding /> building on site
    //     </C>
    //   );
    //}
    return <Container isMap={isMap}>Building on Site</Container>;
  }
  return null;
}

// const MajorStructuresOnSiteDiv = ({
//     majorStructuresOnSite,
//     majorBuildingsAreaSum,
//     numberOfBuildings
//   } : {majorStructuresOnSite : boolean;
//     majorBuildingsAreaSum?:number;
//     numberOfBuildings}) => {
//     if (!!majorStructuresOnSite) {
//       const C = ({ children }) => <div className={className}>{children}</div>;
//       if (numberOfBuildings > 1 && majorBuildingsAreaSum) {
//         return (
//           <C>
//             {`${numberOfBuildings} buildings totalling ${getBuildingArea(
//               measureUnits.ACRES,
//               majorBuildingsAreaSum
//             )}`}{" "}
//             <UnitLabel measureUnit={measureUnits.ACRES} isBuilding />
//           </C>
//         );
//       }
//       if (numberOfBuildings > 1) {
//         return <C>{`${numberOfBuildings} buildings on site`}</C>;
//       }
//       if (numberOfBuildings === 1 && majorBuildingsAreaSum) {
//         return (
//           <C>
//             {getBuildingArea(measureUnit, majorBuildingsAreaSum)}{" "}
//             <UnitLabel measureUnit={measureUnits.ACRES} isBuilding /> building on site
//           </C>
//         );
//       }
//       return <C>Building on Site</C>;
//     }

//     return null;
//   };
const Container = styled.div<{isMap:boolean}>`
  line-height: ${props => (props.isMap ? 22 : 26)}px;
  color: rgb(72, 72, 72) !important;
  font-size: 14px;
`;