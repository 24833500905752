import Grid from "@material-ui/core/Grid";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import React, { useState, useEffect } from "react";
import { createNewPolygon, getGeoAcres } from "../../common/mapHelpers";
import Button from "../../pages/components/controls/Button";
import styled from "styled-components";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import ListingMapEditor from "./ListingMapEditor";

const MapEditor = ({
  field,
  value,
  xs,
  sm,
  md,
  handleChange,
  disabled,
  error,
  hideAutoComplete = false,
}) => {
  const [state, setState] = useState(value || { zoom: 10 });
  const [center, setCenter] = useState(
    value.center || { lat: 53.54, lng: -113.56 }
  );
  const [autoCompleteObj, setAutoCompleteObj] = useState(null);

  const [mapRef, setMapRef] = useState(null);
  const [polyRef, setPolyRef] = useState(null);
  const selectedStrokeColor = "#fff";

  useEffect(() => {
    if (!!autoCompleteObj) {
      //console.log(autoCompleteObj.value.place_id)
      geocodeByPlaceId(autoCompleteObj.value.place_id).then((results) => {
        const locationObj = results[0].geometry.location;
        //console.log(locationObj.lat(), locationObj.lng());
        const newCenter = { lat: locationObj.lat(), lng: locationObj.lng() };

        doStateChange({
          ...state,
          marker: newCenter,
          center: newCenter,
          zoom: 15,
        });
        setCenter(newCenter);
      });
    }
  }, [autoCompleteObj]);

  function doStateChange(s) {
    setState(s);
    handleChange(field.jsonPath, s);
  }

  function saveMapState() {
    if (mapRef) {
      setCenter(mapRef.getCenter().toJSON());
      doStateChange({
        ...state,
        zoom: mapRef.zoom,
        center: mapRef.getCenter().toJSON(),
      });
    }
  }

  const showMarker = state.marker && state.marker.lat && state.marker.lng;

  //   var geocoder = new window.google.maps.Geocoder();
  //   geocoder.geocode(
  //     {
  //       address: '10055-118 ST NW, Edmonton AB'
  //     },
  //     function(results, status) {
  //       if (status === google.maps.GeocoderStatus.OK) {
  //         console.log('hit')
  //       }
  //     }
  //   );

  const btns = [
    {
      label: "Add Pin",
      onClick: () =>
        doStateChange({ ...state, marker: center, center, zoom: mapRef.zoom }),
      show: !state.marker,
      isHighlighted: true,
    },
    {
      label: "Remove Pin",
      onClick: () => {
        doStateChange({});
        setAutoCompleteObj(null);
        setCenter({ lat: 53.54, lng: -113.56 });
      },
      show: !!state.marker,
    },
    {
      label: "Add Polyon",
      onClick: () =>
        doStateChange({
          ...state,
          polygon: createNewPolygon(0.0011, state.marker),
        }),
      show: !!state.marker && !state.polygon,
    },
    {
      label: "Remove Polyon",
      onClick: () => {
        const { polygon, ...s } = state;
        doStateChange(s);
      },
      show: !!state.polygon,
    },
  ];

  return (
    <>
      {!!error && (
        <Grid item lg={12}>
          <MapError>{error}</MapError>
        </Grid>
      )}
      {!hideAutoComplete && <Grid item lg={12}>
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            bounds: [
              { lat: 53.2, lng: -112.8 },
              { lat: 53.888002, lng: -114.175415 },
            ],
            componentRestrictions: {
              country: ["ca"],
            },
          }}
          selectProps={{
            autoCompleteObj,
            value: autoCompleteObj,
            onChange: setAutoCompleteObj,
            placeholder: "Enter Address",
          }}
        />
      </Grid>}
      <Grid item lg={12}>
        {btns
          .filter((b) => b.show)
          .map((b, n) => (
            <Button
              onClick={b.onClick}
              key={`btn_${n}`}
              isHighlighted={b.isHighlighted}
              disabled={disabled}
            >
              {b.label}
            </Button>
          ))}
      </Grid>
      <Grid item xs={xs} sm={sm} md={md}>
        <ListingMapEditor field={field} value={value} handleChange={handleChange} />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md}>
        <GoogleMap
          id="parcel-map"
          mapContainerStyle={{
            height: "400px",
            width: "100%",
          }}
          zoom={state.zoom || 10}
          center={center}
          onDragEnd={saveMapState}
          onZoomChanged={saveMapState}
          onLoad={(map) => setMapRef(map)}
          greedy
          options={{ mapTypeId: "hybrid" }}
        >
          {state.polygon && (
            <Polygon
              paths={state.polygon}
              draggable={!disabled}
              editable={!disabled}
              onLoad={(p) => setPolyRef(p)}
              onMouseUp={(t) =>
                // setState({ ...state, polygon: t.latLng.toJSON() })
                doStateChange({
                  ...state,
                  polygon: polyRef
                    .getPath()
                    .getArray()
                    .map((ll) => ll.toJSON()),
                })
              }
              options={{
                fillColor: selectedStrokeColor,
                strokeColor: selectedStrokeColor,
              }}
            />
          )}
          {showMarker && (
            <Marker
              position={state.marker}
              draggable={!disabled}
              onDragEnd={(t) =>
                doStateChange({ ...state, marker: t.latLng.toJSON() })
              }
            />
          )}
        </GoogleMap>
        {state.polygon && (
          <PolygonSizeDiv>{`Polygon Size: ${getGeoAcres(
            state.polygon
          )} acres`}</PolygonSizeDiv>
        )}
      </Grid>
      
    </>
  );
};

const PolygonSizeDiv = styled.div`
  font-size: 11pt;
  padding: 6px;
  color: #444;
`;
const MapError = styled.div`
  background-color: #ffebee;
  padding: 10px;
  border: 1px solid #d32f2f;
  border-radius: 4px;
  color: #d32f2f;
  font-size: 12px;
`;
export default MapEditor;
