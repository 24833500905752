import React from "react";
import styled from "styled-components";
import {
  getListingTypeFriendlyText,
  getAreaStringWithUnit,
} from "common/newHelperFunctions";
import { ListItemDetail, LISTING_STATUSES } from "types/Types";
import MajorStructuresOnSiteDiv from "./MajorStructuresOnSiteDiv";
import { withTheme } from "@material-ui/core";

type Props = {
  listingItemDetail: ListItemDetail;
  isMap: boolean;
};

const ListItemDetails: React.FC<Props> = ({ listingItemDetail, isMap }) => {
  const {
    listingType,
    shortAddress,
    locus,
    park,
    possibleAreas,
    subdivision,
    tradingCategory,
    majorStructuresOnSite,
    majorBuildingsAreaSum,
    jurisdiction,
    jurisdictionLongName,
    numberOfBuildings,
    status,
  } = listingItemDetail;

  return (
    <Details isMap={isMap}>
      {status && <Chip status={status} />}

      <ListingTypeLabel>
        {getListingTypeFriendlyText(listingType)}
      </ListingTypeLabel>
      <Heading isMap={isMap}>{shortAddress}</Heading>
      <DistrictText isMap={isMap}>
        {locus || (!!jurisdiction && jurisdictionLongName)}
      </DistrictText>
      {(subdivision || park) && (
        <MajorLocation isMap={isMap}>{subdivision || park}</MajorLocation>
      )}
      <MajorStructuresOnSiteDiv
        majorStructuresOnSite={majorStructuresOnSite || false}
        majorBuildingsAreaSum={majorBuildingsAreaSum}
        numberOfBuildings={numberOfBuildings}
        isMap={isMap}
      />
      {tradingCategory && (
        <TradingCategoryText isMap={isMap}>
          {tradingCategory.join(" · ")}
        </TradingCategoryText>
      )}
      {possibleAreas && (
        <SizeText isMap={isMap}>
          {getAreaStringWithUnit(listingType, possibleAreas)}{" "}
        </SizeText>
      )}
    </Details>
  );
};
export default ListItemDetails;

const MajorLocation = styled.div<{ isMap: boolean }>`
  font-size: ${(props) => (props.isMap ? 14 : 14)}px !important;
  line-height: 18px;
  color: rgb(118, 118, 118);
  text-decoration: none;
  padding-bottom: 12px;
`;

const Details = styled.div<{ isMap: boolean }>`
  flex: 1;
  padding: ${(props) => (props.isMap ? "2px 2px" : "0px 20px 12px 20px")};
  position: relative;
  min-height: 50px;
`;
const DistrictText = styled.section<{ isMap: boolean }>`
  font-size: ${(props) => (props.isMap ? 14 : 14)}px !important;
  line-height: 26px;
  color: rgb(118, 118, 118);
  text-decoration: none;
  text-transform: uppercase;
`;
const Heading = styled.section<{ isMap: boolean }>`
  font-size: ${(props) => (props.isMap ? 14 : 18)}px !important;
  font-weight: 600;
  line-height: ${(props) => (props.isMap ? 20 : 28)}px !important;
  color: rgb(72, 72, 72);
  text-decoration: none;
  margin-bottom: ${(props) => (props.isMap ? 0 : 0)}px;
`;

const SizeText = styled.section<{ isMap: boolean }>`
  position: absolute !important;
  right: 10px !important;
  bottom: ${(props) => (props.isMap ? "4px" : "10px")} !important;
  text-align: right !important;
  color: rgb(72, 72, 72) !important;
  font-weight: 500 !important;
`;

const TradingCategoryText = styled.section<{ isMap: boolean }>`
  ${(props) => !props.isMap && "position: absolute !important;"}
  ${(props) => !props.isMap && "left: 18px !important;"}
  ${(props) => !props.isMap && "bottom: 10px !important;"}
  
  max-width: 200px;
  color: rgb(72, 72, 72) !important;
`;
const ChipContainer = styled.section<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  position: absolute;
  right: 0;
  top: 2px;
  display: inline;
  padding: 5px 10px;
  color: #fff;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
`;
const ListingTypeLabel = withTheme(styled.div`
  color: ${(props) => props.theme.palette.primary[500]};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
`);
const Chip = ({ status }: { status: LISTING_STATUSES }) => {
  if (status === LISTING_STATUSES.PUBLISHED) {
    return <></>;
  }
  const backgroundColor =
    status === LISTING_STATUSES.ARCHIVED ? "#777" : "DodgerBlue";
  return (
    <ChipContainer backgroundColor={backgroundColor}>{status}</ChipContainer>
  );
};
