import { Toolbar } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import ReactMarkDown from "react-markdown";
import styled from "styled-components";
// @ts-ignore
import changeLog from "../documentation/changeLog.md";
import MainHeader from "./components/MainHeader";
import LoadingPage from "./LoadingPage";

const ChangeLogPage = (props : RouteComponentProps) => {
  const [markDown, setMarkDown] = useState<string | null>(null);

  useEffect(() => {
    fetch(changeLog)
      .then((res) => res.text())
      .then((md) => setMarkDown(md));
  }, [markDown]);

  if (!markDown) {
    return <LoadingPage />;
  }

  return (
    <>
      <MainHeader/>
      <Toolbar />
      <Container>
        <ReactMarkDown source={markDown} />
      </Container>
    </>
  );
};


const Container = styled.div`
  width: 900px;
  background-color: #fff;
  margin: 30px auto;
  padding: 30px;
  border: 1px solid #ccc;

  & h2 {
    padding-top: 60px;
    color: dodgerblue;
  }

  & li {
    margin-bottom: 20px;
    line-height: 25px;
  }

  & img {
    border: 1px solid #ccc;
    max-width: 800px;
  }
`;

export default ChangeLogPage;
