import React from "react";
import ProgressiveImage from "react-progressive-image";
import styled from "styled-components";
import { settings } from "../../config/firebase";

const ImgViewer300 = ({
  listingId,
  fullImageName,
  onClick,
  height = 200,
  fullSize = false,
}) => {
  const nameParts = fullImageName.split(".");
  const ext = nameParts[1];
  const photoName = nameParts[0];
  const { projectId } = settings.firebaseConfig;

  if (fullSize) {
    const fullUrl = `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${listingId}%2F${fullImageName}?alt=media`;
    return (
      <Image
        lazy
        src={fullUrl}
        alt="an image"
        loading="lazy"
        width="300"
        height={height}
      />
    );
  }
  const imgURLPre = `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F`;
  const imgURLAndName = `${imgURLPre}${listingId}%2F${photoName}`;
  return (
    <ProgressiveImage
      src={`${imgURLAndName}_300x225.${ext}?alt=media`}
      placeholder={`${imgURLAndName}_30x22.${ext}?alt=media`}
    >
      {(src) => (
        <Image
          lazy
          src={src}
          alt="an image"
          loading="lazy"
          width="300"
          height={height}
          onClick={onClick}
        />
      )}
    </ProgressiveImage>
  );
};
const Image = styled.img`
  border-radius: 3px;
  object-fit: fill;
`;
export default ImgViewer300;
