import { withTheme } from "@material-ui/core";
import CheckBox from "@material-ui/icons/CheckBox";
import UncheckBox from "@material-ui/icons/CheckBoxOutlineBlankRounded";
import { Heading } from "components/searchPopup/styledComponents";
import parcelTypeDiagram from "data/landType.png";
import React from "react";
import styled from "styled-components";

type Props = {
  isNoMajorStructures: boolean;
  isMajorStructures: boolean;
  setState(isMajorStructures: boolean, isNoMajorStructures: boolean): void;
};

const TypeForm: React.FC<Props> = ({
  isNoMajorStructures,
  isMajorStructures,
  setState,
}) => {
  return (
    <Container>
      <Heading>With or Without Major Improvements?</Heading>
      <Middle>
        <Side>
          <CheckboxLabel
            onClick={() => setState( isMajorStructures, !isNoMajorStructures,)}
          >
            {isNoMajorStructures ? <StyledCheckBox /> : <StyledUncheckBox />}
            <Label>Land Free of Major Improvements</Label>
          </CheckboxLabel>

          <ImageHolder>
            <ParcelTypeImage
              onClick={() => setState(isMajorStructures, !isNoMajorStructures)}
              position={isNoMajorStructures ? "left top" : "left bottom"}
            />
          </ImageHolder>
        </Side>
        <Side>
          <CheckboxLabel
            onClick={() => setState(!isMajorStructures, isNoMajorStructures, )}
          >
            {isMajorStructures ? (
              <StyledCheckBox />
            ) : (
              <StyledUncheckBox />
            )}
            <Label>Land Featuring Major Improvements</Label>
          </CheckboxLabel>
          <ImageHolder>
            <ParcelTypeImage
             onClick={() => setState(!isMajorStructures, isNoMajorStructures, )}
              position={isMajorStructures ? "right top" : "right bottom"}
            />
          </ImageHolder>
        </Side>
      </Middle>
    </Container>
  );
};
const Container = styled.div`
  width: 900px;
  margin: auto;
`;
const Middle = styled.div`
  margin-top: 20px;
  display: flex;
`;
const Side = styled.div`
  width: 400px;
`;
const StyledCheckBox = withTheme(styled(CheckBox)`
  color: ${(props) => props.theme.palette.primary[600]};
`);
const StyledUncheckBox = withTheme(styled(UncheckBox)`
  color: ${(props) => props.theme.palette.primary[600]};
`);
const CheckboxLabel = styled.div`
  display: flex;
`;
const Label = styled.div`
  font-size: 15pt;
  padding: 0 0 0 15px;
  width: 250px;
`;
type ParcelTypeImageType = {
  position: string;
};
const ParcelTypeImage = styled.div<ParcelTypeImageType>`

  background-image: url("${parcelTypeDiagram}");
  width:176px;
  height:109px;
  position:relative;
  background-position: ${(props) => props.position};
`;
const ImageHolder = styled.div`
  padding-left: 50px;
  padding-top: 20px;
`;
export default TypeForm;
