import firebase, { firestore } from "firebase";
import { Themes } from "themes";
import { JurisdictionLookupsAdminDoc } from "./AdminTypes";

export interface Listing {
  id: string;
  lastSave: LastSave;
  listingInfo: ListingInfo;
  status: LISTING_STATUSES;
  versionId: string;
  listingType: LISTING_TYPE;
  orgId: keyof Themes;
}
export interface LastSave {
  date: firestore.Timestamp;
  uid: string;
  userDisplayName: string;
}

export type MapListingInfo = ListingInfo & {
  listingType: LISTING_TYPE;
  orgId: keyof Themes;
  status: LISTING_STATUSES;
  lastSaveUserId: string;
};

export interface ListingInfoBase {
  majorStructuresOnSite: boolean | null;
  jurisdiction: string | null;
  jurisdictionLongName: string | null;
  locus: string | null;
  park: string | null;
  shortAddress: string | null;
  subdivision: string | null;
  tradingCategory: string[] | null;
}

export type ListingInfo = ListingInfoBase & {
  id: string;
  images: ImageInfo[];
  linc: string | null;
  mapInfo: MapInfo;
  mapRegion: string | null;
  possibleAreas: number[] | null;
  // orgId: keyof Themes;
  //status: LISTING_STATUSES;
};

export type ListItemDetail = ListingInfoBase & {
  listingType: LISTING_TYPE;
  majorBuildingsAreaSum?: number;
  numberOfBuildings?: number;
  status?: LISTING_STATUSES;
  possibleAreas: number[] | null;
};
export interface Brokerage {
  name: string;
  phone?: string;
}
export interface ContactInfo {
  contactName: string;
  description?: string;
  followUpMethod?: string;
  email?: string;
  phone?: string;
}
export interface Crane {
  type: "interior" | "exterior";
  length?: number;
  width?: number;
  numberOfHooks?: 1 | 2 | 3;
  hook1?: Hook;
  hook2?: Hook;
  hook3?: Hook;
}

export interface Cranes {
  exists?: boolean;
  numCraneways?: 1 | 2 | 3;
  crane1?: Crane;
  crane2?: Crane;
  crane3?: Crane;
}
export interface Hook {
  height?: number;
  capacity?: number;
}
export interface ImageInfo {
  fullImageName: string;
  impageType: string;
  isPrimary: boolean;
  ratio: number;
}
export interface Lookups {
  brokerages: Brokerage[];
  dateSaved: firestore.Timestamp;
  id: string;
}

export enum LISTING_STATUSES {
  DELETED = "deleted",
  ARCHIVED = "archived",
  DRAFT = "draft",
  PUBLISHED = "published",
}
export interface MapInfo {
  center?: Point;
  marker?: Point;
  polygon?: Point[];
  secondaryPolygon?: Point[];
  secondaryMarker?: Point;
  zoom?: number;
}
export interface Parking {
  numberOfStalls: number;
  stallsPer1000: number;
}

export enum VERSION_STATUSES {
  DRAFT = "draft",
  CHANGED = "changed",
  PUBLISHED = "published",
}
export enum LISTING_TYPE {
  INDUSTRIAL_LAND = "IL",
  COMMERCIAL_LAND = "CL",
  INDUSTRIAL_SPACE = "IS",
  RETAIL_SPACE = "RS",
  OFFICE_SPACE = "OS",
}
export interface VersionDoc {
  availability?: Availability;
  districts?: string[];
  id: string;
  images?: ImageInfo[];
  lastSave: LastSave;
  legalDescription: LegalDescription;
  landSurveyorDescription?: LandSurveyorDescription;
  listingId: string;
  listingType: LISTING_TYPE;
  location: Location;
  mapRegion?: string;
  mapInfo: MapInfo;
  parking: Parking;
  privateListing?: PrivateListing;
  proposedLandUseDistrict?: string[];
  rezoningApplicationPending?: boolean;
  space?: Space;
  status: VERSION_STATUSES;
  video1?: string;
  trafficFlow?: TrafficFlowInfo;
  taxRollNumber?: string;
  pieces?: Pieces;
}
// land
export interface VersionDoc {
  lotIsContiguous?: boolean;
  majorBuildingsAreaSum?: number;
  numberOfBuildings?: number;
  structuresOnSite?: boolean;
  termsConditions?: TermsConditions;
  cranes?: Cranes;
  cellTower?: CellTower;
}
export type CellTower = {
  cellTowerSiteIsOffered?: boolean;
};
export interface Space {
  baySize?: number;
  buildingClass?: string;
  budgetedOfficeArea?: number;
  ceilingClearanceUnderJoist?: number;
  driveThrough?: string;
  existingOfficeArea?: number;
  fireSuppressionSystem?: boolean;
  loadingDock?: string[];
  mezzanineIncluded?: boolean;
  mezzanineFunction?: string;
  numberOfFloors?: number;
  numberOfPieces?: number;
  officeAdditionalRentProrated?: string;
  officeBasicRentProrated?: string;
  officeStorageArea?: number;
  officeActualvEstimated?: string;
  projectName?: string;
  siteFloorPlans?: string;

  spaceRangeTo?: number;
  spaceRangeFrom?: number;
  tenantImprovementAllowance?: number;

  //spaceSets?:SpaceSets;
  availableSpaces?: AvailableSpaces;
}

export type TrafficFlowInfo = {
  showTrafficFlow?: boolean;
  trafficFlowInfoYears?: TrafficFlowInfoYear[];
};

export interface TermsConditions {
  additionalRent?: number;
  additionalRentBasedOn?: string;
  circaYear?: string;
  termsConditions?: string;
  termsBasicRentBasedOn?: string;
  termsRateBasedOn?: string;
  termsRate?: number;
  termsPrice?: number;
  termsBasicRentIsRange?: boolean;
  termsBasicRent?: number;
  termsBasicRentRangeFrom?: number;
  termsBasicRentRangeTo?: number;
  termsActualOrEstimated?: string;
  grossCurrentRent?: number;
}
export type TrafficFlowInfoYear = {
  year: number;
  adjacentArterial: string;
  volume: number;
  measurementUnit: TrafficFlowMeasurementUnit;
};
export type TrafficFlowMeasurementUnit = "avgWeekday" | "avgWeekly";
export type Combination = {
  lots: number[];
};

export type AvailableSpace = {
  description: string;
  area: number;
} & OrdinalMarkerPolygon;

export type AvailableSpaces = {
  spaceArray: AvailableSpace[];
  combinations: Combination[];
  zoom?: number;
  center?: Point;
  showMap?: boolean;
  image?: string | null;
};
// interface TrafficFlow {
//   daily?: number;
//   weekly?: number;
// }

export interface Availability {
  linc?: string;
  ancillaryOfferRemarks?: string;
  brochure?: string;
  shortAddress?: string;
  tradingCategory?: string[];
  tradingStatus?: string[];
  mlsNumber?: string;
  brokeragePropertyManagement?: string;
  brokerageContactNumber?: string;
  principalContactInfo?: ContactInfo[];
  auctionType?: string;
  auctionReserveAmount?: number;
}

export interface PrivateListing {
  contact?: string;
  contactNumber?: string;
}

export interface DraftVersionDoc {
  lastSave: LastSave;
  listingType: LISTING_TYPE;
  status: VERSION_STATUSES;
  mapInfo: MapInfo;
  location?: Location;
}
export interface EditorProps {
  marketSegment: LISTING_TYPE;
  disabled: boolean;
  versionDoc: VersionDoc;
  saveListing: (versionDoc: VersionDoc) => void;
  getStorageRef: () => firebase.storage.Reference;
  lookups: any;
  jurisdictionLookups: JurisdictionLookupsAdminDoc;
  errors: CommonErrorObj;
}
export type CommonErrorObj = {
  //linc: string | null;
  jurisdiction: string | null;
  mapRegion: string | null;
  addressNumber: string | null;
  roadwayName: string | null;
  roadwayType: string | null;
  ruralUnitNum: string | null;
  ruralRoadNum: string | null;
  ruralTypeNum: string | null;
  tradingCategory: string | null;
  tradingStatus: string | null;
  images: string | null;
  mapInfo: string | null;
  locus: string | null;
  brokeragePropertyManagement: string | null;
  brokerageContactNumber: string | null;
  privateListingContact: string | null;
  privateListingContactNumber: string | null;
  polygon: string | null;
  pieces: string | null;
};

export type LandErrorObj = CommonErrorObj & {
  structuresOnSite: string | null;
  numberOfBuildings: string | null;
  majorBuildingsAreaSum: string | null;
  listingTitle: string | null;

};
export type SpaceErrorObj = CommonErrorObj & {
};

export type LandEditorProps = EditorProps & {
  errors: LandErrorObj;
};

export interface LandSurveyorDescription {
  portion?: string;
  section?: string;
  township?: string;
  range?: string;
  meridian?: string;
}
export interface Location {
  isDeveloped?: boolean;
  isUrban?: boolean;
  addressType?: "urban" | "rural" | "none";
  jurisdiction: string;
  locationRemarks?: string;
  locationUrban?: LocationUrban;
  locationRural?: LocationRural;
  locationNone?: LocationNone;
  locus?: string;
  park?: string;
  shortAddress?: string;
  subdivision?: string;
}

export interface LocationUrban {
  unitNumber?: string;
  addressNumber?: string;
  roadwayName?: string;
  roadwayType?: string;
  quadrant?: string;
}
export interface LocationRural {
  ruralUnitNum?: number;
  ruralRoadNum?: number;
  ruralRoadExt?: string;
  ruralTypeNum?: number;
  ruralTypeExt?: string;
}
export interface LocationNone {
  title?: string;
}
export interface LegalDescription {
  plan: string;
  block: string;
  lot: string;
  unit: string;
}
export interface Jurisdiction {
  id: string;
  name: string;
}

export type OrdinalMarkerPolygon = {
  ordinal: number;
  marker: Point | null;
  polygon: Point[] | null;
};

export interface Point {
  lat: number;
  lng: number;
}

export type SavedSearch = {
  area: {
    max: number;
    min: number;
    ordinal: number;
    value: string;
  };
  clientName: string;
  dateSaved?: firestore.Timestamp;
  isMajorStructures: boolean;
  isNoMajorStructures: boolean;
  mapRegions: string[];
  marketSegment: LISTING_TYPE;
  searchId?: string;
  isDeleted: boolean;
};

export type SearchDefaultType = {
  isNoMajorStructures: boolean;
  isMajorStructures: boolean;
  areaSliderOrdinal: number;
  useSimpleSize: boolean;
  minAreaTick: number;
  maxAreaTick: number;
  tradingCategories: {
    forLease: boolean;
    forSale: boolean;
    restricted: boolean;
    willBuildToSuit: boolean;
  };
  marketSegment: LISTING_TYPE;
  mapRegions: MapRegions;
  clientName?: string;
  searchId?: string;
  dateSaved?: firestore.Timestamp;
};

export type MapRegions = {
  northEastEdmonton?: boolean;
  northWestEdmonton?: boolean;
  southEastEdmonton?: boolean;
  southWestEdmonton?: boolean;
  centralEdmonton?: boolean;
  northEastMetro?: boolean;
  southMetro?: boolean;
  westMetro?: boolean;
};
export type OrganizationTypes = "contributors" | "prospects" | "sponsors";

export type Pieces = {
  numberOfPieces?: number;
  numberOfParcels?: number;
  numberOfSpaces?: number;
  numberOfAncillaryLots?: number;
  pieces?: Piece[];
  canPiecesBeCombined?: boolean;
  pieceCombinations?: PieceCombination[];
  showMap?: boolean;
  mapInfo: PiecesMapInfo | null;
  image?: string | null;
};
export type PiecesMapInfo ={
  zoom: number;
  center: Point;
}
export type Piece = {
  description: string | null;
  sizeData?: SizeData[];
  mapArea:number | null;
  marker: Point | null; 
  polygon: Point[]  | null;
  ordinal: number;
};
export type PieceCombination = {
  selectedPieces: number[];
  sizeData: SizeData[];
  description?: string;
};
export type SizeData = {
  type: "fixed" | "range" | "toBeDetermined";
  size: number | null;
  sizeFrom: number | null;
  sizeTo: number | null;
};
export type EmailRole = {
  email: string;
  role: string;
}