import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useState } from "react";
import styled from "styled-components";
import ImageUploadButton from "./FileUploader";
import ImgViewer300 from "./ImgViewer300";
import DeleteIcon from "@material-ui/icons/Delete";
const ImageControl = ({
  versionDoc,
  field,
  value,
  xs,
  sm,
  md,
  onBlur,
  getStorageRef,
  disabled,
  error,
}) => {
  const [lastUploaded, setLastUploaded] = useState(value || "");

  function uploadComplete(fileName) {
    setLastUploaded(fileName);
    const img = {
      fullImageName: fileName,
      imageType: "General",
      isPrimary: !value || !value.some((i) => !!i.isPrimary),
      ratio: 0.75,
    };
    onBlur(value ? [...value, img] : [img]);
  }

  function onDelete(img) {
    if (window.confirm("Delete image?")) {
      onBlur(
        value ? value.filter((i) => i.fullImageName !== img.fullImageName) : []
      );
    }
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Flex>
        {value &&
          value.map((img, n) => {
            const itIsJustLoadedSoUseFull = img.fullImageName === lastUploaded;

            return (
              <ImgContainer key={`img_${n}`}>
                <ImgViewer300
                  fullImageName={img.fullImageName}
                  listingId={versionDoc.listingId}
                  key={`img_${n}`}
                  fullSize={itIsJustLoadedSoUseFull}
                />
                <DeleteButtonDiv>
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => onDelete(img)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </DeleteButtonDiv>
              </ImgContainer>
            );
          })}
        <UploadDiv>
          <ImageUploadButton
            //images={value}
            getStorageRef={getStorageRef}
            uploadComplete={uploadComplete}
            disabled={disabled}
            labelOverride="Upload Image"
          />
          {!error ? (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled">
              Required
            </p>
          ) : (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
              style={{ borderTop: "1px solid red" }}
            >
              {error}
            </p>
          )}
        </UploadDiv>
      </Flex>
    </Grid>
  );
};

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const UploadDiv = styled.div`
  padding: 20px;
`;

const ImgContainer = styled.div`
  width: 300px;
  display: inline-block;
  position: relative;
`;

const DeleteButtonDiv = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
`;

export default ImageControl;
