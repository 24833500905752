import { RouteComponentProps } from "@reach/router";
import React from "react";
import styled from "styled-components";
import PageHeader from "./components/PageHeader";

const Page404 = (props: RouteComponentProps) => {
  //const [visible, setVisible] = useState<boolean>(false);
  
  // add a timeout so we dont get the flash of this page as we 
  // see if the user is logged in when a new page is openedO
  // useEffect(() => {
  //   let id:number = 0;
  //   id = setTimeout(function() { //Start the timer
  //     setVisible(true) //After 1 second, set render to true
  // }, 1000)


  // return () => {
  //   // Clean up the subscription
  //   clearTimeout(id)
  // };
  // }, []);

  return (
    <>
      <PageHeader />
      {true && (
        <Wrapper>
          <Image404 src="/404.png" />
          <Line />
          <TextHeader>Oops!</TextHeader>
          <TextBody>
            We can't seem to find the page you are looking for.{" "}
          </TextBody>
        </Wrapper>
      )}
    </>
  );
};

const Image404 = styled.img`
margin-top:100px;
  width: 1000px;
`;

const Line = styled.div`
  border-bottom: 1px solid rgb(235, 235, 235);
  padding-top: 20px;
`;

const Wrapper = styled.div`
  width: 1020px;
  margin: auto;
`;

const TextHeader = styled.h1`
  margin-top: 30px;
  font-weight: 600;
  text-align: center;
`;

const TextBody = styled.p`
  text-align: center;
`;

export default Page404;
