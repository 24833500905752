import { useState, useEffect } from "react";
import { SavedSearch } from "types/Types";
import firebase from "../config/firebase";

export default function usePublicSearch(userId:string, searchId:string ) {
  const db = firebase.firestore();

  const [searchDoc, setSearchDoc] = useState<SavedSearch | null | undefined>(null);



  const savedSearchDocument = db
        .collection("users")
        .doc(userId)
        .collection("savedSearches")
        .doc(searchId);

    useEffect(() => {


          
        const unsubscribe = savedSearchDocument.onSnapshot(
            function(docSnapshot) {
              const doc = docSnapshot.data()  as SavedSearch | undefined;
              if(!doc || doc.isDeleted === true){
                setSearchDoc(undefined) 
              }
              else{
                setSearchDoc({...doc, searchId:searchId})
              }
              
              
          }, (error) => console.log(error));

          return () => unsubscribe();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    if(searchDoc === undefined){
      return {searchDoc:null, isNotFound:true}
    }

    return {searchDoc , isNotFound: false} ;
}
