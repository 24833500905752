import OpenIcon from "@material-ui/icons/OpenInNew";
import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";

type DocSidePageLinkProps = {
  title: string;
  slug?: string;
  isCurrent?: boolean;
  pathPrefix?: string;
  indented?: boolean;
};
type ExternalLinkProps = {
  title: string;
  url: string;
};
export function ExternalLink(props: ExternalLinkProps) {
  return (
    <Container isCurrent={false} isIndented={false}>
      <a href={props.url} target="_blank" rel="noreferrer">
        <F1>{props.title}</F1>
        <StyledIcon fontSize="small" />
      </a>
    </Container>
  );
}
type ClickLinkProps = {
  title: string;
  onClick(): void;
  showIcon?:boolean;
  isCurrent?:boolean;
};
export function ClickLink({title, onClick, showIcon=true, isCurrent=false}: ClickLinkProps) {
  return (
    <Container isCurrent={isCurrent} isIndented={false}>
      <button onClick={onClick}>
        <F1>{title}</F1>
        {showIcon && <StyledIcon fontSize="small" />}
      </button>
    </Container>
  );
}

export default function PageLink({
  title,
  pathPrefix,
  slug,
  isCurrent,
  indented,
}: DocSidePageLinkProps) {
  return (
    <Container isCurrent={!!isCurrent} isIndented={!!indented}>

        <Link to={`/${pathPrefix}/${slug}`}>{title}</Link>

    </Container>
  );
}
type Props = { isCurrent: boolean; isIndented: boolean };
const Container = styled.div<Props>`
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  ${(props) => (props.isIndented ? "padding:0 0 0 16px;" : "padding: 0px;")}

  & > a, & > button {
    background-color:#fff;
    border:0;
    text-decoration: none;
    color: #3b454e;
    outline-width: 0px;
    line-height: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size:15px;
    width:100%;
    text-align:left;
    padding: 9px 24px 9px ${(props) => (props.isIndented ? "16px" : "16px")};
    ${(props) => props.isIndented && "border-left:1px solid #ccc;"}
    ${(props) =>
      props.isCurrent &&
      "background-color:#fff;color:dodgerblue;border:1px solid #E6ECF1;"}
      border-right-width:0;
  }

  & > a:hover, & > button:hover {
    background-color: ${(props) => (props.isCurrent ? "#fff" : "#e6ecf1")};
  }
`;
const StyledIcon = styled(OpenIcon)`
  color: #aaa;
`;

const F1 = styled.div`
  flex: 1;
`;
