import React, { useState } from "react";
import { Button } from "@material-ui/core";
import ExtraFeaturesPopup from "components/extraFeaturesPopup/ExtraFeaturesPopup";
type Props = {
  overrideIsOpen: boolean;
};
export default function ExtraElementsButton({ overrideIsOpen }: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Button
      id="extra-elements-button"
        style={{ color: "#0079ff", border: "1px solid #4ea2ff" }}
        size="large"
        variant="outlined"
        color="primary"
        onClick={() => setIsOpen(true)}
      >
        Extra Elements
      </Button>

      <ExtraFeaturesPopup
        isOpen={isOpen || overrideIsOpen}
        handleClose={() => setIsOpen(false)}
      />
    </>
  );
}
