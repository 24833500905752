import React, { useState } from "react";
import styled from "styled-components";
// @ts-ignore
import FirebaseFileUploader from "react-firebase-file-uploader";
import Icon from "@material-ui/icons/NoteAddOutlined";
import LinkIcon from "@material-ui/icons/InsertLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, IconButton } from "@material-ui/core";
import LinkEditorControl from "./LinkEditorControl";

type FileUploadProps = {
  acceptString?: string;
  getStorageRef: () => firebase.storage.Reference;
  uploadComplete: (fileName: string) => void;
  disabled: boolean;
  includeLink?: boolean;
  value: string;
  labelOverride?: string;
};
export default function FileUpload({
  acceptString = "image/*",
  getStorageRef,
  uploadComplete,
  disabled,
  value,
  includeLink = false,
  labelOverride="Upload File"
}: FileUploadProps) {
  const [fileInfo, setFileInfo] = useState({
    isUploading: false,
    progress: 0,
    url: "",
    showLinkEditor: false,
  });

  function handleUploadStart() {
    setFileInfo({ ...fileInfo, isUploading: true, progress: 1 });
  }
  function handleProgress(progress: any) {
    setFileInfo({ ...fileInfo, progress: progress });
  }
  function handleUploadError(error: any) {
    setFileInfo({ ...fileInfo, isUploading: false });
  }
  function handleUploadSuccess(fileName: string) {
    uploadComplete(fileName);
  }
  if (!!fileInfo.progress && fileInfo.progress !== 100) {
    return <CircularProgress />;
  }
  if (disabled) {
    return <></>;
  }

  if (fileInfo.showLinkEditor) {
    return (
      <LinkEditorControl
        url={value}
        setUrl={(url:string) => uploadComplete(url)}
        cancelClick={() =>
          setFileInfo((s) => ({ ...s, showLinkEditor: false }))
        }
      />
    );
  }

  return (
    <Container>
      <StyledButtonLabel>
        <Icon name="file" />
        <div style={{paddingLeft:6}}>{labelOverride}</div>
      
      <FirebaseFileUploader
          hidden
          accept={acceptString}
          storageRef={getStorageRef()}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
        </StyledButtonLabel>
      {includeLink && (
        <Button variant="outlined" startIcon={<LinkIcon />} onClick={() => setFileInfo((s) => ({ ...s, showLinkEditor: true }))}>
          Link
        </Button>
      )}
    </Container>
  );
}

const StyledButtonLabel = styled.label`
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.87);
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  padding: 12px 12px 12px 12px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size:14px;
  border:1px solid #ccc;
  margin-right:6px;
  
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: none;

  display: inline-flex;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const Container = styled.div`
  display: flex;
  align-content: center;
`;
