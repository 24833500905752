import React, { useContext, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "@reach/router";
import { Button } from "@material-ui/core";
import ProspectTerms from "./ProspectTerms";
import useUser from "store/useUser";
import { User } from "types/AdminTypes";
import { AppContext } from "AppContext";
import { firestore } from "firebase";
import Template from "./Template";
import InputField from "./sponsorContributorEmailTable/InputField";

type ProspectRegistrationPageProps = {} & RouteComponentProps;

export default function ProspectRegistrationPage({}: ProspectRegistrationPageProps) {
  const { user } = useContext(AppContext);
  console.log(user);
  const { addNew } = useUser("gex");
  const firstName = user?.displayName?.split(" ")[0];
  const lastName = user?.displayName?.split(" ")[1];
  console.log(firstName, lastName);
  const [state, setState] = useState({
    registrationType: "Prospect",
    firstName: firstName || "",
    surname: lastName || "",
    title: "",
    brokerage: "",
    emailOrPhone: user?.email || "",
    organization: "",
    businessAddress: "",
    businessPhoneNumber: "",
  });
  const [formState, setFormState] = useState({
    isValidated: false,
    isLoading: false,
    isSubmitted: false,
    termsAgreed: false,
    privacyAgreed: false,
  });

  type FieldType = {
    id: string;
    label: string;
    isRequired?: boolean;
    value: string;
    onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  };

  const errors: string[] = (function () {
    const e: string[] = [];
    if (state.firstName.length < 2) {
      e.push("firstName");
    }
    if (state.surname.length < 2) {
      e.push("surname");
    }
    if (state.emailOrPhone.length < 2) {
      e.push("emailOrPhone");
    }
    if (state.businessAddress.length < 2) {
      e.push("businessAddress");
    }
    return e;
  })();

  const fields: FieldType[] = [
    {
      id: "firstName",
      label: "First Name",
      value: state.firstName,
      isRequired: true,
      onChange: (e) => setState({ ...state, firstName: e.target.value }),
    },

    {
      id: "surname",
      label: "Surname",
      isRequired: true,
      value: state.surname,
      onChange: (e) => setState({ ...state, surname: e.target.value }),
    },
    {
      id: "title",
      label: "Title",
      value: state.title,
      onChange: (e) => setState({ ...state, title: e.target.value }),
    },
    {
      id: "organization",
      label: "Organization  (where applicable)",
      value: state.organization,
      onChange: (e) => setState({ ...state, organization: e.target.value }),
    },
    {
      id: "emailOrPhone",
      label: "Phone Number or Email Address",
      value: state.emailOrPhone,
      isRequired: true,
      onChange: (e) => setState({ ...state, emailOrPhone: e.target.value }),
    },
    {
      id: "businessAddress",
      label: "Business Address",
      value: state.businessAddress,
      isRequired: true,
      onChange: (e) => setState({ ...state, businessAddress: e.target.value }),
    },
    {
      id: "businessPhoneNumber",
      label: "Business Phone Number",
      value: state.businessPhoneNumber,
      isRequired: false,
      onChange: (e) =>
        setState({ ...state, businessPhoneNumber: e.target.value }),
    },
  ];

  async function submitForm() {
    if (errors.length > 0) {
      setFormState({ ...formState, isValidated: true });
      return;
    }
    setFormState({ ...formState, isLoading: true });
    if (!user) return;
    const newUser: User = {
      ...user,
      orgId: "gex",
      roles: ["prospect"],
      marketSegments: ["IL", "CL", "OS", "RS", "IS"],
      registrationInfo: {
        registrationType: "prospect",
        firstName: state.firstName,
        surname: state.surname,
        title: state.title,
        organization: state.organization,
        phoneOrEmail: state.emailOrPhone,
        businessAddress: state.businessAddress,
        businessPhoneNumber: state.businessPhoneNumber,
        registrationDate: firestore.Timestamp.fromDate(new Date()),
      },
    };

    const t = await addNew(newUser);
    window.location.href = "/tour";
  }

  const isButtonDisabled =
    !formState.termsAgreed || !formState.privacyAgreed || formState.isLoading;
  console.log(errors);
  return (
    <Template>
      <h2>Prospect Registration Form</h2>
      <p>
        Fill out the Form. Agree to the Terms and Conditions and Privacy Policy
      </p>
      {fields.map((f) => (
        <InputField
          key={`tf_${f.id}`}
          {...f}
          disabled={formState.isLoading}
          multiline={f.id === "businessAddress"}
          isError={formState.isValidated && errors.includes(f.id)}
        />
      ))}
      <ProspectTerms
        setTermsChecked={(isChecked) =>
          setFormState({ ...formState, termsAgreed: isChecked })
        }
        setPrivacyChecked={(isChecked) =>
          setFormState({ ...formState, privacyAgreed: isChecked })
        }
        isDisabled={formState.isLoading}
      />
      {formState.isValidated && errors.length > 0 && (
        <div style={{ color: "red" }}>
          Enter required info:{" "}
          {errors
            .map((id) => fields.find((f) => f.id === id)?.label)
            .join(", ")}
        </div>
      )}
      <ButtonHolder>
        <Button
          color="primary"
          variant="contained"
          disabled={isButtonDisabled}
          onClick={submitForm}
        >
          Submit
        </Button>
      </ButtonHolder>
    </Template>
  );
}


const ButtonHolder = styled.div`
  padding-top: 20px;
`;
