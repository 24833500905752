import { LoadScript } from "@react-google-maps/api";
import { DEFAULT_CENTER } from "common/constants";
import React, { useState } from "react";

import styled from "styled-components";
import { MapListingInfo, VersionDoc, Point } from "types/Types";
import GoogleMap from "./GoogleMap";
type props = {
  land:VersionDoc;
  listings: MapListingInfo[] | undefined;
  isEditor: boolean;
  currentPage?:VersionDoc;
  isPiecesMap: boolean;
}
const arr = ["places", "geometry"] as ["places", "geometry"];
const ListingMap = ({ land, listings, isEditor, currentPage , isPiecesMap } : props) => {
  const [mapState, setMapState] = useState<{selectedMarkerId:string|null}>({
    selectedMarkerId: null,
  });
  const mapInfo = land.mapInfo;

  if (!land.mapInfo) {
    return <></>;
  }

  //const hasAvailableSpaces = land.location.listingSpaceOptions === "multiple" || land.location.listingSpaceOptions === "combo";

  const zoom = function() {
    if(isPiecesMap){

      if( land.pieces?.mapInfo?.zoom){
        return land.pieces?.mapInfo?.zoom;
      } 
    }
    if(mapInfo.zoom){
      return mapInfo.zoom;
    }
    return 11;
  }();

  const center = function() {
    if(isPiecesMap){

      if(land.pieces?.mapInfo?.center){
        return land.pieces?.mapInfo?.center;
      }
    }
    if(mapInfo.center){
      return mapInfo.center;
    }
    return DEFAULT_CENTER;
  }();

  const omps = function() {
    if(isPiecesMap){

      if(land.pieces?.pieces){
        return land.pieces?.pieces;
      }
    }
    return undefined;
  }();


  const selectedMarkerId : string | null =
    !!mapState.selectedMarkerId && !!listings &&
    listings.some((i) => i.id === mapState.selectedMarkerId)
      ? mapState.selectedMarkerId
      : null;

  return (

    <MapDiv>
          <LoadScript
    id="script-loader"
  googleMapsApiKey="AIzaSyARh9SCNR-vNADst2P-co2fPy0yiSKhP64"
  libraries={arr}
  >
      <GoogleMap
        mapInfos={listings || []}
        defaultCenter={center}
        defaultZoom={zoom}
        isEditor={isEditor}
        currentPage={currentPage}
        selectedMarkerId={selectedMarkerId}
        markerClick={(id) => {
          if (id === selectedMarkerId) {
            setMapState({ ...mapState, selectedMarkerId: null });
          } else {
            setMapState({ ...mapState, selectedMarkerId: id });
          }
        }}
        omps={omps}
        isPublic={true}
        isRealtor={false}
        hideMap={false}
      />
      </LoadScript>
    </MapDiv>
  );
};
const MapDiv = styled.div`
  background-color: #eee;
  height: 500px;
  width: 100%;
  margin-top:16px;
`;
export default ListingMap;
