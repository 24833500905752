import { useCallback, useEffect, useState } from "react";
import { JurisdictionLookupsAdminDoc } from "types/AdminTypes";
import { Listing, LISTING_STATUSES, VersionDoc } from "types/Types";
import firebase from "../config/firebase";
//import { LISTING_STATUS } from "../../common/constants";

export default function useListingStore() {
  const db = firebase.firestore();
  //const [state, setState] = useState({ versions: null, listing: null });
  const [listings, setListings] = useState<Listing[] | null>(null);
  const [adminDocs, setAdminDocs] = useState<{id:string}[] | null>(null);

  useEffect(() => {


    const unsubscribes = [
      db.collection("listings").onSnapshot((querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        } as Listing)).filter(d => d.status === LISTING_STATUSES.PUBLISHED);
        setListings(docs);
      }),
      db.collection("administration").onSnapshot((querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        } ));
        setAdminDocs(docs);
      }),
    ];

    return () => unsubscribes.forEach((s) => s());
  }, [db]);

  const getVersionDocForListingId : (id:string) => Promise<[VersionDoc | null , Listing | null  ] > = useCallback(  async function (id: string) {
    return new Promise(function (resolve, reject) {
      if (!listings) {
        return resolve([null, null]);
      }
      const listing = listings.find((l) => l.id === id);
    
      if(!listing){
        throw new Error("Listing not found.")
      }
      
      db.collection("listings")
        .doc(id)
        .collection("versions")
        .doc(listing.versionId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const verDoc = doc.data();
            // setVersions({ ...versions, verDoc });
            resolve([verDoc as VersionDoc, listing ]);
          } else {
            reject("No such document");
          }
        });
    });
  }, [db, listings]) ;

  return {
    state:  !!listings && !!adminDocs ? {listings, adminDocs} : null,
    getVersionDocForListingId,
    jurisdictionLookups: adminDocs?.find(ad => ad.id === 'jurisdictionLookups') as JurisdictionLookupsAdminDoc | undefined
  };
}
