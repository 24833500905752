import SearchIcon from "@material-ui/icons/Add";
import React from "react";
import Button from "./Button";


const NewSearchButton = ({ onClick }) => {
  return (
    <Button isHighlighted onClick={onClick} noMargin>
      <SearchIcon fontSize="small" /> <div>New Search</div>
    </Button>
  );
};

export default NewSearchButton;
