import {
  Button,
  FormControl, Grid, InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextFieldMUI from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalBackground from "pages/components/ModalBackground";
import React from "react";
import styled from "styled-components";
import { CellColumnNumber, IdName, SizesField } from "types/fieldComponentTypes";
import { SizeData } from "types/Types";




type Props = {
  label: string;
  value: SizeData[] ;

  hideToBeDetermined?: boolean;
  handleChange: ( value: SizeData[] ) => void;
  error: string | null;
  measurementUnit: string;

};


const SizesEditor = ({label, value, hideToBeDetermined, measurementUnit,  error, handleChange}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [sizeDataArray, setSizeDataArray] = React.useState<SizeData[]>(value || [
    { type: "fixed" },
  ]);

  React.useEffect(() => {
    setSizeDataArray(value || [{ type: "fixed" }]);
  }, [value]);

  const sizeText = function() {
    return sizeDataArray
      .map((sizeData) => {
        if (sizeData.type === "fixed") {
          return sizeData.size;
        } else if (sizeData.type === "range") {
          
          return `${sizeData.sizeFrom || ""}–${sizeData.sizeTo || ""}`;
        } else {
          return "To be determined";
        }
      })
      .join(", ");
  }();

  function close() {
    setOpen(false);
    handleChange( sizeDataArray);
  }
  console.log(sizeText, parseInt(sizeText))
  const sizeNumber = parseInt(sizeText);
  const formattedSizeText  = isNaN(sizeNumber) ? sizeText : sizeNumber.toLocaleString();
  return (<>

      <TextFieldMUI
        label={ label}
        variant="filled"
        fullWidth
        value={formattedSizeText}
        helperText={error || 'Required'}
        error={!!error}
        onFocus={() => {
          setOpen(true);
        }}
      />
   
      {open && (
        <ModalBackground>
          <Container>
            {sizeDataArray.map((sizeData, index) => (
              <SizeRow
                key={`size_${index}`}
                sizeData={sizeData}
                setSizeData={(newSizeData) => {
                  const newArray = [...sizeDataArray];
                  newArray[index] = newSizeData;
                  setSizeDataArray(newArray);
                }}
                deleteRow={() => {
                  const newArray = [...sizeDataArray];
                  newArray.splice(index, 1);
                  setSizeDataArray(newArray);
                }}
                measurementUnit={measurementUnit}
                hideToBeDetermined={hideToBeDetermined}
              />
            ))}

            <Flex>
              <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  setSizeDataArray([...sizeDataArray, { type: "fixed", size: null, sizeFrom: null, sizeTo: null }]);
                }}
              >
                Add Possible Size
              </Button>
              <Flex1></Flex1>
              <Button onClick={close}>Close</Button>
            </Flex>
          </Container>
        </ModalBackground>
        
      )}
    </>
  );
};

type SizeRowProps = {
  sizeData: SizeData;
  setSizeData: (newSizeData: SizeData) => void;
  hideToBeDetermined?: boolean;
  deleteRow(): void;
  measurementUnit:string;
};

function SizeRow({ sizeData, setSizeData, deleteRow, hideToBeDetermined, measurementUnit }: SizeRowProps) {
  const { type, size, sizeFrom, sizeTo } = sizeData;

  return (
    <FlexBorder>
      <Col1>
        <FormControl fullWidth>
          <InputLabel
            style={{ marginLeft: 10, marginTop: 3 }}
            id="demo-simple-select-label"
          >
            Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            variant="filled"
            onChange={(e) =>
              setSizeData({
                ...sizeData,
                type: e.target.value as SizeData["type"],
              })
            }
          >
            <MenuItem value={"fixed"}>Fixed</MenuItem>
            <MenuItem value={"range"}>Range</MenuItem>
            {!hideToBeDetermined  && <MenuItem value={"toBeDetermined"}>To Be Determined</MenuItem>}
          </Select>
        </FormControl>
      </Col1>
      {type === "range" && (
        <>
          <ColTextBox>
            <NumberTextBox
              label={`From (${measurementUnit})`}
              value={sizeFrom}
              onChange={num => {
                setSizeData({
                  ...sizeData,
                  sizeFrom: num,
                });
              }}
            />
          </ColTextBox>
          <ColTextBox>
          <NumberTextBox
              label={`To (${measurementUnit})`}
              value={sizeTo}
              onChange={(num) => {
                setSizeData({
                  ...sizeData,
                  sizeTo: num,
                });
              }}
            />
          </ColTextBox>
        </>
      )}
      {type === "fixed" && (
        <>
          <ColTextBox>
          <NumberTextBox
              label={`Size (${measurementUnit})`}
              value={size}
              
              onChange={(num) => {
                setSizeData({
                  ...sizeData,
                  size: num,
                });
              }}
            />
          </ColTextBox>
          <ColTextBox></ColTextBox>
        </>
      )}
      { type === "toBeDetermined" && (
        <>
          <ColTextBox></ColTextBox>
        </>
      )}
      <ColLast>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={deleteRow}          
        >
          <DeleteIcon />
        </IconButton>
      </ColLast>
    </FlexBorder>
  );
}

function NumberTextBox({
  label,
  value,
  onChange,
}: {
  label: string;
  value: number | null;
  onChange: (e: number | null) => void;
}) {
  return (
    <TextFieldMUI
      label={label}
      //type="number"
      variant="filled"
      fullWidth
      value={value || ""}
      autoFocus
      onChange={(e) => {

        
        const num = parseFloat(e.target.value);
        onChange(isNaN(num)? null : num);
      }}
    />
  );
}

const FlexBorder = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 10px;
`;
const Flex = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
`;
const Flex1 = styled.div`
  flex: 1;
`;
const Col1 = styled.div`
  width: 100px;
  margin-right: 10px;
`;
const ColTextBox = styled.div`
  flex: 1;
  margin-right: 10px;
`;
const ColLast = styled.div`
  width: 60px;
  text-align: right;
`;
type ListingSizeRowProps = {
  index: number;
  editMode?: boolean;
};


const Container = styled.div`
  width: 550px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  background-color: #fff;
  margin: auto;
  padding: 20px 10px 0px 10px;
`;

const Row = styled.div`
  padding: 16px 0;
`;
export default SizesEditor;
