import React from "react";
import { Light, Strong } from "./ListingPageStyledComponents";
import { LandSurveyorDescription } from "types/Types";
import { MapField } from "types/fieldComponentTypes";

type Props = {
  value: LandSurveyorDescription | undefined | null;
  field: MapField;
};

export default function LandSurveyorDescriptionFieldLabel({
  field,
  value,
}: Props) {
  if (!value) {
    return null;
  }
  const { portion, section, township, range, meridian } = value;
  return (
    <p>
      <Light>{field.label} </Light>
      <Strong>{`${portion} ${section} ${township} ${range} ${meridian}`}</Strong>
    </p>
  );
}
