import { getAreasForPieces } from "common/newHelperFunctions";
import ContactArrayFieldLabel from "components/preview/ContactArrayFieldLabel";
import ContactRow from "components/preview/ContactRow";
import FileFieldLabel from "components/preview/FileFieldLabel";
import StringFieldLabel from "components/preview/StringFieldLabel";
import NumberFieldLabel from "components/preview/NumberFieldLabel";
import React from "react";
import { Availability, Lookups, Pieces } from "types/Types";

import {
  Box,
  Line,
  Title,
} from "../../components/preview/ListingPageStyledComponents";
import * as fields from "../../types/fieldDefinitions";
import BrochureButton from "./controls/BrochureButton";
type Props = {
  availability: Availability;
  pieces: Pieces;
  lookups: Lookups;
  showTitle: boolean;
  hideContactInfo: boolean;
  hideTitle: boolean;
  listingId: string;
  isSpaceListing: boolean;
};
const AvailabilitySection = ({
  availability,
  pieces,
  showTitle,
  hideContactInfo,
  listingId,
  isSpaceListing,
  hideTitle = false,
}: Props) => {
  if (!availability) return <></>;
  const showMLSNumber = !!(
    availability &&
    !!availability.tradingStatus &&
    !!availability.tradingStatus.includes("MLS")
  );

  const showAncillaryOffer =
    (pieces.numberOfAncillaryLots || 0) > 0 &&
    (pieces.numberOfParcels || 1) === 1;
  const showAuction = !!(availability && !!availability.tradingCategory?.includes("Auction"));
  return (
    <>
      <Box gridColumn="col 1 / span 1">
        {showTitle && <Title>Availability</Title>}
      </Box>
      <Box gridColumn="col 2 / span 4">
        {!!availability.shortAddress && !hideTitle && (
          <Title>{availability.shortAddress}</Title>
        )}
        <StringFieldLabel
          field={fields.tradingCategory}
          value={availability.tradingCategory}
        />
        {showAuction && availability.auctionType !== "Reserved"  &&
          <StringFieldLabel
            field={fields.auctionType}
            value={availability.auctionType}
          />
        }
{ showAuction && availability.auctionType === "Reserved"    &&   <NumberFieldLabel
          field={fields.auctionReserveAmount}
          value={availability.auctionReserveAmount}
          isCurrency
        />}
        <StringFieldLabel
          field={fields.tradingStatus}
          value={availability.tradingStatus}
        />
        {showMLSNumber && (
          <StringFieldLabel
            field={fields.mlsNumber}
            value={availability.mlsNumber}
          />
        )}

        {!hideContactInfo && (
          <ContactRow
            contactName={availability.brokeragePropertyManagement}
            phone={availability.brokerageContactNumber}
            topMargin
          />
        )}

        {!hideContactInfo && (
          <ContactArrayFieldLabel
            value={availability.principalContactInfo}
            field={fields.principalContactInfo}
          />
        )}
        {/* <FileFieldLabel
          value={availability.brochure}
          field={fields.brochure}
          listingId={listingId}
        /> */}
        <BrochureButton
          value={availability.brochure}
          field={fields.brochure}
          listingId={listingId}
        />

        {showAncillaryOffer && (
          <>
            <StringFieldLabel
              label="Ancillary Offer"
              value={getAreasForPieces(pieces, isSpaceListing)}
            />
            <StringFieldLabel
              value={availability.ancillaryOfferRemarks}
              field={fields.ancillaryOfferRemarks}
              showValueBelowLabel
            />
          </>
        )}
      </Box>
      <Line gridColumn="col 2 / span 4"></Line>
    </>
  );
};

export default AvailabilitySection;
