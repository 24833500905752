import React, {useState} from 'react';
import styled from 'styled-components';
import { AvailableSpaces, Combination, Pieces } from 'types/Types';
import { OrdinalTD, Table, TableHeader, Td } from "./ListingPageStyledComponents";
import {   getLetterForOrdinal, sortString } from "../../common/helperFunctions";
import { getAreaStringForArrayOfSizeData} from 'common/newHelperFunctions';

type props = {
    pieces?:Pieces;
    isSpaceListing:boolean;

}

function getComboString(numbers:number[]){
    const ordinals = numbers.map((n)=>getLetterForOrdinal(n));
    return ordinals.join(" + ");
}




export default function PiecesCombosListingRenderTable ({pieces, isSpaceListing} : props) {
    if(!pieces?.pieceCombinations) {
        return null;
    }

    const output: [string, string][] = pieces.pieceCombinations.map((c, i) => [
        getComboString(c.selectedPieces),
        getAreaStringForArrayOfSizeData(c.sizeData, isSpaceListing),
      ]);
    return (<Table>
        <tbody>
          <tr>
            <TableHeader style={{ minWidth: 122 }}></TableHeader>
            <TableHeader>Total Area</TableHeader>
          </tr>
          {output
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map((o, i) => {
              return (
                <tr key={`configTableRow_${i}`}>
                  <OrdinalTD>
                    <strong>{o[0]}</strong>
                  </OrdinalTD>
                  <Td>{o[1]}</Td>
                </tr>
              );
            })}
        </tbody>
      </Table>);
}