import { getBuildingSizeForOrdinal } from "./helperFunctions";
import {AREA_DISTRIBUTION_ACRES} from "common/newConstants"

export const colors = {
  green1: "#4c9e45",
};
export const measureUnits = {
  ACRES: "acres",
  METERS_SQUARED: "meters_squared",
};
export const MAP_REGION = {
  northEastEdmonton: "northEastEdmonton",
  northWestEdmonton: "northWestEdmonton",
  southEastEdmonton: "southEastEdmonton",
  southWestEdmonton: "southWestEdmonton",
  centralEdmonton: "centralEdmonton",
  northEastMetro: "northEastMetro",
  southMetro: "southMetro",
  westMetro: "westMetro",
};

export const EDMONTON_REGIONS = {
  [MAP_REGION.northEastEdmonton]: "Northeast",
  [MAP_REGION.northWestEdmonton]: "Northwest",
  [MAP_REGION.southEastEdmonton]: "Southeast",
  [MAP_REGION.southWestEdmonton]: "Southwest",
  [MAP_REGION.centralEdmonton]: "Central",
};
export const REGION_NAMES = {
  [MAP_REGION.northEastMetro]: "North & East",
  [MAP_REGION.southMetro]: "South",
  [MAP_REGION.westMetro]: "West",
  ...EDMONTON_REGIONS,
};
export const JURISDICTION_IDS = {
  beaumont: "beaumont",
  devon: "devon",
  edmonton: "edmonton",
  fort_saskatachewan: "fort_saskatachewan",
  leduc: "leduc",
  leduc_county: "leduc_county",
  morinville: "morinville",
  nisku: "nisku",
  parkland_county: "parkland_county",
  spruce_grove: "spruce_grove",
  st_albert: "st_albert",
  stony_plain: "stony_plain",
  strathcona_county: "strathcona_county",
  sturgeon_county: "sturgeon_county",
};
// export const METRO_JURISDICTION_MAP_REGION = {
//   beaumont: MAP_REGION.southMetro,
//   devon: MAP_REGION.southMetro,
//   fort_saskatachewan: MAP_REGION.northEastMetro,
//   leduc: MAP_REGION.southMetro,
//   leduc_county: MAP_REGION.southMetro,
//   morinville: MAP_REGION.northEastMetro,
//   nisku: MAP_REGION.southMetro,
//   parkland_county: MAP_REGION.westMetro,
//   spruce_grove: MAP_REGION.westMetro,
//   st_albert: MAP_REGION.northEastMetro,
//   stony_plain: MAP_REGION.westMetro,
//   strathcona_county: MAP_REGION.northEastMetro,
//   sturgeon_county: MAP_REGION.northEastMetro,
// };

export const ROADWAY_TYPES = [
  "Avenue",
  "Boulevard",
  "Close",
  "Court",
  "Crescent",
  "Drive",
  "Freeway",
  "Highway",
  "Lake",
  "Lane",
  "Road",
  "Street",
  "Trail",
  "Way",
];


export const QUADRANTS = ["NE", "NW", "SE", "SW"];

export const SECTIONS =    [
  "N",
  "NE",
  "E",
  "SE",
  "S",
  "SW",
  "W",
  "NW"
];

export const REGION_SUBTEXT = {
  northEastMetro:
    "Fort Saskatchewan, Morinville, Sherwood Park, St. Albert,  Sturgeon County, Strathcona County",
  southMetro:
    "Beaumont, City of Leduc, Devon, Leduc County (including Nisku Industrial Park)",
  westMetro:
    "Spruce Grove, Stony Plain, Parkland County (including Acheson Industrial Park)",
};



export const AREA_DISTRIBUTION_SM = AREA_DISTRIBUTION_ACRES.map(
  (i) => i * 4000
);

export const BUILD_MIN_AREA_SF = Array(30)
  .fill()
  .map((i, n) => {
    return { value: getBuildingSizeForOrdinal(n) };
  });



export const STATUS_OPTIONS = {
  published: { color: "green", text: "PUBLISHED", textColor: "#0eb87f", menuOptions: ['unpublish', 'archive', 'delete'] },
  changed: { color: "dodgerBlue", text: "CHANGED", textColor: "dodgerBlue", menuOptions: ['undoChanges', 'archive', 'publish', 'delete'] },
  draft: { color: "dodgerBlue", text: "DRAFT", textColor: "dodgerBlue", menuOptions: ['publish', 'delete'] },
  archived: { color: "#bdbdbd", text: "ARCHIVED", textColor: "dodgerBlue", menuOptions: ['unarchive', 'delete'] },
};

export const VERSION_STATUSES = {
  DRAFT: 'draft',
  CHANGED: 'changed',
  PUBLISHED: 'published'
}
export const DEFAULT_CENTER ={ lat: 53.5444, lng: -113.4909 }