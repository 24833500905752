import { useState, useEffect, useCallback } from "react";
import { User } from "types/AdminTypes";
import { SavedSearch } from "types/Types";
import firebase from "../config/firebase";

type Props = {
  savedSearches: SavedSearch[] | null;
  addSearch: (doc: SavedSearch) => string;
  saveSearch: (doc: SavedSearch) => void;
  deleteSearch: (doc: SavedSearch) => void;
};

export default function useStore(user: User): Props {
  const [savedSearches, setSavedSearches] = useState<SavedSearch[] | null>(
    null
  );
  const isUserLoggedIn = !!user;

  const getSavedSearchesCollection = useCallback(() => {
    const db = firebase.firestore();
    return isUserLoggedIn
      ? db.collection("users").doc(user.uid).collection("savedSearches")
      : null;
  }, [isUserLoggedIn, user.uid]);
  const savedSearchesCollection = getSavedSearchesCollection();
  if (!savedSearchesCollection) {
    throw new Error("No search collection");
  }

  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    const ssc = getSavedSearchesCollection();
    if (!ssc) {
      return;
    }

    const unsubscribe = ssc.onSnapshot(function (querySnapshot) {
      const queryItems = querySnapshot.docs.map((doc) => ({
        searchId: doc.id,
        ...doc.data(),
      })) as SavedSearch[];
      setSavedSearches(queryItems);
    });

    return () => unsubscribe();
  }, [isUserLoggedIn, getSavedSearchesCollection]);

  return {
    savedSearches: savedSearches,
    addSearch: (doc: SavedSearch) => {
      const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
      if (!savedSearchesCollection) {
        throw new Error("No search collection");
      }
      const newItemRef = savedSearchesCollection.doc();
      newItemRef.set({ ...doc, dateSaved });
      return newItemRef.id;
    },
    saveSearch: (doc: SavedSearch) => {
      const docRef = savedSearchesCollection.doc(doc.searchId);
      const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
      docRef.set({ ...doc, dateSaved });
    },
    deleteSearch: (doc: SavedSearch) => {
      const docRef = savedSearchesCollection.doc(doc.searchId);
      const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
      docRef.set({ ...doc, dateSaved, isDeleted: true });
    },
  };
}
