import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { TrafficFlowInfo, TrafficFlowInfoYear } from "types/Types";
import EditTable from "./EditTable";

type TrafficFlowEditorProps = {
    value?: TrafficFlowInfo;
    onBlur:(value:TrafficFlowInfo)=>void;
};

type State = TrafficFlowInfo;
type TableReturnData = TrafficFlowInfoYear[];
export default function TrafficFlowEditor({value, onBlur}: TrafficFlowEditorProps) {
  const [state, setState] = useState<State>({
    showTrafficFlow: value?.showTrafficFlow || false,
    trafficFlowInfoYears: value?.trafficFlowInfoYears || [],
    
  });
  function onTrafficFlowInfoBlur(data: TrafficFlowInfoYear[]) {
    //console.log(data);
    const newValue: TrafficFlowInfo = {
        showTrafficFlow: true,
        trafficFlowInfoYears: data,
    }
    onBlur(newValue);
    return new Promise(r => setTimeout(r, 1000));
  }


  return (
    <>
    <Grid item md={12}>
      <FormControlLabel
        control={
          <Switch
            checked={state.showTrafficFlow}
            onChange={() => {
              const useTrafficFlow = !state.showTrafficFlow;
              setState({ ...state, showTrafficFlow: useTrafficFlow });

              //saveAvalableSpaces( {...state, useTrafficFlow } );
            }}
          />
        }
        label="Show Traffic Flow Data"
      />

      {state.showTrafficFlow && (
      <EditTable
        title=""
        columns={[
          {
            title: "Year",
            field: "year",
            lookup: {
              2016: "2016",
              2017: "2017",
              2018: "2018",
              2019: "2019",
              2020: "2020",
              2021: "2021",
              2022: "2022",
            },

            
          },
          {
            title: "Adjacent Arterial",
            field: "adjacentArterial",
            
          },

          {
            title: "Measurement Unit",
            field: "measurementUnit",
            lookup: { avgWeekday: "Average Weekday", avgWeekly: "Average Weekly" },
          },
          {
            title: "Volume",
            field: "volume",
            type: "numeric",
            render: (rowData: TrafficFlowInfoYear) => {
                return <>{rowData.volume && rowData.volume.toLocaleString()}</>;
              },
          },
        ]}
        data={state.trafficFlowInfoYears}
        onBlur={onTrafficFlowInfoBlur}
        setData={(trafficFlowInfoYears: TrafficFlowInfoYear[]) => {
            setState({ ...state, trafficFlowInfoYears: trafficFlowInfoYears });
        }}
        hidePaging
        hideOuterBox
      />)}</Grid>
      </>
  );
}
