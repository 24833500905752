import { useEffect, useState } from "react";
import firebase from "config/firebase";
import { User } from "types/AdminTypes";
import {
  Listing,
  ListingInfo,
  VersionDoc,
  LISTING_STATUSES,
  DraftVersionDoc,
  LISTING_TYPE,
} from "types/Types";
import {
  getLastSaveObject,
  getListingInfoFromDoc,
  updateVersionLocationAndSaveData,
} from "./newStoreHelperMethods";
import { JurisdictionLookupsAdminDoc } from "types/AdminTypes";
import { Themes } from "themes";
import { canUserEditListing } from "common/newHelperFunctions";

export default function useEditorStore(user: User, orgId: keyof Themes) {
  const db = firebase.firestore();
  const [listings, setListings] = useState<Listing[] | null>(null);
  const [lookups, setLookups] = useState<any>(null);
  const [
    jurisdictionLookups,
    setJurisdictionLookups,
  ] = useState<JurisdictionLookupsAdminDoc | null>(null);

  const isUserLoggedIn = !!user;
  

  function filterOutUnavailableListings(listing:Listing, user:User){
    if(orgId !== "gex" && listing.orgId !== orgId){
      return false;
    }
    return canUserEditListing(listing, user);
  }
 
 


  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    const lookupsDocRef = db
    .collection("administration")
    .doc("brokeragesLookups");
    const jurisdictionLookupsDocRef = db
    .collection("administration")
    .doc("jurisdictionLookups");
    const listingsCollection = db.collection("listings");
    const unsubscribes = [
      listingsCollection.onSnapshot((querySnapshot) => {
        const listingDocs = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as Listing[];

        

        const queryDocs = listingDocs.filter(
          (d) => filterOutUnavailableListings(d, user)
        );
        setListings(queryDocs);
      }),
      lookupsDocRef.onSnapshot((docSnapshot) => {
        setLookups(docSnapshot.data());
      }),
      jurisdictionLookupsDocRef.onSnapshot((docSnapshot) => {
        setJurisdictionLookups(
          docSnapshot.data() as JurisdictionLookupsAdminDoc
        );
      }),
    ];

    return () => unsubscribes.forEach((s) => s());
  }, [isUserLoggedIn, db, user]);

  // i believe this is only used by ListingInfoEditor page which is for admins.
  async function refreshAllListings() {
    if (!listings) {
      throw new Error("No listings.");
    }
    listings.forEach(async (listing) => {
      const { id } = listing;

      const listingDocRef = db.collection("listings").doc(id);
      const versionCollectRef = listingDocRef.collection("versions");

      versionCollectRef.onSnapshot((querySnapshot) => {
        const queryDocs = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as VersionDoc[];
        queryDocs.forEach((doc) => {
          const batch = db.batch();
          const newDoc = { ...doc };
          newDoc.location.jurisdiction = "edmonton";
          newDoc.listingType = LISTING_TYPE.INDUSTRIAL_LAND;

          batch.set(versionCollectRef.doc(doc.id), newDoc);
          batch.commit();
        });
        // update listing doc.
        const batch = db.batch();
        batch.set(listingDocRef, {
          ...listing,
          listingType: LISTING_TYPE.INDUSTRIAL_LAND,
        });
        batch.commit();
      });
    });
  }

  async function adminSaveListingInfo(info: ListingInfo) {
    if (!isUserLoggedIn || !listings) {
      throw new Error("user not logged in");
    }
    const { id, mapRegion } = info;
    const listing = listings.find((l) => l.id === id);
    if (!listing) {
      throw new Error("listing not found");
    }

    if (mapRegion !== listing.listingInfo.mapRegion) {
      const batch = db.batch();
      const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
      const listingDocRef = db.collection("listings").doc(id);
      const versionDocRef = db
        .collection("listings")
        .doc(id)
        .collection("versions")
        .doc(listing.versionId);
      const versionDocSnapShot = await versionDocRef.get();
      const versionDoc = versionDocSnapShot.data();
      const newVersionDoc = { ...versionDoc, mapRegion } as VersionDoc;
      newVersionDoc.location.jurisdiction = "edmonton";

      batch.set(versionDocRef, newVersionDoc);
      batch.set(listingDocRef, {
        ...listing,
        listingInfo: { ...listing.listingInfo, mapRegion },
        lastSave: {
          date: dateSaved,
          uid: user.uid,
          userDisplayName: user.displayName,
        },
      });
      return batch.commit();
    }
    return new Promise((resolve) => {
      resolve(null);
    });
  }
  async function addNew(versionDoc: VersionDoc) {
    const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());

    if (!isUserLoggedIn) {
      throw new Error("user not logged in");
    }
    if (!jurisdictionLookups) {
      throw Error("jurisdictionLookups not set");
    }

    const listingCollectionRef = db.collection("listings");
    const listingDocRef = listingCollectionRef.doc();
    const listingId = listingDocRef.id;

    const versionDocRef = listingDocRef.collection("versions").doc();
    //const dateSaved = new firebase.firestore.Timestamp.fromDate(new Date());
    const updatedVersionDoc = {
      ...updateVersionLocationAndSaveData(versionDoc, dateSaved, user),
      listingId,
      id:versionDocRef.id

    };

    const info: ListingInfo = getListingInfoFromDoc(
      updatedVersionDoc,
      jurisdictionLookups
    ) as ListingInfo;
    const listingDoc: Listing = {
      id: listingId,
      listingInfo: info,
      versionId: versionDocRef.id,
      lastSave: getLastSaveObject(dateSaved, user),
      status: LISTING_STATUSES.DRAFT,
      listingType: versionDoc.listingType,
      orgId: orgId,
    };
    const batch = db.batch();
    batch.set(listingDocRef, { ...listingDoc, id: listingId } as any);
    batch.set(versionDocRef, updatedVersionDoc);
    await batch.commit();
    return listingId;
  }

  async function  copyListing (versionDoc: VersionDoc)  {
    const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
        const newAvailablity =versionDoc.availability ?   {...versionDoc.availability, brochure:null,video1:null,} : {};

        const newListing: VersionDoc = {...versionDoc, images:[], listingId:'', mapInfo:{},  availability:newAvailablity, lastSave:{date:dateSaved, uid:'', userDisplayName:'' }  };
        return addNew(newListing);
      }
  const editorStore: EditorStore | null =
    !!listings && !!lookups && !!jurisdictionLookups
      ? { listings, lookups, jurisdictionLookups }
      : null;
  const returnMethods: ReturnMethods = {
    addNew,
    adminSaveListingInfo,
    refreshAllListings,
    copyListing
  };
  return { editorStore, returnMethods };
}
interface EditorStore {
  listings: Listing[];
  lookups: any;
  jurisdictionLookups: JurisdictionLookupsAdminDoc;
}

interface ReturnMethods {
  addNew(versionDoc: DraftVersionDoc): Promise<string>;
  copyListing(versionDoc: DraftVersionDoc): Promise<string>;
  adminSaveListingInfo(info: ListingInfo): Promise<unknown>;
  refreshAllListings(): Promise<void>;
}
