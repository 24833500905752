import { Link } from "@reach/router";
import { AppContext } from "AppContext";
import HeaderBuilder from "components/HeaderBuilder";
import React, { useContext } from "react";
import styled from "styled-components";
import UserAvatar from "./UserAvatar";

const PageHeader = () => {
  const { user } = useContext(AppContext);
  return (
    <HeaderBuilder>
      <RightCell>
        <NavUl>
          <NavLi>
            {!user && <LoginButton />}
            {user && <UserAvatar />}
          </NavLi>
        </NavUl>
      </RightCell>
    </HeaderBuilder>
  );
};

const height = 70;

const LoginButton = () => {
  return (
    <StyledLink style={{ color: "#333", borderColor: "#888" }} to="/login">
      Log in
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  height: ${height - 2}px;
  line-height: ${height - 2}px;
  color: inherit;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  background: transparent;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  text-decoration: none;
  margin: 0px;
  padding: 0px 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.25s ease 0s;
  &:focus {
    outline: none !important;
  }
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom-width: 0px;

  &:hover {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 0px !important;
  }
  vertical-align: middle;
`;

const RightCell = styled.div`
  flex: 1;
  text-align: right;
`;

const NavUl = styled.ul`
  display: table;
  height: ${height}px;
  list-style: none;
  padding: 0px;
  margin: 0px;
  float: right;
`;

const NavLi = styled.li`
  display: table-cell;
  padding: 0 10px;
  vertical-align: top;
`;

export default PageHeader;
