import { Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import React from "react";
import { VersionDoc } from "types/Types";
import FileDownloadLink from "./FileDownloadLink";
import FileUploader from "./FileUploader";
import ButtonMenuDelete from "./ButtonMenuDelete";
type Props = {
  versionDoc: VersionDoc;
  value: string;
  onBlur: (value: string | null) => void;
  getStorageRef: () => firebase.storage.Reference;
  disabled: boolean;
  includeLink?: boolean;
};
const FileControl = ({
  versionDoc,
  value,
  onBlur,
  getStorageRef,
  disabled,
  includeLink,
}: Props) => {
  function uploadComplete(fileName: string) {
    onBlur(fileName);
  }
  const valueIsLink =
    !!value &&
    (value.indexOf("http://") === 0 || value.indexOf("https://") === 0);
  if (valueIsLink) {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <LinkContainer>
          <Link  variant="body2" href={value} target="_blank" >
            Link to Document
          </Link>
          <ButtonMenuDelete onDelete={() => onBlur(null)} />
        </LinkContainer>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      {!!value ? (
        <FileDownloadLink
          fileName={value}
          listingId={versionDoc.listingId}
          deleteFile={() => onBlur(null)}
        />
      ) : (
        <FileUploader
          acceptString="image/*,application/pdf"
          getStorageRef={getStorageRef}
          uploadComplete={uploadComplete}
          disabled={disabled}
          includeLink={includeLink}
          value={value}
        />
      )}
    </Grid>
  );
};

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default FileControl;
