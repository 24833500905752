import { Button, TextField, MenuItem, IconButton } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { EmailRole } from "types/Types";
type EmailTableProps = {
  roles: string[];
  emailRoleArray: EmailRole[];
  addRow(): void;
  updateRow(ordinnal: number, email: string, role: string): void;
    deleteRow(ordinnal: number): void;
    showErrors: boolean;
};

const EmailTable = ({ roles, emailRoleArray, addRow, updateRow, deleteRow, showErrors }: EmailTableProps) => {

    

  return (
    <div>
      {emailRoleArray.map((er, index) => {
        const roleError = showErrors && er.role === "";
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailError = showErrors && !emailRegex.test(er.email) ;
        return (
          <Row key={index}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              value={er.email}
              onChange={(e) => updateRow(index, e.target.value, er.role)}
              error={emailError}
              helperText={emailError   && 'Enter Valid Email'}
            />
            <TextField
              label="Role"
              select
              variant="filled"
              fullWidth
              value={er.role}
              onChange={(e) => updateRow(index, er.email, e.target.value)}
              error={roleError}
              helperText={roleError && 'Required'}
              
            >
                {roles.map((role) => (<MenuItem key={role} value={role}>{role}</MenuItem>) )}
            </TextField>
            <div>
            <IconButton
                    aria-label="Edit Contact"
                    edge="end"
                    color="secondary"
                    onClick={() => deleteRow(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </div>
          </Row>
        );
      })}
      <Button color="primary" startIcon={<AddIcon />} onClick={addRow}>
        Add User
      </Button>
    </div>
  );
};

const ButtonRow = styled.div`
  background-color: #f2f2f2;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  & > div {
    margin: 10px;
    }
`;
export default EmailTable;
