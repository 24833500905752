import { Paper, withTheme } from "@material-ui/core";
import RowListItem from "pages/components/RowListItem";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { MapListingInfo } from "types/Types";

type ListingPanelProps = {
  listings: MapListingInfo[];
  rowItemClick(id: string | null): void;
  rowItemHover(id: string | null): void;
  children: ReactNode;
  selectedItemId: string | null;
  isPublic: boolean;
  isRealtor: boolean;
  shouldShowEditButton:(orgId:string) => boolean;
};
export default function ListingPanel({
  listings,
  rowItemClick,
  rowItemHover,
  selectedItemId,
  children,
  isPublic,
  isRealtor,
  shouldShowEditButton
}: ListingPanelProps) {
  return (
    <Paper style={{ margin: "0 16px 0px 8px" }} elevation={2}>
      {children}
      <ListingHolder>
        <NumLots>{`${listings.length} Records`}</NumLots>

        {listings.length === 0 && (
          <NoRecordsMessage>Adjust the search filters.</NoRecordsMessage>
        )}
        {listings.map((land: any, n: number) => {
          return (
            <RowListItem
              key={`row_${n}`}
              land={land}
              onClick={rowItemClick}
              onHover={rowItemHover}
              isSelected={land.id === selectedItemId}
              isPublic={isPublic}
              isRealtor={isRealtor}
              showEditButton={shouldShowEditButton(land.orgId)}
            />
          );
          //   return showMap ? (
          //     row
          //   ) : (
          //     <LinkRowListItem
          //       href={`/${isPublic ? "pland" : "land"}/${land.id}`}
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       key={`link_${n}`}
          //     >
          //       {row}
          //     </LinkRowListItem>
          //   );
        })}
      </ListingHolder>
    </Paper>
  );
}
const NoRecordsMessage = styled.div``;
const NumLots = withTheme(styled.div`
  font-weight: 400;

  font-size: 16px;

  color: ${(props) => props.theme.palette.primary[600]};
  padding: 12px;
  font-family: "Montserrat", sans-serif;
`);

const ListingHolder = styled.div`
  padding: 12px;
`;
