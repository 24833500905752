import { AREA_DISTRIBUTION_ACRES } from "common/newConstants";
import React from "react";
import {
  AREA_DISTRIBUTION_SM,
  measureUnits
} from "../common/constants";

export function getDistributionByUnitType(measureUnit) {
  return measureUnit === measureUnits.METERS_SQUARED
    ? AREA_DISTRIBUTION_SM
    : AREA_DISTRIBUTION_ACRES;
}
export function getMeterSQForTickAndUnit(measureUnit, tick) {
  return measureUnit === measureUnits.METERS_SQUARED
    ? AREA_DISTRIBUTION_SM[tick]
    : AREA_DISTRIBUTION_ACRES[tick] * 4046.86;
}

export function getBuildingArea(area) {
  return area.toLocaleString(undefined, { maximumFractionDigits: 0 });
}
export function formatNumber(num) {
  return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,");
}

function getMetricString(acres) {
  return (Math.round(acres / 404.6856) * 10).toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
}
function getAcresString(acres) {
  return acres.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
}

export function getBuildingSizeForOrdinal(ordinal) {
  return ordinal > 10 ? ordinal * 1000 + (ordinal - 10) * 5000 : ordinal * 1000;
}

function getParcelSizeRange(areaAcres, measureUnit) {
  const min = Math.min(...areaAcres);
  const max = Math.max(...areaAcres);

  if (measureUnit === measureUnits.METERS_SQUARED)
    return (
      <>
        {`${getMetricString(min)}–${getMetricString(max)} m`}
        <sup>2</sup>
      </>
    );
  //const acres = parcelSize / 4046.856;
  return <>{`${getAcresString(min)}–${getAcresString(max)} acres`}</>;
}
function getSingleValueArea(parcelSize, measureUnit) {
  if (measureUnit === measureUnits.METERS_SQUARED)
    return (
      <>
        {`${getMetricString(parcelSize)} m`}
        <sup>2</sup>
      </>
    );

  return <>{`${getAcresString(parcelSize)} acres`}</>;
}

export function getParcelSize(areaAcres, measureUnit) {
  if (!Array.isArray(areaAcres)) {
    return getSingleValueArea(areaAcres, measureUnit);
  }
  if (areaAcres.length === 1) {
    return getSingleValueArea(areaAcres[0], measureUnit);
  }
  return getParcelSizeRange(areaAcres, measureUnit);
}






/**************************/
export const getValue = (lot, jsonPath) => {
  const jsonArray = jsonPath.split(".");
  return getValueFromJsonArray(lot, jsonArray);
};

function getValueFromJsonArray(obj, jsonArray) {
  const newArray = [...jsonArray];
  const prop = newArray.shift();
  const value = obj[prop];
  if (value === undefined) {
    return null;
  }

  if (newArray.length > 0 && value !== null) {
    return getValueFromJsonArray(value, newArray);
  }
  return value;
}


export function setValue(lot, jsonPath, value) {
  console.log(jsonPath, value)
  setValueForJsonArray(lot, jsonPath.split("."), value);
  return lot;
}
function setValueForJsonArray(obj, jsonArray, value) {
  const newArray = [...jsonArray];
  const prop = newArray.shift();

  if (newArray.length > 0) {
    if (!obj[prop]) {
      obj[prop] = {};
    }
    setValueForJsonArray(obj[prop], newArray, value);
    return;
  }
  obj[prop] = value;
}
export function isEmptyOrSpaces(str) {
  return (
    str === undefined ||
    str === null ||
    str === "" ||
    (typeof str === "string" && str.match(/^ *$/) !== null) ||
    (typeof str === "object" && isObjectEmpty(str))
  );
}
function isObjectEmpty(str) {
  for (let [, value] of Object.entries(str)) {
    if (!isEmptyOrSpaces(value)) {
      return false;
    }
  }
  return true;
}
export function getLetterForOrdinal(ordinal) {
  return (ordinal + 10).toString(36).toUpperCase();
}
export function getCombinationString(combination) {
  if (
    !combination.lots ||
    combination.lots.some((o) => typeof o !== "number")
  ) {
    return "invalid";
  }
  return combination.lots
    .sort()
    .map((o) => getLetterForOrdinal(o))
    .join(" + ");
}


export function sortString(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}
export function getLegalDescriptionString(legalDescription) {
  if (!legalDescription) {
    return <></>;
  }
  const { plan, block, lot, unit } = legalDescription;

  return <>{`${plan || "_"} ${block || "_"}  ${lot || "_"}   ${unit || "_"}`}</>;
}

export function getFriendlyDateWithTime(m) {
  const hour = m.getHours();
  const isPm = hour > 11;
  const displayHour = isPm ? hour - 12 : hour;
  const minNum = m.getUTCMinutes();
  const minStr = minNum === 0 ? "00" : minNum < 10 ? `0${minNum}` : `${minNum}`;
  return (
    m.toDateString() +
    " -  " +
    (displayHour || "12") +
    ":" +
    minStr +
    " " +
    (isPm ? "PM" : "AM")
  );
}
