import { withTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { ROADWAY_TYPES, SECTIONS } from "common/constants";
import { getListingTypeFriendlyText } from "common/newHelperFunctions";
import AddNewMapEditor from "components/controls/AddNewMapEditor";
import ListingMapEditor from "components/controls/ListingMapEditor";
import MapEditor from "components/controls/MapInfoControl";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { JurisdictionLookupsAdminDoc } from "types/AdminTypes";
import { IdName } from "types/fieldComponentTypes";
import { DraftVersionDoc, Location, Lookups } from "types/Types";
import { setValue } from "../../common/helperFunctions";
import * as fields from "../../types/fieldDefinitions";
import FieldGroupHeading from "../controls/FieldGroupHeading";
import FieldEditor from "../FieldEditor";
export interface AddNewDialogState {
  versionDoc: DraftVersionDoc;
  geoCode: any;
  activeStep: number;
  errors: Errors;
  isSaving: boolean;
  isDialogOpen: boolean;
}
interface Errors {
  address?:string;
  jurisdiction?: string;
  roadwayType?: string;
  ruralRoadNum?: string;
  ruralUnitNum?: string;
  ruralTypeNum?: string;
}
type Props = {
  versionDoc: DraftVersionDoc;
  handleChange: any;
  getStorageRef: any;
  errors: any;
  disabled: any;
  lookups: any;
  jurisdictionLookups: JurisdictionLookupsAdminDoc;
};
export default function AddNewDialog({
  open,
  onCancelClick,
  onFinishClick,
  lookups,
  jurisdictionLookups,
  state,
  setState,
}: {
  open: boolean;
  onCancelClick: () => void;
  onFinishClick: (versionDoc: DraftVersionDoc) => void;
  lookups: Lookups;
  jurisdictionLookups: JurisdictionLookupsAdminDoc;
  state: AddNewDialogState;
  setState: (state: AddNewDialogState) => void;
}) {
  // if(!state){
  //   return null;
  // }
  console.log(state)
  const { versionDoc, geoCode, activeStep, errors, isSaving } = state;
  function handleChange(jsonPath: string, value: any) {
    const newLot = setValue({ ...versionDoc }, jsonPath, value);
    if (value && !value.marker) {
      setState({ ...state, versionDoc: newLot, geoCode: null, errors: {} });
    } else {
      setState({ ...state, versionDoc: newLot, errors: {} });
    }

    //saveListing(newLot);
  }
  function handleFinish() {
    const errors: Errors = {};
 

    if (!versionDoc.location || !versionDoc.location.jurisdiction) {
      errors.jurisdiction = "select a jurisdiction";
    }

    

    if (
      versionDoc.location &&
      versionDoc.location.addressType === "urban" &&
      (!versionDoc.location.locationUrban ||
        !versionDoc.location.locationUrban.roadwayType)
    ) {
      errors.roadwayType = "select a roadway type";
    }
    if (
      !!versionDoc.location &&
      versionDoc.location.addressType === "rural"
      
    ) {
      if(!versionDoc.location.locationRural){
        errors.ruralRoadNum = "Enter Road #";
        errors.ruralUnitNum = "Enter Unit #";
        errors.ruralTypeNum = "Enter Type #";
      }
      else{
        const {
          ruralRoadNum,
          ruralUnitNum,
          ruralTypeNum,
        } = versionDoc.location.locationRural;
  
        if(!ruralRoadNum){
          errors.ruralRoadNum = "Enter  Road #";
        }
        if(!ruralUnitNum){
          errors.ruralUnitNum = "Enter Unit #";
        }
        if(!ruralTypeNum){
          errors.ruralTypeNum = "Enter Type #";
        }
      }
     
    }

    if (Object.keys(errors).length > 0) {
      setState({ ...state, errors });
      return;
    }
    setState({ ...state, isSaving: true });
    onFinishClick(state.versionDoc);
  }



  const props: Props = {
    versionDoc,
    handleChange,
    lookups,
    jurisdictionLookups,
    errors,
    getStorageRef: undefined,
    disabled: false,
  };

  //var geocoder = new window.google.maps.Geocoder();

  const setGeoCode = useCallback(
    (result) => {
      if (result) {
        const locationValues = parseAddressFromGeoCode(
          result,
          jurisdictionLookups
        );
        setState({
          ...state,
          geoCode: result,
          versionDoc: {
            ...state.versionDoc,
            location: locationValues || undefined,
          },
        });
      }
    },
    [jurisdictionLookups, setState, state]
  );
  return (
    <Dialog fullScreen open={open} onClose={onCancelClick}>
      <StyledAppBar>
        <Toolbar>
          <StyledTypography variant="h6">
            Add New {getListingTypeFriendlyText(versionDoc.listingType)}
          </StyledTypography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCancelClick}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>
      <DialogTitle id="alert-dialog-title">And New Listing</DialogTitle>
      <DialogContent>
        <Content>
          {isSaving && <div>Saving</div>}
          {!isSaving && activeStep === 0 && (
            <StepOne
              stepProps={props}
              geoCode={geoCode}
              setGeoCode={setGeoCode}
            />
          )}
          {!isSaving && activeStep === 1 && <StepTwo stepProps={props} />}
          {!isSaving && (
            <Stepper
              activeStep={activeStep}
              setActiveStep={(step: number) =>
                setState({ ...state, activeStep: step })
              }
              isBackDisabled={activeStep === 0}
              isNextDisabled={!versionDoc.mapInfo.marker}
              handleFinish={handleFinish}
            />
          )}
        </Content>
      </DialogContent>
    </Dialog>
  );
}

const StepOne = ({
  stepProps,
  geoCode,
  setGeoCode,
}: {
  stepProps: Props;
  geoCode: google.maps.GeocoderResult;
  setGeoCode: (result: google.maps.GeocoderResult | null) => void;
}) => {
  const { versionDoc } = stepProps;

  useEffect(
    function () {
      if (window.google && versionDoc.mapInfo.marker) {
        const geocoder = new window.google.maps.Geocoder();
        const { lat, lng } = versionDoc.mapInfo.marker;
        var latLng = new window.google.maps.LatLng(lat, lng);
        const request: google.maps.GeocoderRequest = { location: latLng };
        geocoder.geocode(request, function (results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results![0]) {
              setGeoCode(results![0]);
            } else {
              setGeoCode(null);
            }
          } else {
            setGeoCode(null);
          }
        });
      }
    },
    [versionDoc.mapInfo.marker, setGeoCode]
  );

  return (
    <Grid container spacing={3}>
      <FieldGroupHeading>Add Location Marker</FieldGroupHeading>
      {/* <FieldEditor field={fields.mapInfo} {...stepProps} /> */}
      {/* <MapEditor field={fields.mapInfo} {...stepProps} /> */}
      <AddNewMapEditor
        field={fields.mapInfo}
        mapInfo={versionDoc.mapInfo}
        handleChange={stepProps.handleChange}

      />
      <Grid item md={12}>
        <GeometryLabel geoCode={geoCode} />
      </Grid>
    </Grid>
  );
};
const StepTwo = ({ stepProps }: { stepProps: Props }) => {
  const { versionDoc } = stepProps;
  const addressType = versionDoc?.location?.addressType;

  return (
    <Grid container spacing={3}>
      <FieldEditor field={fields.jurisdiction} {...stepProps} />

      <Break />
      <FieldEditor field={fields.addressType} {...stepProps} />
      {(!addressType || addressType === "urban") && (
        <FieldEditor field={fields.locationUrban} {...stepProps} />
      )}
      {addressType === "rural" && (
        <FieldEditor field={fields.locationRural} {...stepProps} />
      )}
      {addressType === "none" && (
        <FieldEditor field={fields.locationNone} {...stepProps} />
      )}
    </Grid>
  );
};

function parseAddressFromGeoCode(
  geoCode: google.maps.GeocoderResult,
  jurisdictionsLookups: JurisdictionLookupsAdminDoc
): Location | null {
  //   const geoStreetNumber = geoCode.address_components.find(gc => gc.types.includes("street_number"));
  //   const geoRoute= geoCode.address_components.find(gc => gc.types.includes("route"));
  //   const addressNumber = geoStreetNumber.short_name;
  //   const roadwayLong = geoRoute.long_name;
  const jurisdiction = getLookupJurisdiction(jurisdictionsLookups, geoCode);
  if (!jurisdiction) {
    return null;
  }
  const isUrban = getIsUrbanFromGeoCode(geoCode);

  const addressObject = isUrban
    ? getUrbanAddressFromGeoCode(geoCode, SECTIONS, ROADWAY_TYPES)
    : getRuralAddressFromGeoCode(geoCode);
  return {
    jurisdiction,
    addressType: isUrban ? "urban" : "rural",
    ...addressObject,
  };
}
function getUrbanAddressFromGeoCode(
  geoCode: google.maps.GeocoderResult,
  sections: string[],
  roadwayTypes: string[]
) {
  const geoStreetNumber = geoCode.address_components.find((gc) =>
    gc.types.includes("street_number")
  );
  const geoRoute = geoCode.address_components.find((gc) =>
    gc.types.includes("route")
  );
  let quadrant = null;
  let roadwayName = null;
  let roadwayType = null;
  if (!!geoRoute) {
    const route = geoRoute.short_name;
    const lastTwoCharacters = route.substring(route.length - 2).trim();
    const isOk = sections.includes(lastTwoCharacters);
    if (isOk) {
      quadrant = lastTwoCharacters;
    }
    const routeParts = geoRoute.long_name.split(" ");
    roadwayName = routeParts[0];
    roadwayType = roadwayTypes.includes(routeParts[1]) ? routeParts[1] : null;
  }
  return {
    locationUrban: {
      addressNumber: (geoStreetNumber && geoStreetNumber.short_name) || null,
      quadrant,
      roadwayName,
      roadwayType,
    },
  };
}
function getRuralAddressFromGeoCode(geoCode: google.maps.GeocoderResult) {
  return {};
}
function getLookupJurisdiction(
  jurisdictionLookups: JurisdictionLookupsAdminDoc,
  geoCode: google.maps.GeocoderResult
): string | null {
  const geoLocality = geoCode.address_components.find((gc) =>
    gc.types.includes("locality")
  );
  if (geoLocality) {
    //TODO: remove Acheson and add something to the lookup so that it will work.
    const lUpJurisdiction = jurisdictionLookups.jurisdictions.find(
      (j: IdName) => j.name === geoLocality.short_name || (geoLocality.short_name === "Acheson" && j.name ==="Parkland County")
    );
    if (lUpJurisdiction) {
      return lUpJurisdiction.id;
    }
  }

  const geoAdmiAreaLevel3 = geoCode.address_components.find((gc) =>
    gc.types.includes("administrative_area_level_3")
  );
  if (geoAdmiAreaLevel3) {
    const lUpJurisdiction2 = jurisdictionLookups.jurisdictions.find(
      (j: IdName) => j.name === geoAdmiAreaLevel3.short_name
    );
    if (lUpJurisdiction2) {
      return lUpJurisdiction2.id;
    }
  }
  return null;
}
function getIsUrbanFromGeoCode(geoCode: google.maps.GeocoderResult) {
  const geoRoute = geoCode.address_components.find(
    (gc) =>
      (gc.short_name && gc.short_name.includes("Range Rd")) ||
      gc.short_name.includes("Township Rd ")
  );
  return !geoRoute;
}

const Stepper = ({
  activeStep,
  isBackDisabled,
  isNextDisabled,
  setActiveStep,
  handleFinish,
}: {
  activeStep: number;
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  setActiveStep: (num: number) => void;
  handleFinish: () => void;
}) => (
  <StepperContainer>
    <Button
      onClick={() => setActiveStep(activeStep - 1)}
      disabled={isBackDisabled}
    >
      Back
    </Button>
    <Spacer />
    <PrimaryButton
      variant="contained"
      onClick={() =>
        activeStep === 1 ? handleFinish() : setActiveStep(activeStep + 1)
      }
      color="primary"
      disabled={isNextDisabled}
    >
      {activeStep === 1 ? "Finish" : "Next"}
    </PrimaryButton>
  </StepperContainer>
);

const StepperContainer = styled.div`
  margin-top: 22px;
  text-align: right;
  margin-right: 20px;
`;

const Content = styled.div`
  margin: 60px auto;
  max-width: 900px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 4px;
`;

const StyledTypography = styled(Typography)`
  flex: 1;
`;
const StyledAppBar = withTheme(styled(AppBar)`
  position: relative;
  color: #fff !important;
  background-color: ${(props) => props.theme.palette.primary[600]} !important;
`);
const GeometryLabel = ({
  geoCode,
}: {
  geoCode: google.maps.GeocoderResult | null;
}) => {
  if (!!geoCode) {
    return <>{geoCode.formatted_address}</>;
  }
  return <></>;
};
const PrimaryButton = styled(Button)`
  color: #fff !important;
  text-transform: uppercase;
`;
const Spacer = styled.div`
  display: inline-block;
  width: 9px;
`;
const Break = () => <Grid item xs={12}></Grid>;
