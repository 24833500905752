import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { IconButton } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
type LinkEditorControlProps = {
  url: string;
  setUrl: (url: string) => void;
  cancelClick: () => void;
};
export default function LinkEditorControl({
  setUrl,
  url,
  cancelClick,
}: LinkEditorControlProps) {
  const [value, setValue] = useState<string>(url || "");
  //const [isValid, setIsValid] = useState<boolean>(true)

  function validURL(str: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function saveURL() {
    if( value.indexOf('http') === 0 ){
        console.log('good to save')
        setUrl(value);
    }
    else{
        const valueWithPrefix = `https://${value}`
        setUrl(valueWithPrefix);
    }
  }

  const isValidUrl = validURL(value);

  const showError = value === "" || isValidUrl;

  return (
    <Container>
      <TextField
        label="URL"
        variant="filled"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        error={!showError}
        helperText={!showError && "Incorrect URL."}
        style={{ width: 300 }}
      />
      <IconButton disabled={!isValidUrl} onClick={saveURL}>
        <SaveIcon />
      </IconButton>
      <IconButton onClick={cancelClick}>
        <CancelIcon />
      </IconButton>
    </Container>
  );
}

const Container = styled.div``;
