import HeaderBuilder from "components/HeaderBuilder";
import React from "react";
import styled from "styled-components";
import LogoControl from "../../components/controls/LogoControl";
import ExtraElementsButton from "./ExtraElementsButton";

const MainHeader = () => {
  //const {company} = useTheme();

  return (
    <HeaderBuilder noLogo>
      <LogoControl redirectToWebsite />
      <RightCell>
        <ExtraElementsButton />
      </RightCell>
    </HeaderBuilder>
  );
};
// const StyledAppBar = styled(AppBar)`
//   z-index: 1201 !important;
//   background-color: #fff !important;
//   border-bottom: 1px solid #ccc;
// `;

const RightCell = styled.div`
  flex: 1;
  text-align: right;
`;

export default MainHeader;
