import { withTheme } from '@material-ui/core/styles';
import { InfoWindow } from "@react-google-maps/api";
import { AppContext } from 'AppContext';
import { canUserEditListing, canUserEditMapListingInfo } from 'common/newHelperFunctions';
import React from "react";
import styled from "styled-components";
import { ListingInfo, LISTING_STATUSES, MapListingInfo } from 'types/Types';
import ImgViewer300 from "../../components/controls/ImgViewer300";
import ListItemDetails from "../../components/lists/ListItemDetails";

type Props = {
  isEditor:boolean;
  isPublic:boolean;
  isRealtor:boolean;
  land:MapListingInfo;
  markerClose(): void;
}

const MarkerInfoWindow = ({
  isEditor,
  isPublic,
  isRealtor,
  land,
  markerClose,

}:Props) => {
  const primaryImage = land.images && land.images.find((i) => i.isPrimary);
  const {user} = React.useContext(AppContext);
 
  const canUserEdit = !user ? false :  canUserEditMapListingInfo(land, user);

  //const canUserEdit = canUserEditListing(land , user);
  return (
    <InfoWindow onCloseClick={markerClose} position={land.mapInfo.marker} options={{pixelOffset: new google.maps.Size(0,-40)}}>
 

          <div style={{ width: 300 }}>
            {primaryImage && (
              <ImgViewer300
                fullImageName={primaryImage.fullImageName}
                listingId={land.id}
                height={100}
                onClick={()=>{}}
              />
            )}
            <ListItemDetails  listingItemDetail={land} isMap />
            <BtnDiv>
              {land.status === LISTING_STATUSES.PUBLISHED && (<OpenButton
              href={`/${isPublic ? "pland" : isRealtor ? "rland" : "land"}/${land.id}`}
              target="_blank"
              rel="noopener noreferrer"
              
              >OPEN</OpenButton>)}
              {canUserEdit && (
                <EditButton href={`/editor/${land.id}`} target="_blank">
                  EDIT
                </EditButton>
              )}
            </BtnDiv>
          </div>


    </InfoWindow>
  );
};
const BtnDiv = styled.div`
  text-align: center;
`;
const OpenButton = withTheme(styled.a`
  background-color: ${props => props.theme.palette.primary.main};
  color: #fff;
  width: 90px;
  text-align: center;
  padding: 8px 0;
  text-decoration: none;
  border-radius: 3px;
  box-shadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)";
  display: inline-block;
  margin: 0 5px;
`);
const EditButton = withTheme(styled.a`
  background-color: #fff;
  border: 1px solid ${props => props.theme.palette.primary[800]};
  color: ${props => {
    return props.theme.palette.primary[500]
  }};
  width: 60px;
  text-align: center;
  padding: 7px 0;
  border-radius: 3px;
  box-shadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)";
  display: inline-block;
  text-decoration: none;
  margin: 0 5px;
  :hover{
    background-color:${props => props.theme.palette.primary[100]};
}
`);
export default styled(MarkerInfoWindow)`
  text-decoration: none;
`;
