import { Button } from "@material-ui/core";
import { navigate } from "@reach/router";
import { AppContext } from "AppContext";

import HeaderBuilder from "components/HeaderBuilder";
import React, { useContext } from "react";
import styled from "styled-components";
import ExtraElementsButton from "./ExtraElementsButton";
import UserAvatar from "./UserAvatar";

const MainHeader = ({isLoading = false, noExtra=false, overrideIsExtraElementsOpen=false}:{isLoading?:boolean, noExtra?:boolean, overrideIsExtraElementsOpen?:boolean}) => {

  const { user } = useContext(AppContext);
  return (<>
    <HeaderBuilder>
      <RightCell>
        {!user && (<Button
        onClick={() => navigate(`/`)}
        >Login</Button>)}
        {!!user  && (<>
          { !noExtra && <ExtraElementsButton overrideIsOpen={overrideIsExtraElementsOpen} /> }
        <UserAvatar />
        </>)}
      </RightCell>
    </HeaderBuilder>

   </>
  );
};

const RightCell = styled.div`
  flex: 1;
  text-align: right;
`;

export default MainHeader;
