/* global google */
export function createNewPolygon(spacing, marker) {
    const {lat, lng} = marker
    return [{ lat: lat - spacing, lng: lng - spacing },
    { lat: lat - spacing, lng: lng + spacing },
    { lat: lat + spacing, lng: lng + spacing },
    { lat: lat + spacing, lng: lng - spacing }];
}

export function getGeoAcres(polygon) {
    if (google.maps.geometry) {
      var googleLatLngs = polygon.map((ll) => {
        return new google.maps.LatLng(ll.lat, ll.lng);
      });
      const sizeSM =  google.maps.geometry.spherical.computeArea(googleLatLngs);
      const sizeAcres = sizeSM / 4047;
      const sizeAcresRounded = Math.round(sizeAcres * 100)/100;
      return sizeAcresRounded;
    }
    return null;
  }