import { DEFAULT_CENTER } from "common/constants";
import React, { useState } from "react";
import styled from "styled-components";
import { Field } from "types/fieldComponentTypes";
import { MapInfo } from "types/Types";
import MultipleZoneMapEditor, { Zone } from "./MultipleZoneMapEditor";
type ListingMapEditorProps = {
  field: Field;
  value: MapInfo;
  handleChange(jsonPath: string, value: any): void;
};
const ListingMapEditor = React.memo(
  ({ field, value, handleChange }: ListingMapEditorProps) => {
    //const [zones, setZones] = useState<Zone[]>(value.zones || []);

    const zones: Zone[] = [
      {
        marker: value.marker || null,
        polygon: value.polygon || null,
        area: 0,
        description: "Main",
      },
      {
        marker: value.secondaryMarker || null,
        polygon: value.secondaryPolygon || null,
        area: 0,
        description: "Secondary (if applicable)",
      },
    ];
    return (
      <>
        <MultipleZoneMapEditor
          center={value.center || DEFAULT_CENTER}
          zones={zones}
          zoom={value.zoom || 12}
          updateMap={(center, zoom, zones) => {
           
            if (zones.length !== 2) {
              return;
            }
            const zone0 = zones[0];
            const zone1 = zones[1];
            const newMapInfo: MapInfo = {
              center,
              zoom,
              ...(zone0.marker && { marker: zone0.marker }),
              ...(zone0.polygon && { polygon: zone0.polygon }),
              ...(zone1.marker && { secondaryMarker: zone1.marker }),
              ...(zone1.polygon && { secondaryPolygon: zone1.polygon }),
            };
            handleChange(field.jsonPath, newMapInfo);
          }}
        />
      </>
    );
  }
);

export default ListingMapEditor;
