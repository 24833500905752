import React, { useState } from "react";
import styled from "styled-components";
import ModalBackground from "./ModalBackground";
import Button from "./controls/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextField from "@material-ui/core/TextField";
import Snackbar from "./controls/SnackBar";
const SharedSearchModal = ({ userId, searchId, closeForm }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const link = `${window.location.origin}\\pl\\${userId}\\${searchId}`;
  return (
    <ModalBackground>
      <Container>
        <FormHolder>
          <h1>Share Listings</h1>
          <p>Below is a url to the results. You can send these to your client by sending them this link.</p>
          <TextField
          style={{ width: "100%"
        
        }}
            defaultValue={link} 
            multiline
            variant="filled"
            InputProps={{
              readOnly: true
            }}
          />
        </FormHolder>
        <ButtonHolder>
          <CopyToClipboard text={link} onCopy={() => setIsLinkCopied(true)}>
          <Button>Copy to Clipboard</Button>

          </CopyToClipboard>
          <Button onClick={() => closeForm()}>Close</Button>
        </ButtonHolder>
      </Container>
      <Snackbar 
        isSnackbarOpen={isLinkCopied}
        setIsOpen={isOpen => setIsLinkCopied(isOpen)}
        
        variant='success'
        message='Link copied to Clipboard!'

      />
    </ModalBackground>
  );
};
const Container = styled.div`
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  background-color: #fff;
  margin: auto;
`;
const FormHolder = styled.div`
  padding: 30px;
  > h1 {
    color: green;
    font-weight: 500;
    margin-top: 0px;
  }
  > textarea {
    width: 100%;
  }
`;
const ButtonHolder = styled.div`
  padding: 0 30px 30px 30px;
  text-align: right;
`;

export default SharedSearchModal;
