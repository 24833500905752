import React from "react";
import styled from "styled-components";
import MapCheckboxControl from "../../pages/components/controls/MapCheckboxControl";
import MapControl from "../../pages/components/controls/MapControl";
import { Heading } from "components/searchPopup/styledComponents";

type Props = {
  state: any;
  setState(s:object):void;
}

const LocationForm : React.FC<Props> = ({ state, setState }) => {
  const setARegionsValue = (regionName:string) => {
    setState({ ...state, [regionName]: !state[regionName] });
  };
  
  return (
    <Container>
      <MapHolder>
        <MapControl regions={state} setRegions={setARegionsValue} />
      </MapHolder>
      <FormHolder>
        <Heading>Select Search Area(s)</Heading>
        <MapCheckboxControl regions={state} setRegions={setARegionsValue} />
      </FormHolder>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  width: 1080px;
  background-color: #f6f6f6;
`;
const MapHolder = styled.div`
  width: 500px;
`;
const FormHolder = styled.div`
  flex: 1;
  padding-left: 30px;
`;
export default LocationForm;
