import Add from "@material-ui/icons/Add";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef } from "react";

const tableIcons = {
  Add: forwardRef((props, ref) => <Add {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function EditTable({
  title,
  columns,
  data,
  onBlur,
  setData,
  formatData = (event, data) => data,
  hidePaging = false,
  hideOuterBox = false,
  disabled = false,
  pageSize = 10,
  
}) {

  function stripTableData(rows) {
    return rows.map((r) => {
      const { tableData, ...props } = r;
      return props;
    });
  }

  const components = hideOuterBox
    ? {
        Container: (props) => {
          return <div style={props.style}>{props.children}</div>;
        },
      }
    : null;


  return (
    <MaterialTable
      icons={tableIcons}
      title={title}
      components={components}
      options={{
        search: false,
        pageSize,
        actionsColumnIndex: -1,
        paging: !hidePaging,
      }}
      disabled={disabled}
      columns={columns}
      data={data}
      editable={ disabled ? {} : {
        onRowAdd: (newData) => {
          const newState = [...data];
          newState.push(formatData('add', newData));
          setData(newState)
          return onBlur(stripTableData(newState));
        },
        onRowUpdate: (newData, oldData) => {
          
          if (oldData) {
            const newState = [...data];
            newState[data.indexOf(oldData)] = formatData('update', newData);
            setData(newState)

            return onBlur(stripTableData(newState), newData);
          }
          return new Promise((resolve) => {
            resolve();
          });
        },
        onRowDelete: (oldData) => {
          let newData = [...data];
          newData.splice(data.indexOf(oldData), 1);
          setData(newData)
          return onBlur(stripTableData(newData));
        },
      }}
    />
  );
}
