import { FormHelperText } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { CellColumnNumber, IdName, MultiPickField } from "types/fieldComponentTypes";


function optionsContainAllValues(options : string[], values : string[]){
  for(var v of values){
    if(!options.includes(v)){
      return false;
    }
  }
  return true;
}
type Props = {
  id:string,
  field: MultiPickField<string | IdName>,
  value:string[],
  xs: CellColumnNumber,
  sm: CellColumnNumber,
  md: CellColumnNumber,
  disabled: boolean,
  onBlur: (value:string) => void,
  handleChange: (jsonPath: string, value: string | null  ) => void,
  options: string[],
  error: string | null,
}
const MultiPickControl = ({id,  field, value, xs, sm, md, onBlur, options, error, disabled } : Props) => {
  const [state, setState] = useState<string[]>(value);
  useEffect(() => {
    setState(value);
  }, [value]);
  if(value && value.length > 0 && !optionsContainAllValues(options, value)) {
    //for some reason value is no longer an option
    const  s = new Set([...options, ...value]);
    options = Array.from(s.values());
  }
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <FormControl variant="filled" fullWidth error={!!error} >
        <InputLabel id={id} >
          {field.formLabelOverride || field.label} 
        </InputLabel>
        <Select
          disabled={disabled}
          labelId={id}
          value={state || []}
          onChange={(e:any) => {
            setState(e.target.value);
            onBlur(e.target.value)
          }}
          renderValue={(selected:any) => {
            return selected.join(", ");
          }}

          multiple
          error={!!error}
        >
          {options.sort().map((name, n) => (
            <MenuItem  value={name} key={`mi_${n}`}>
              <Checkbox checked={!!state && state.some(v => v === name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{ error || (field.isRequired ? 'Required' : null)}</FormHelperText>
      </FormControl>
    </Grid>
  );
};

export default MultiPickControl;
