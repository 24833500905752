import { Button, MenuItem, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import React, { useState } from "react";
import styled from "styled-components";
import { getLetterForOrdinal } from "../../common/helperFunctions";
import { createNewPolygon } from "../../common/mapHelpers";
import MultipleZoneMap from "./MultipleZoneMap";
import { Point } from "types/Types";
import MultipleZoneTable from "./MultipleZoneTable";
export type Zone = {
  marker: Point | null;
  polygon: Point[] | null;
  area: number | null;
  description: string | null;
};

type MultipleZoneMapEditorProps = {
  zones: Zone[];
  center: Point;
  zoom: number;
  disabled?: boolean;
  updateMap(center: Point, zoom: number, zones: Zone[]): void;
};

const MultipleZoneMapEditor = React.memo(
  ({
    zones,
    center,
    zoom,
    disabled,
    updateMap,
  }: MultipleZoneMapEditorProps) => {

    const [selectedOrdinal, setSelectedOrdinal] = useState(-1);


    function updateZone(
      ordinal: number,
      marker: Point | null,
      polygon: Point[] | null
    ) {

      const currentLot = zones[ordinal];
      const newLots = [...zones];
      newLots[ordinal] = {
        area: currentLot.area || null,
        description: currentLot.description || null,
        marker,
        polygon: polygon || null,
      };
      updateMap(center, zoom, newLots);
    }

    function updateLotPolygon(ordinal: number, polygon: Point[]) {
      const selectedZone = zones[ordinal];
      updateZone(ordinal, selectedZone.marker, polygon);
    }
    function updateLotMarker(ordinal: number, marker: Point) {
      const selectedZone = zones[ordinal];
      updateZone(ordinal, marker, selectedZone.polygon);
    }
    function addPin() {
      updateLotMarker(selectedOrdinal, center);
    }
    function addPolygon() {
      const selectedZone = zones[selectedOrdinal] as Zone;
      if (!selectedZone || !selectedZone.marker) {
        return;
      }
      const polygon = createNewPolygon(0.0005, selectedZone.marker);
      updateLotPolygon(selectedOrdinal, polygon);
    }
    function removePin() {
      //const newLots = [...zones];
      // newLots[selectedOrdinal] = {} as Zone;
      // updateMap(center, zoom, newLots);
      updateZone(selectedOrdinal, null, null);
    }
    function removePolygon() {
      // const { marker } = zones[selectedOrdinal] as Zone;
      // const newLots = [...zones];
      // newLots[selectedOrdinal] = { marker: marker, polygon: null } as Zone;
      // updateMap(center, zoom, newLots);
      const selectedZone = zones[selectedOrdinal];
      updateZone(selectedOrdinal, selectedZone.marker, null);
    }
    const btns = (() => {
      const b = [];
      if (selectedOrdinal > -1) {
        const current = zones[selectedOrdinal];
        if (!current) {
          setSelectedOrdinal(-1);
          return;
        }
        if (!current.marker) {
          b.push({ onClick: addPin, text: "Add Pin", color: "default" });
        } else if (!current.polygon) {
          b.push({ onClick: addPolygon, text: " Add Polygon", color: "default"  });
        }
        if (current.polygon) {
          b.push({ onClick: removePolygon, text: "Remove Polygon", color: "secondary"  });
        }
        if (current.marker) {
          b.push({ onClick: removePin, text: "Remove", color: "secondary"  });
        }
      }
      return b;
    })() as {onClick: () => void, text: string, color: "default" | "secondary" }[];

    const mapMarkers = zones
      //.filter((z) => !!z.marker)
      .map((l, n) => ({ ordinal: n, marker: l.marker }))
      .sort((a, b) => {
        const aIsSelected = a.ordinal === selectedOrdinal;
        const bIsSelected = b.ordinal === selectedOrdinal;
        if (aIsSelected) {
          return -1;
        }
        if (bIsSelected) {
          return 1;
        }
        return a.ordinal - b.ordinal;
      }) as { ordinal: number; marker: Point | null }[];
    const mapPolygons = zones
      .map((l, n) => ({ ordinal: n, polygon: l.polygon }))
      .filter((l) => l.polygon) as { ordinal: number; polygon: Point[] }[];
    return (
      <>
        <Grid item lg={12}>
          <MultipleZoneTable
            zones={zones}
            selectedOrdinal={selectedOrdinal}
            setSelectedOrdinal={setSelectedOrdinal}
            addPin={addPin}
            addPolygon={addPolygon}
            removePin={removePin}
            removePolygon={removePolygon}
          />
        </Grid>
        <Grid item lg={12}>
          <MultipleZoneMap
            zoom={zoom}
            center={center}
            mapMarkers={mapMarkers}
            mapPolygons={mapPolygons}
            selectedOrdinal={selectedOrdinal}
            setSelectedOrdinal={setSelectedOrdinal}
            selectedMarkerChanged={updateLotMarker}
            selectedPolygonChanged={updateLotPolygon}
            mapZoomChanged={(z) => updateMap(center, z, zones)}
            mapCenterChanged={(c) => updateMap(c, zoom, zones)}
            disabled={disabled}
          />
        </Grid>
      </>
    );
  }
);

const BtnRow = styled.div`
  padding: 10px;

  & > div {
    margin: 0 16px;
  }
  border-bottom: 1px solid #ccc;
`;

export default MultipleZoneMapEditor;
