import { LoadScript } from "@react-google-maps/api";
import { DEFAULT_CENTER } from "common/constants";
import MainTabs from "components/MainTabs";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { LISTING_TYPE, MapListingInfo } from "types/Types";
import { useWindowDimensions } from "../../common/hooks";
import GoogleMap from "./GoogleMap";
type ListMapControlsProps = {
  children: ReactNode;
  listings: MapListingInfo[];
  mapState: { selectedMarkerId: string | null; hoverMarkerId?: string | null };
  setMapState(state: { selectedMarkerId: string | null }): void;
  hideMap: boolean;
  mode: Mode;
  canUserSearch: boolean;
  canUserEdit: boolean;
  addNewClick?(listingType:LISTING_TYPE):void;
  overrideIsAddOpen?:boolean;
};

type Mode = "editor" | "search" | "savedSearches" | "public" | "realtor";

const ListMapControl = ({
  mode,
  children,
  listings,
  mapState,
  setMapState,
  hideMap,
  canUserSearch,
  canUserEdit,
  addNewClick,
  overrideIsAddOpen = false,
}: ListMapControlsProps) => {
  const { width } = useWindowDimensions();
  const showMap = width >= 1100;
  const { selectedMarkerId, hoverMarkerId } = mapState;


  function rowItemClick(id: string) {
    if (id === selectedMarkerId) {
      setMapState({
        ...mapState,
        selectedMarkerId: null,
      });
    } else {
      setMapState({
        ...mapState,
        selectedMarkerId: id,
      });
    }
  }

  return (
    <Main>
      {showMap && (
        <MapDiv>
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyARh9SCNR-vNADst2P-co2fPy0yiSKhP64"
            libraries={["places"]}
          >
            <GoogleMap
              mapInfos={listings}
              defaultCenter={DEFAULT_CENTER}
              defaultZoom={11}
              selectedMarkerId={selectedMarkerId}
              hoverMarkerId={hoverMarkerId}
              hideMap={hideMap}
              isEditor={mode !== "public"}
              isPublic={mode === "public"}
              isRealtor={mode === "realtor"}
              markerClick={rowItemClick}
              greedy
            />
          </LoadScript>
        </MapDiv>
      )}

      <ListPane>
        {mode !== "public" && (
          <MainTabs
            selectedTab={mode === "editor" ? "editor" : "search"}
            showEdit={canUserEdit}
            showSearch={canUserSearch}
            addNewClick={addNewClick}
            overrideIsAddOpen={overrideIsAddOpen}
          />
        )}
        {children}
      </ListPane>
    </Main>
  );
};

const ListPane = styled.div`
  @media (min-width: 1100px) {
    width: 700px !important;
  }
  margin-left: 15px;
  padding-top: 90px;
  padding-bottom: 30px;
  min-height:100vh;
`;
const MapDiv = styled.div`
  background-color: #fff;
  height: calc(100vh - 70px);
  position: fixed;
  top: 65px;
  width: calc(100vw - 750px);
  margin-left: 720px;
`;

const Main = styled.div`
  height: 100%;
  background-color: #f6f7f9;
`;

export default ListMapControl;
