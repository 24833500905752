import Button from "@material-ui/core/Button";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import React from "react";
import styled from "styled-components";
import { settings } from "../../config/firebase";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem } from "@material-ui/core";

const FileDownloadLink = ({ fileName, listingId, deleteFile = null}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { projectId } = settings.firebaseConfig;
  const fullUrl = `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${listingId}%2F${fileName}?alt=media`;
  return (
    <Container>
      <Button href={fullUrl} target="_blank" startIcon={<FileIcon />}>
        download
      </Button>
      {!!deleteFile && <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
 
          <MenuItem   onClick={() => {
            handleClose();
            deleteFile();
            }}>
            Delete
          </MenuItem>

      </Menu></>}

    </Container>
  );
};

const Container = styled.div`
  padding: 0 20px;
`;

export default FileDownloadLink;
