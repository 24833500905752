import firebase from "config/firebase";
import React from 'react';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
type InviteStepOneProps = {
    inviteCode:string;
}
export default function InviteStepOne ({inviteCode} : InviteStepOneProps) {


    const uiConfig = {
        signInFlow: "popup",
        signInSuccessUrl: `/invite/${inviteCode}`,
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, 
          firebase.auth.EmailAuthProvider.PROVIDER_ID]
      };
    return (<div>
        <h1>Welcome</h1>
        <p>Please choose a sign in option:</p>

        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
    </div>);
}