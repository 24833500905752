import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { CellColumnNumber, IntegerPickerField } from "types/fieldComponentTypes";



type Props = {
  field: IntegerPickerField,
  value:number,
  xs: CellColumnNumber,
  sm: CellColumnNumber,
  md: CellColumnNumber,
  disabled: boolean,
  onBlur: (value:number) => void

}

const IntegerPickerFieldControl:React.FC<Props> = ({ field, value, xs, sm, md, onBlur, disabled }) => {


    const range = field.max - field.min + 1;
    if(range <1){
        throw new Error("invalid range");
    }

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <TextField
        label={field.label}
        select
        variant="filled"
        fullWidth
        value={value || field.min}
        onChange={e => onBlur(parseInt( e.target.value, 10))}
        multiline={field.multiline}
        disabled={disabled}
      >
        { [...Array(range)].map( (_,n) =>{ 
            const v = n + field.min;
            return (
            <MenuItem value={v} key={`n_${v}`}>{v}</MenuItem>
        )})}
        
      </TextField>
    </Grid>
  );
};
export default IntegerPickerFieldControl;
