import { AREA_DISTRIBUTION_ACRES } from "common/newConstants";
import {
  getAreaForCombination,
  getJurisdictionLongNameById,
  getShortAddress,
  isSpaceMarketSegment,
} from "common/newHelperFunctions";
import { firestore } from "firebase";
import { JurisdictionLookupsAdminDoc, User } from "types/AdminTypes";
import { AvailableSpace, Combination, SizeData, VersionDoc } from "types/Types";
import {
  Availability,
  LastSave,
  Listing,
  ListingInfo,
  LISTING_STATUSES,
  Location,
  VERSION_STATUSES,
} from "./../types/Types";

export function updateVersionLocationAndSaveData(
  versionDoc: VersionDoc,
  dateSaved: firestore.Timestamp,
  user: User
): VersionDoc {
  return {
    ...versionDoc,
    location: {
      ...versionDoc.location,
      shortAddress: getShortAddress(versionDoc),
    },
    lastSave: getLastSaveObject(dateSaved, user),
  };
}
export function getLastSaveObject(
  dateSaved: firestore.Timestamp,
  user: User
): LastSave {
  return {
    date: dateSaved,
    uid: user.uid,
    userDisplayName: user.displayName || "NOT_SET",
  };
}
export function updateListingDocBasedOnVersionDocAndSaveBoth(
  listingDocRef: firestore.DocumentReference,
  listing: Listing,
  versionDoc: VersionDoc,
  jurisdictionLookups: JurisdictionLookupsAdminDoc,
  db: firestore.Firestore,
  dateSaved: firestore.Timestamp,
  user: User
) {
  const batch = db.batch();
  const versionDocRef = listingDocRef.collection("versions").doc(versionDoc.id);
  const updatedListingDoc = getUpdateListingDoc(
    listing,
    versionDoc,
    jurisdictionLookups,
    dateSaved,
    user
  );
  batch.set(versionDocRef, versionDoc);
  batch.set(listingDocRef, updatedListingDoc);
  return batch.commit();
}

function getUpdateListingDoc(
  listing: any,
  versionDoc: VersionDoc,
  jurisdictionLookups: JurisdictionLookupsAdminDoc,
  dateSaved: any,
  user: User
) {
  if (versionDoc.status === VERSION_STATUSES.PUBLISHED) {
    return {
      ...listing,
      //listingInfo: getListingInfoFromDoc(versionDoc),
      versionId: versionDoc.id,
      lastSave: getLastSaveObject(dateSaved, user),
    };
  } else if (versionDoc.status === VERSION_STATUSES.DRAFT) {
    return {
      ...listing,
      listingInfo: getListingInfoFromDoc(versionDoc, jurisdictionLookups),
      versionId: versionDoc.id,
      lastSave: getLastSaveObject(dateSaved, user),
      status: LISTING_STATUSES.DRAFT,
    };
  } else {
    return listing;
  }
}

export function getListingInfoFromDoc(
  doc: VersionDoc,
  jurisdictionLookups: JurisdictionLookupsAdminDoc
): ListingInfo {
  // if(!doc.mapRegion){
  //   throw new Error('mapRegion required')
  // }

  return {
    id: doc.listingId,
    images: doc.images || [],
    mapInfo: doc.mapInfo,
    majorStructuresOnSite: !!doc.structuresOnSite,
    ...getAvailabilityProps(doc.availability),
    ...getLocationProps(doc.location),
    possibleAreas: getPossibleAreas(doc),
    mapRegion: doc.mapRegion || null,
    jurisdiction: doc.location.jurisdiction,
    jurisdictionLongName: getJurisdictionLongNameById(
      doc.location.jurisdiction,
      jurisdictionLookups
    ),
  };
}

function getAvailabilityProps(availability: Availability | undefined) {
  if (!availability) {
    return { linc: null, tradingCategory: null };
  }
  return {
    linc: availability.linc || null,
    tradingCategory: availability.tradingCategory || null,
  };
}

function howMuchToAddNext(current: number) {
  if (current < 5000) {
    return 5000 / 5;
  }
  if (current < 10000) {
    return 10000 / 5;
  }
  if (current < 50000) {
    return 50000 / 5;
  }
  if (current < 100000) {
    return 100000 / 5;
  }
  if (current < 200000) {
    return 200000 / 5;
  }
  if (current < 500000) {
    return 500000 / 5;
  }
  return 250000;
}

function getPossibleAreas(doc: VersionDoc) {
  
  if (!doc.pieces?.pieces) {
    return null;
  }
  const pieces = doc.pieces.pieces;
  let possibleAreas: number[] = [];

  for (const piece of pieces) {
    if (piece.sizeData) {
      possibleAreas = possibleAreas.concat(getPossibleAreaForSizeData(piece.sizeData));
    }
  }
  if (
    doc.pieces.canPiecesBeCombined &&
    doc.pieces.pieceCombinations &&
    doc.pieces.pieceCombinations.length > 0
  ) {
    for (const combination of doc.pieces.pieceCombinations) {
      if (combination.sizeData) {
        possibleAreas = possibleAreas.concat(getPossibleAreaForSizeData(combination.sizeData));
      }
    }
  }
  return possibleAreas;
}

function getPossibleAreaForSizeData(sizeData: SizeData[]): number[] {
  const possibleAreas = [];
  for (const sd of sizeData) {
    if (sd.type === "fixed" && sd.size) {
      possibleAreas.push(sd.size);
    }
    if (sd.type === "range" && sd.sizeFrom && sd.sizeTo) {
      //TODO: ADD MORE SIZES BETWEEN FROM AND TO
      possibleAreas.push(sd.sizeFrom);
      possibleAreas.push(sd.sizeTo);
    }
  }
  return possibleAreas;
}

function getLocationProps(location: Location) {
  if (!location) {
    return {
      shortAddress: null,
      locus: null,
      park: null,
      subdivision: null,
    };
  }
  return {
    shortAddress: location.shortAddress || null,
    locus: location.locus || null,
    park: location.park || null,
    subdivision: location.subdivision || null,
  };
}

export function addNewDraftDocFromPublishedDoc(
  listingDocRef: firestore.DocumentReference,
  listingDoc: Listing,
  versionDoc: VersionDoc,
  jurisdictionLookups: JurisdictionLookupsAdminDoc,
  db: firestore.Firestore,
  dateSaved: firestore.Timestamp,
  user: User
) {
  const versionDocRef = listingDocRef.collection("versions").doc();
  const updatedVersionDoc = {
    ...versionDoc,
    id: versionDocRef.id,
    lastSave: getLastSaveObject(dateSaved, user),
    status: VERSION_STATUSES.DRAFT,
  };
  //return;
  const batch = db.batch();
  const updatedListingDoc = getUpdateListingDoc(
    listingDoc,
    updatedVersionDoc,
    jurisdictionLookups,

    dateSaved,
    user
  );
  batch.set(versionDocRef, updatedVersionDoc);
  batch.set(listingDocRef, updatedListingDoc);
  return batch.commit();
}

// export function getPossibleAreasFromCombinations(
//   combinations: Combination[],
//   space: AvailableSpace[]
// ) {
//   const possibleAreas: number[] = getPossibleAreasFromAvailableSpaces(space);
//   for (let x = 0; x < combinations.length; x++) {
//     const combination = combinations[x].lots;
//     const area = getAreaForCombination(combination, space);
//     if (!!area && !possibleAreas.includes(area)) {
//       possibleAreas.push(area);
//     }
//   }

//   return possibleAreas;
// }
// export function getPossibleAreasFromAvailableSpaces(space: AvailableSpace[]) {
//   console.log("space", space);
//   const possibleAreas: number[] = [];
//   for (let x = 0; x < space.length; x++) {
//     possibleAreas.push(space[x].area || 0);
//   }

//   return possibleAreas;
// }
