import React from "react";

type UnauthorizedGoToMainPageProps = {};
export default function UnauthorizedGoToMainPage(_: UnauthorizedGoToMainPageProps) {
  return (
    <div>
      Unauthorized! <a href="/">Go to Main Page</a>
    </div>
  );
}
