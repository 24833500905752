import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DropDownIcon from "@material-ui/icons/ArrowDropDown";
import { AppContext } from "AppContext";
import { sortString } from "common/helperFunctions";
import { getListingTypeFriendlyText } from "common/newHelperFunctions";
import React, { useContext } from "react";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function AddButtonMenu({ onNewClick, overideIsOpen = false }) {
  const inputRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleClick = (event) => {
    setMenuOpen(true);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const { user } = useContext(AppContext);
  if (!user || user.marketSegments.length === 0)
    throw new Error("No User or no Market Segments");

  const userMarketSegments = user.marketSegments;

  return (
    <div style={{ paddingTop: 6 }}>
      <Button
        aria-controls="customize`d`-menu"
        aria-haspopup="true"
        variant="contained"
        style={{ backgroundColor: "dodgerBlue", color: "#fff" }}
        id="add-posting-button"
        endIcon={<DropDownIcon />}
        onClick={handleClick}
        ref={inputRef}
      >
        <AddIcon fontSize="small" /> Add
      </Button>
      {inputRef && (
        <StyledMenu
          anchorEl={inputRef.current}
          keepMounted
          open={menuOpen || overideIsOpen}
          onClose={handleClose}
        >
          <div id="add-posting-menu">
            {userMarketSegments
              .sort((a, b) => sortString(a, b))
              .map((ms) => (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onNewClick(ms);
                  }}
                  key={`ms_${ms}`}
                >
                  {getListingTypeFriendlyText(ms)}
                </MenuItem>
              ))}
          </div>
        </StyledMenu>
      )}
    </div>
  );
}
