import { RouteComponentProps } from "@reach/router";
import { AppContext } from "AppContext";
import { DEFAULT_CENTER } from "common/constants";
import { getUserAbilities } from "common/newHelperFunctions";
import AddNewDialog, {
  AddNewDialogState,
} from "components/editor/AddNewDialog";
import AdminSearchBox from "components/editor/AdminSearchBox";
import ListingPanel from "components/ListingPanel";
import firebase from "config/firebase";
import ListMapControl from "pages/components/ListMapControl";
import MainHeader from "pages/components/MainHeader";
import UnauthorizedGoToMainPage from "pages/components/UnauthorizedGoToMainPage";
import LoadingPage from "pages/LoadingPage";
import React, { useContext, useState } from "react";
import { getLastSaveObject } from "store/newStoreHelperMethods";
import useEditorStore from "store/useEditorStore";
import styled from "styled-components";
import { Themes } from "themes";
import { User } from "types/AdminTypes";

import {
  DraftVersionDoc,
  Listing,
  LISTING_TYPE,
  MapListingInfo,
  VERSION_STATUSES,
  LISTING_STATUSES,
} from "types/Types";
export type All_LISTING_TYPE = LISTING_TYPE | "all";

type State = {
  addDialogState: AddNewDialogState | null;
  mapState: MapState;
  searchState: SearchState;
};

export type SearchState = {
  searchText: string | null;
  listingType: All_LISTING_TYPE;
};
type MapState = {
  selectedMarkerId: string | null;
  hoverMarkerId?: string | null;
};
const getDefaultAddDialogState = (
  type: LISTING_TYPE,
  user: User
): AddNewDialogState => ({
  versionDoc: {
    mapInfo: {
      center: DEFAULT_CENTER,
    },
    listingType: type,
    status: VERSION_STATUSES.DRAFT,
    lastSave: getLastSaveObject(
      firebase.firestore.Timestamp.fromDate(new Date()),
      user
    ),
  },
  geoCode: null,
  activeStep: 0,
  errors: {},
  isDialogOpen: false,
  isSaving: false,
});

type EditorPageProps = {
  overrideIsAddOpen?: boolean;
  overrideIsExtraElementsOpen?: boolean;
} & RouteComponentProps;

const EditorPage = (props: EditorPageProps) => {
  const { user, orgId } = useContext(AppContext);
  const { overrideIsAddOpen, overrideIsExtraElementsOpen } = props;
  if (!user) throw new Error("no user");

  const userOrgId = user.orgId;
  if (!userOrgId) throw new Error("no user org Id");

  const userAbilities = getUserAbilities(user);

  const { editorStore, returnMethods } = useEditorStore(
    user,
    orgId as keyof Themes
  );
  const [state, setState] = useState<State>({
    addDialogState: null,
    mapState: {
      selectedMarkerId: null,
    },
    searchState: {
      searchText: null,
      listingType: "all",
    },
  });

  if (!userAbilities.canEdit) {
    return <UnauthorizedGoToMainPage />;
  }

  const { addDialogState, searchState } = state;
  const { searchText, listingType } = searchState;
  const { addNew } = returnMethods;

  if (!editorStore) {
    return <LoadingPage />;
  }

  function sortListings(a: Listing, b: Listing) {
    if (
      a.status === LISTING_STATUSES.ARCHIVED &&
      b.status !== LISTING_STATUSES.ARCHIVED
    ) {
      return 1;
    }
    if (
      a.status !== LISTING_STATUSES.ARCHIVED &&
      b.status === LISTING_STATUSES.ARCHIVED
    ) {
      return -1;
    }
    return (a.listingInfo.shortAddress || "").localeCompare(
      b.listingInfo.shortAddress || ""
    );
  }

  const listingInfos: MapListingInfo[] = editorStore.listings
    .filter((i) => searchFilter(i, searchText || "", listingType))
    .sort((a, b) => sortListings(a, b))
    .map((l) => ({
      ...l.listingInfo,
      status: l.status,
      listingType: l.listingType,
      orgId: l.orgId,
      lastSaveUserId: l.lastSave.uid,
    }));

  function doAddNew(versionDoc: DraftVersionDoc) {
    return addNew(versionDoc).then((id) => {
      setState({
        mapState: { selectedMarkerId: id },
        addDialogState: null,
        searchState: {
          searchText: null,
          listingType: "all",
        },
      });
      window.open(`editor/${id}`);
    });
  }
  const setAddDialogState = (s: AddNewDialogState) =>
    setState({ ...state, addDialogState: s });
  const setSearch = (searchState: SearchState) =>
    setState({ ...state, searchState });

  return (
    <ListMapControl
      mode="editor"
      listings={listingInfos}
      mapState={state.mapState}
      setMapState={(mapState: MapState) => setState({ ...state, mapState })}
      hideMap={addDialogState?.isDialogOpen || false}
      canUserEdit={userAbilities.canEdit}
      canUserSearch={userAbilities.canSearch}
      addNewClick={(listingType) =>
        setAddDialogState({
          ...getDefaultAddDialogState(listingType, user),
          isDialogOpen: true,
        })
      }
      overrideIsAddOpen={overrideIsAddOpen}
    >
      <MainHeader overrideIsExtraElementsOpen={overrideIsExtraElementsOpen} />
      {!!addDialogState && (
        <AddNewDialog
          open={addDialogState?.isDialogOpen || false}
          onCancelClick={() =>
            setState({
              ...state,
              addDialogState: null,
            })
          }
          lookups={editorStore.lookups}
          jurisdictionLookups={editorStore.jurisdictionLookups}
          onFinishClick={doAddNew}
          state={addDialogState}
          setState={setAddDialogState}
        />
      )}

      {editorStore && editorStore.listings.length === 0 ? (
        <NoListingInDB />
      ) : (
        <ListingPanel
          listings={listingInfos}
          selectedItemId={state.mapState.selectedMarkerId}
          isPublic={false}
          isRealtor={false}
          rowItemClick={(id) => {
            if (id === null) {
              setState((s) => ({
                ...s,
                mapState: { ...s.mapState, selectedMarkerId: null },
              }));
              return;
            }

            const listing = listingInfos.find((li) => li.id === id);
            //const hasPin = !!listing?.mapInfo.marker?.lat;

            setState((s) => ({
              ...s,
              mapState: { ...s.mapState, selectedMarkerId: id },
            }));
            return;
          }}
          rowItemHover={(id) =>
            setState((s) => ({
              ...s,
              mapState: { ...s.mapState, hoverMarkerId: id },
            }))
          }
          shouldShowEditButton={(orgId) => true}
        >
          <SearchBoxContainer>
            <AdminSearchBox
              searchState={state.searchState}
              setSearch={setSearch}
            />
          </SearchBoxContainer>
        </ListingPanel>
      )}
    </ListMapControl>
  );
};

const NoListingInDB = () => {
  return (
    <NoListingContainer>
      No postings to edit. To add a posting, click on the Add button.{" "}
    </NoListingContainer>
  );
};

const NoListingContainer = styled.div``;

const isInText = (listingValue: string, searchText: string) => {
  const trimmedLowerText = !searchText ? null : searchText.trim().toLowerCase();
  if (!trimmedLowerText || trimmedLowerText.length === 0) {
    return true;
  }

  if (!listingValue || !searchText) {
    return false;
  }
  return listingValue.trim().toLowerCase().includes(trimmedLowerText);
};

const searchFilter = (
  listing: Listing,
  searchText: string,
  listingType: LISTING_TYPE | "all"
) => {
  const { linc, shortAddress } = listing.listingInfo;
  if (listingType !== "all" && listing.listingType !== listingType) {
    return false;
  }

  return (
    (!!linc && isInText(linc, searchText)) ||
    (!!shortAddress && isInText(shortAddress, searchText))
  );
};

const SearchBoxContainer = styled.div`
  padding: 30px 40px 20px 40px;
  display: flex;
`;

export default EditorPage;
