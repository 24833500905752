import { Grid, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import {
  CellColumnNumber,

  IdName, PickListField
} from "types/fieldComponentTypes";
type IdValueNameInputValue = IdName & { inputValue?: string };
type CreatablePickListControlProps = {
  field: PickListField;
  value: string | null;
  xs?: CellColumnNumber;
  sm?: CellColumnNumber;
  md?: CellColumnNumber;
  disabled: boolean;
  onBlur: (value: boolean) => void;
  handleChange: (jsonPath: string, value: string | null) => void;
  options: IdValueNameInputValue[];
  error?: string | null;
};
const filter = createFilterOptions<IdValueNameInputValue>();
export default function EditablePickListField({
  field,
  value,
  xs = 12,
  sm,
  md,
  handleChange,
  options,
  error,
  disabled = false,
}: CreatablePickListControlProps) {
  const [state, setState] = useState<string>(value || "");
  // SET IT BLANK INSTEAD OF NULL, BECAUSE OF ERROR OF SWITCHING BEWTEEN
  // UNCONTROLLED TO CONTROLLED.
  React.useEffect(() => {
    setState(value || "");
  }, [value]);

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <Autocomplete
        value={state}
        options={options}
        getOptionLabel={(idName) => {
          // Value selected with enter, right from the input
          if (typeof idName === "string") {
            return idName;
          }
          // Add "xxx" option created dynamically
          // if (idName.inputValue) {
          //   return idName.inputValue;
          // }
          // Regular option
          return idName.name;
        }}
        onChange={(event, newValue) => {

          if (typeof newValue === "string") {
            console.warn("should add this to the lookup");
            setState(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setState(newValue.inputValue);
          } else {
            setState(newValue?.id || "");
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              id: "add",
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onBlur={() => handleChange(field.jsonPath, state === "" ? null : state)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={field.id}
        //   renderOption={(option) => option.name}
        //style={{ width: 300 }}
        forcePopupIcon={true}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={field.label}
            variant="filled"
            error={!!error}
            helperText={error}
            disabled={disabled}
          />
        )}
      />
    </Grid>
  );
}
