import React, { useState } from "react";
import { Point } from "types/Types";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { getLetterForOrdinal } from "common/helperFunctions";

type MultipleZoneMapProps = {
  zoom: number;
  center: Point;
  mapMarkers: { ordinal: number; marker: Point | null }[];
  mapPolygons: {
    ordinal: number;
    polygon: Point[];
  }[];
  selectedOrdinal?: number;
  selectedPolygonChanged(ordinal: number, polygon: Point[]): void;
  selectedMarkerChanged(ordinal: number, marker: Point): void;
  mapZoomChanged(zoom: number): void;
  mapCenterChanged(center: Point): void;
  setSelectedOrdinal(ordinal: number): void;
  onMapClick?: (location:Point) =>void;
  disabled?: boolean;
};


export default function MultipleZoneMap({
  zoom,
  center,
  mapMarkers,
  mapPolygons,
  selectedOrdinal,
  selectedPolygonChanged,
  selectedMarkerChanged,
  mapZoomChanged,
  mapCenterChanged,
  setSelectedOrdinal,
  onMapClick,
  disabled,
}: MultipleZoneMapProps) {
  const [polyRef, setPolyRef] = useState<google.maps.Polygon | null>(null);
  const [mapRef, setMapRef] = useState<any| null>(null);
  const [mapType, setMapType] = useState<string>("hybrid");
  const selectedStrokeColor = "#304ffe";
  const strokeColor = "#fff";
  const fillColor = "#fff";
  const icon = {
    scaledSize: new google.maps.Size(28, 45),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(14, 45),
  };

  const  SVG_MARKER = {
    path: "M12.3606 0.0210949C12.2718 0.0281262 12.0019 0.0527356 11.7568 0.0703137C7.43057 0.44297 3.44177 3.12539 1.44204 7.01016C0.596687 8.64844 0.152698 10.2762 0.0354846 12.1746C-0.220253 16.2527 2.08849 23.3578 6.96171 33.4863C7.6863 34.984 9.42319 38.4082 10.2934 40.043C11.4087 42.1418 12.9609 44.9613 13 44.9648C13.0177 44.9648 13.9235 43.3617 14.4776 42.3457C19.9901 32.2383 23.9079 23.2488 25.3251 17.4586C25.7904 15.5496 25.9787 14.2207 25.9787 12.832C25.9787 10.8316 25.5737 9.08086 24.7035 7.28789C22.7429 3.25195 18.8038 0.527345 14.2787 0.0703137C13.8808 0.0316418 12.627 -0.00351443 12.3606 0.0210949Z",
    fillColor: "#304ffe",
    strokeColor:"white",
    fillOpacity: 1,
    rotation: 0,
    scale: 1,
    anchor: new google.maps.Point(14,45),
    labelOrigin: new google.maps.Point(13,15),
    labelColor: "white",
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        height: "450px",
        width: "100%",
      }}
      onLoad={(map) => setMapRef(map)}
      onClick={ !!onMapClick ?  e => onMapClick(e.latLng!.toJSON()) : undefined}
      onMapTypeIdChanged={() => {
        if (mapRef) {
          //console.log(mapRef.getMapTypeId());
          setMapType(mapRef.getMapTypeId());
        }
      }}
      center={center}
      zoom={zoom}
      options={{ mapTypeId: mapType }}
      onDragEnd={() => {
        if (mapRef) {
          mapCenterChanged(mapRef.getCenter().toJSON());
        }
      }}
      onZoomChanged={() => {
        if (mapRef) {
          mapZoomChanged(mapRef.getZoom());
        }
      }}
    >
      {mapPolygons.map((l) =>
        l.ordinal === selectedOrdinal ? (
          <Polygon
            key={`p1_${l.ordinal}`}
            paths={l.polygon}
            draggable
            editable
            onLoad={(p) => {
              setPolyRef(p);
            }}
            onMouseUp={() =>
              selectedPolygonChanged(
                selectedOrdinal,
                polyRef!
                  .getPath()
                  .getArray()
                  .map((ll) => ({ lat: ll.lat(), lng: ll.lng() }))
              )
            }
            options={{
              fillColor: selectedStrokeColor,
              strokeColor: selectedStrokeColor,
            }}
          />
        ) : (
          <Polygon
            key={`p_${l.ordinal}`}
            paths={l.polygon}
            options={{
              fillColor,
              strokeColor,
            }}
          />
        )
      )}
      {mapMarkers.map((l) =>{
        if (!l.marker) {
          return null;
        }
        if(l.ordinal === selectedOrdinal) {

          return (<Marker
            key={`c_${l.ordinal}`}
            position={l.marker}
            draggable
            onDragEnd={(t) =>
              selectedMarkerChanged(selectedOrdinal, t.latLng!.toJSON())
            }
          />)
        }
        return <Marker
            key={`c_${l.ordinal}`}
            icon={SVG_MARKER}
            label={ {text:`${getLetterForOrdinal( l.ordinal)}`, color:"white"} }
            position={l.marker}
            onClick={() => (disabled ? {} : setSelectedOrdinal(l.ordinal))}
          />
      })}
        
    </GoogleMap>
  );
}
