import { RouteComponentProps } from "@reach/router";
import ListingHeader from "components/preview/ListingHeader";
import ListingRenderer from "components/preview/ListingRenderer";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Listing, VersionDoc } from "types/Types";
import useListingStore from "../store/useListingStore";
import LoadingPage from "./LoadingPage";
import Page404 from "./Page404";

type Props = {
  id?: string;
  isPublic?:boolean;
  isRealtor?:boolean;
} & RouteComponentProps;
 const ListingPage : React.FC<Props> = ({ id,  isPublic = false, isRealtor=false }) => {
  const [data, setData] = useState< [VersionDoc | null, Listing | null]>( [null, null]);
  const [is404, setIs404] = useState(false);
  const { state, getVersionDocForListingId } = useListingStore();
  const isLoaded = !!state;

  const lookups = !!state
    ? state.adminDocs.find((d) => d.id === "brokeragesLookups")
    : null;
  const allListings = !!state ? state.listings : null;
  useEffect(
    function () {
      if (!isLoaded || !id) {
        return;
      }
      getVersionDocForListingId(id)
        .then(([versionDoc, listing]) => {
          if (!!versionDoc && !!listing) {
            setData([versionDoc, listing]);
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          setIs404(true);
        });
    },
    [isLoaded, id, getVersionDocForListingId]
  );

  if (is404) {
    return <Page404/>;
  }
  const [verionDoc, listing] = data;
  if (verionDoc == null || !state || !lookups || !allListings || !listing || !listing.orgId) {
    return <LoadingPage />;
  }
  return (
    <Page>
      <ListingHeader  listOwnerOrgId={listing.orgId}  />
      <ListingRenderer
        isPublic={isPublic}
        isRealtor={isRealtor}
        versionDoc={verionDoc}
        allListings={allListings}
        lookups={lookups}
       
      />
    </Page>
  );
};

export default ListingPage;

const Page = styled.div`
  padding-top: 70px;
`;