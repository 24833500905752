import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { getLetterForOrdinal } from "common/helperFunctions";
import React from "react";
import styled from "styled-components";
import { PieceCombination } from "types/Types";
import AddIcon from "@material-ui/icons/Add";
import SizesEditor from "./SizesEditor";
import DeleteIcon from "@material-ui/icons/Delete";

type CombinationsTableProps = {
  data: PieceCombination[];
  numberOfPieces: number;
  addCombo(): void;
  deleteCombo(index: number): void;
  handleChange(value: PieceCombination, index: number): void;
  handleLetterChange(value: PieceCombination, index:number):void;
  handleBlur(value: PieceCombination, index: number): void;
  measurementUnit: string;
};

function CombinationsTable({
  data,
  numberOfPieces,
  addCombo,
  deleteCombo,
  handleChange,
  handleLetterChange,
  handleBlur,
  measurementUnit,
}: CombinationsTableProps) {
  return (
    <>
      <h3>Combinations</h3>

      {data.map((combination, comboIndex) => {
        return (
          <GridUnderline container spacing={3} key={`combo_row_${comboIndex}`}>
            <Grid item sm={12} md={4}>
              {[...Array(numberOfPieces)].map((e, letterIndex) => {

                return (
                  <OrdinalCheckbox
                    index={letterIndex}
                    key={`cb_${comboIndex}_${letterIndex}`}
                    checked={
                      combination.selectedPieces &&
                      combination.selectedPieces.includes(letterIndex)
                    }
                    onChange={() => {
                      const newSelectedPieces =
                        combination.selectedPieces?.includes(letterIndex)
                          ? combination.selectedPieces.filter((x) => x !== letterIndex)
                          : [...(combination.selectedPieces || []), letterIndex];
 
                          handleLetterChange(
                        { ...combination, selectedPieces: newSelectedPieces },
                        comboIndex
                      );
                    }}
                  />
                );
              })}
            </Grid>
            <Grid item sm={12} md={3}>
              <SizesEditor
                label="Size (acres)"
                value={combination.sizeData || [{ type: "fixed" }]}
                error={null}
                measurementUnit={measurementUnit}
                handleChange={(value) => {
                  handleBlur({ ...combination, sizeData: value }, comboIndex);
                }}
              />
            </Grid>
            <Grid item sm={10} md={4}>
              <TextField
                label="Description"
                variant="filled"
                fullWidth
                value={combination.description || ""}
                onChange={(e) => {
                  handleChange(
                    { ...combination, description: e.target.value },
                    comboIndex
                  );
                }}
                onBlur={(e) => {
                  handleBlur(
                    { ...combination, description: e.target.value },
                    comboIndex
                  );
                }}
              />
              <Spacer />
            </Grid>
            <Grid sm={2} item md={1}>
              <IconButton onClick={() => deleteCombo(comboIndex)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </GridUnderline>
        );
      })}
      <DivAddCombo>
        <Button color="primary" startIcon={<AddIcon />} onClick={addCombo}>
          Add Combination
        </Button>
      </DivAddCombo>
    </>
  );
}

function OrdinalCheckbox({
  index,
  checked,
  onChange,
}: {
  index: number;
  checked?: boolean;
  onChange(): void;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked || false}
          name={getLetterForOrdinal(index)}
          onClick={onChange}
          //onclick={onChange}
        />
      }
      label={getLetterForOrdinal(index)}
    />
  );
}
const Spacer = styled.div`
  height: 20px;
`;

const GridUnderline = styled(Grid)`
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
`;
const DivAddCombo = styled.div`
  padding: 20px 0;
`;
export default CombinationsTable;
