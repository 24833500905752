import PropTypes from "prop-types";
import React from "react";
import { Handles, Rail, Slider, Ticks } from "react-compound-slider";
import { Handle, Tick } from "components/searchPopup/controls/sliderComponents";
type Props = {
  distribution: any;
  values: any;
  setValues: any;
};
const LandSizeHolder: React.FC<Props> = ({
  distribution,
  values,
  setValues,
}) => {
  const NUMSTEPS = distribution.length;

  const domain: [any, any] = [1, NUMSTEPS];
  function formatNumber2(num: number) {
    return distribution[num - 1].value;
  }

  return (
    <Slider
      mode={2}
      step={1}
      domain={domain}
      values={values}
      rootStyle={{
        position: "relative",
        width: "100%",
      }}
      onUpdate={(v) => setValues(v)}
    >
      <Rail>
        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      {/* <Tracks left={false} right={false}>
                 {({ tracks, getTrackProps }) => (
                   <div className="slider-tracks">
                     {tracks.map(({ id, source, target }) => (
                       <Track
                         key={id}
                         source={source}
                         target={target}
                         getTrackProps={getTrackProps}
                       />
                     ))}
                   </div>
                 )}
               </Tracks> */}
      <Ticks count={6}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick
                key={tick.id}
                tick={tick}
                count={ticks.length}
                format={(num) => formatNumber2(num)}
              />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};
// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: "absolute",
  width: "100%",
  height: 42,
  transform: "translate(0%, -50%)",
  borderRadius: 7,
  cursor: "pointer",

  // border: '1px solid white',
};

export function SliderRail({ getRailProps }: { getRailProps: any }) {
  return (
    <>
      <div style={railOuterStyle} {...getRailProps()} />
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: 10,
          transform: "translate(0%, -50%)",
          borderRadius: 3,
          pointerEvents: "none",
          backgroundColor: "rgb(155,155,155)",
        }}
      />
    </>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};

export default LandSizeHolder;
