import { JurisdictionLookupsAdminDoc } from './AdminTypes';
import {
  TextField,
  EditablePickListField,
  PickListField,
  MultiPickField,
  BoolField,
  MapField,
  FIELD_TYPE,
  IntegerPickerField,
  IdName,
  INPUT_TYPE as TEXTFIELD_INPUT_TYPE,
  GoogleMapField,
  ContactInfoField,
  ImageField,
  VideoField,
  FileField,
  CraneField,
  BrokerageContactField,
  TrafficFlowInfoField,
  SpaceSetsField,
  SizesField,
  NumericPickListField,
  SpacesField as PiecesField,
} from "./fieldComponentTypes";
import { VersionDoc } from "types/Types";
import { QUADRANTS, ROADWAY_TYPES } from 'common/constants';

const getJurisdictionOption = (
  versionDoc: VersionDoc,
  jurisdictionLookup: JurisdictionLookupsAdminDoc,
  prop: string
): IdName[] => {
 
  if (versionDoc.location && versionDoc.location.jurisdiction) {
    const lotJurisdiction = versionDoc.location.jurisdiction;
    const lookupJurisdiction = jurisdictionLookup.jurisdictions.find(
      (j) => j.id === lotJurisdiction
    );
    if (prop === "locuses" && !!lookupJurisdiction?.locuses) {
      return lookupJurisdiction.locuses.map((i) => ({ id: i.value, name: i.value }));
    }
    if (prop === "parks" && !!lookupJurisdiction?.parks) {
      return lookupJurisdiction.parks.map((i) => ({ id: i.value, name: i.value }));
    }
    if (prop === "districts" && !!lookupJurisdiction?.districts) {
      return lookupJurisdiction.districts.map((i) => ({ id: i.value, name: i.value }));
    }
    if (prop === "subdivisions" && !!lookupJurisdiction?.subdivisions) {
      return lookupJurisdiction.subdivisions.map((i) => ({ id: i.value, name: i.value }));
    }
    if (prop === "mapRegions" && !!lookupJurisdiction?.mapRegions) {
      return lookupJurisdiction?.mapRegions.sort( (a,b) => a.name.localeCompare(b.name));
    }
  }
  return [];
};
export const additionalRent: TextField = {
  id: "additionalRent",
  label: "Additional Rent",
  formLabelOverride: "Additional Rent ($/ft²)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.additionalRent",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
};
export const additionalRentBasedOn : PickListField = {
  id: "additionalRentBasedOn",
  label: "Rent Based On",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "termsConditions.additionalRentBasedOn",
  getOptions: () => {
    return [
      {id:'Ground Floor Area', name:'Ground Floor Area'},
      {id:'Total Area', name:'Total Area'},
    ]
  },
}

export const addressType: PickListField = {
  id: "addressType",
  label: "Address Type",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "location.addressType",
  radio:true,
  defaultValue:'urban',
  getOptions: (data) => {
    if(data.versionDoc.listingType === 'IL' || data.versionDoc.listingType === 'CL'){
      return [
        {id:'urban', name:'Civic'},
        {id:'rural', name:'Rural'},
        {id:'none', name:'Intersection or Other Description'},
  
      ]
    }
    return [
      {id:'urban', name:'Civic'},
      {id:'none', name:'Intersection or Other Description'},

    ]

  },
}


export const ancillaryOfferRemarks: TextField = {
  id: "ancillaryOfferRemarks",
  label: "Ancillary Offer Remarks",
  type: FIELD_TYPE.TEXT,
  jsonPath: "availability.ancillaryOfferRemarks",
  md: 12,
  lg: 12,

};



export const baySize: TextField = {
  id: "baySize",
  label: "Bay Size",
  formLabelOverride: "Bay Size (ft²)",
  jsonPath: "space.baySize",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  //isRequired: true
};

export const brokeragePropertyManagement: BrokerageContactField = {
  id: "brokeragePropertyManagement",
  label: "Brokerage/ Property Manager/ Owner",
  type: FIELD_TYPE.BROKERAGE_CONTACT,
  jsonPath: "availability.brokeragePropertyManagement",
  jsonPathBrokerageContactNumber:'availability.brokerageContactNumber',
  isRequired:true
};
export const brochure: FileField = {
  id: "brochure",
  label: "Brochure",
  jsonPath: "availability.brochure",
  type: FIELD_TYPE.FILE_OR_LINK,
};
export const spaceSets: SpaceSetsField = {
  id: "spaceSets",
  label: "Space Sets",
  jsonPath: "space.availableSpaces",
  type: FIELD_TYPE.SPACE_SETS,
  spaceType: "SPACE",
}
export const LandSizesEditor: SizesField = {
  id: "sizes",
  label: "Possible Sizes (acres)",
  jsonPath: "space.sizes",
  type: FIELD_TYPE.SIZES,
  md:3,
  isRequired:true,
  hideToBeDetermined:true
  
}
export const landSpaceSets: SpaceSetsField = {
  id: "landSpaceSets",
  label: "Sets",
  jsonPath: "space.availableSpaces",
  type: FIELD_TYPE.SPACE_SETS,
  spaceType: "SET"
}
export const budgetedOfficeArea: TextField = {
  id: "budgetedOfficeArea",
  label: "Budgeted Office/Showroom Area",
  formLabelOverride: " Budgeted Office/Showroom Area (%)",
  jsonPath: "space.budgetedOfficeArea",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  type: FIELD_TYPE.TEXT,
  //isRequired:true
};
export const buildingClass: PickListField = {
  id: "buildingClass",
  label: "Building Class",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "space.buildingClass",
  md: 3,
  getOptions: () => {
    return [
      {id:'A', name:'A'},
      {id:'B', name:'B'},
      {id:'C', name:'C'},
    ]
  },
}

export const numberOfParcels: NumericPickListField = {
  id: "numberOfParcels",
  label: "Number Of Parcels",
  type: FIELD_TYPE.NUMERIC_PICKLIST,
  jsonPath: "pieces.numberOfParcels",
  defaultValue: 1,
  md: 3,
  options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
}
export const numberOfSpaces: NumericPickListField = {
  id: "numberOfSpaces",
  label: "Number Of Spaces",
  type: FIELD_TYPE.NUMERIC_PICKLIST,
  jsonPath: "pieces.numberOfSpaces",
  defaultValue: 1,
  md: 3,
  options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
}
export const numberOfAncillaryLots: NumericPickListField = {
  id: "numberOfAncillaryLots",
  label: "Number Of Ancillary Lots",
  type: FIELD_TYPE.NUMERIC_PICKLIST,
  jsonPath: "pieces.numberOfAncillaryLots",
  defaultValue: 0,
  md: 3,
  options: [0,1,2,3,4,5,6,7,8,9,10]
}

export const canParcelsBeCombined: BoolField = {
  id: "canPiecesBeCombined",
  label: "Can Parcels Be Combined?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "pieces.canPiecesBeCombined",
  md: 3,
  defaultValue: false,
  isRequired: true
}
export const canSpacesBeCombined: BoolField = {
  id: "canSpacesBeCombined",
  label: "Can Spaces Be Combined?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "pieces.canPiecesBeCombined",
  md: 3,
  defaultValue: false,
  isRequired: true
}
export const canAncillaryLotsBeCombined: BoolField = {
  id: "canAncillaryLotsBeCombined",
  label: "Can Lots Be Combined?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "pieces.canPiecesBeCombined",
  md: 3,
  defaultValue: false
}


export const pieces: PiecesField = {
  id: "landPieces",
  jsonPath: "pieces",
  type: FIELD_TYPE.PIECES,
  label: ''
}
export const spacePieces: PiecesField = {
  id: "spacePieces",
  jsonPath: "pieces",
  type: FIELD_TYPE.PIECES,
  label: ''
}
// export const cellTowerExists: BoolField = {
//   id:"cellTowerExists",
//   label:"Cell Tower Exists",
//   type: FIELD_TYPE.BOOL,
//   jsonPath: "cellTower.cellTowerExists",
//   md: 3,
// }
export const cellTowerSiteIsOffered: BoolField = {
  id:"cellTowerSiteIsOffered",
  label:"Site Is Offered",
  type: FIELD_TYPE.BOOL,
  jsonPath: "cellTower.cellTowerSiteIsOffered",
  md: 3,
}
export const ceilingClearanceUnderJoist: TextField = {
  id: "ceilingClearanceUnderJoist",
  jsonPath: "space.ceilingClearanceUnderJoist",
  type: FIELD_TYPE.TEXT,
  label: "Ceiling Clearance Under Joist",
  formLabelOverride: "Ceiling Clearance Under Joist (ft)",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
};

function getYears() : IdName[] {
  const date : Date = new Date();
  const year: number = date.getFullYear();
  return Array.from({ length: year-2017}).map( (y,n) => {
    const sYear = (year - n +1).toString();
    return ({id:sYear, name:sYear})
  })
  
}

export const circaYear: PickListField = {
  id: "circaYear",
  label: "Circa",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "termsConditions.circaYear",
  md: 3,
  getOptions: () => {
    return getYears()

  },
}



export const crane1: CraneField = {
  id: "craneway1",
  jsonPath: "cranes.crane1",
  type: FIELD_TYPE.CRANE,
  label: "Crane Way 1",
  craneNumber: 1,
};
export const crane2: CraneField = {
  id: "craneway2",
  jsonPath: "cranes.crane2",
  type: FIELD_TYPE.CRANE,
  label: "Crane Way 2",
  craneNumber: 2,
};
export const crane3: CraneField = {
  id: "cranes",
  jsonPath: "cranes.crane3",
  type: FIELD_TYPE.CRANE,
  label: "Crane Way 3",
  craneNumber: 3,
};
export const craneExists: BoolField = {
  id: "craneExists",
  jsonPath: "cranes.exists",
  type: FIELD_TYPE.BOOL,
  label: "Craneway exists?",
  md: 3,
};

export const craneWayQuantity: IntegerPickerField = {
  id: "numCraneWays",
  jsonPath: "cranes.numCraneways",
  type: FIELD_TYPE.INTEGER_PICKER,
  label: "Number of Craneways",
  min: 1,
  max: 3,
  md: 3,
};

export const driveThrough: PickListField = {
  id: "driveThrough",
  label: "Drive-Through",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "space.driveThrough",
  md: 6,
  getOptions: () => {
    return [
      {id:'Available', name:'Available'},
      {id:'Existing', name:'Existing'},
      {id:'Not Available', name:'Not Available'},
    ]

  },
}
export const existingOfficeArea: TextField = {
  id: "existingOfficeArea",
  label: "Existing Office/Showroom Area",
  formLabelOverride: "Existing Office/Showroom Area (ft²)",
  jsonPath: "space.existingOfficeArea",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  type: FIELD_TYPE.TEXT,
  //isRequired:true
};
export const fireSuppressionSystem: BoolField = {
  id: "fireSuppressionSystem",
  label: "Fire Suppression System?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "space.fireSuppressionSystem",
  md: 6,
};

export const images: ImageField = {
  id: "images",
  label: "Images",
  type: FIELD_TYPE.IMAGE,
  jsonPath: "images",
  //isRequired:true
};
export const isDeveloped: BoolField  = {
  id: "isDeveloped",
  label: "Is Developed",
  type: FIELD_TYPE.BOOL,
  jsonPath: "location.isDeveloped",
  md: 3,
  //isRequired: true
};
export const isUrban: BoolField = {
  id: "isUrban",
  label: "Address Type",
  type: FIELD_TYPE.BOOL,
  jsonPath: "location.isUrban",
  radio: true,
  trueLabel: "Urban",
  falseLabel: "Rural",
};

export const jurisdiction: PickListField = {
  id: "jurisdiction",
  label: "Jurisdiction",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "location.jurisdiction",
  getOptions: ({jurisdictionLookups}) => {
    return jurisdictionLookups.jurisdictions.map((j) => ({
      id: j.id,
      name: j.longName,
    }));
  },
  useIdInsteadOfValueForValue:true
};
export const landSurveyorDescription: MapField = {
  id: "landSurveyorDescription",
  jsonPath: "landSurveyorDescription",
  label: "Legal Description",
  formLabelOverride: "Alberta Township Survey",
  type: FIELD_TYPE.FIELD_COLLECTION,
  fields: [
    {
      id: "portion",
      label: "Portion",
      type: FIELD_TYPE.TEXT,
      jsonPath: "landSurveyorDescription.portion",
      md: 2,
    },
    {
      id: "section",
      label: "Section",
      type: FIELD_TYPE.TEXT,
      jsonPath: "landSurveyorDescription.section",
      md: 2,
    },
    {
      id: "township",
      label: "Township",
      type: FIELD_TYPE.TEXT,
      jsonPath: "landSurveyorDescription.township",
      md: 2,
    },
    {
      id: "range",
      label: "Range",
      type: FIELD_TYPE.TEXT,
      jsonPath: "landSurveyorDescription.range",
      md: 2,
    },
    {
      id: "meridian",
      label: "Meridian",
      type: FIELD_TYPE.TEXT,
      jsonPath: "landSurveyorDescription.meridian",
      md: 2,
    },
  ],
};

export const legalDescription: MapField = {
  id: "legalDescription",
  jsonPath: "legalDescription",
  label: "Legal Description",
  formLabelOverride: "Torrens",
  type: FIELD_TYPE.FIELD_COLLECTION,
  fields: [
    {
      id: "plan",
      label: "Plan",
      type: FIELD_TYPE.TEXT,
      jsonPath: "legalDescription.plan",
      md: 3,
    },
    {
      id: "block",
      label: "Block",
      type: FIELD_TYPE.TEXT,
      jsonPath: "legalDescription.block",
      md: 3,
    },
    {
      id: "lot",
      label: "Lot",
      type: FIELD_TYPE.TEXT,
      jsonPath: "legalDescription.lot",
      md: 3,
    },
    {
      id: "unit",
      label: "Unit",
      type: FIELD_TYPE.TEXT,
      jsonPath: "legalDescription.unit",
      md: 3,
    },
  ],
};
export const linc: TextField = {
  id: "linc",
  label: "LINC",
  type: FIELD_TYPE.TEXT,
  jsonPath: "availability.linc",
};
export const loadingDock: MultiPickField = {
  id: "loadingDock",
  jsonPath: "space.loadingDock",
  label: "Loading",
  options: [
    "Grade Level",
    "Truck Height",
    "Grade Level/Truck Height Combination",
    "Cross-dock",
  ],
  type: FIELD_TYPE.MULTIPICK,
};
export const locationRemarks: TextField = {
  id: "locationRemarks",
  label: "Location Remarks",
  type: FIELD_TYPE.TEXT,
  jsonPath: "location.locationRemarks",
  sm: 12,
  md: 12,
  lg: 12,
  multiline: true,
};
export const locationUrban: MapField = {
  id: "locationUrban",
  label: "Civic Address",
  type: FIELD_TYPE.FIELD_COLLECTION,
  jsonPath: "location.locationUrban",
  fields: [
    {
      id: "unitNumber",
      label: "Unit #",
      type: FIELD_TYPE.TEXT,
      jsonPath: "location.locationUrban.unitNumber",
      md: 4,
    },
    {
      id: "addressNumber",
      label: "Address #",
      type: FIELD_TYPE.TEXT,
      jsonPath: "location.locationUrban.addressNumber",
      md: 4,
      isRequired:true
    },
    {
      id: "roadwayName",
      label: "Roadway Name",
      type: FIELD_TYPE.TEXT,
      jsonPath: "location.locationUrban.roadwayName",
      md: 4,
      isRequired:true
    },
    {
      id: "roadwayType",
      label: "Roadway Type",
      type: FIELD_TYPE.PICKLIST,
      options: ROADWAY_TYPES.map((i) => ({ id: i, name: i })),
      jsonPath: "location.locationUrban.roadwayType",
      md: 4,
      isRequired:true
    },
    {
      id: "quadrant",
      label: "Quadrant",
      type: FIELD_TYPE.PICKLIST,
      options: QUADRANTS.map((i) => ({ id: i, name: i })),
      jsonPath: "location.locationUrban.quadrant",
      md: 2,
    },
  ],
};
export const locationRural: MapField = {
  id: "locationRural",
  label: "Address (Rural)",
  type: FIELD_TYPE.FIELD_COLLECTION,
  jsonPath: "location.locationRural",
  fields: [
    {
      id: "ruralUnitNum",
      label: "Unit #",
      type: FIELD_TYPE.TEXT,
      inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
      jsonPath: "location.locationRural.ruralUnitNum",
      md: 3,
      isRequired:true
    },
    {
      id: "ruralRoadNum",
      label: "Road #",
      type: FIELD_TYPE.TEXT,
      inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
      jsonPath: "location.locationRural.ruralRoadNum",
      md: 3,
      isRequired:true
    },

    {
      id: "ruralRoadExt",
      label: "Road ext",
      type: FIELD_TYPE.PICKLIST,
      jsonPath: "location.locationRural.ruralRoadExt",
      md: 3,
      getOptions: ({versionDoc, jurisdictionLookups}) => {

        return [
          {id:'A', name:'A'},
          {id:'B', name:'B'},
          {id:'C', name:'C'},
          {id:'D', name:'D'},
        
        ];
      }
    },
    {
      id: "ruralTypeNum",
      label: "Road Type #",
      type: FIELD_TYPE.TEXT,
      inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
      jsonPath: "location.locationRural.ruralTypeNum",
      md: 3,
      isRequired:true
    },
    {
      id: "ruralTypeExt",
      label: "Road Type Ext",
      type: FIELD_TYPE.PICKLIST,
      jsonPath: "location.locationRural.ruralTypeExt",
      md: 3,
      getOptions: ({versionDoc, jurisdictionLookups}) => {

        const values = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

        return [...values].map(c => ({id:c, name:c}))
        
        
      }
    },    
  ],
};
export const locationNone: MapField = {
  id: "locationNome",
  label: "Listing Title",
  type: FIELD_TYPE.FIELD_COLLECTION,
  jsonPath: "location.locationNone",
  fields: [
    {
      id: "listingTitle",
      label: "Title",
      type: FIELD_TYPE.TEXT,
      jsonPath: "location.locationNone.title",
      md: 12,
      isRequired:true
    }],
    
};
export const locus: PickListField = {
  id: "locus",
  label: "Locus",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "location.locus",
  isRequired:false,
  getOptions: ({versionDoc, jurisdictionLookups}) => {
    if (versionDoc !== undefined) {
      return getJurisdictionOption(versionDoc, jurisdictionLookups,  "locuses");
    }
    return [];
  },
};
export const lotIsContiguous: BoolField = {
  id: "lotIsContiguous",
  label: "Is Lot Contiguous",
  type: FIELD_TYPE.BOOL,
  jsonPath: "lotIsContiguous",
  md:3
};
export const majorBuildingsAreaSum: TextField = {
  id: "majorBuildingsAreaSum",
  label: "Building Area'",
  formLabelOverride: "Major Buildings Area Sum (ft²)",
  jsonPath: "majorBuildingsAreaSum",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 6,
  lg: 6,
  isRequired: true
};
export const mapInfo: GoogleMapField = {
  id: "mapInfo",
  label: "Map Location",
  type: FIELD_TYPE.MAP_INFO,
  jsonPath: FIELD_TYPE.MAP_INFO,
  sm: 12,
  md: 12,
  lg: 12,
  isRequired:true
};
export const mapRegion: PickListField = {
  id: "mapRegion",
  label: "Map Region",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "mapRegion",
  getOptions: ({versionDoc, jurisdictionLookups}) => {
    if (versionDoc !== undefined) {
      return getJurisdictionOption(versionDoc, jurisdictionLookups, "mapRegions");
    }
    return [];
  },
  isRequired:true,
  useIdInsteadOfValueForValue:true
};
export const mezzanineIncluded: BoolField = {
  id: "mezzanineIncluded",
  label: "Mezzanine Included?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "space.mezzanineIncluded",
  md: 6,
};
export const mezzanineFunction : PickListField = {
  id: "mezzanineFunction",
  label: "Mezzanine Function",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "space.mezzanineFunction",
  getOptions: () => {
    return [
      {id:'Developed', name:'Developed'},
      {id:'Storage', name:'Storage'},
    ]

  },
}
export const officeStorageArea: TextField = {
  id: "officeStorageArea",
  label: "Area",
  formLabelOverride: "Area (ft²)",
  jsonPath: "space.officeStorageArea",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 6,
};


export const termsActualOrEstimated : PickListField = {
  id: "termsActualOrEstimated",
  label: "Actual v. Estimated",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "termsConditions.termsActualOrEstimated",
  md: 3,
  lg: 3,
  getOptions: () => {
    return [
      {id:'Actual', name:'Actual'},
      {id:'Estimated', name:'Estimated'},
    ]
  },
}
export const mlsNumber: TextField = {
  id: "mlsNumber",
  label: "MLS #",
  jsonPath: "availability.mlsNumber",
  type: FIELD_TYPE.TEXT,
  
}

export const numberOfBuildings: TextField = {
  id: "numberOfBuildings",
  label: "Number Of Buildings",
  jsonPath: "numberOfBuildings",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
  isRequired: true
};
export const numberOfFloors: TextField = {
  id: 'numberOfFloors',
  label: 'Number of Floors',
  jsonPath: 'space.numberOfFloors',
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
}

export const park: EditablePickListField = {
  id: "park",
  label: "Park",
  type: FIELD_TYPE.EDITABLE_PICKLIST,
  jsonPath: "location.park",
  getOptions: ({versionDoc, jurisdictionLookups}) => {
    if (versionDoc !== undefined) {
      return getJurisdictionOption(versionDoc,jurisdictionLookups, "parks");
    }
    return [];
  },
};
export const parkingNumStalls: TextField = {
  id: "parkingNumStalls",
  jsonPath: "parking.numberOfStalls",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  label: "Number of Stalls",
  md: 3,
  lg: 3,
};
export const parkingStallsPer1000: TextField = {
  id: "parkingStallsPer1000",
  label: "Stalls/1000 sq. ft.",
  jsonPath: "parking.stallsPer1000",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
};
export const principalContactInfo: ContactInfoField = {
  id: "principalContactInfo",
  label: "Owner/Gate Keeper",
  type: FIELD_TYPE.CONTACT_INFO_ARRAY,
  jsonPath: "availability.principalContactInfo",
};
/* PRIVATE LISTING FIELDS  ****************************************************** */


export const privateListingContact: TextField = {
  id: "privateListingContact",
  label: "Contact Name",
  type: FIELD_TYPE.TEXT,
  jsonPath: "privateListing.contact",
  isRequired:true,
};
export const privateListingContactNumber: TextField = {
  id: "privateListingContactNumber",
  label: "Contact Number",
  type: FIELD_TYPE.TEXT,
  jsonPath: "privateListing.contactNumber",
  isRequired:true,
};




/******************************************************************************* */



export const proposedLandUseDistrict: MultiPickField = {
  id: "proposedLandUseDistrict",
  label: "Proposed Land Use District(s)",
  type: FIELD_TYPE.MULTIPICK,
  jsonPath: "proposedLandUseDistrict",
  getOptions: ({versionDoc, jurisdictionLookups}) =>
    getJurisdictionOption(versionDoc, jurisdictionLookups, "districts").map((i) => i.name),
  getOptionLabel: (option) => option,
};

export const projectName: TextField = {
  id: "projectName",
  label: "Project Name/ Building Number",
  jsonPath: "space.projectName",
  type: FIELD_TYPE.TEXT,
};
export const rezoningApplicationPending: BoolField = {
  id: "rezoningApplicationPending",
  label: "Rezoning Application Pending?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "rezoningApplicationPending",
};
export const siteFloorPlans: FileField = {
  id: "siteFloorPlans",
  label: "Site/Floor Plan(s)",
  jsonPath: "space.siteFloorPlans",
  type: FIELD_TYPE.FILE,
};
// export const spaceSize: TextField = {
//   id: "spaceSize",
//   label: "Space Size",
//   formLabelOverride: "Space Size (ft²)",
//   jsonPath: "space.spaceSize",
//   type: FIELD_TYPE.TEXT,
//   inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
//   md: 3,
//   isRequired: true
// };
export const spaceSizeIsRange: BoolField = {
  id: "spaceSizeIsRange",
  label: "Fixed or Range Bound?",
  type: FIELD_TYPE.BOOL,
  defaultValue: false,
  jsonPath: "space.spaceSizeIsRange",
  md: 3,
  trueLabel: "Range",
  falseLabel: "Fixed",
  isRequired: true
};
export const spaceRangeFrom: TextField = {
  id: "spaceRangeFrom",
  label: "Size From (ft²)",
  jsonPath: "space.spaceRangeFrom",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
  isRequired: true

};
export const spaceRangeTo: TextField = {
  id: "spaceRangeTo",
  label: "Size To (ft²)",
  jsonPath: "space.spaceRangeTo",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
  isRequired: true

};
export const structuresOnSite: BoolField = {
  id: "structuresOnSite",
  label: "Structures On Site?",
  type: FIELD_TYPE.BOOL,
  jsonPath: "structuresOnSite",
  isRequired: true
};
export const subdivision: EditablePickListField = {
  id: "subdivisions",
  label: "Subdivision",
  type: FIELD_TYPE.EDITABLE_PICKLIST,
  jsonPath: "location.subdivision",
  getOptions: ({versionDoc, jurisdictionLookups}) => {
    if (versionDoc !== undefined) {
      return getJurisdictionOption(versionDoc, jurisdictionLookups,"subdivisions");
    }
    return [];
  },
};
export const taxRollNumber : TextField = {
  id:"taxRollNumber",
  label: "Tax Roll #",
  type: FIELD_TYPE.TEXT,
  jsonPath: "taxRollNumber"
}
export const termsConditions: TextField = {
  id: "termsConditions",
  label: "Terms & Conditions",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.termsConditions",
  md: 12,
  lg: 12,
  multiline: true,
};
export const termsPrice: TextField = {
  id: "termsPrice",
  label: "Sale Price",
  formLabelOverride: "Sale Price ($)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.termsPrice",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
};
export const termsBasicRentIsRange: BoolField = {
  id: "termsBasicRentIsRange",
  label: "Basic Rent (Fixed/Range)",
  type: FIELD_TYPE.BOOL,
  defaultValue: false,
  jsonPath: "termsConditions.termsBasicRentIsRange",
  md: 3,
  lg: 3,

  trueLabel: "Range",
  falseLabel: "Fixed"
};
export const termsBasicRentRangeFrom: TextField = {
  id: "termsBasicRentRangeFrom",
  label: "Basic Rent From  ($/ft²)",
  jsonPath: "termsConditions.termsBasicRentRangeFrom",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
  isRequired: true

};
export const termsBasicRentRangeTo: TextField = {
  id: "termsBasicRentRangeTo",
  label: "Basic Rent To  ($/ft²)",
  jsonPath: "termsConditions.termsBasicRentRangeTo",
  type: FIELD_TYPE.TEXT,
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
  lg: 3,
  isRequired: true

};


export const termsBasicRent: TextField = {
  id: "termsBasicRent",
  label: "Basic Rent",
  formLabelOverride: "Basic Rent ($/ft²)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.termsBasicRent",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
};
export const grossCurrentRent: TextField = {
  id: "grossCurrentRent",
  label: "Gross Current Rent",
  formLabelOverride: "Gross Current Rent ($/ft²)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.grossCurrentRent",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
};
export const termsBasicRentBasedOn : PickListField = {
  id: "termsBasicRentBasedOn",
  label: "Based On",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "termsConditions.termsBasicRentBasedOn",
  getOptions: () => {
    return [
      {id:'Ground Floor Area', name:'Ground Floor Area'},
      {id:'Total Area', name:'Total Area'},
    ]

  },
}
export const termsRate: TextField = {
  id: "termsRate",
  label: "Sale Price",
  formLabelOverride: "Sale Price ($/ft²)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "termsConditions.termsRate",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 3,
};

export const termsRateBasedOn : PickListField = {
  id: "termsRateBasedOn",
  label: "Based On",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "termsConditions.termsRateBasedOn",
  getOptions: () => {
    return [
      {id:'Ground Floor Area', name:'Ground Floor Area'},
      {id:'Total Area', name:'Total Area'},
    ]

  },
}

export const tenantImprovementAllowance: TextField = {
  id: "tenantImprovementAllowance",
  label: "Tenant Improvement Allowance",
  formLabelOverride: "Tenant Improvement Allowance ($/ft²)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "space.tenantImprovementAllowance",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 6,
};

// export const thirdPartyFollowUp: ContactInfoField = {
//   id: "thirdPartyFollowUp",
//   label: "3rd Party Follow-up",
//   type: FIELD_TYPE.CONTACT_INFO_ARRAY,
//   jsonPath: "availability.thirdPartyFollowUp",
// };
export const tradingCategory: MultiPickField = {
  id: "tradingCategory",
  label: "Trading Category",
  type: FIELD_TYPE.MULTIPICK,
  jsonPath: "availability.tradingCategory",
  options: [
    "Auction",
    "For Lease",
    "For Sale",
    "For-Sub-Lease",
    "Lease to Own",
    "Pending",
    "Will Build To Suit",
  ],
  getOptionLabel: (option) => option,
  isRequired: true
};
export const tradingStatus: MultiPickField = {
  id: "tradingStatus",
  label: "Trading Status",
  type: FIELD_TYPE.MULTIPICK,
  jsonPath: "availability.tradingStatus",
  options: ["By Owner", "General", "Exclusive", "MLS", "Receivership", "GeX Private Listing"],
  getOptionLabel: (option: string) => option,
  isRequired: true
};

export const auctionType: PickListField = {
  id: "auctionType",
  label: "Auction Reserve",
  type: FIELD_TYPE.PICKLIST,
  jsonPath: "availability.auctionType",
  options:[
    {id:"Reserved", name:"Reserved"},
    {id:"Undisclosed", name:"Undisclosed"},
    {id:"Unreserved", name:"Unreserved"}
  ],
}

export const auctionReserveAmount: TextField = {
  id: "auctionReserveAmount",
  label: "Auction Reserve Amount",
  formLabelOverride: "Auction Reserve Amount ($)",
  type: FIELD_TYPE.TEXT,
  jsonPath: "availability.auctionReserveAmount",
  inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
  md: 6,
};
// export const trafficFlowDaily: TextField = {
//   id: "trafficFlowDaily",
//   label: "Average Daily Traffic Flow",
//   type: FIELD_TYPE.TEXT,
//   jsonPath: "space.trafficFlow.daily",
//   inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
// };
// export const trafficFlowWeekly: TextField = {
//   id: "trafficFlowWeekday",
//   label: "Average Weekday Traffic Flow",
//   type: FIELD_TYPE.TEXT,
//   jsonPath: "space.trafficFlow.weekday",
//   inputType: TEXTFIELD_INPUT_TYPE.NUMBER,
// };
export const trafficFlowInfo: TrafficFlowInfoField = {
  id: "trafficFlowInfo",
  label: "Traffic Flow Info",
  type: FIELD_TYPE.TRAFFIC_FLOW_INFO,
  jsonPath: "trafficFlow"
}
export const video1: VideoField = {
  id: "video1",
  label: "Video",
  type: FIELD_TYPE.VIDEO,
  jsonPath: "video1",
};
export const zoning: MultiPickField = {
  id: "zoning",
  label: "Zoning",
  formLabelOverride: "Zoning District(s)",
  type: FIELD_TYPE.MULTIPICK,
  jsonPath: "districts",
  getOptions: ({versionDoc, jurisdictionLookups}) =>
    getJurisdictionOption(versionDoc,jurisdictionLookups, "districts").map((i) => i.name),
  getOptionLabel: (option) => option,
  //isRequired:true
};
