import { Grid, TextField } from '@material-ui/core';
import { getLetterForOrdinal } from 'common/helperFunctions';
import React from 'react';
import styled from 'styled-components';
import { Piece, SizeData } from 'types/Types';
import SizesEditor from './SizesEditor';


type SpaceRowProps = {
    value: Piece | null;
    index: number;
    prefix: string;
    onlyShowSizeField: boolean;
    handleChange: (value: Piece) => void;
    handleBlur: (value: Piece) => void;
    isSpaceListing: boolean;
  };
  
  function SpaceRow({
    index,
    value,
    prefix,
    onlyShowSizeField,
    handleChange,
    handleBlur,
    isSpaceListing
  }: SpaceRowProps) {
    const sizeData = value?.sizeData || [{ type: "fixed", size: null, sizeFrom: null, sizeTo: null }] as SizeData[];
    const ordinal = getLetterForOrdinal(index);
    const measurementUnit = isSpaceListing ? "ft²" : "acres";
    return (
      <Grid  container spacing={3}>
        {!onlyShowSizeField && (
          <Grid item md={1} sm={12}>
            <OrdinalCell>{ordinal}.</OrdinalCell>
          </Grid>
        )}
        <Grid item md={onlyShowSizeField ? 5 : 4} sm={12}>
          <SizesEditor
            label={
              onlyShowSizeField
                ? `Area (${measurementUnit})`
                : `${prefix} ${ordinal} Area (${measurementUnit})`
            }
            value={sizeData}
            error={null}
            measurementUnit={measurementUnit}
            handleChange={(sd) => {
              if(!value) return;
              const newValue:Piece = { ...value, sizeData: sd };
              handleBlur(newValue);
            }}
          />
        </Grid>
        {!onlyShowSizeField && (
          <Grid item md={7} sm={12}>
            <TextField
              label={
                onlyShowSizeField
                  ? "Description"
                  : `${prefix} ${ordinal} Description`
              }
              variant="filled"
              fullWidth
              value={value?.description || ""}
              onChange={(e) => {
                if(!value) return;
                handleChange({ ...value, description: e.target.value });
              }}
              onBlur={(e) => { 
                if(!value) return;
                handleBlur({ ...value, description: e.target.value } || {}); }}
            />
          </Grid>
        )}
      </Grid>
    );
  }
  
  
  
  const OrdinalCell = styled.div`
    padding-top: 20px;
    font-weight: bold;
  `;
  

export default SpaceRow;
