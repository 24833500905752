import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type Props = {
  open:boolean,
  setOpen: (isOpen:false) => void,
  title: string,
  description:string,
  handleOk: () => void
}

export default function ConfirmDialog({
  open,
  setOpen,
  title,
  description,
  handleOk,
}: Props) {

  const handleClose = () => {
    setOpen(false);
  };

  return (

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            CANCEL
          </Button>
          <Button onClick={handleOk} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}
