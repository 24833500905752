import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { LISTING_TYPE } from 'types/Types';
import { getListingTypeFriendlyText } from 'common/newHelperFunctions';
import { User } from 'types/AdminTypes';
import { AppContext } from 'AppContext';
import { All_LISTING_TYPE } from 'pages/editor/EditorPage';

type Props = {
    value: All_LISTING_TYPE;
    onChange(type:All_LISTING_TYPE):void;
}

export default function SearchTypeMenuButton({value, onChange}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {user} = useContext(AppContext);
  if(!user){
      throw new Error('no user')
  }
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const  handleMenuItemClick = (type: All_LISTING_TYPE) => {
    
    setAnchorEl(null);
    onChange(type);
  }

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleButtonClick}>
        {value === 'all' ? 'All Segments' : getListingTypeFriendlyText(value)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem value={"all"} onClick={() => handleMenuItemClick('all')}>All</MenuItem>
        {getListingTypeMenuItem(LISTING_TYPE.COMMERCIAL_LAND, user, handleMenuItemClick)}
        {getListingTypeMenuItem(LISTING_TYPE.INDUSTRIAL_LAND, user, handleMenuItemClick)}
        {getListingTypeMenuItem(LISTING_TYPE.INDUSTRIAL_SPACE, user, handleMenuItemClick)}
        {getListingTypeMenuItem(LISTING_TYPE.OFFICE_SPACE, user, handleMenuItemClick)}
        {getListingTypeMenuItem(LISTING_TYPE.RETAIL_SPACE, user, handleMenuItemClick)}
      </Menu>
    </>
  );
}

function getListingTypeMenuItem(type: LISTING_TYPE, user: User, onClick: (type: All_LISTING_TYPE) => void) {
    return (
      <MenuItem value={type} disabled={!user.marketSegments.includes(type)} onClick={() => onClick(type)}>
        {getListingTypeFriendlyText(type)}
      </MenuItem>
    );
  }