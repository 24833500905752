import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { BoolField, CellColumnNumber } from "types/fieldComponentTypes";
type Props = {
  field: BoolField;
  value: string;
  xs: CellColumnNumber;
  sm: CellColumnNumber;
  md: CellColumnNumber;
  disabled: boolean;
  onBlur: (value: boolean) => void;
  trueLabel: string;
  falseLabel: string;
};
const RadioBoolFieldControl = ({
  field,
  value,
  xs,
  sm,
  md,
  onBlur,
  trueLabel,
  falseLabel,
  disabled,
}: Props) => {
  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <FormLabel component="legend" disabled={disabled}>
        {field.formLabelOverride || field.label}
      </FormLabel>
      <RadioGroup
        aria-label={field.formLabelOverride || field.label}
        name={field.formLabelOverride || field.label}
        value={value}
        onChange={(e) => onBlur(e.target.value === "true")}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label={trueLabel}
          disabled={disabled}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={falseLabel}
          disabled={disabled}
        />
      </RadioGroup>
    </Grid>
  );
};
export default RadioBoolFieldControl;
