
import { createContext } from "react";
import { Themes } from "themes";
import { User } from "types/AdminTypes";
import { OrganizationTypes } from "types/Types";

export type AppContextType =   {
    webSiteBaseUrl: string;
    user: null | User;
    orgId: keyof Themes;
    handleLogout():void;
    organizationType: OrganizationTypes,
    setOrganizationType(views:OrganizationTypes) : void,
    //setIsPrelaunch(value:boolean): void,
    //isPrelaunch: boolean;

}
const appContext: AppContextType = {
    webSiteBaseUrl:'',
    user: null,
    orgId: 'gex',
    handleLogout: () => {},
    organizationType: 'contributors',
    //isPrelaunch:true,
    setOrganizationType: (v) => {
        
    },
    
}

export const AppContext = createContext(
    appContext
);