import {
  getAreaStringForArrayOfSizeData,
  trimPiecesToFitNumber,
} from "common/newHelperFunctions";
import React from "react";
import { Pieces } from "types/Types";
import {
  OrdinalTD,
  Table,
  TableHeader,
  Td,
} from "../../components/preview/ListingPageStyledComponents";

type PiecesTableProps = {
  pieces: Pieces;
  isSpaceListing: boolean;
};

const PiecesTable = ({ pieces, isSpaceListing }: PiecesTableProps) => {
  const pArray = trimPiecesToFitNumber(pieces, isSpaceListing);
  const showDescription = true;

  return (
    <Table>
      <tbody>
        <tr>
          <TableHeader></TableHeader>
          {showDescription && <TableHeader>Description</TableHeader>}
          <TableHeader>Area</TableHeader>
        </tr>
        {pArray.map((piece, i) => (
          <tr key={`lotTableRow_${i}`}>
            <OrdinalTD>{String.fromCharCode(65 + i)}</OrdinalTD>
            {showDescription && <Td>{piece.description}</Td>}
            <Td>{getAreaStringForArrayOfSizeData(piece.sizeData || [], isSpaceListing)}</Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PiecesTable;
