import { createStyles, makeStyles, Theme, withTheme } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import ListingPanel from "components/ListingPanel";
import React, { useState } from "react";
import styled from "styled-components";
import { LISTING_TYPE, MapRegions, SearchDefaultType } from "types/Types";
import {
  filterLots,
  getFriendlyQueryText,
} from "../common/newHelperFunctions";
import useListingStore from "../store/useListingStore";
import ListMapControl from "./components/ListMapControl";
import PublicHeader from "./components/PublicHeader";
import { SEARCH_DEFAULT } from "common/newConstants";
import StepSearch from "components/searchPopup/StepSearch";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
type Props = {
  startingMarketSegment?: LISTING_TYPE;
} & RouteComponentProps;

const PublicSearchPage = ({ startingMarketSegment }: Props) => {
  const [isSearchDialogOpen, setSearchDialogOpen] = useState<boolean>(true);
  const [searchFields, setSearchFields] = useState<SearchDefaultType>({
    ...SEARCH_DEFAULT,
    marketSegment: startingMarketSegment || LISTING_TYPE.INDUSTRIAL_LAND,
  });

  const { state: listingState } = useListingStore();
  const [mapState, setMapState] = useState<{ selectedMarkerId: string | null }>(
    {
      selectedMarkerId: null,
    }
  );
  if (!listingState) {
    return <h3>loading</h3>;
  }

  function handleSearchClose(newSearchFields: SearchDefaultType) {
    setSearchFields(newSearchFields);
    setSearchDialogOpen(false);
  }

  //const searchFields = createSearchFieldsFromSearchDoc(searchDoc);

  const listingInfos = listingState.listings
    .filter((i) => filterLots(i, searchFields))
    .map((l) => ({
      ...l.listingInfo,
      status: l.status,
      listingType: l.listingType,
      orgId: l.orgId,
      lastSaveUserId:l.lastSave.uid,
    }));
  return (
    <ListMapControl
      mode="public"
      listings={listingInfos}
      mapState={mapState}
      setMapState={setMapState}
      hideMap={false}
      canUserEdit={false}
      canUserSearch={false}
    >
      <PublicHeader />
      <ListingPanel
        listings={listingInfos}
        selectedItemId={mapState.selectedMarkerId}
        isPublic={true}
        isRealtor={false}
        rowItemClick={(id) => {
          setMapState((s) => ({
            ...s,
            selectedMarkerId: id,
          }));
        }}
        rowItemHover={(id) => {}}
        shouldShowEditButton={(orgId) => false}
      >
        <PublicListingHeader
          queryText={getFriendlyQueryText(
            searchFields.marketSegment,
            searchFields.areaSliderOrdinal,
            Object.keys(searchFields.mapRegions).filter(
              (key) =>
                !!(searchFields.mapRegions as any)[key as keyof MapRegions]
            ),
            searchFields.isMajorStructures,
            searchFields.isNoMajorStructures,
            true
          )}
          onRefineSearch={() => setSearchDialogOpen(true)}
        />
      </ListingPanel>
      {isSearchDialogOpen && (
        <StepSearch
          searchFields={searchFields}
          closeForm={handleSearchClose}
          isPublic
        />
      )}
    </ListMapControl>
  );
};
const PublicListingHeader = ({
  queryText,
  onRefineSearch,
}: {
  queryText: string | JSX.Element;
  onRefineSearch?: () => void;
}) => {
  const classes = useStyles();
  return (
    <HeaderContainer>
      <QueryText>{queryText}</QueryText>
      <Button
        size="small"
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={onRefineSearch}
      >
        Refine Search
      </Button>
    </HeaderContainer>
  );
};
const HeaderContainer = withTheme(styled.div`
  padding: 30px 30px 10px 30px;
`);
const QueryText = styled.div`
  font-size: 18px;
  color: #777;
  line-height: 25px;
  & > strong {
    color: #000;
    font-weight: 500;
  }
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      color: "#0079ff",
    },
  })
);

export default PublicSearchPage;
