import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import styled from "styled-components";
import { isEmptyOrSpaces } from "../../common/helperFunctions";

export default function FormDialog({
  state,
  setState,
  handleClose,
  handleSave,
}) {
  const { isNew, isOpen, contact } = state;
 
  const [errorState, setErrorState] = useState({});
  if (!isOpen) {
    return <></>;
  }
  const { contactName, followUpMethod, description, email, phone } = contact;
  function anyTrue(obj)
  {
    for(var o in obj)
        if(!!obj[o]) return true;
    return false;
  }

  function checkSave() {
    console.log('test')
    const es = {
      description: isEmptyOrSpaces(description),
      contactName: isEmptyOrSpaces(contactName),

    };
    console.log(es)
    if(anyTrue(es)){
      setErrorState(es);
      return;
    }
    setErrorState({});
    handleSave();
  }

    console.log(errorState)
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isNew ? "Add Contact" : "Edit Contact"}
      </DialogTitle>
      <DialogContent>

        <Row>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="description">Description</InputLabel>
            <Select
              labelId="sDescription"
              value={contact.description}
              onChange={(e) =>
                setState({
                  ...state,
                  contact: {
                    ...state.contact,
                    description: e.target.value,
                  },
                })
              }
              error={errorState.description}
            >
              <MenuItem value="Employee">Employee</MenuItem>
              <MenuItem value="Owner">Owner</MenuItem>
              <MenuItem value="Property Manager">Property Manager</MenuItem>
              <MenuItem value="Realtor">Realtor</MenuItem>
              <MenuItem value="Tenant">Tenant</MenuItem>
            </Select>
          </FormControl>
        </Row>
        <Row>
          <TextField
            label="Contact Name"
            variant="filled"
            fullWidth
            error={errorState.contactName}
            value={contactName || ""}
            onChange={(e) =>
              setState({
                ...state,
                contact: { ...contact, contactName: e.target.value },
              })
            }
          />
        </Row>
        {/* <Row>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="followup">Follow-Up Method</InputLabel>
            <Select
              labelId="sFollowUp"
              value={contact.followUpMethod}
              onChange={(e) =>
                setState({
                  ...state,
                  contact: {
                    ...state.contact,
                    followUpMethod: e.target.value,
                  },
                })
              }
              error={errorState.followUpMethod}
            >
              <MenuItem value="Email">Email</MenuItem>
              <MenuItem value="Phone">Phone</MenuItem>
            </Select>
          </FormControl>
        </Row> */}
        
        <Row>
          <TextField
            label={"Email"}
            variant="filled"
            fullWidth
            value={
                contact.email
            }
            onChange={(e) => {
 
                setState({
                  ...state,
                  contact: { ...state.contact, email: e.target.value },
                });
              
            }}
          />
        </Row>
        <Row>
          <TextField
            label={"Phone"}
            variant="filled"
            fullWidth
            value={
                contact.phone
            }
            onChange={(e) => {
 
                setState({
                  ...state,
                  contact: { ...state.contact, phone: e.target.value },
                });
              
            }}
          />
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {setErrorState({}); handleClose()}}>Cancel</Button>
        <Button onClick={checkSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const Row = styled.div`
  padding: 10px 0;
  min-width: 400px;
`;

// const ModalBackground = styled.div`
//   height: 100vh !important;
//   position: fixed !important;
//   top: 0px !important;
//   width: 100% !important;
//   z-index: 101;
//   background: rgba(255, 255, 255, 0.85) !important;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
// const Container = styled.div`
//   width: 1080px;
//   box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;
//   border: 1px solid #e5e5e5;
//   border-radius: 10px;

//   background-color: #fff;
//   margin: auto;
//`;
// const EditContactDialog = ({ isOpen, isNew, closeDialog, saveRow, editContact, setEditContact }) => {
//   const contact = editContact.contact;
//   const btns = [
//     <FlatButton
//       label="Submit"
//       primary={true}
//       onTouchTap={() => {
//         saveRow();
//       }}
//       disabled={!contact || !contact.contactName
//         || !contact.followUpMethod || (!contact.email && !contact.phone)}
//     />,
//     <FlatButton
//       label="Cancel"
//       secondary={true}
//       onTouchTap={closeDialog}
//     />
//   ];
//   return (<Dialog
//     title={isNew ? "Add Contact" : "Edit Contact"}
//     actions={btns}
//     modal={false}
//     open={isOpen}

//     onRequestClose={closeDialog}
//   >
//     <TextField
//       onChange={(e) => setEditContact({ ...editContact, contact: {...editContact.contact, contactName: e.target.value }})}
//       hintText={"Enter Contact Name"}
//       fullWidth={true}
//       floatingLabelText="Contact Name"
//       value={contact.contactName}
//       inputStyle={{ color: 'green' }}
//     />
//     <SelectField
//       floatingLabelText='Description'
//       value={contact.description}
//       onChange={(event, index, value) => setEditContact({ ...editContact, contact: {...editContact.contact, description: value}})}>
//       <MenuItem value={'Employee'} primaryText='Employee' />
//       <MenuItem value={'Private Owner'} primaryText='Private Owner' />
//       <MenuItem value={'Property Manager'} primaryText="Property Manager" />
//       <MenuItem value={'Realtor'} primaryText='Realtor' />
//       <MenuItem value={'Tenant'} primaryText='Tenant' />
//     </SelectField><br/>
//     <SelectField
//       floatingLabelText='Follow-Up Method'
//       value={contact.followUpMethod}
//       onChange={(event, index, value) => setEditContact({ ...editContact, contact: {...editContact.contact, followUpMethod: value, phone: null, email: null}})}>
//       <MenuItem value={'Email'} primaryText="Email" />
//       <MenuItem value={'Phone'} primaryText="Phone" />
//     </SelectField>
//     {contact.followUpMethod === 'Email' &&
//       <TextField
//         onChange={(e) => setEditContact({ ...editContact, contact: {...editContact.contact, email: e.target.value }})}

//         hintText={"Enter Email"}
//         fullWidth={true}
//         floatingLabelText="Email"
//         value={contact.email}
//         inputStyle={{ color: 'green' }}
//       />

//     }
//     {contact.followUpMethod === 'Phone' &&
//       <TextField
//         onChange={(e) => setEditContact({ ...editContact, contact: {...editContact.contact, phone: e.target.value }})}
//         hintText={"Enter Phone Number"}
//         fullWidth={true}
//         floatingLabelText="Phone Number"
//         value={contact.phone}
//         inputStyle={{ color: 'green' }}
//       />

//     }

//   </Dialog>)
// }

// const NotOpen = () => <div></div>

// const enhance = compose(
//   branch(props => !props.isOpen, renderComponent(NotOpen))
// );
// export default enhance(EditContactDialog);
