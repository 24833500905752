
import { Button, Paper } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import EmailTable from "pages/registration/sponsorContributorEmailTable/EmailTable";
import SponsorMembershipTable from "pages/registration/sponsorContributorEmailTable/SponsorMembershipTable";

import React, { useState } from "react";
import styled from "styled-components";
import MainHeader from "./components/MainHeader";

type Props = {} & RouteComponentProps;

export default function TestPage({}: Props) {

  return (
    <div>
       
    <h1>test</h1>
    </div>
  );
}
