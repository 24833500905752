import { SearchDefaultType } from './../types/Types';
import { LISTING_TYPE } from "types/Types";



export const SEARCH_DEFAULT: SearchDefaultType = {
  isNoMajorStructures: true,
  isMajorStructures: false,
  areaSliderOrdinal: 3,
  useSimpleSize: true,
  minAreaTick: 0,
  maxAreaTick: 48,
  tradingCategories: {
    forLease: true,
    forSale: true,
    restricted: true,
    willBuildToSuit: true,
  },
  mapRegions: {},
  marketSegment: LISTING_TYPE.INDUSTRIAL_LAND,
};
export type DistributionRow = {
  ordinal: number;
  value: string;
  min: number;
  max: number;
};

export const AREA_DISTRIBUTION_ACRES: DistributionRow[] = [
  { ordinal: 0, value: " < 1 acre", min: 0, max: 1.5 },
  { ordinal: 1, value: "1 acre", min: 0.5, max: 2 },
  { ordinal: 2, value: "2", min: 1.5, max: 3 },
  { ordinal: 3, value: "3", min: 2.5, max: 3.75 },
  { ordinal: 4, value: "4", min: 3, max: 5 },
  { ordinal: 5, value: "5", min: 4, max: 6 },
  { ordinal: 6, value: "6", min: 5, max: 7 },
  { ordinal: 7, value: "7", min: 6, max: 8 },
  { ordinal: 8, value: "8", min: 7, max: 10 },
  { ordinal: 9, value: "9", min: 8, max: 11 },
  { ordinal: 10, value: "10", min: 9, max: 12 },
  { ordinal: 11, value: "11", min: 10, max: 13 },
  { ordinal: 12, value: "12", min: 11, max: 14 },
  { ordinal: 13, value: "13", min: 12, max: 15 },
  { ordinal: 14, value: "14", min: 13, max: 16 },
  { ordinal: 15, value: "15", min: 14, max: 17 },
  { ordinal: 16, value: "20", min: 16, max: 25 },
  { ordinal: 17, value: "25", min: 23, max: 30 },
  { ordinal: 18, value: "30", min: 28, max: 35 },
  { ordinal: 19, value: "35", min: 30, max: 40 },
  { ordinal: 20, value: "40", min: 35, max: 45 },
  { ordinal: 21, value: "45", min: 40, max: 50 },
  { ordinal: 22, value: "50", min: 45, max: 55 },
  { ordinal: 23, value: "55", min: 50, max: 60 },
  { ordinal: 24, value: "60", min: 55, max: 65 },
  { ordinal: 25, value: "65", min: 60, max: 70 },
  { ordinal: 26, value: "70", min: 65, max: 80 },
  { ordinal: 27, value: "75", min: 70, max: 85 },
  { ordinal: 28, value: "100", min: 90, max: 120 },
  { ordinal: 29, value: "125", min: 110, max: 140 },
  { ordinal: 30, value: "150", min: 135, max: 170 },
  { ordinal: 31, value: "175", min: 155, max: 190 },
  { ordinal: 32, value: "200", min: 180, max: 225 },
  { ordinal: 33, value: "225", min: 200, max: 250 },
  { ordinal: 34, value: "250", min: 225, max: 2000 },
];

export const AREA_DISTRIBUTION_SQFT : DistributionRow[] = [
    { ordinal: 1, value: "< 1,000", min:300, max: 1500},
    { ordinal: 2, value: "1,000", min:750, max: 1750},
    { ordinal: 3, value: "1,500", min:1200, max: 2250},
    { ordinal: 4, value: "2,000", min:1750, max: 3000},
    { ordinal: 5, value: "2,500", min:2250, max: 3750},
    { ordinal: 6, value: "3,000", min:2750, max: 4500},
    { ordinal: 7, value: "3,500", min:3250, max: 5250},
    { ordinal: 8, value: "4,000", min:3750, max: 6000},
    { ordinal: 9, value: "4,500", min:4250, max: 6750},
    { ordinal: 10, value: "5,000", min:4750, max: 7500},
    { ordinal: 11, value: "6,000", min:5750, max: 9000},
    { ordinal: 12, value: "7,000", min:6750, max: 10000},
    { ordinal: 13, value: "8,000", min:7750, max: 11000},
    { ordinal: 14, value: "9,000", min:8750, max: 12000},
    { ordinal: 15, value: "10,000", min:9750, max: 13250},
    { ordinal: 16, value: "12,500", min:12250, max: 15625},
    { ordinal: 17, value: "15,000", min:14500, max: 18750},
    { ordinal: 18, value: "17,500", min:17000, max: 21875},
    { ordinal: 19, value: "20,000", min:19500, max: 25000},
    { ordinal: 20, value: "25,000", min:24500, max: 30000},
    { ordinal: 21, value: "30,000", min:29500, max: 36000},
    { ordinal: 22, value: "35,000", min:34500, max: 42000},
    { ordinal: 23, value: "40,000", min:39500, max: 48000},
    { ordinal: 24, value: "50,000", min:49400, max: 60000},
    { ordinal: 25, value: "60,000", min:59250, max: 72000},
    { ordinal: 26, value: "70,000", min:69000, max: 84000},
    { ordinal: 27, value: "80,000", min:79000, max: 96000},
    { ordinal: 28, value: "90,000", min:88750, max: 108000},
    { ordinal: 29, value: "100,000", min:98750, max: 120000},
    { ordinal: 30, value: "125,000", min:123500, max: 150000},
    { ordinal: 31, value: "150,000", min:148500, max: 180000},
    { ordinal: 32, value: "175,000", min:173250, max: 210000},
    { ordinal: 33, value: "200,000", min:198000, max: 240000},  
  ];
