import { LinearProgress } from "@material-ui/core";
import { AppContext } from 'AppContext';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { User } from 'types/AdminTypes';
type UserDataNeedsUpdatingPageProps = {
    updateUser: (user:User) => Promise<unknown>
}
export default function UserDataNeedsUpdatingPage ({updateUser} : UserDataNeedsUpdatingPageProps) {
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const {user} = useContext(AppContext);


    useEffect( () => {
        if(!user){
            return;
        }
        if(!isUpdated) {

            updateUser(user).then(
                () => {
                    setIsUpdated(true);
                    window.location.replace("/")
                }
            )
        }   
        
    }, [isUpdated, user,updateUser])

    if(isUpdated){
        return(<div>updated</div>)
    }

    return (<div>
        <LinearProgress />
        <Container>updating user record</Container>
    </div>);
}

const Container = styled.div`
    margin: 50px auto;
    width:400px;
    color:#777;
`;