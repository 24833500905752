import { RouteComponentProps } from "@reach/router";
import { AppContext } from "AppContext";
import { AREA_DISTRIBUTION_ACRES, SEARCH_DEFAULT } from "common/newConstants";
import { filterLots, getFriendlyQueryText, getUserAbilities } from "common/newHelperFunctions";
import ListingPanel from "components/ListingPanel";
import SearchPanelHeader from "components/SearchPanelHeader";
import ListMapControl from "pages/components/ListMapControl";
import React, { useContext, useState } from "react";
import useListingStore from "store/useListingStore";
import { MapRegions, SavedSearch, SearchDefaultType } from "types/Types";
import StepSearch from "../components/searchPopup/StepSearch";
import useStore from "../store/useStore";
import Snackbar from "./components/controls/SnackBar";
import MainHeader from "./components/MainHeader";
import SavedSearchesForm from "./components/SavedSearchesForm";
import SharedSearchModal from "./components/SharedSearchModal";
import LoadingPage from "./LoadingPage";

type Mode = "savedSearches" | "search";
type State = {
  isStepSearchOpen: boolean;
  isSavedSearchSnackbarOpen: boolean;
  isSharedSearchModalOpen: boolean;
  mode: Mode;
  mapState: {
    selectedMarkerId: string | null;
  };
};

const MainPage = (props: RouteComponentProps) => {
  const { user } = useContext(AppContext);
  if (!user) {
    throw new Error("no user");
  }

  const userAbilities = getUserAbilities(user);

  if(!userAbilities.canSearch){
    throw new Error('Unauthorized')
  }


  const ls = useListingStore();
  
  const [state, setState] = useState<State>({
    isStepSearchOpen: false,
    isSharedSearchModalOpen: false,
    mode: "savedSearches",
    isSavedSearchSnackbarOpen: false,
    mapState: {
      selectedMarkerId: null,
    },
  });
  const { savedSearches, saveSearch, addSearch, deleteSearch } = useStore(user);
  const [searchFields, setSearchFields] = useState<SearchDefaultType>(
    SEARCH_DEFAULT
  );

  if (!ls?.state || !savedSearches) {
    return <LoadingPage />;
  }

  const listingInfos = ls.state.listings
    .filter((i) => filterLots(i, searchFields))
    .map((l) => ({
      ...l.listingInfo,
      status: l.status,
      listingType: l.listingType,
      orgId: l.orgId,
      lastSaveUserId:l.lastSave.uid,
    }));

  function handleSave(newState: SearchDefaultType) {

    const saveDoc: SavedSearch = {
      clientName: newState.clientName || "",
      area: AREA_DISTRIBUTION_ACRES[newState.areaSliderOrdinal],
      mapRegions: Object.keys(newState.mapRegions).filter(
        (key) => !!newState.mapRegions[key as keyof MapRegions]
      ),
      isMajorStructures: newState.isMajorStructures,
      isNoMajorStructures: newState.isNoMajorStructures,
      marketSegment: newState.marketSegment,
      isDeleted: false,
    };

    if (!searchFields.searchId) {
      const searchId = addSearch(saveDoc);
      setSearchFields({ ...newState, searchId });
    } else {
      saveSearch({ ...saveDoc, searchId: searchFields.searchId });
      setSearchFields({ ...newState, searchId: searchFields.searchId });
    }
    setState({
      ...state,
      isSavedSearchSnackbarOpen: true,
      isStepSearchOpen: false,
      mode: "search",
    });
  }

  return (
    <ListMapControl
      mode="savedSearches"
      listings={listingInfos}
      mapState={state.mapState}
      setMapState={(mapState) => setState({ ...state, mapState: mapState })}
      hideMap={state.isStepSearchOpen}
      canUserEdit={userAbilities.canEdit}
      canUserSearch={userAbilities.canSearch}
    >
      <MainHeader
        // onSavedSearchClick={() => setState({ ...state, mode: "savedSearches" })}

      />
      {state.mode === "savedSearches" && (
        <SavedSearchesForm
          savedSearches={savedSearches}
          saveSearch={saveSearch}
          setSearchFields={setSearchFields}
          newSearchClick={() => {
            setSearchFields(SEARCH_DEFAULT);
            setState({
              ...state,

              isStepSearchOpen: true,
            });
          }}
          closeForm={() => setState({ ...state, mode: "search" })}
          deleteSearch={(doc: SavedSearch) => deleteSearch(doc)}
        />
      )}
      {state.mode === "search" && (
        <ListingPanel
          listings={listingInfos}
          selectedItemId={state.mapState.selectedMarkerId}
          isPublic={false}
          isRealtor={false}
          rowItemClick={(id) => {
            

            setState((s) => ({
              ...s,
              mapState: { ...s.mapState, selectedMarkerId: id },
            }));
          }}
          rowItemHover= {(id) => setState(s => ({
            ...s,
            mapState: { ...s.mapState, hoverMarkerId:id}
          }))}
          shouldShowEditButton={(orgId) => false}
        >
          <SearchPanelHeader
            queryText={getFriendlyQueryText(
              searchFields.marketSegment,
              searchFields.areaSliderOrdinal,
              Object.keys(searchFields.mapRegions).filter(
                (key) => !!searchFields.mapRegions[key as keyof MapRegions]
              ),
              searchFields.isMajorStructures,
              searchFields.isNoMajorStructures,
              true
            )}
            clientName={searchFields.clientName}
            clientNameChange={(value) => setSearchFields({...searchFields, clientName: value})}
            clientNameSave={() => handleSave(searchFields)}
            closeClick={() =>
              setState((s) => ({ ...s, mode: "savedSearches" }))
            }
            dateSaved={searchFields.dateSaved}
            refineSearchClick={() =>
              setState((s) => ({ ...s, isStepSearchOpen: true }))
            }
            shareSearchClick={() =>
              setState((s) => ({ ...s, isSharedSearchModalOpen: true }))
            }
          />
        </ListingPanel>
      )}
      {state.isStepSearchOpen && (
        <StepSearch
          searchFields={searchFields}
          //setSearchFields={setSearchFields}
          closeForm={handleSave} //() => setState({ ...state, isStepSearchOpen: false })
        />
      )}
      {state.isSharedSearchModalOpen && (
        <SharedSearchModal
          userId={user.uid}
          searchId={searchFields.searchId}
          closeForm={() =>
            setState({ ...state, isSharedSearchModalOpen: false })
          }
        />
      )}
      <Snackbar
        isSnackbarOpen={state.isSavedSearchSnackbarOpen}
        setIsOpen={(isOpen: boolean) =>
          setState({ ...state, isSavedSearchSnackbarOpen: isOpen })
        }
        variant="success"
        message="Search Saved"
      />
    </ListMapControl>
  );
};

export default MainPage;
