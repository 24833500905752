import { Grid } from "@material-ui/core";
import { StarRateOutlined } from "@material-ui/icons";
import { DEFAULT_CENTER } from "common/constants";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import styled from "styled-components";
import { Field } from "types/fieldComponentTypes";
import {  MapInfo, Point } from "types/Types";
import MultipleZoneMap from "./MultipleZoneMap";
import MultipleZoneMapEditor, { Zone } from "./MultipleZoneMapEditor";
type AddNewMapEditorProps = {
  field: Field;
  mapInfo: MapInfo;
  handleChange(jsonPath: string, value: any): void;
};
type State = {
  center: Point;
  zoom: number;
  selectedOrdinal?: number;
  marker:Point | null;
}
type AutoCompleteProps = {
  value: {
    place_id: string;
  }
};
export default function AddNewMapEditor({
  field,
  mapInfo,
  handleChange,
}: AddNewMapEditorProps) {
  //const [zones, setZones] = useState<Zone[]>(value.zones || []);
  
  // const zones: Zone[] = [
  //   {
  //     marker: value.marker || null,
  //     polygon: value.polygon || null,
  //     area: 0,
  //     description: "Main",
  //   },
  //   {
  //     marker: value.secondaryMarker || null,
  //     polygon: value.secondaryPolygon || null,
  //     area: 0,
  //     description: "Secondary (if applicable)",
  //   },
  // ];
  const [state, setState] = useState<State>({
    center: mapInfo.center || DEFAULT_CENTER,
    zoom: mapInfo.zoom || 12,
    marker: mapInfo.marker || null,
    
  });
  const [autoCompleteObj, setAutoCompleteObj] = useState<AutoCompleteProps | null>(null);
  useEffect(() => {
    if (!!autoCompleteObj) {
      //console.log(autoCompleteObj.value.place_id)
      geocodeByPlaceId(autoCompleteObj.value.place_id).then((results) => {
        const locationObj = results[0].geometry.location;
        //console.log(locationObj.lat(), locationObj.lng());
        const newCenter: Point = { lat: locationObj.lat(), lng: locationObj.lng() };

        doStateChange({
          ...state,
          marker: newCenter,
          center: newCenter,
          zoom: 15,
          selectedOrdinal:0,
        });
      });
    }
  }, [autoCompleteObj]);

  function doStateChange(newState: State) {
    console.log(newState)
    setState(newState);
    handleChange(field.jsonPath, {
      ...mapInfo,
      marker: newState.marker,
      center: newState.center,
      zoom: newState.zoom,
    });
  }
  function updateLotMarker(ordinal: number, marker: Point) {
    const newMapInfo: MapInfo = {
      ...mapInfo,
      marker,
    };
    handleChange(field.jsonPath, newMapInfo);
  }
  function addPin( point:Point) {
    doStateChange({ ...state, marker: point, center: point, zoom: state.zoom, selectedOrdinal:0 });
  }
  
  const mapMarkers = state.marker && ([{ordinal:0, marker: state.marker}] || []) || [] as { ordinal: number; marker: Point | null; }[] 
  
  // function updateMap(center: Point, zoom: number, zones: Zone[]) {
  //   if (zones.length !== 2) {
  //     return;
  //   }
  //   const zone0 = zones[0];
  //   // const zone1 = zones[1];
  //   const newMapInfo: MapInfo = {
  //     center,
  //     zoom,
  //     ...zone0.marker && {marker: zone0.marker},
  //     ...zone0.polygon && {polygon: zone0.polygon } ,
  //     // ...zone1.marker && {secondaryMarker: zone1.marker},
  //     // ...zone1.polygon && {secondaryPolygon: zone1.polygon},
  //   };
  //   handleChange(field.jsonPath, newMapInfo);
  
  // }


  
  return (<>
          <Grid item md={12}>
          <p>To add a location marker either:</p>
          <Ul>
            <li><b>enter the address</b> below, or</li>
            <li><b>click</b> on the location on the map</li>
          </Ul>
        </Grid>
      <Grid item md={12}>
        <GooglePlacesAutocomplete
          autocompletionRequest={{
            bounds: [
              { lat: 53.2, lng: -112.8 },
              { lat: 53.888002, lng: -114.175415 },
            ],
            componentRestrictions: {
              country: ["ca"],
            },
          }}
          selectProps={{
            autoCompleteObj,
            value: autoCompleteObj,
            onChange: setAutoCompleteObj,
            placeholder: "Enter Address",
          }}
        />

      </Grid>
     
                <MultipleZoneMap
            zoom={state.zoom}
            center={state.center}
            mapMarkers={mapMarkers}
            mapPolygons={[]}
            selectedOrdinal={state.selectedOrdinal}
            setSelectedOrdinal={(ordinal) => setState({...state, selectedOrdinal: ordinal})}
            selectedMarkerChanged={updateLotMarker}
            selectedPolygonChanged={() => {}}
            mapZoomChanged={(z) => setState({...state, zoom: z})}
            mapCenterChanged={(c) => setState({...state, center: c})}
            onMapClick={(point) => addPin(point)}
            disabled={false}
          />
      </>
  );
}
const Ul = styled.ul`
  & li {
    line-height: 1.5;
  }
`;