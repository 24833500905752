import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import { FIELD_TYPE } from "types/fieldComponentTypes";
import { getValue } from "../common/helperFunctions";
import BoolFieldControl from "./controls/BoolFieldControl";
import BrokerageContactControl from "./controls/BrokerageContactControl";
import ContactInfoArray from "./controls/ContactInfoArray";
import FileControl from "./controls/FileControl";
import ImageControl from "./controls/ImageControl";
import MapInfoControl from "./controls/MapInfoControl";
import MultiPickControl from "./controls/MultiPickControl";
import PickListControl from "./controls/PickListControl";
import RadioBoolFieldControl from "./controls/RadioBoolFieldControl";
import TextFieldControl from "./controls/TextFieldControl";
import VideoFieldControl from "./controls/VideoFieldControl";
import IntegerPickerFieldControl from "./controls/IntegerPickerFieldControl";
import CraneFieldControl from "./controls/CraneFieldControl";
import EditablePickListField from "./controls/EditablePickListControl";
import TrafficFlowEditor from "./controls/TrafficFlowEditor";
import { DEFAULT_CENTER } from "common/constants";
import ListingMapEditor from "./controls/ListingMapEditor";
import SizesEditor from "./pieces-editor/SizesEditor";
import NumericPickList from "./controls/NumericPickList";
import PiecesEditor from "./pieces-editor/PiecesEditor";
import { isSpaceMarketSegment } from "common/newHelperFunctions";
export default function FieldEditor({
  field,
  versionDoc,
  handleChange,
  getStorageRef,
  errors,
  disabled,
  lookups,
  jurisdictionLookups,
  //doUpload
}) {
  if (!field) {
    return (
      <Grid item xs="3">
        not found
      </Grid>
    );
  }
  const xs = field.xs || 12;
  const sm = field.sm || 12;
  const md = field.md || 6;

  /* todo: 
    I am doing this because I havent yet cleaned up the 
    data. There is still a boolean 'isUrban' value that is used mostly for 
    older records. The 'addressType' if not there, should use this.
    Later I will update records in the database so this is not needed.
  */
  //  function cleanVersionDoc () {

  //   if(versionDoc.location.locationType){
  //     return versionDoc;
  //   }
  //   return {...versionDoc, location: {...versionDoc.location, locationType: versionDoc.location.isUrban ? 'urban' : 'rural'}}
  // }

  const getId = (field) => {
    if (field.jsonPath)
      return field.jsonPath ? field.jsonPath.replace(".", "_") : "";
    else return field.label.replace(".", "_");
  };

  function hasChanged(v) {
    function isStringEmptyOrNull(v) {
      return !v || v.trim().length === 0;
    }
    const currentValue = getValue(versionDoc, field.jsonPath);
    return (
      currentValue !== v &&
      (typeof v !== "string" ||
        !isStringEmptyOrNull(v) ||
        !isStringEmptyOrNull(currentValue))
    );
  }

  const handleBlur = (v) => {
    if (hasChanged(v)) {
      handleChange(field.jsonPath, v);
    }
  };

  const commonProps = {
    field,
    xs,
    sm,
    md,
    id: getId(field),
    error: errors && errors[field.id],
    disabled,
  };

  const value = field.jsonPath && getValue(versionDoc, field.jsonPath);
  const textProps = {
    value,
    onBlur: handleBlur,
    ...commonProps,
  };

  if (field.type === FIELD_TYPE.INTEGER_PICKER) {
    return <IntegerPickerFieldControl {...textProps} />;
  }
  if (field.type === FIELD_TYPE.CRANE) {
    return <CraneFieldControl {...textProps} />;
  }

  if (field.type === FIELD_TYPE.TEXT) {
    return <TextFieldControl {...textProps} />;
  }
  if (field.type === FIELD_TYPE.BOOL) {
    if (field.radio) {
      return <RadioBoolFieldControl {...textProps} {...field} />;
    }
    return <BoolFieldControl {...textProps} />;
  }
  if (field.type === FIELD_TYPE.FIELD_COLLECTION) {
    return (
      <>
        <FormLabel item xs={12}>
          {field.formLabelOverride || field.label}
        </FormLabel>
        {field.fields.map((childField, n) => (
          <FieldEditor
            handleChange={handleChange}
            key={`cf_${n}`}
            field={childField}
            versionDoc={versionDoc}
            errors={errors}
            disabled={disabled}
          />
        ))}
        <Spacer item xs={12} />
      </>
    );
  }
  if (field.type === FIELD_TYPE.MULTIPICK) {
    const options = field.isLookup
      ? field.getOptions({ versionDoc, lookups, jurisdictionLookups })
      : field.options ||
        field.getOptions({ versionDoc, lookups, jurisdictionLookups });
    return <MultiPickControl {...textProps} options={options} />;
  }
  if (field.type === FIELD_TYPE.PICKLIST) {
    return (
      <PickListControl
        {...textProps}
        lookups
        options={
          field.options ||
          field.getOptions({ versionDoc, lookups, jurisdictionLookups })
        }
        handleChange={handleChange}
      />
    );
  }

  if (field.type === FIELD_TYPE.NUMERIC_PICKLIST) {
    return <NumericPickList {...textProps} handleChange={handleChange} />;
  }
  if (field.type === FIELD_TYPE.BROKERAGE_CONTACT) {
    const phoneValue =
      field.jsonPathBrokerageContactNumber &&
      getValue(versionDoc, field.jsonPathBrokerageContactNumber);
    const nameValue = !value
      ? null
      : Array.isArray(value) && value.length > 0
      ? value[0]
      : value;
    return (
      <BrokerageContactControl
        {...textProps}
        brokerages={lookups.brokerages}
        handleChange={handleChange}
        phoneValue={phoneValue}
        nameValue={nameValue}
        contactError={errors.brokerageContactNumber}
      />
    );
  }
  if (field.type === FIELD_TYPE.EDITABLE_PICKLIST) {
    return (
      <EditablePickListField
        {...textProps}
        lookups
        options={
          field.options ||
          field.getOptions({ versionDoc, lookups, jurisdictionLookups })
        }
        handleChange={handleChange}
      />
    );
  }

  if (field.type === FIELD_TYPE.MAP_INFO) {
    return (
      <ListingMapEditor
        field={field}
        value={value}
        handleChange={handleChange}
      />
    );
  }
  if (field.type === FIELD_TYPE.VIDEO) {
    return <VideoFieldControl {...textProps} handleChange={handleChange} />;
  }
  if (field.type === FIELD_TYPE.IMAGE) {
    return (
      <ImageControl
        {...textProps}
        handleChange={handleChange}
        getStorageRef={getStorageRef}
        //doUpload={doUpload}
        versionDoc={versionDoc}
      />
    );
  }
  if (field.type === FIELD_TYPE.PIECES) {
    const center =
      (versionDoc.mapInfo && versionDoc.mapInfo.center) || DEFAULT_CENTER;
    return (
      <PiecesEditor
        {...textProps}
        listingId={versionDoc.listingId}
        getStorageRef={getStorageRef}
        listingCenter={center}
        handleChange={handleChange}
        isSpaceListing={isSpaceMarketSegment(versionDoc.listingType)}
      />
    );
  }
  if (
    field.type === FIELD_TYPE.FILE ||
    field.type === FIELD_TYPE.FILE_OR_LINK
  ) {
    return (
      <FileControl
        {...textProps}
        getStorageRef={getStorageRef}
        versionDoc={versionDoc}
        includeLink={field.type === FIELD_TYPE.FILE_OR_LINK}
      />
    );
  }
  if (field.type === FIELD_TYPE.CONTACT_INFO_ARRAY) {
    return (
      <ContactInfoArray
        value={value || []}
        {...textProps}
        onBlur={handleBlur}
      />
    );
  }

  if (field.type === FIELD_TYPE.TRAFFIC_FLOW_INFO) {
    return (
      <TrafficFlowEditor
        value={value || []}
        {...textProps}
        onBlur={handleBlur}
      />
    );
  }
  if (field.type === FIELD_TYPE.SIZES) {
    return (
      <SizesEditor value={value} field={field} handleChange={handleChange} />
    );
  }

  return <FieldGroup>unknown field: {field.type}</FieldGroup>;
}

const FieldGroup = styled.div`
  margin: 16px !important;
  min-width: 200px !important;
`;

const FormLabel = styled(Grid)`
  font-weight: 600;
  padding: 24px 12px 6px 12px !important;
`;

const Spacer = styled(Grid)`
  padding: 24px 12px 6px 12px !important;
`;
