import {
  createStyles,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Theme
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { AppContext } from "AppContext";
import { useDebounceFn } from "common/hooks";
import { SearchState } from "pages/editor/EditorPage";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import SearchTypeMenuButton from "./SearchTypeMenuButton";


type Props = {
  searchState:SearchState
  setSearch(searchState:SearchState): void;
}

const AdminSearchBox = ({
  searchState,
  setSearch,
}: Props) => {
  const { user } = useContext(AppContext);
  const [state, setState] = React.useState<SearchState>(searchState);
  if (!user) {
    throw new Error("no user");
  }


  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
      },
      divider: {
        height: 28,
        margin: 4,
      },
    })
  );
  const classes = useStyles();

  const cbSetSearch = useCallback(setSearch, [state, setSearch]);
  const cb = React.useCallback(() => {
    if(state.searchText !== searchState.searchText || state.listingType !== searchState.listingType){
      cbSetSearch(state)
    }
  }, [state, searchState, cbSetSearch])
  useDebounceFn(state,cb, 500 )

  return (
    <SearchHolder>
      <InputBase
        className={classes.input}
        placeholder="Search Address or LINC"
        inputProps={{ "aria-label": "Search Address or LINC" }}
        onChange={(e) => {
          setState({...state, searchText: e.target.value});
        }}
        value={state.searchText || ""}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <SearchTypeMenuButton value={state.listingType} onChange={lt => setState({...state, listingType:lt })} />
      <IconButton
        className={classes.iconButton}
        aria-label="clear"
        onClick={() => {
          setState({searchText:null, listingType:'all'});

        }}
      >
        <ClearIcon />
      </IconButton>
    </SearchHolder>
  );
};

const SearchHolder = styled.div`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #e8e8e8;
  width:100%;
`;

export default AdminSearchBox;
