import { Link } from "@reach/router";
import { AppContext } from 'AppContext';
import React, { useContext } from "react";
import styled from "styled-components";
import themes, { Themes } from 'themes';


function LogoControl({listOwnerOrgId,  isDocumentionLogo  = false, className, redirectToWebsite=false} 
  : {listOwnerOrgId?: keyof Themes, isDocumentionLogo?:boolean, className?:string, redirectToWebsite?:boolean}) {
    const {orgId} = useContext(AppContext);
    const theme  = themes[listOwnerOrgId || orgId];
    const imageDetails = isDocumentionLogo ? 
    {fileName:'logo_doc', width:theme.company.headerLogo.width, height:theme.company.headerLogo.height, divWidth: theme.company.headerLogo.width } :
    {fileName:'logo2', width:theme.company.headerLogo.width, height:theme.company.headerLogo.height, divWidth: theme.company.headerLogo.width + 30}



    const img =     <img
    src={`/${theme.company.folder}/${imageDetails.fileName}.png`}
    width={imageDetails.width}
    height={imageDetails.height}
    alt="logo"
  />;

  if(redirectToWebsite){
    const websiteUrl = window.location.host.includes('localhost:3000') ? 'http://localhost:8000' : process.env.REACT_APP_WEBSITE_URL;
    return (
      <LogoCell w={imageDetails.divWidth} className={className} hideIfSmall={!isDocumentionLogo}  >
        <a href={websiteUrl}  >
         {img}
        </a>
      </LogoCell>
    );

  }

  return (
    <LogoCell w={imageDetails.divWidth} className={className} hideIfSmall={!isDocumentionLogo}  >
      <Link to={ `/`}  >
       {img}
      </Link>
    </LogoCell>
  );
}
type LogoCellProps = {
  w:number;
  hideIfSmall:boolean;
}
const LogoCell = styled.div<LogoCellProps>`
  vertical-align: middle;
  width: ${props => props.w}px;
  vertical-align:middle;
  box-sizing: border-box;
  ${props => props.hideIfSmall && '@media (max-width: 800px) {display: none; }'}

`;

export default LogoControl;
