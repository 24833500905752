import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import styled from "styled-components";
import ConfirmDialog from "./ConfirmDialog";
import EditContactDialog from "./EditContactDialog";
const ContactInfoArray = ({ field, value, xs, sm, md, onBlur, disabled, error }) => {
  const [state, setState] = useState({
    dialogState: {
      isOpen: false,
    },
    confirmDialogState: {
      isOpen: false,
  },
  });
  function rowEdit(row) {
    setDialogState({ ...state.dialogState, isOpen: true, contact: { ...row } });
  }
  function handleSave() {
    const { ordinal, ...contact } = state.dialogState.contact;
    const { contactName, description, phone, email } = contact;
    const newValue = !!value ? [...value] : [];
    const row = { contactName: contactName || "", description: description || "" , email: email || "", phone: phone  || "" };

    // const row =
    //   contact.followUpMethod === "Phone"
    //     ? { contactName, description, followUpMethod, phone }
    //     : { contactName, description, followUpMethod, email };
    if (state.dialogState.isNew) {
      newValue.push(row);
    } else {
      newValue[ordinal] = row;
    }
    onBlur(newValue);
    setDialogState({ isOpen: false });
  }
  function handleClose() {
    setDialogState({ isOpen: false });
  }
  function setDialogState(dialogState) {
    setState({ ...state, dialogState });
  }
  function setConfirmDialogState(confirmDialogState) {
    setState({ ...state, confirmDialogState });
  }
  function addRow() {
    setDialogState({
      contact: {
        contactName: "",
        description: "",
        email: "",
        phone: "",
      },
      isOpen: true,
      isNew: true,
    });
  }
  function confirmDelete(ordinal) {
    setConfirmDialogState({ isOpen: true, ordinal });
  }
  function rowDelete() {
    const ordinal = state.confirmDialogState.ordinal;
    const newValue = [...value];
    newValue.splice(ordinal, 1);
    onBlur(newValue);
    setConfirmDialogState({ isOpen: false });
  }

  return (
    <Grid item lg={12}>
      {value && value.length > 0 && (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Contact Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((row, n) => (
              <TableRow key={`row_${n}`}>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
                <TableCell>{row.contactName}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {row.phone}
                </TableCell>
                <SmallTableCell>
                  <IconButton
                    aria-label="Edit Contact"
                    edge="end"
                    onClick={() => rowEdit({ ...row, ordinal: n })}
                    disabled={disabled}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Edit Contact"
                    edge="end"
                    color="secondary"
                    onClick={() => confirmDelete(n)}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                </SmallTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <BtnDiv>
        <Button
          color="primary"
          startIcon={<AddIcon />}
          disabled={disabled}
          onClick={addRow}
        >
          Add Contact
        </Button>
      </BtnDiv>
      <EditContactDialog
        state={state.dialogState}
        setState={setDialogState}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <ConfirmDialog
        open={state.confirmDialogState.isOpen}
        title="Delete Contact?"
        //description='tester'
        setOpen={() => setConfirmDialogState({ isOpen: false })}
        handleOk={rowDelete}
      />
      {error 
      // ? (
      //   <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled">
      //     Required
      //   </p>
      // ) : 
      
      && (
        <p
          className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
          style={{ borderTop: "1px solid red" }}
        >
          {error}
        </p>
      )}
    </Grid>
  );
};
const StyledTableCell = styled(TableCell)`
  font-weight: 600 !important;
`;
const SmallTableCell = styled(TableCell)`
  padding: 0px !important;
  width: 100px;
`;
const BtnDiv = styled.div`
  padding: 10px;
`;
export default ContactInfoArray;
