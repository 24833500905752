import { AREA_DISTRIBUTION_ACRES, SEARCH_DEFAULT } from "common/newConstants";
import { firestore } from "firebase";
import {
  DocumentationPage,
  PageCollection,
} from "pages/documentation/DocumentationTypes";
import React from "react";
import themes, { Themes } from "themes";
import { JurisdictionLookupsAdminDoc, Role, User } from "types/AdminTypes";
import {
  Listing,
  ListingInfo,
  LISTING_STATUSES,
  LISTING_TYPE,
  MapListingInfo,
  OrganizationTypes,
  Pieces,
  SavedSearch,
  SearchDefaultType,
  SizeData,
  VersionDoc,
} from "types/Types";
import { EDMONTON_REGIONS, measureUnits, REGION_NAMES } from "./constants";
import { getParcelSize } from "./helperFunctions";
import { AREA_DISTRIBUTION_SQFT, DistributionRow } from "./newConstants";
export function getListingTypeFriendlyText(listingTypeCode: LISTING_TYPE) {
  if (listingTypeCode === LISTING_TYPE.COMMERCIAL_LAND) {
    return "Commercial Land";
  }
  if (listingTypeCode === LISTING_TYPE.INDUSTRIAL_LAND) {
    return "Industrial Land";
  }
  if (listingTypeCode === LISTING_TYPE.INDUSTRIAL_SPACE) {
    return "Industrial Space";
  }
  if (listingTypeCode === LISTING_TYPE.OFFICE_SPACE) {
    return "Office Space";
  }
  if (listingTypeCode === LISTING_TYPE.RETAIL_SPACE) {
    return "Retail Space";
  }
  throw new Error("Unknown Market Segment:");
}
export function getMarketSegmentFromText(enumString: string) {
  if (enumString === LISTING_TYPE.COMMERCIAL_LAND.toString()) {
    return LISTING_TYPE.COMMERCIAL_LAND;
  }
  if (enumString === LISTING_TYPE.INDUSTRIAL_LAND.toString()) {
    return LISTING_TYPE.INDUSTRIAL_LAND;
  }
  if (enumString === LISTING_TYPE.INDUSTRIAL_SPACE.toString()) {
    return LISTING_TYPE.INDUSTRIAL_SPACE;
  }
  if (enumString === LISTING_TYPE.OFFICE_SPACE.toString()) {
    return LISTING_TYPE.OFFICE_SPACE;
  }
  if (enumString === LISTING_TYPE.RETAIL_SPACE.toString()) {
    return LISTING_TYPE.RETAIL_SPACE;
  }
  throw new Error("Unknown Market Segment" + enumString);
}

export function getFriendlyQueryText(
  marketSegment: LISTING_TYPE,
  areaSliderOrdinal: number,
  selectedMapRegions: string[],
  isMajorStructures: boolean,
  isNoMajorStructures: boolean,
  withBolding = false
) {
  const selectedEdmontonLocations = selectedMapRegions.filter((l: string) =>
    Object.keys(EDMONTON_REGIONS).includes(l)
  );
  const selectedOtherLocations = selectedMapRegions.filter(
    (l: string) => !Object.keys(EDMONTON_REGIONS).includes(l)
  );
  const selectedNamesArray: string[] = [];
  if (selectedEdmontonLocations.length === 5) {
    selectedNamesArray.push(`Edmonton (all regions)`);
  } else if (selectedEdmontonLocations.length > 0) {
    const selectedEdmontonNames = selectedEdmontonLocations.map(
      (l: string) => EDMONTON_REGIONS[l]
    );
    selectedNamesArray.push(
      `Edmonton (${selectedEdmontonNames.sort().join(", ")})`
    );
  }

  selectedNamesArray.push(
    ...selectedOtherLocations.map((l: string) => REGION_NAMES[l]).sort()
  );

  const trailingText = getTrailingText(
    marketSegment,
    isMajorStructures,
    isNoMajorStructures
  );

  const friendlySize: string = isSpaceMarketSegment(marketSegment)
    ? getFriendlySQFT(areaSliderOrdinal)
    : getFriendlyAcres(areaSliderOrdinal);
  return withBolding
    ? getFriendlyQueryTextWithStyles(
        getListingTypeFriendlyText(marketSegment),
        friendlySize,
        trailingText,
        selectedNamesArray
      )
    : getFriendlyQueryTextWithoutStyles(
        getListingTypeFriendlyText(marketSegment),
        friendlySize,
        trailingText,
        selectedNamesArray
      );
}
function getTrailingText(
  marketSegment: LISTING_TYPE,
  isMajorStructures: boolean,
  isNoMajorStructures: boolean
) {
  if (
    marketSegment !== LISTING_TYPE.COMMERCIAL_LAND &&
    marketSegment !== LISTING_TYPE.INDUSTRIAL_LAND
  ) {
    return "";
  }
  if (isMajorStructures && isNoMajorStructures) {
    return " including Land with and without major structures.";
  }
  if (isMajorStructures) {
    return " including Land featuring major structures only. (Excludes sites with no major structures). ";
  }
  return ` without major structures.`;
}

export function isLandMarketSegment(marketSegment: LISTING_TYPE) {
  return (
    marketSegment === LISTING_TYPE.COMMERCIAL_LAND ||
    marketSegment === LISTING_TYPE.INDUSTRIAL_LAND
  );
}
export function isRetailOrOfficeSpace(marketSegment: LISTING_TYPE) {
  return (
    marketSegment === LISTING_TYPE.OFFICE_SPACE ||
    marketSegment === LISTING_TYPE.RETAIL_SPACE
  );
}

export function getJurisdictionLongNameById(
  id: string,
  jurisdictionLookups: JurisdictionLookupsAdminDoc
) {
  const jurisdiction = jurisdictionLookups.jurisdictions.find(
    (j) => j.id === id
  );
  return !!jurisdiction ? jurisdiction.longName : null;
}

function getFriendlyQueryTextWithoutStyles(
  marketSegmentName: string,
  friendlyAcres: string,
  trailingText: string,
  selectedNamesArray: string[]
) {
  const mapRegionsArray = [
    friendlyAcres,
    " of ",
    marketSegmentName,
    " in ",
    selectedNamesArray[0],
  ];

  for (let x = 1; x < selectedNamesArray.length; x++) {
    const isLast = x === selectedNamesArray.length - 1;
    if (isLast) {
      mapRegionsArray.push(` and  ${selectedNamesArray[x]}`);
    } else {
      mapRegionsArray.push(`, ${selectedNamesArray[x]}`);
    }
  }
  mapRegionsArray.push(trailingText);
  return mapRegionsArray.join("");
}
function getFriendlyQueryTextWithStyles(
  marketSegmentName: string,
  friendlyAcres: string,
  trailingText: string,
  selectedNamesArray: string[]
) {
  const mapRegionsArray = [
    <strong key={"mapRegion_0"}>{selectedNamesArray[0]}</strong>,
  ];

  for (let x = 1; x < selectedNamesArray.length; x++) {
    const isLast = x === selectedNamesArray.length - 1;
    if (isLast) {
      mapRegionsArray.push(<span key={`and_${x}`}>{" and "}</span>);
      mapRegionsArray.push(
        <strong key={`strong_${x}`}>{selectedNamesArray[x]}</strong>
      );
    } else {
      mapRegionsArray.push(<span key={`comma${x}`}>{", "}</span>);
      mapRegionsArray.push(
        <strong key={`strong_${x}`}>{selectedNamesArray[x]}</strong>
      );
    }
  }
  return (
    <>
      <strong>{friendlyAcres} </strong> of <strong>{marketSegmentName}</strong>{" "}
      in {mapRegionsArray} {trailingText}
    </>
  );
}

export function getFriendlyAcres(ordinal: number) {
  const value = AREA_DISTRIBUTION_ACRES[ordinal].value; // AREA_DISTRIBUTION_ACRES[num].value;
  if (ordinal === 0 || ordinal === 1) {
    return value;
  }

  return `${value} acres`;
}
export function getFriendlySQFT(ordinal: number) {
  const value = AREA_DISTRIBUTION_SQFT[ordinal].value; // AREA_DISTRIBUTION_ACRES[num].value;
  return `${value} ft²`;
}
export function getAreaStringWithUnit(
  marketSegment: LISTING_TYPE,
  possibleAreas?: number[]
) {
  if (!possibleAreas) {
    return null;
  }
  if (isSpaceMarketSegment(marketSegment)) {
    return getSpaceSizeHTML(possibleAreas);
  }

  return getParcelSize(possibleAreas, measureUnits.ACRES);
}

export function getSpaceSizeHTML(spaceSize: number | number[]) {
  if (!Array.isArray(spaceSize)) {
    return nonRangeSpaceHtml(spaceSize);
  }
  if (spaceSize.length === 0) {
    return null;
  }
  if (spaceSize.length === 1) {
    return nonRangeSpaceHtml(spaceSize[0]);
  }
  return rangedSpaceHtml(spaceSize);
}
function nonRangeSpaceHtml(spaceSize: number) {
  return (
    <>
      {`${spaceSize.toLocaleString()} ft`}
      <sup>2</sup>
    </>
  );
}
function rangedSpaceHtml(spaceSizes: number[]) {
  const min = Math.min(...spaceSizes);
  const max = Math.max(...spaceSizes);
  return (
    <>
      {`${min.toLocaleString()}—${max.toLocaleString()} ft`}
      <sup>2</sup>
    </>
  );
}
export function filterLots(
  listing: {
    listingType: LISTING_TYPE;
    listingInfo: ListingInfo;
    status: LISTING_STATUSES;
  },
  {
    marketSegment,
    useSimpleSize,
    areaSliderOrdinal,
    mapRegions,
    isMajorStructures,
    isNoMajorStructures,
  }: SearchDefaultType
) {
  const { listingInfo } = listing;

  const isSpaceListing = isSpaceMarketSegment(listing.listingType);
  return (
    listing.status === LISTING_STATUSES.PUBLISHED &&
    listing.listingType === marketSegment.toString() &&
    filterIfSizeLessThanMinSize(
      useSimpleSize,
      areaSliderOrdinal,
      listingInfo,
      isSpaceListing ? AREA_DISTRIBUTION_SQFT : AREA_DISTRIBUTION_ACRES
    ) &&
    filterIfSizeMoreThanMaxSize(
      useSimpleSize,
      areaSliderOrdinal,
      listingInfo,
      isSpaceListing ? AREA_DISTRIBUTION_SQFT : AREA_DISTRIBUTION_ACRES
    ) &&
    filterIfLocationNotInLocations(mapRegions, listingInfo.mapRegion) &&
    filterParcelType(
      isMajorStructures,
      isNoMajorStructures,
      listingInfo.majorStructuresOnSite
    )
  );
}

function filterIfLocationNotInLocations(
  mapRegions: any,
  mapRegion: string | null
): boolean {
  return !!mapRegion && !!mapRegions[mapRegion];
}
export function filterParcelType(
  isMajorStructures: boolean,
  isNoMajorStructures: boolean,
  majorStructuresOnSite: boolean | null | undefined
) {
  return (
    (isMajorStructures || !majorStructuresOnSite) &&
    (isNoMajorStructures || majorStructuresOnSite)
  );
}

function filterIfSizeLessThanMinSize(
  useSimpleSize: boolean,
  areaSliderOrdinal: number,
  listingInfo: ListingInfo,
  distribution: DistributionRow[]
) {
  if (useSimpleSize) {
    if (!listingInfo.possibleAreas) {
      return false;
    }
    return (
      areaSliderOrdinal === 0 ||
      listingInfo.possibleAreas.some(
        (a) => a > distribution[areaSliderOrdinal].min
      )
    );
  }
  return true;
  // return (
  //   minAreaTick === 0 ||
  //   listing.areaSQM.some(
  //     a => a > getMeterSQForTickAndUnit(measureUnit, minAreaTick)
  //   )
  // );
}
function filterIfSizeMoreThanMaxSize(
  useSimpleSize: boolean,
  areaSliderOrdinal: number,
  listingInfo: ListingInfo,
  distribution: DistributionRow[]
) {
  if (!listingInfo.possibleAreas) {
    return false;
  }
  if (useSimpleSize) {
    return listingInfo.possibleAreas.some(
      (a) => a <= distribution[areaSliderOrdinal].max
    );
  }
  return true;
}

export function getShortAddress(versionDoc: VersionDoc) {
  const NOT_SET = "Not Set";
  if (!versionDoc.location) {
    return NOT_SET;
  }
  const { addressType, locationUrban, locationRural, locationNone, isUrban } =
    versionDoc.location;

  // this undefined check is needed because we changed a field (isUrban)
  // and addressType may not be set.
  const addressTypeFixed = !!addressType
    ? addressType
    : !!isUrban
    ? "urban"
    : "rural";

  if (addressTypeFixed === "urban" && !!locationUrban) {
    const addressArray = [
      locationUrban.unitNumber,
      locationUrban.addressNumber,
      locationUrban.roadwayName,
      locationUrban.roadwayType,
      locationUrban.quadrant,
    ].filter((a) => !!a);
    if (addressArray.length === 0) {
      return NOT_SET;
    }
    return addressArray.join(" ");
  }

  if (addressTypeFixed === "rural" && !!locationRural) {
    const unsetRequires =
      !locationRural.ruralRoadNum ||
      !locationRural.ruralTypeNum ||
      !locationRural.ruralUnitNum;
    if (unsetRequires) {
      return NOT_SET;
    }
    return `${locationRural.ruralUnitNum || ""}-${
      locationRural?.ruralRoadNum || ""
    } ${locationRural?.ruralRoadExt || ""} TWP RD ${
      locationRural.ruralTypeNum || ""
    } ${locationRural.ruralTypeExt || ""}`;
  }
  if (addressTypeFixed === "none" && !!locationNone) {
    if (!locationNone.title) {
      return NOT_SET;
    }
    return locationNone.title;
  }
  return NOT_SET;
}

export function isSpaceMarketSegment(marketSegment: LISTING_TYPE) {
  return !isLandMarketSegment(marketSegment);
}

export function getAreaStringForCombination(
  combination: any,
  lots: any,
  isSqFt: boolean
) {
  const area = getAreaForCombination(combination, lots);
  if (area === null) return <>--</>;
  if (isSqFt) {
    return <>{`${getAcresString(area)} ft²`}</>;
  }
  return <>{`${getAcresString(area)} ${area > 1 ? "acres" : "acre"}`}</>;
}
function getAcresString(acres: number) {
  return acres.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
}
export function getAreaForCombination(combination: any, lots: any) {
  if (
    !combination ||
    combination.length === 0 ||
    combination.some((o: any) => typeof o !== "number")
  ) {
    return null;
  }
  const area = combination.reduce((p: any, n: any) => lots[n].area + p, 0);
  return area;
}

export function createSearchFieldsFromSearchDoc({
  area,
  clientName,
  isMajorStructures,
  isNoMajorStructures,
  mapRegions,
  marketSegment,
  searchId,
}: SavedSearch) {
  if (!searchId) {
    throw new Error("Search Id Required");
  }

  return createSearchObject(
    searchId,
    clientName,
    marketSegment,
    area.ordinal,
    mapRegions,
    isMajorStructures,
    isNoMajorStructures
  );
}

export function createSearchObject(
  searchId: string,
  clientName: string,
  marketSegment: LISTING_TYPE,
  searchAreaOrdinal: number,
  mapRegions: string[],
  isMajorStructures: boolean,
  isNoMajorStructures: boolean,
  dateSaved?: firestore.Timestamp
) {
  const newSearch = {
    ...SEARCH_DEFAULT,
    marketSegment: marketSegment,
    areaSliderOrdinal: searchAreaOrdinal,
    searchId: searchId,
    clientName: clientName,
    mapRegions: convertMapRegionsIntoBoolMap(mapRegions),
    isMajorStructures,
    isNoMajorStructures,
    dateSaved: dateSaved,
  };
  return newSearch;
}
function convertMapRegionsIntoBoolMap(mapRegions: string[]) {
  let rtn: any = {};
  mapRegions.forEach((region) => {
    rtn[region] = true;
  });
  return rtn;
}
export function getOrgId(): keyof Themes {
  const partOne = window.location.host.split(".")[0];

  const nonSubDomains = [
    "localhost:3000",
    "propertyservicesexchange",
    "go-solo-spark",
    "go-solo-spark-2",
    "gex-app-prod",
    "gosolox",
  ];
  if (nonSubDomains.some((item) => item === partOne)) {
    return "gex";
  }
  if (!Object.keys(themes).includes(partOne)) {
    throw new Error("theme not found");
  }
  return partOne as keyof Themes;
}

export function getFriendlyRoleNamePlural(role: Role): string {
  if (role === "orgAdmin") {
    return "Administrators";
  }
  if (role === "assistant") {
    return "Assistants";
  }
  if (role === "realtor") {
    return "Realtors and Contributors";
  }
  if (role === "staff") {
    return "Non-licensed Support Staff";
  }
  if (role === "prospect") {
    return "Prospects";
  }
  if (role === "management") {
    return "Management";
  }
  throw new Error("unknown role");
}
export function getFriendlyRoleNameSingular(role: Role): string {
  if (role === "orgAdmin") {
    return "Administrator";
  }
  if (role === "assistant") {
    return "Assistant";
  }
  if (role === "realtor") {
    return "Realtor or Contributor";
  }
  if (role === "staff") {
    return "Staff Member";
  }
  if (role === "prospect") {
    return "Prospect";
  }
  if (role === "management") {
    return "Management";
  }
  throw new Error("unknown role");
}

export function isUserInRoleThatIsNotOrgAdmin(user: User): boolean {
  if (user.roles.length === 0) {
    return false;
  }
  if (user.roles.length === 1) {
    return user.roles[0] !== "orgAdmin";
  }
  return true;

  // const isUserInOtherRole =
  // user.roles &&
  // user.roles.some(
  //     (r) => r === "realtor" || r === "assistant" || r === "staff" || r === "prospect" || r === "management"
  //   );

  // return !isUserInOtherRole;
}

type UserAbilities = {
  canEdit: boolean;
  canSearch: boolean;
  canAdmin: boolean;
};

export function getUserAbilities(user: User): UserAbilities {
  return {
    canEdit: canEdit(user),
    canSearch: canSearch(user),
    canAdmin: user.roles.includes("orgAdmin"),
  };
}

function canEdit(user: User) {
  if (user.isUserAdmin) {
    return true;
  }
  if (
    user.roles.includes("realtor") ||
    user.roles.includes("assistant") ||
    user.roles.includes("staff") ||
    user.roles.includes("management") ||
    user.roles.includes("prospect")
  ) {
    return true;
  }

  return false;
}

export function canUserEditMapListingInfo(
  mapInfo: MapListingInfo,
  user: User
): boolean {
  return canUserEdit(
    user,
    mapInfo.status,
    mapInfo.listingType,
    mapInfo.orgId,
    mapInfo.lastSaveUserId
  );
}

export function canUserEditListing(listing: Listing, user: User): boolean {
  return canUserEdit(
    user,
    listing.status,
    listing.listingType,
    listing.orgId,
    listing.lastSave.uid
  );
  //   const isUserAdmin = user.isUserAdmin ;

  // if(listing.status === LISTING_STATUSES.DELETED){
  //   return false;
  // }
  // if(isUserAdmin){
  //   return true;
  // }
  // if(listing.orgId !== user.orgId){
  //   return false;
  // }
  // if(user.orgId === "gex" && listing.lastSave.uid !== user.uid){
  //   return false;
  // }
  // if(user.marketSegments && user.marketSegments.length > 0){
  //   return user.marketSegments.includes(listing.listingType);
  // }
  // return false;
}

function canUserEdit(
  user: User,
  listingStatus: LISTING_STATUSES,
  listingType: LISTING_TYPE,
  listingOrgId: string,
  lastSaveUserId: string
) {
  const isUserAdmin = user.isUserAdmin;

  if (listingStatus === LISTING_STATUSES.DELETED) {
    return false;
  }
  if (isUserAdmin) {
    return true;
  }
  if (listingOrgId !== user.orgId) {
    return false;
  }
  if (user.orgId === "gex" && lastSaveUserId !== user.uid) {
    return false;
  }
  if (user.marketSegments && user.marketSegments.length > 0) {
    return user.marketSegments.includes(listingType);
  }
  return false;
}

function canSearch(user: User) {
  if (user.isUserAdmin) {
    return true;
  }
  if (
    user.roles.includes("realtor") ||
    user.roles.includes("assistant") ||
    user.roles.includes("management") ||
    user.roles.includes("prospect")
  ) {
    return true;
  }

  return false;
}

export function getUserFriendlyName(user?: User | null) {
  if (!user) {
    return "---";
  }
  if (user.displayName) {
    return `${user.displayName} (${user.email})`;
  }
  return user.email;
}

export function getFilteredDocPages(
  collection: PageCollection,
  organizationType: OrganizationTypes
) {
  const filterItems = (item: DocumentationPage, dvo: OrganizationTypes) => {
    if (dvo === "prospects") return item.showToProspects;
    if (dvo === "contributors") return item.showToContributors;
    if (dvo === "sponsors") return item.showToSponsors;
    return false;
  };

  return collection.items
    .filter((l) => filterItems(l, organizationType))
    .sort((a, b) => {
      if (a.category.index === b.category.index) {
        return (b.index || 100) - (a.index || 100);
      }
      return (b.category.index || 100) - (a.category.index || 100);
    });
}

export function isUserOrgAdmin(user: User, orgId: keyof Themes) {
  if (user.isUserAdmin) {
    return true;
  }

  if (user.orgId === orgId && user.roles.some((r) => r === "orgAdmin")) {
    return true;
  }
  return false;
}
export function getLinkToInvite(inviteCode: string) {
  return `${window.location.origin}/invite/${inviteCode}`;
}
export function showPrivateSaleSection(versionDoc: VersionDoc) {
  return (
    versionDoc.availability?.tradingStatus?.includes("GeX Private Listing") ||
    false
  );
}

export function getPrivateListingInstructions() {
  //<a href="/docs/sponsor-private-listing-service">test</a>
  return (
    <div>
      <p>
        <b>This listing has a Trading Status of "GeX Private Listing".</b>{" "}
      </p>{" "}
      <p> Please provide contact information so the Seller knows who to pay.</p>{" "}
    </div>
  );
}

export function getTermsConditionsHelperValues(
  tradingCategory?: string[]
): [boolean, boolean] {
  const saleCategories = ["For Sale", "Pending", "Will Build To Suit"];

  const leaseCategories = [
    "For Lease",
    "For-Sub-Lease",
    "Lease to Own",
    "Pending",
    "Will Build To Suit",
  ];

  const showSaleControls =
    !!tradingCategory &&
    saleCategories.some((r) => tradingCategory?.includes(r));
  const showLeaseControls =
    !!tradingCategory &&
    leaseCategories.some((r) => tradingCategory?.includes(r));

  return [showSaleControls, showLeaseControls];
}

export function getNumberOfPieces(p: Pieces, isSpaceListing: boolean) {
  const numParcel = p?.numberOfParcels || 1;
  const numAncillaryLots = p?.numberOfAncillaryLots || 0;
  const numSpaces = p?.numberOfSpaces || 1;
  return isSpaceListing
    ? numSpaces
    : numParcel === 1
    ? numAncillaryLots || 1
    : numParcel;
  //return p.numberOfPieces || 0;
}

export function trimPiecesToFitNumber(pieces: Pieces, isSpaceListing: boolean) {
  if (!pieces.pieces) {
    return [];
  }
  const numberOfPieces = getNumberOfPieces(pieces, isSpaceListing);
  return pieces.pieces.length > numberOfPieces
    ? pieces.pieces.slice(0, numberOfPieces)
    : pieces.pieces;
}

export function getAreasForPieces(pieces: Pieces, isSpaceListing: boolean) {
  let areas = trimPiecesToFitNumber(pieces, isSpaceListing)
    .filter((p) => !!p.sizeData)
    .map((p) => p.sizeData)
    .flat();

  if(pieces?.canPiecesBeCombined && pieces?.pieceCombinations && pieces.pieceCombinations.length > 0){
    const combinedAreas = pieces.pieceCombinations.filter((c) => !!c.sizeData).map((c) => c.sizeData).flat();
    areas = areas.concat(combinedAreas);

  }

  return getAreaStringForArrayOfSizeData(areas, isSpaceListing);
}

export function getAreaStringForArrayOfSizeData(
  sizeDatas: (SizeData | undefined)[],
  isSpaceListing: boolean
) {
  const suffix = isSpaceListing ? "ft²" : "acres";
  const areaStrings = sizeDatas
    .filter((sd) => {
      return (
        !!sd?.type &&
        ((sd.type === "fixed" && sd.size) ||
          (sd.type === "range" && sd.sizeFrom && sd.sizeTo) ||
          sd.type === "toBeDetermined")
      );
    })
    .sort((a, b) => {
      if (a?.type === "fixed" && b?.type === "fixed") {
        return (a.size || 0) - (b.size || 0);
      }
      if (a?.type === "range" && b?.type === "range") {
        return (a.sizeFrom || 0) - (b.sizeFrom || 0);
      }
      if (a?.type === "fixed" && b?.type === "range") {
        return -1;
      }
      if (b?.type === "fixed" && a?.type === "range") {
        return 1;
      }
      if(a?.type === "toBeDetermined" && b?.type === "toBeDetermined"){
        return 0;
      }
      if(a?.type === "toBeDetermined"){
            return 1;
      }
      
      return -1;
    })
    .map((sd) => {
      if (!sd) {
        return "";
      }
      if (sd?.type === "fixed") {
        return sd.size ? `${sd.size.toLocaleString()} ${suffix}` : "";
      }
      if (sd?.type === "range") {
        return `${sd.sizeFrom || ""} - ${sd.sizeTo || ""} ${suffix}`;
      }

      return "To Be Determined";
    }).filter(onlyUnique);

  return areaStrings.join(", ");
}
function onlyUnique(value:string, index:number, self:string[]) {
  return self.indexOf(value) === index;
}