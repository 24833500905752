import { Link, RouteComponentProps } from '@reach/router';
import React, {useState} from 'react';
import styled from 'styled-components';

type AlreadyRegisteredPageProps = {} & RouteComponentProps;
export default function AlreadyRegisteredPage ({} : AlreadyRegisteredPageProps) {
    return (<Container><p>This account has already been registered.</p><p><StyledLink to="/">Enter Site</StyledLink> </p> </Container>);
}

const Container = styled.div`
    margin: 20px auto;
    max-width: 500px;
`;

const StyledLink = styled(Link)`
    color: #005cb8;
    text-decoration: none;
`;