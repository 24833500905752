import React from "react";
import { withTheme } from "@material-ui/core/styles";
import styled from "styled-components";
export default function Button({
  onClick,
  children,
  disabled = false,
  noMargin = false,
  isHighlighted = false,
}) {
  return (
    <StyledButton
      disabled={disabled}
      noMargin={noMargin}
      isHighlighted={isHighlighted}
      onClick={() => {
        if (!disabled && !!onClick) {
          return onClick();
        }
        return null;
      }}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = withTheme(styled.button`
  font-family: inherit;
  background: ${(props) =>
    props.disabled
      ? "#eee"
      : props.isHighlighted
      ? props.theme.palette.primary[600]
      : "#fff"};
  font-size: 14px;
  color: ${(props) =>
    props.disabled ? "#ddd" : props.isHighlighted ? "#fff" : "#333"};
  padding: 6 12px;
  box-shadow: none;
  border: 0px;
  font-weight: 500;
  border-radius: 4px;
  cursor: ${(props) =>
    props.disabled ? "#ddd" : props.isHighlighted ? "#fff" : "#333"};
  ${(props) => !props.noMargin && "margin-left: 10px;"}
  ${(props) => !props.isHighlighted && "border: 1px solid rgb(220, 224, 224);"}
 :hover {
    cursor: ${(props) => (!!props.disabled ? "default" : "pointer")};
  }
  display: inline-block;

  padding: 8px 12px 8px 12px;

  > div {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
  }
  > svg {
    vertical-align: middle;
  }
`);
