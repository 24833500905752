import {
  Box,
  Line
} from "components/preview/ListingPageStyledComponents";
import React from "react";
import { Crane, Cranes } from "types/Types";
import { Light, Strong } from "./ListingPageStyledComponents";

type CranesListingSectionProps = {
  cranes?: Cranes;
};
export default function CranesListingSection({
  cranes,
}: CranesListingSectionProps) {
  if (!cranes?.crane1) {
    return null;
  }

  const numberOfCranes = cranes.numCraneways || 1;

  return (
    <>
      <CraneSection crane={cranes.crane1} number={1} />
      {(numberOfCranes >= 2 && cranes.crane2) && <CraneSection crane={cranes.crane2} number={2} />}
      {(numberOfCranes >= 3 && cranes.crane3) && <CraneSection crane={cranes.crane3} number={3}/>}
      <Line gridColumn="col 2 / span 4"></Line>
    </>
  );
}
type CraneSectionType = {
  crane: Crane;
  number: 1 | 2 | 3;
};
function CraneSection({ crane, number }: CraneSectionType) {

  const numberOfHooks = crane.numberOfHooks || 1;

  return (
    <>

      <Box gridColumn="col 2 / span 4">
        <b>Crane {number}</b>
        <Light>{' '}{getCraneString(crane.type, crane.width, crane.length)}</Light>
      </Box>

      {crane.hook1 && (
        <Box gridColumn="col 2 /span 4">
          <ul>
            <li>
            <Light>Hook 1: </Light>
          <Strong>{getHookString(crane.hook1.height, crane.hook1.capacity)}</Strong>

            </li>
            {numberOfHooks >=2 && (<li>
              <Light>Hook 2: </Light>
          <Strong>{getHookString(crane?.hook2?.height, crane?.hook2?.capacity)}</Strong>
            </li>)}
            {numberOfHooks >=3 && (<li>
              <Light>Hook 3: </Light>
          <Strong>{getHookString(crane?.hook3?.height, crane?.hook3?.capacity)}</Strong>
            </li>)}
          </ul>
         
        </Box>
      )}
   
      
    </>
  );
}
function getCraneString(type: "exterior" | "interior", width?: number, length?:number) {
  if (!!width && !!length) {
    return `${type} crane with a ${width} ft width and ${length} foot length`;
  }
  if (!!width) {
    return `${type} crane with a ${width} ft width`;
  }
  if (!!length) {
    return `${type} crane with a ${length} ft length`;
  }
  return `${type}`;
}



function getHookString(height?: number, capacity?: number) {
  if (!!height && !!capacity) {
    return `${height} ft height and ${capacity} ton capacity`;
  }
  if (!!height) {
    return `${height} ft height`;
  }
  if (!!capacity) {
    return `${capacity} ton capacity`;
  }
  return "--";
}
