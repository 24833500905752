import { withTheme } from "@material-ui/core";
import styled from "styled-components";

export const Strong = styled.strong`
font-weight: 400 !important;
margin-left:5px;
color: #555;

`;

export const Light = styled.span`
font-weight: 500;
color: #222;
`;

export const Box = styled.div`
  color: rgb(72, 72, 72);
  font-size: 16px;
  grid-column: ${(p : BoxProps) => p.gridColumn};
  & strong {
    font-weight: 600;
  }
`;

interface BoxProps {
  gridColumn:string
}

export const WrapDiv = styled.span`
  display: block;
  padding-top: 8px;
`;

export const Table = styled.table`
    padding-top: 8px;
`;

export const TableHeader = styled.th`
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    padding-left: 40px;
    padding-bottom: 10px;
`;

export const OrdinalTD = withTheme(styled.td`
  font-weight: 600;
  color: ${props =>  props.theme.palette.primary[600]};
  padding-bottom: 10px;
`);

export const Td = styled.td`
  padding-left: 40px;
`;

export const Title = withTheme(styled.h3`
  font-weight: 500;
  font-size: 16px;
  color: ${props =>  props.theme.palette.primary[600]};
`);

export const Line = withTheme(styled.div`
  grid-column: ${(p :BoxProps ) => p.gridColumn};
  border-bottom: 1px solid ${props =>  props.theme.palette.primary[600]};
  padding-top: 10px;
`);


