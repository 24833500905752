import { withTheme } from "@material-ui/core/styles";
import { useLockBodyScroll } from 'common/hooks';
import { AREA_DISTRIBUTION_ACRES, AREA_DISTRIBUTION_SQFT } from "common/newConstants";
import { isLandMarketSegment, isSpaceMarketSegment } from "common/newHelperFunctions";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LISTING_TYPE, MapRegions, SearchDefaultType } from "types/Types";
import LocationForm from "./LocationForm";
import MarketSegmentForm from "./MarketSegmentForm";
import SizeForm from "./SizeForm";
import SpaceSizeForm from "./SpaceSizeForm";
import StepSearchNavBar from "./StepSearchNavBar";
import TypeForm from "./TypeForm";
import { AppContext } from "AppContext";

type Props = {
  searchFields: SearchDefaultType;
  //setSearchFields(search: SearchDefaultType): void;
  closeForm(state:SearchDefaultType): void;
  isPublic?:boolean;
};

const StepSearch: React.FC<Props> = ({
  searchFields,
  //setSearchFields,
  closeForm,
  isPublic = false
}) => {
  const [state, setState] = useState<SearchDefaultType>(searchFields);
  const [selectedIndex, setSelectedIndex] = useState( 0);
  const {
    areaSliderOrdinal,
    mapRegions,
    isNoMajorStructures,
    isMajorStructures,
    marketSegment,
  } = state;
  const setAreaSliderOrdinal = (n: number) =>
    setState({ ...state, areaSliderOrdinal: n });
  const setMapRegions = (m: MapRegions) =>
    setState({ ...state, mapRegions: m });
  const setTypeState = (
    isMajorStructures: boolean,
    isNoMajorStructures: boolean
  ) => setState({ ...state, isMajorStructures, isNoMajorStructures });
  const setMarketSegment = (ms: LISTING_TYPE) => {
    
    setState({ ...state, marketSegment: ms });

  }
  useLockBodyScroll();


  function nextClick() {
    setSelectedIndex(selectedIndex + 1);
  }

  function searchClick() {
    if (!Object.values(mapRegions).includes(true)) {
      alert("You must select at least one location");
      return;
    }
    if(isLandMarketSegment(marketSegment) && (!isMajorStructures && !isNoMajorStructures )){
      alert("You must select at least one type");
      return;
    }
    
    closeForm(state);
  }
  const showNext =
    (isLandMarketSegment( marketSegment) && selectedIndex < 3) || selectedIndex < 2;
  
  const isSpaceSearch =  isSpaceMarketSegment(marketSegment);
  const { user } = useContext(AppContext);
  if(!isPublic && (!user || user.marketSegments.length === 0))
    throw new Error("No User or no Market Segments");

  const numMapRegions = Object.keys(state.mapRegions).filter(
    (key) => !!(state.mapRegions  as any)[key as keyof MapRegions]
  ).length;

  console.log(numMapRegions)

  const isNextDisabled = (selectedIndex === 2 && numMapRegions === 0 ); 
  const isSearchDisabled = !state.isMajorStructures && !state.isNoMajorStructures;


  const userMarketSegments = !user ? ["IL", "CL", "OS", "RS", "IS"] :   user.marketSegments;
  return (
    <ModalBackground>
      
      <Container>
        <StepSearchNavBar
          selectedIndex={selectedIndex}
          setSelectedIndex={(i: number) => {
            setSelectedIndex(i);
          }}
          showType={isLandMarketSegment( marketSegment) }
        />
        <FormHolder>
          {selectedIndex === 0 && (
            <MarketSegmentForm
              state={marketSegment}
              setState={setMarketSegment}
              userMarketSegments={userMarketSegments}
            />
          )}
          {selectedIndex === 1 && isSpaceSearch && (
            <SpaceSizeForm
              state={areaSliderOrdinal}
              setState={setAreaSliderOrdinal}
              distribution={AREA_DISTRIBUTION_SQFT}
            />
          )}
          {selectedIndex === 1 && !isSpaceSearch && (
            <SizeForm
              state={areaSliderOrdinal}
              setState={setAreaSliderOrdinal}
              distribution={AREA_DISTRIBUTION_ACRES}
            />
          )}
          {selectedIndex === 2 && (
            <LocationForm state={mapRegions} setState={setMapRegions} />
          )}
          {selectedIndex === 3 && (
            <TypeForm
              isMajorStructures={isMajorStructures}
              isNoMajorStructures={isNoMajorStructures}
              setState={setTypeState}
            />
          )}
        </FormHolder>
        <StepFormButtonContainer>
          
          {showNext && (<NextButton  onClick={nextClick} disabled={isNextDisabled}>NEXT</NextButton>)}
          {!showNext && (
            <SearchButton onClick={searchClick} disabled={isSearchDisabled}>SEARCH</SearchButton>
          )}
        </StepFormButtonContainer>
      </Container>
    </ModalBackground>
  );
};

//https://bennettfeely.com/clippy/

const FormHolder = styled.div`
  background-color: #f6f6f6;
  height: 560px;
`;
const Container = styled.div`
  width: 1080px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;
  border: 1px solid #e5e5e5;
  border-radius: 10px;

  background-color: #fff;
  margin: auto;
`;


const ModalBackground = styled.div`
  height: 100vh !important;
  position: fixed !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 101;
  background: rgba(255, 255, 255, 0.85) !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StepFormButtonContainer = styled.div`
  height: 100px;
  border-bottom-left-radius: 10px;
`;



const NextButton = withTheme(styled.button`
  font-family: inherit;
  background: ${(props) => props.disabled ? "#E0E0E0" : props.theme.palette.primary.dark};
  font-size: 14px;
  color: ${(props) => props.disabled ? "#AEAEAE" : "#fff"};
  padding: 0 12px;
  height: 40px;
  width: 120px;
  border: 0px;
  font-weight: 500;
  border-radius: 4px;
  cursor: ${(props) => props.disabled ? "default" : "pointer"};
  margin-left: 10px;
  box-shadow: ${(props) => props.disabled ? "none" : "-1px 2px 1px rgba(0, 0, 0, 0.15)"};
  margin-top: 30px;
  float: right;
  margin-right: 90px;
`);

const SearchButton = styled.button`
  font-family: inherit;
  background: ${(props) => props.disabled ? "#E0E0E0" : "#0095ff"};
  background: ;
  font-size: 16px;
  color: ${(props) => props.disabled ? "#AEAEAE" : "#fff"};
  padding: 0 12px;
  height: 50px;
  width: 150px;
  border: 0px;
  font-weight: 500;
  border-radius: 4px;
  cursor: ${(props) => props.disabled ? "default" : "pointer"};
  margin-left: 10px;
  box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.15);
  top: 25px;
  float: right;
  margin-right: 90px;
  position: relative;
`;


export default StepSearch;
