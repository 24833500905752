import Button from "@material-ui/core/Button";
import { amber, blue, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import MUISnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import React from "react";
const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[700],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const SnackBar = ({
  isSnackbarOpen,
  message,
  setIsOpen,
  handleUndo = null,
  variant = "info",
  
}) => {
  const classes = useStyles();

  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  const actions = [];
  if (handleUndo) {
    actions.push(
      <Button key="undo" size="small" onClick={handleUndo}>
        UNDO
      </Button>
    );
  }

  actions.push(
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      className={classes.close}
      onClick={handleClose}
    >
      <CloseIcon />
    </IconButton>
  );

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={isSnackbarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
    >
      <SnackbarContent
        className={clsx(classes[variant])}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={actions}
      />
    </MUISnackbar>
  );
};

export default SnackBar;
