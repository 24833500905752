import { createMuiTheme, LinearProgress } from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { getOrgId, getUserAbilities } from "common/newHelperFunctions";
import InvitePage from "pages/invite/InvitePage";
import NotInOrganizationPage from "pages/NotInOrganizationPage";
import NotVerifiedPage from "pages/NotVerifiedPage";
import AlreadyRegisteredPage from "pages/registration/AlreadyRegisteredPage";
import ContributorRegistrationPage from "pages/registration/ContributorRegistrationPage";
import PrivacyPolicy from "pages/registration/PrivacyPolicyPage";
import ProspectRegistrationPage from "pages/registration/ProspectRegistrationPage";
import RegistrationLoginPage from "pages/registration/RegistrationLoginPage";
import SponsorRegistrationPage from "pages/registration/SponsorRegistrationPage";
import TermsPage from "pages/registration/TermsPage";
import TestPage from "pages/TestPage";
import UserDataNeedsUpdatingPage from "pages/UserDataNeedsUpdatingPage";
import React, { ReactNode, useContext, useState } from "react";
import Loadable from "react-loadable";
import useUser from "store/useUser";
import styled from "styled-components";
import { getTheme } from "themes";
import { OrganizationTypes } from "types/Types";
import { AppContext, AppContextType } from "./AppContext";
import firebase from "./config/firebase";
import ChangeLogPage from "./pages/ChangeLogPage";
import EditorPage from "./pages/editor/EditorPage";
import ListingPage from "./pages/ListingPage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import Page404 from "./pages/Page404";
import PublicListingPage from "./pages/PublicListingPage";
import PublicSearchPage from "./pages/PublicSearchPage";
import RegisterPage from "./pages/RegisterPage";
import WelcomePage from "./pages/WelcomePage";
function App() {
  const orgId = getOrgId() || "gex";
  const { user, setUserNull, userInfoNeedsUpdate, updateUserInfo } =
    useUser(orgId);
  const themeJSON = getTheme(orgId);
  const theme = createMuiTheme(themeJSON);
  const [organizationType, setOrganizationType] = useState<OrganizationTypes>(
    themeJSON.company.organizationType
  );
  //const [isPrelaunch, setIsPrelaunch] = useState<boolean>(true);
  const webSiteBaseUrl =
    process.env.REACT_APP_WEBSITE_URL || "http://localhost:3000";
  const appContextValue: AppContextType = {
    webSiteBaseUrl,
    user,
    orgId,
    organizationType,
    setOrganizationType,
    //setIsPrelaunch,
    handleLogout: () => {
      setUserNull();
      firebase.auth().signOut();
    },
    //isPrelaunch,
  };

  if (userInfoNeedsUpdate) {
    return (
      <AppContext.Provider value={appContextValue}>
        <ThemeProvider theme={theme}>
          <UserDataNeedsUpdatingPage updateUser={updateUserInfo} />
        </ThemeProvider>
      </AppContext.Provider>
    );
  }

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider theme={theme}>
        <Router>
          <InvitePage path="/invite/:inviteCode" />
          <IsLoggedIn
            path="/"
            yesCantSearch={
              <AuthRoute>
                <EditorPage />
              </AuthRoute>
            }
            yesCanSearch={
              <AuthRoute>
                <MainPage />
              </AuthRoute>
            }
            no={<WelcomePage />}
          />
          <WelcomePage path="/welcome" />
          <TestPage path="/test" />
          <PublicSearchPage path="/search/:startingMarketSegment" />
          <LoginPage path="/login" />
          <LoginPage path="/login/dd" redirect="/duediligence" />
          <PublicListingPage path="/pl/:userId/:searchId" />
          <RegisterPage path="/register" />
          <ChangeLogPage path="/changelog" />
          <PrivacyPolicy path="/privacy-policy" />
          <TermsPage path="/terms" />

          <ListingPage path="/pland/:id" isPublic />
          <ListingPage path="/rland/:id" isRealtor />
          <AlreadyRegisteredPage path="already-registered" />
          <UnregistedRoute path="/prospect-registration" redirect="/prospect-registration">
            <ProspectRegistrationPage path="/" />
          </UnregistedRoute>
          <UnregistedRoute path="/sponsor-registration" redirect="/sponsor-registration">
            <SponsorRegistrationPage path="/" />
          </UnregistedRoute>
          <UnregistedRoute path="/contributor-registration" redirect="/contributor-registration">
            <ContributorRegistrationPage path="/" />
          </UnregistedRoute>
          <AuthRoute path="/land">
            <ListingPage path={":id"} />
          </AuthRoute>
          <AuthRoute path="/admin" isAdmin>
            {/* @ts-ignore */}
            <AsyncBulkJsonUpload path="/bulkJson" />
            {/* @ts-ignore */}
            <AsyncListingInfoEditorPage path="/listingInfoEditor" />
            {/* @ts-ignore */}
            <AsyncVersionDocFieldGetterPage path="/vdfg" />
            <AsyncLookupsEditorPage path="/lookups" />
            <AsyncListingsEditorPage path="/listingsEditor" />
          </AuthRoute>
          <AuthRoute path="/orgAdmin" isOrgAdmin>
            <AsynOrgUserAdmin path="/userAdmin" />
          </AuthRoute>
          <AuthRoute path={"/tour"}>
            <AsyncTourPage path="/" />
          </AuthRoute>
          <AuthRoute path={"/editor"}>
            <AsyncEditorPage path="/" />
            <AsyncListingEditorPage path=":id" />
            <AsyncListingEditorPage path="/:id/:copy" />
          </AuthRoute>
          <AsyncDocumentationPage path="/docs" />
          <AsyncDocumentationPage path="/docs/:slug" />
          <AsyncDueDiligenceSite path="/duediligence" />
          <AsyncDueDiligenceSite path="/duediligence/:slug" />
          <AsyncDDEditorPageList path="/duediligence/editor" />
          <AsyncDDEditorPage path="/duediligence/editor/:pageId" />
          <Page404 default />
        </Router>
        {process.env.REACT_APP_ENVIRONMENT === "STAGING" && (
          <EnvironmentBox>STAGING</EnvironmentBox>
        )}
      </ThemeProvider>
    </AppContext.Provider>
  );
}


const UnregistedRoute: any = ({
  children,
  redirect,
}: {
  children: ReactNode & RouteComponentProps;
  redirect: string;
}) => {
  const { user } = useContext(AppContext);

  console.log("user", user, children);


  if (!user) {
    return <RegistrationLoginPage redirect={redirect} />;
  }
  if (!!user.orgId) {
    return <AlreadyRegisteredPage />;
  }
  return children;
};

const AuthRoute: any = ({
  children,
  isAdmin = false,
  isOrgAdmin = false,
}: {
  children: ReactNode;
  isAdmin: boolean;
  isOrgAdmin: boolean;
} & RouteComponentProps) => {
  const { user, orgId } = useContext(AppContext);

  // if not logged in
  if (!user) {
    return <LoginPage />;
  }

  //if going to admin pages not super admin
  if (isAdmin && !user.isUserAdmin) {
    return <div>Unauthorized</div>;
  }
  // if going to user admin and orgAdmin
  if (
    isOrgAdmin &&
    !user.isUserAdmin &&
    user.roles.indexOf("orgAdmin") === -1
  ) {
    return <div>Unauthorized</div>;
  }

  if (!user.verified && user.roles.includes("prospect")) {
    return <NotVerifiedPage />;
  }

  //if user is super admin or user is in org and has roles
  //render children.

  return user.isUserAdmin ||
    (orgId === user.orgId && user.roles && user.roles.length > 0) ? (
    children
  ) : (
    <NotInOrganizationPage />
  );
};

const IsLoggedIn = ({
  yesCantSearch,
  yesCanSearch,
  no,
}: {
  yesCanSearch: JSX.Element;
  yesCantSearch: JSX.Element;
  no: JSX.Element;
} & RouteComponentProps) => {
  const { user } = useContext(AppContext);
  if (!user) {
    return no;
  }

  const abilities = getUserAbilities(user);
  console.log("user", user, abilities);
  if (abilities.canSearch) {
    return yesCanSearch;
  }
  if (abilities.canEdit) {
    return <Redirect to="editor" noThrow />;
  }
  if (abilities.canAdmin) {
    return <Redirect to="orgAdmin/userAdmin" noThrow />;
  }

  return <NotInOrganizationPage />;
};
const Loading = () => <LinearProgress />;
const AsyncListingEditorPage = Loadable({
  loader: () => import("./pages/editor/ListingEditorPage"),
  loading: Loading,
});
const AsyncVersionDocFieldGetterPage = Loadable({
  loader: () => import("./pages/administration/VersionDocFieldGetterPage"),
  loading: Loading,
});
const AsyncTourPage = Loadable({
  loader: () => import("./pages/TourPage"),
  loading: Loading,
});

const AsyncEditorPage = Loadable({
  loader: () => import("./pages/editor/EditorPage"),
  loading: Loading,
});

const AsyncBulkJsonUpload = Loadable({
  loader: () => import("./pages/administration/BulkJsonUploadPage"),
  loading: Loading,
});
const AsyncListingInfoEditorPage = Loadable({
  loader: () => import("./pages/administration/ListingInfoEditorPage"),
  loading: Loading,
});
const AsyncListingsEditorPage = Loadable({
  loader: () => import("./pages/administration/ListingsEditorPage"),
  loading: Loading,
});
const AsyncLookupsEditorPage = Loadable({
  loader: () => import("./pages/administration/LookupsEditorPage"),
  loading: Loading,
});
const AsyncDocumentationPage = Loadable({
  loader: () => import("./pages/documentation/DocPage"),
  loading: Loading,
});
const AsynOrgUserAdmin = Loadable({
  loader: () => import("./pages/orgAdmin/UserAdmin"),
  loading: Loading,
});
const AsyncDueDiligenceSite = Loadable({
  loader: () => import("./pages/dueDiligence/DDPost"),
  loading: Loading,
});
const AsyncDDEditorPageList = Loadable({
  loader: () => import("./pages/dueDiligence/DDEditorPageList"),
  loading: Loading,
});
const AsyncDDEditorPage = Loadable({
  loader: () => import("./pages/dueDiligence/DDEditor"),
  loading: Loading,
});


const EnvironmentBox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  background-color: #fdff77;
  height: 30px !important;
  margin: 3px;

  border-radius: 3px;
  font-weight: 500;
  text-align: center;
  padding-top: 6px;
  z-index: 1301;
`;

export default App;