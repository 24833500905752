import Grid from "@material-ui/core/Grid";
import React from 'react';
import styled from 'styled-components';

export default function FieldGroupHeading ({ children }: {children:any}) {
    return (
        <Grid item xs={12}>
          <FieldGroupHeadingH4>{children}</FieldGroupHeadingH4>
        </Grid>
      );
}


const FieldGroupHeadingH4 = styled.h4`
  margin: 24px 12px 0px 0px !important;
`;

