import { LinearProgress } from "@material-ui/core";
import HeaderBuilder from "components/HeaderBuilder";
import React from "react";
import styled from "styled-components";

type LoadingPageProps = {};
export default function LoadingPage(props: LoadingPageProps) {
  return (
    <div>
      <HeaderBuilder>
        <span></span>
      </HeaderBuilder>
      <LoadingContainer>
        <LinearProgress />
      </LoadingContainer>
    </div>
  );
}

const LoadingContainer = styled.div`
  padding-top: 65px;
`;
