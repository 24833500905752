import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {
  CellColumnNumber,
  IdName,
  NumericPickListField,
} from "types/fieldComponentTypes";

type Props = {
  field: NumericPickListField;
  value: number;
  xs: CellColumnNumber;
  sm: CellColumnNumber;
  md: CellColumnNumber;
  disabled: boolean;
  onBlur: (value: boolean) => void;
  handleChange: (jsonPath: string, value: number | null) => void;
  error: string | null;
};

const NumericPickList = ({
  field,
  value,
  xs,
  sm,
  md,
  handleChange,
  error,
  disabled = false,
}: Props) => {
  const [, setState] = useState<number>(!!value  ? value : field.defaultValue || 0);
  // SET IT BLANK INSTEAD OF NULL, BECAUSE OF ERROR OF SWITCHING BEWTEEN
  // UNCONTROLLED TO CONTROLLED.
  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Grid item xs={12} sm={sm} md={md}>
      {/* <TextField
            variant="filled"
            label={field.label}
            error={!!error}
            helperText={error || (field.isRequired ? 'Required' : null)}
            disabled={disabled}
            onBlur={ e => {
                const value = e.target.value;
              
                const found = options.find(o => o.name.toUpperCase() === value.toUpperCase())
                if(!!found){
                  handleChange(field.jsonPath, !!field.useIdInsteadOfValueForValue ? found.id : found.name);
                }
            }
          }
          /> */}
      <FormControl fullWidth>
        <InputLabel style={{ marginTop: 5, marginLeft: 10 }} id="label">
          {field.label}
        </InputLabel>
        <Select
          labelId="label"
          value={!!value ? value :  field.defaultValue  }
          variant="filled"
          onChange={(e) => {
            handleChange(field.jsonPath, e.target.value as number);
          }}
        >
          {field.options &&
            field.options.map((option) => (
              <MenuItem value={option} key={`option_${option}`}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default NumericPickList;
