import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import YouTube from "react-youtube";
const VideoFieldControl = ({ field, value, xs, sm, md, onBlur, disabled }) => {
  const [state, setState] = useState(value || "");
  useEffect(() => {
    setState(value || "");
  }, [value]);

  const letters = /^[a-z0-9_-]+$/i; //i means ignorecase
  const isInvalid = !!value && value.length > 6 && !value.match(letters);
  const showVideo = !isInvalid && !!value;

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <TextField
        label={field.label}
        variant="filled"
        fullWidth
        value={state}
        onChange={e => setState(e.target.value)}
        onBlur={e => onBlur(e.target.value)}
        multiline={field.multiline}
        type={field.inputType || "text"}
        error={isInvalid}
        helperText={isInvalid ? "Incorrect entry." : ""}
        disabled={disabled}
      />
      {showVideo && (
        <YouTube
          videoId={value}
          opts={{
            height: "390",
            width: "640",
            playerVars: {}
          }}
          // onReady={this._onReady}
        />
      )}
    </Grid>
  );
};
export default VideoFieldControl;
