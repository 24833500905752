import styled from "styled-components";
export default   styled.div`
  height: 100vh !important;
  position: fixed !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 1300;
  background: rgba(255, 255, 255, 0.85) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  left:0;
`;
