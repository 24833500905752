import React from "react";
import { TrafficFlowInfoYear, TrafficFlowMeasurementUnit } from "types/Types";
import { Light } from "./ListingPageStyledComponents";
type TrafficFlowInfoSectionProps = {
  trafficFlowInfoYears?: TrafficFlowInfoYear[];
};

function getFriendlyMeasurementUnit(
  measurementUnit: TrafficFlowMeasurementUnit
) {
  switch (measurementUnit) {
    case "avgWeekday":
      return "Average Weekday";
    case "avgWeekly":
      return "Average Weekly";
    default:
      return "";
  }
}

export default function TrafficFlowInfoSection({
  trafficFlowInfoYears,
}: TrafficFlowInfoSectionProps) {
  return (
    <div>
      {trafficFlowInfoYears
        ?.filter(
          (x) =>
            !!x.adjacentArterial ||
            !!x.measurementUnit ||
            !!x.year ||
            !!x.volume
        )
        .map((x,n) => {
          return (
            <p key={`tf_${n}`}>
              <Light>{x.adjacentArterial}</Light>:{" "}
              {x.volume && x.volume.toLocaleString()}{" "}
              {getFriendlyMeasurementUnit(x.measurementUnit)} ({x.year})
            </p>
          );
        })}
    </div>
  );
}

