import { Grid, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import {
  BrokerageContactField
} from "types/fieldComponentTypes";
import { Brokerage } from "types/Types";
type BrokerageContactProps = {
  field: BrokerageContactField;
  nameValue: string | null;
  phoneValue: string | null;
  onBlur: (value: boolean) => void;
  handleChange: (jsonPath: string, value: string | null) => void;
  brokerages: Brokerage[];
  error?: string | null;
  contactError?:string | null;
};
const filter = createFilterOptions<string>();
export default function BrokerageContactControl({
  field,
  nameValue,
  phoneValue,
  handleChange,
  brokerages,
  error,
  contactError
}: BrokerageContactProps) {

  

  //console.log(value, field.jsonPathBrokerageContactNumber)
  const [state, setState] = useState<{name:string, phone:string}>({name:nameValue || '', phone: phoneValue || '' });
  // SET IT BLANK INSTEAD OF NULL, BECAUSE OF ERROR OF SWITCHING BEWTEEN
  // UNCONTROLLED TO CONTROLLED.
  // React.useEffect(() => {
  //   setState({name:nameValue || '', phone: phoneValue || '' });
  //   console.log('use effect', state);
  // }, [nameValue, phoneValue]);

  const options: string[] = brokerages.map(b => b.name);


  return ([
    <Grid item  md={6} key='brok'>
      <Autocomplete
        value={state.name}
        options={options}
        onChange={(event, value) => {
          const foundBroker = brokerages.find(b => b.name.toLocaleLowerCase() === value?.toLocaleLowerCase());
          if (foundBroker) {
            setState({ name: foundBroker.name, phone: foundBroker.phone || "" });
            handleChange(field.jsonPathBrokerageContactNumber, foundBroker?.phone || '')
          }
          else {
            setState({ name: value || "", phone: "" });
            handleChange(field.jsonPathBrokerageContactNumber, '')
          }
        }}
        renderInput={(params) => {
          return (
          <TextField
            {...params}
            label={field.label}
            variant="filled"
            error={!!error}
            helperText={error}
            onBlur={e => {
                setState({ ...state, name: e.target.value });
                handleChange(field.jsonPath, e.target.value);
            }}
          />
        )}}
        />
      {/* <Autocomplete
        value={state.name}
        options={options}
        getOptionLabel={(idName) => {
          // Value selected with enter, right from the input
          return idName;
        }}
        onChange={(event, newValue) => {

          const foundBroker = brokerages.find(b => b.name.toLocaleLowerCase() === newValue?.toLocaleLowerCase())

          console.log("****", foundBroker, newValue)



          setState({...state, name:newValue || '', phone: foundBroker?.phone || ''})
          handleChange(field.jsonPathBrokerageContactNumber, foundBroker?.phone || '')

          // if (typeof newValue === "string") {
          //   console.warn("should add this to the lookup");
          //   setState(newValue);
          // } else if (newValue && newValue.inputValue) {
          //   // Create a new value from the user input
          //   setState(newValue.inputValue);
          // } else {
          //   setState(newValue?.id || "");
          // }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const value = params.inputValue.trim();
          // add the value they are entering to the list
          if (params.inputValue !== "" && filtered.indexOf(value) === -1) {
            filtered.push(value);
          }
          return filtered;
        }}
        onBlur={() =>{
           handleChange(field.jsonPath, state.name === "" ? null : state.name)
          }
           
           //handleChange(field.jsonPath, {name:state.name, phone:state.phone})}
           //handleChange(field.jsonPath, {name:state.name, phone:state.phone})}
          }
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={field.id}
        //   renderOption={(option) => option.name}
        //style={{ width: 300 }}
        forcePopupIcon={true}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label={field.label}
            variant="filled"
            error={!!error}
            helperText={error || (field.isRequired ? 'Required' : null)}
            onBlur={() => {
              handleChange(field.jsonPath, state.name)
            }}
            
          />
        )}
      /> */}
    </Grid>,
  <Grid item  md={6} key='brok2'><TextField
  variant="filled"
  label="Contact Number"
  value={state.phone}
  error={!!contactError}
  helperText={contactError || (field.isRequired ? 'Required' : null)}
  onChange={e => {

    const {value } = e.target; 
      setState(s => ({...s, phone:value}))
    
  }}
  onBlur={() => {
    handleChange(field.jsonPathBrokerageContactNumber, state.phone)
  }}
        fullWidth
  
  /></Grid>  
  ]
  );
}
