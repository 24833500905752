import React from "react";
import { ContactInfoField } from "types/fieldComponentTypes";
import { ContactInfo } from "types/Types";
import ContactRow from "./ContactRow";

type Props = {
  value: ContactInfo[] | undefined;
  field: ContactInfoField;
};

export default function ContactArrayFieldLabel({ field, value }: Props) {
  if (!value) {
    return null;
  }
  return (
    <div style={{ margin: "16px 0" }}>
      {value.map((ci, n) => ( <ContactRow  
      key={`ci_${n}`}
      contactName={ci.contactName || ''}
       label={ci.description || ''}
       email={ci.email}
       phone={ci.phone}
        topMargin
      />

      ))}
    </div>
  );
}

