import React from 'react';

import styled from 'styled-components';


const SponsorMembershipTable = () => {

    return (
        <Container>
        <Table>
          <thead>
            <tr>
              <Th style={{minWidth:140}}>Role</Th>
              <Th>Description</Th>
              <Th>Membership</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td><b>Broker</b></Td>
              <Td></Td>
              <Td>Full</Td>
            </tr>
            <tr>
              <Td><b>Realtor</b></Td>
              <Td></Td>
              <Td>Full</Td>
            </tr>
            <tr>
              <Td><b>Unlicensed Staff</b></Td>
              <Td>Unlicensed staff are employees of a brokerage who do not hold a real estate license.</Td>
              <Td>50% off</Td>
            </tr>
            <tr>
              <Td><b>Assistant</b></Td>
              <Td>An assistant is a person who provides support to a broker or realtor.</Td>
              <Td>Free</Td>
            </tr>

            <tr>
              <Td><b>Support Staff</b></Td>
              <Td>Support staff are employees of a brokerage who provide administrative and logistical support to brokers, realtors, and other staff.  </Td>
              <Td>Free</Td>
            </tr>
          </tbody>
        </Table></Container>
      );
};

export default SponsorMembershipTable;
const Container = styled.div`
padding: 8px 8px -1px 8px;
border: 1px solid #ccc;
font-size:10pt;
`;
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Th = styled.th`

  padding: 8px;
  text-align: left;
`;

const Td = styled.td`
  border-top: 1px solid #ccc;
  border-left: 0;
  border-right: 0;
  padding: 8px;
  text-align: left;
`;
