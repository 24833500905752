import React, {useState} from 'react';
import styled from 'styled-components';
import AgreeToTermsAndPrivacyControl from './AgreeToTermsAndPrivacyControl';

type ProspectTermsProps = {
    setTermsChecked(isChecked:boolean):void;
    setPrivacyChecked(isChecked:boolean):void;
    isDisabled:boolean;
    
}
export default function ProspectTerms ({setTermsChecked, setPrivacyChecked, isDisabled} : ProspectTermsProps) {

    return (<AgreeToTermsAndPrivacyControl setTermsChecked={setTermsChecked} setPrivacyChecked={setPrivacyChecked} isDisabled={isDisabled}>
        <h1>Prospect Terms and Conditions</h1>
          <p>
          These Prospect Terms and Conditions contain the terms that govern your
          Prospect-level Membership access to and use of the GeX and together with
          the Prospect Registration Form is an agreement (the “Agreement”) between
          Go Solo Land Inc. (“GSL”), operating as Property Services Exchange, and
          you or the entity that you represent (“you”). This Agreement takes
          effect when you click an “I Accept” button or check box on the Prospect
          Registration Form presented with these terms (the “Effective Date”). If
          you are entering into this Agreement for an entity, you represent to GSL
          that you have legal authority to bind that entity. You represent to GSL
          that you are lawfully able to enter into contracts. Please see Section 9
          for definitions of certain capitalized terms used in this Agreement.
        </p>
        <h2>1. Agreement</h2>
        <OL>
          <li>
             <strong>Acceptance</strong>. The parties agree to the terms of the
            Agreement.
          </li>
          <li>
             Changes <strong>in Writing Only</strong>. This Agreement
            constitutes the entire understanding and agreement between the parties
            and supersedes all previous negotiations and commitments. Any
            amendment to this Agreement must be made in writing.
          </li>
          <li>
             Revisions to the Agreement. GSL may revise the Agreement on notice
            of 30 days; by continuing to use the Services you agree to such
            revisions.
          </li>
          <li>
             <strong>Authentication</strong>. You must use authentication
            methods as communicated by GSL from time-to-time when providing Data
            or accessing Content.
          </li>
          <li>
             <strong>Hosting and Management of the GeX</strong>. GSL will host
            and manage the GeX and GeX Website, but may use third-party service
            providers, including cloud-service providers, to host and manage.
          </li>
          <li>
             <strong>Conformity with Legislation</strong>. You agree that you
            shall bear sole responsibility to satisfy yourself that your use of
            Content complies with any applicable legislation.
          </li>
          <li>
             <strong>Term</strong>. The Agreement shall commence upon your
            acceptance of this Agreement and shall terminate at the end of the
            calendar month in which notice of termination is provided by you or
            GSL (the “Term”).
          </li>
        </OL>
        <h2>2. Licensor Data</h2>
        <OL>
          <li>
             Licence. You grant GSL a non-exclusive, worldwide, royalty-free
            licence to use, reproduce, modify, share and display the Data as
            necessary for the purposes of the GeX and this Agreement.
          </li>
          <li>
             Input of Data. You shall input Data into the GeX in the manner and
            form required by GSL from time-to-time. For greater certainty, if you
            elect to input Data for any industrial and commercial real estate, you
            shall input Required Fields as Data, and GSL may specify, on the GeX
            Website or in a notice, further information that you are required to
            input into the GeX as Data. You warrant that the Data does not contain
            any viruses or other programming routines that are intended to (i)
            cause injury to any software or hardware of GSL or (ii) intercept,
            copy, or encrypt any data or information.
          </li>
          <li>
             Currency of Data. You warrant that the Data you submit to the GeX
            is current. You agree that you must keep all Data in the GeX Current.
            For greater certainty, you shall inform GSL if any Data is no longer
            Current, in the manner and form required by GSL from time-to-time.
          </li>
          <li>
             Authorization to Use Data. You warrant, and indemnify GSL against
            any claims by third parties that arise for any breach of, the
            following:
          </li>
          <OLLetter>
            <li> you have authorization to license the Data to GSL;</li>
            <li>
               you have the right to disclose any personal information included
              in the Data to GSL;
            </li>
            <li>
               the Data does not infringe any intellectual property rights or
              other rights of any third-party;
            </li>
            <li>
               the Data is not defamatory, hateful, pornographic, or harmful to
              minors; and
            </li>
            <li>
               the Data is not intentionally false, inaccurate, or otherwise
              misleading.
            </li>
          </OLLetter>
          <li>
             Review of Data. GSL is under no obligation to review the Data, but
            may do so at any time for the purpose of operating the GeX and
            associated services or in order to comply with the law or an order of
            any government, court, or other legal authority. GSL may refuse to
            enter into the GeX, or remove from the GeX, any portion of the Data at
            any time for any reason.
          </li>
          <li>
             <strong>Consent</strong>. You consent to the collection, use and
            disclosure of personal information by GSL for the purpose of this
            Agreement, including the placement and retention of your personal
            information in the GeX Database indefinitely, and disclosure of your
            personal information as part of Services that you choose to use.
          </li>
          <li>
             Verification. For greater certainty, you agree to provide GSL with
            such information as may be requested by GSL from time to time to
            verify, for the purposes of operating the GeX and associated services
            or in order to comply with the law or an order of any government,
            court or other legal authority:
          </li>
          <OLLetter>
            <li>
              your identity or the identity of any person who is authorized by
              GSL to use the GeX through your Prospect-Level Membership;
            </li>
            <li>your ownership or control of the Data; or</li>
            <li>
              your capacity to enter into this Agreement or authority to bind
              the entity on whose behalf you have entered into this Agreement.
            </li>
          </OLLetter>
        </OL>
        <h2>3. Access to Content</h2>
        <OL>
          <li>
             Description of GeX. The GeX includes the aggregate of information,
            curated and obtained by GSL, including the Data, relating to
            industrial and commercial real estate for sale or lease.
          </li>
          <li>
               <strong>Licence</strong>
            . GSL grants you a limited, non-exclusive, non-transferrable, limited
            right license, during the Term, to use, reproduce, display, modify,
            and create derivative works of Content from the GeX for private use
            and for the purpose of facilitating your purchase, sale, or lease of
            real estate. You may not distribute or make Content or other
            information from the GeX available to any other third party.
          </li>
          <li>
             <strong>Services</strong>. You shall access and use Content and
            submit Data only in accordance with the terms of the Agreement,
            including the terms of the Services.
          </li>
          <li>
               <strong>Real Estate Transactions Use Only</strong>
            . You may access Reports from the GeX Website and you may print or
            otherwise save individual Reports produced by the GeX Website for
            private use. You may use Content for private use and for the purpose
            of facilitating your purchase, sale, or lease of real estate. Any
            other commercial use of the Content is prohibited except with the
            prior written permission of GSL.
          </li>
          <li>
             <strong>No Alteration of Reports</strong>. You must not
              amend Reports, make derivate works from the Reports, or alter any
              proprietary notices or trademarks included on such Reports
            .
          </li>
          <li>
             <strong>No Data Scraping</strong>.
              You are not permitted to access or copy data from the GeX Website
              and GeX other than by making use of the facilities that may be
              provided by GSL.
              Content must not be modified, merged with other information or
              published in any form.
            For greater certainty, you are prohibited from using a bot, crawler,
            or any other similar automated process to copy Content or other
            information from the GeX Website and GeX.
          </li>
          <li>
             <strong>Content Provided “As Is”</strong>. Content is provided on
            an “as is” basis and is provided for informational purposes only. None
            of the information provided is warranted to be accurate or current.
            GSL disclaims all warranties and shall not be liable for any damages
            of any kind, or other compensation under any other theory of
            liability, due to the use of Content. You agree that GSL shall not be
            liable for any damages of any kind, or other compensation under any
            other theory of liability, arising out of your access to or use of the
            GeX Website, Content, or GeX. Content is not intended to recommend any
            course of action. If specific advice is needed with regard to legal,
            financial or other matters, such advice should be sought from
            qualified professionals.
          </li>
          <li>
             <strong>Changes to Reports Formats</strong>. GSL may suspend,
            discontinue or change any aspect of Content without notice or
            liability.
          </li>
          <li>
             <strong>Restricted Access</strong>. GSL reserves the right, in its
            sole discretion, to limit or suspend any user's access to Content or
            the GeX Website without notice or liability, for any reason
            whatsoever.
          </li>
          <li>
               <strong>Removal of Content</strong>
            . For greater certainty, GSL may remove any Content from the GeX
            Website which does not comply with the terms of this Agreement.
          </li>
        </OL>
        <h2>4. Intellectual Property &amp; Confidentiality</h2>
        <OL>
          <li>
             <strong>Marketing Rights</strong>. You may (i) announce to the
            public that you are an authorized non-exclusive user of the GeX, and
            (ii) advertise the GeX under the GSL Marks. You acknowledge that the
            GSL Marks, as between you and GSL, are owned and licensed solely and
            exclusively by GSL. Nothing herein will grant to you any rights, title
            or interest in the GSL Marks.
          </li>
          <li>
             <strong>Goodwill</strong>. All use of the GSL Marks and associated
            goodwill will inure to the benefit of GSL. All rights not expressly
            granted are reserved to GSL. You must not attempt to register any
            trademarks, marks or trade names confusingly similar to those of GSL.
          </li>
          <li>
             <strong>Quality and Use of Trademarks</strong>. You will comply
            with GSL’s reasonable instructions and quality control requirements
            regarding use of GSL Marks, as communicated by GSL from time-to-time.
          </li>
          <li>
             Confidentiality.
              Other than so far as necessary to comply with this Agreement, you
              must not disclose to any third party any part of this Agreement,
              including these Terms and Conditions and all pricing information, or
              any information exchanged between the parties for or in relation to
              this Agreement. The obligation of confidentiality is intended to be
              perpetual except to such information that no longer has the
              character of confidential information under common law of Alberta,
              or that is ordered to be disclosed by a court or other legal
              authority with power to force disclosure of the information.
            You shall only use confidential information for the purposes of this
            Agreement.
          </li>
          <li>
             <strong>Protection of Information</strong>. You agree that aspects
            of the GeX Website and associated documentation, including the
            specific design and structure of individual Reports and Content,
            constitute trade secrets and confidential information of GSL. You
            shall not disclose, provide, or otherwise make available such trade
            secrets or confidential information in any form to any third party
            without the prior written consent of GSL. You shall implement
            reasonable security measures to protect such trade secrets and
            confidential information.
          </li>
          <li>
             <strong>Third-Party Infringements</strong>. You must take
            reasonable efforts to notify GSL of any evidence that comes to its
            attention that would lead one to reasonably conclude that a third
            party is infringing any GSL intellectual property rights.
          </li>
        </OL>
        <h2>5. Payment</h2>
        <OL>
          <li>
             <strong>Service Fees</strong>. Post-Launch, fees and charges for
            any new Service or new feature of a Service will be effective when GSL
            posts updated fees and charges on the GeX Website, unless GSL
            expressly states otherwise in a notice.
          </li>
          <li>
             <strong>Invoices</strong>. All invoices shall be payable within 15
            days of their receipt by you. GSL shall not be liable to pay interest
            upon any deposit.
          </li>
          <li>
             <strong>Missed Payments</strong>. If you have failed to pay an
            invoice on time, GSL may cancel any outstanding obligations it may
            have to you.
          </li>
          <li>
             <strong>Interest</strong>. Any overdue invoice amounts shall be
            subject to the lesser of per diem simple interest of 0.05% per day or
            the highest rate of interest permitted by law.
          </li>
        </OL>
        <h2>6. Liability and Choice of Laws</h2>
        <OL>
          <li>
             <strong>Limitation of Liability</strong>. To the maximum extent
            permitted by applicable law, GSL’s entire liability under any
            provision of the agreement shall be limited to direct damages equal to
            the amount actually paid to GSL by you for the particular Services
            concerned.
          </li>
          <li>
             <strong>Indemnity</strong>. You agree to indemnify and hold GSL
            harmless from damages of any kind, or other compensation under any
            other theory of liability, arising out of your use of the Content and
            GeX Website, including from any information contained in the GeX.
          </li>
          <li>
             <strong>Force Majeure</strong>. In no event shall GSL be liable
            for any failure or delay in the performance of its obligations under
            this Agreement arising out of forces beyond its reasonable control,
            including strikes, work stoppages, accidents, acts of war or
            terrorism, civil or military disturbances, nuclear or natural
            catastrophes or acts of God, and interruptions, loss or malfunctions
            of utilities, communications or computer services.
          </li>
          <li>
             <strong>Irreparable harm</strong>. You acknowledge that a breach
            by you of a material term of the Agreement could cause irreparable
            harm to GSL for which monetary damages may be difficult to ascertain
            or an inadequate remedy. Each party therefore agrees that GSL will
            have the right to injunctive relief for any such violation of the
            Agreement, in addition to any other rights and remedies available at
            law or in equity.
          </li>
          <li>
               <strong>Governing Law</strong>
            . The Agreement shall be construed under the laws of Alberta and shall
            be exclusively enforced in the courts of Alberta or the Federal Court
            of Canada. You agree that you shall consent to any request by GSL that
            a hearing or examination be held by teleconference or at a venue in
            Alberta, including examination of you.
          </li>
        </OL>
        <h2>7. Representations</h2>
        <OL>
        <li>
           <strong>Corporations</strong>. If a party is a corporation, that
          party warrants that it is duly incorporated in the jurisdiction of your
          residence and is in compliance with all regulatory filing duties and
          obligations incumbent upon it under applicable laws to maintain the
          corporation in good standing.
        </li>
        <li>
           <strong>Partnerships</strong>. If a party is a partnership, that
          party warrants that in the jurisdiction of its residence, if that
          jurisdiction mandates registration, it is registered and the partnership
          is in compliance with all regulatory filing duties and obligations
          incumbent upon under applicable laws to maintain the registration of the
          partnership in good standing.
        </li>
        </OL>
        <h2>8. Notices</h2>
        <OL>
        <li>
           <strong>To You</strong>. GSL may provide any notice to you under
          this Agreement by (i) posting a notice on the GeX Website or (ii)
          sending a message to the email address then associated with your
          account. Notices provided by posting on the GeX Website are effective on
          posting. Notices GSL provides by email will be effective when GSL sends
          the email. It is your responsibility to keep your email address current.
          You will be deemed to have received any email sent to the email address
          then associated with your account when GSL sends the email, whether or
          not you actually receive the email.
        </li>
        <li>
           <strong>To GSL</strong>. To give GSL notice under this Agreement,
          you must contact GSL by email or personal delivery, overnight courier or
          registered or certified mail to the follow address, as applicable:
        </li>
        <OLLetter>
        <li>
          email address: samuel@gosololand.com
        </li>
        <li>
          mailing address: Go Solo Land Inc. o/a Property Services Exchange,
          320 Westridge Road, Edmonton, AB T5T 1C5
        </li>
        </OLLetter>
        <p>
          GSL may update the email or mailing address for notices to GSL by
          posting a notice on the GeX Website. Notices provided by personal
          delivery will be effective immediately. Notices provided by email or
          overnight courier will be effective one business day after they are
          sent. Notices provided by registered or certified mail will be effective
          three business days after they are sent.
        </p>
        </OL>
            
        <h2>9. Interpretation</h2>
        <OL>
        <li>
           <strong>Definitions</strong>. For the purpose of these terms and
          conditions:
        </li>
        <OLLetter>
        <li>
          “<em>Content</em>” means all data, graphics, reports, forms, or
          other information from the GeX Website in its intended format and
          intended for viewing by you, including Reports.
        </li>
        <li>
          <em>“Current” </em>means submitting
            any changes to your Data to the GeX within two weeks of the change
          during the Term of this Agreement.<em></em>
        </li>
        <li>
             “<em>Data</em>” means any information relating to industrial and
            commercial real estate that you submit to the GeX, including personal
            information identifying ownership or control of the real estate.
        </li>
        <li>
             “<em>GeX</em>” or <em>“Greater Edmonton Exchange Database”</em>
            means any information entered into or produced from GSL’s database
            whether from the Data, data from GSL’s licensors, or other information
            collected by GSL by any means, and includes Content and Reports.
        </li>
        <li>
           “<em>GeX</em> <em>Website</em>” means the website, mobile
          application, or other portal authorized by GSL and intended to permit
          you to access Content.
        </li>
        <li>
           “<em>GSL Marks</em>” includes trademarks and trade names that GSL
          may adopt from time to time.
        </li>
        
        <li>
           <em>“Launch”</em> means the date as notified by GSL that the
          Services listed on the GeX Website are made available and all fees are
          payable.
        </li>
        <li>
           <em>“Members”</em> means persons who have registered with the GeX.
        </li>
        <li>
           <em>“Membership”</em> means participation in the GeX as a member.
        </li>
        <li>
           <em>“Post-Launch”</em> means the period after Launch.
        </li>
        <li>
          <em>“Pre-Launch”</em> means the period prior to Launch.
        </li>
        <li>
           “<em>Reports</em>” means all reports or other information regarding
          real estate available for sale or lease produced from the GeX as a
          result of a query made at the GeX Website and intended for use and
          viewing by you. <em></em>
        </li>
        <li>
          <em>“Required Field” </em>means part of a description of real estate
          that is described as “required” on the GeX Website, including
          confirmation of availability of the real estate, and such information as
          specified to be mandatory by GSL from time to time in a notice.<em></em>
        </li>
        <li>
           <em>“Service” </em>means each of the services made available by GSL
          to Prospects, including those services made available to Prospects on
          the terms described in the GeX Website or as provided by GSL in a
          notice. <em></em>
        </li>
        <li>
           <em>“Prospect” </em>or “<em>Prospect-level Membership”</em> means
          participation in the GeX in accordance with the terms of this Agreement.
        </li>
        <li>
           <em>“You”</em> means you and the brokerage you represent.
        </li>
        </OLLetter>
        <li>
           <strong>English Language</strong>. The parties have expressly
          requested and agreed that these terms and conditions and all related
          documents be drawn up in English.
          <em>
            Les parties conviennent et exigent expressement que le présent contrat
            et les documents qui s’y rattachent soient redigés en anglais
          </em>
          .
        </li>
        <li>
           <strong>Syntax.</strong> For greater certainty, in this Agreement,
          the words “including” or “includes” has the same meaning as
          “including/includes without limitation” or “including/includes but not
          limited to” and must not be construed to limit any general statement
          that it follows to the specific or similar items or matters immediately
          following it.
        </li>
        <li>
           <strong>Persons under control of a party</strong>. Any reference to
          a party includes the officers, directors, agents, servants, employees,
          representatives, successors, assigns, subcontractors, consultant and
          professional advisers, or related or affiliate entities of that party
          and all those under their control.
        </li>
        <li>
           <strong>Time</strong>. Time shall be of the essence of the Agreement
          and of every part thereof.
        </li>
        <li>
           <strong>Currency</strong>. Unless otherwise stated, all prices in
          are Canadian Dollars (CAD).
        </li>
        <li>
           <strong>No Partnership or Joint Venture</strong>. The parties to the
          Agreement are not partners or joint venturers with each other and
          nothing herein shall be construed to make them partners or joint
          venturers or impose any liability as such on either of them.
        </li>
        <li>
           <strong>Waiver</strong>. No waiver by GSL of any term or condition
          of the Agreement or any breach thereof shall be made effective unless
          made in writing. The waiver by any party of any breach under the
          Agreement shall not be deemed to be a waiver of any subsequent breach of
          a like or different nature. Any failure by GSL to insist upon or enforce
          strict performance of any right or provision of the Agreement shall not
          constitute or be construed as a waiver of any right or provision.{" "}
        </li>
        <li>
           <strong>Survival</strong>. If this Agreement is terminated for any
          reason, the terms of this Agreement which by their nature should survive
          termination shall so survive. For greater certainty, at least the
          following terms shall survive termination of this Agreement: Sections 4,
          5 and 6.
        </li>
        <li>
           <strong>Prevail</strong>. In case of any inconsistency between
          these Prospect Terms and Conditions and any other provisions forming
          part of this Agreement, these Prospect Terms and Conditions shall
          prevail.
        </li>
        <li>
           <strong>Binding on successors</strong>. The Agreement shall enure
          to the benefit of and be binding upon the parties hereto and their
          permitted successors and assignees.
        </li>
        <li>
           <strong>Severable</strong>. These Prospect Terms and Conditions and
          the Prospect Registration Form shall be read as a single Agreement. If a
          court of competent jurisdiction should find any provision of the
          Agreement to be invalid or unenforceable, such finding shall not affect
          or impair the validity and enforceability of the remaining provisions.
        </li>
        <li>
          <strong>
            <em>Contra proferentem</em>
          </strong>
          . Any rule of construction to the effect that ambiguities are to be
          resolved against the drafting party shall not apply in interpreting the
          Agreement.
        </li>
        <li>
           <strong>Singular and Plural</strong>. Reference to the singular
          includes a reference to the plural and vice versa.
        </li>
        <li>
           <strong>Sections and Subsections</strong>. These terms and
          conditions are divided into Sections and Subsections.
        </li>
        <li>
          <strong>Headings</strong>. The headings contained in the Agreement
          are for convenience of reference only and shall not limit or otherwise
          affect in any way the meaning or interpretation of the Agreement.
        </li>
        </OL>
        </AgreeToTermsAndPrivacyControl> );
}




export const OL = styled.ol`
  counter-reset: list;
  margin: 0;

  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
    padding-left: 0.5em;
  }
  & > li:before {
    counter-increment: list;
    content: "(" counter(list, numbers) ")";
    position: absolute;
    left: -2em;
  }
`
export const OLLetter = styled.ol`
  counter-reset: letters;
  margin: 0;
  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
    padding-left: 1em;
  }
  & > li:before {
    counter-increment: letters;
    content: "(" counter(letters, lower-alpha) ")";
    position: absolute;
    left: -2em;
  }
`

export const OLRoman = styled.ol`
  counter-reset: roman;
  margin: 0;
  margin-left: 1em;
  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: roman;
    content: "(" counter(roman, lower-roman) ")";
    position: absolute;
    left: -2em;
  }
`
