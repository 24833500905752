import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  getListingTypeFriendlyText,
  getMarketSegmentFromText,
} from "common/newHelperFunctions";
import { Heading } from "components/searchPopup/styledComponents";
import React, { useEffect } from "react";
import styled from "styled-components";
import { LISTING_TYPE } from "types/Types";

type Props = {
  state: LISTING_TYPE;
  setState(ms: LISTING_TYPE): void;
  userMarketSegments:string[];
};

const MarketSegmentForm: React.FC<Props> = ({ state, setState, userMarketSegments }) => {

  
  useEffect(function() {
    if(!userMarketSegments.includes(state))
    {
      setState( userMarketSegments[0] as LISTING_TYPE)
    }
  
  }, [userMarketSegments, state, setState])
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(
      getMarketSegmentFromText((event.target as HTMLInputElement).value)
    );
  };

  // const values = state.values;
  // const ordinal = values[0] -1;

  return (
    <Container>
      <Heading>Select Market Segment</Heading>
      <FormControl component="fieldset" style={{ marginLeft: 50 }}>
        <FormLabel component="legend">Market Segment</FormLabel>
        <RadioGroup
          aria-label="Market Segment"
          name="marketSegment"
          value={state}
          onChange={handleChange}
        >
          {Object.values(LISTING_TYPE).map((type) => (
            <FormControlLabel
              key={type}
              value={type}
              control={<Radio color="primary" />}
              label={getListingTypeFriendlyText(type as LISTING_TYPE)}
              disabled={!userMarketSegments.includes(type)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );
};
export default MarketSegmentForm;

const Container = styled.div`
  position: relative;
  height: 100%;
  padding: 0 80px;
`;
