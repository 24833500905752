import { Toolbar } from "@material-ui/core";
import React from "react";
import MainHeader from "./components/MainHeader";
import styled from "styled-components";
export default function NotVerifiedPage() {

  return (
    <>
      <MainHeader />
      <Toolbar />
      <Container>
        <h1>Verification Request</h1>
        <p>
          To <b>post</b> listing data in our database, we request that you{" "}
          <b>verify your identity to protect the integrity of our data</b>. You
          will receive a link and simple instructions shortly. Following
          successful verification, you will receive an email inviting you to
          post your listing data.
        </p>
        <p>
          You do not need to verify your identity to <b>search</b> listing data.
          Furthermore, we{" "}
        </p>
        <ul>
          <li>Don’t solicit </li>
          <li>Use cookies</li>
        </ul>
        <p>
          If you would like additional clarification, please don't hesitate to
          reach out by emailing us using the Contact form on our website.
        </p>
        <p> Thank you for your cooperation.</p>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 900px;
  background-color: #fff;
  margin: 30px auto;
  padding: 30px;
  border: 1px solid #ccc;

  & h1 {

    color: dodgerblue;
  }

  & li {

    line-height: 25px;
  }


  & p {
    line-height: 25px;
  }
`;
