import React, { useState } from "react";
import { Field } from "types/fieldComponentTypes";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { Button } from "@material-ui/core";
import { settings } from "../../../config/firebase";
type BrochureButtonProps = {
  listingId: string;
  value: string | undefined;
  field: Field;
};
export default function BrochureButton({ value, field, listingId }: BrochureButtonProps) {
  if (!value) {
    return null;
  }
  const valueIsLink =
    !!value &&
    (value.indexOf("http://") === 0 || value.indexOf("https://") === 0);

    const url = (() => {
        if (valueIsLink) {
            return value;
        }
        const { projectId } = settings.firebaseConfig;
        return `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${listingId}%2F${value}?alt=media`;
    })();


    return (<p>
        <Button
        variant="contained"
        color="primary"
        startIcon={<FileIcon />}
        href={url}
        target="_blank"
      >
        {field.label}
      </Button></p>
    );
  
}
