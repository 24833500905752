import React from "react";
import styled from "styled-components";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Link from "@material-ui/core/Link";
type Props = {
  setTermsChecked(isChecked: boolean): void;
  setPrivacyChecked(isChecked: boolean): void;
  isDisabled: boolean;
  children: React.ReactNode;
};

export default function AgreeToTermsAndPrivacyControl({
  setTermsChecked,
  setPrivacyChecked,
  isDisabled,
  children,
}: Props) {
  const privacyURL = `${process.env.PUBLIC_URL}/privacy-policy`;
  return (
    <div>
      <TermsDiv>{children}</TermsDiv>
      <Spacer />
      <PrivacyPolicyBox>
        <Link href={privacyURL} target="_blank">
          Privacy Policy
        </Link>
      </PrivacyPolicyBox>
      <StyledIAgreeCheckbox
        disabled={isDisabled}
        control={
          <Checkbox
            name="checkedC"
            color="primary"
            onChange={(e, isChecked) => setTermsChecked(isChecked)}
          />
        }
        label="I Agree to the Terms and Conditions"
      />
      <div>
        <StyledIAgreeCheckbox
          disabled={isDisabled}
          control={
            <Checkbox
              name="checkedC"
              color="primary"
              onChange={(e, isChecked) => setPrivacyChecked(isChecked)}
            />
          }
          label="I Agree to the Privacy Policy"
        />
      </div>
    </div>
  );
}

const StyledIAgreeCheckbox = styled(FormControlLabel)`
  color: dodgerblue;
  font-weight: 600;
`;

const Spacer = styled.div`
  height: 20px;
`;

const PrivacyPolicyBox = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  margin: 0 0px 20px 0px;
  font-weight: 600;
`;

const TermsDiv = styled.div`
  max-height: 800px;
  overflow-y: scroll;
  border: 1px solid #ccc;

  padding: 10px;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
`;
