import { Toolbar } from "@material-ui/core";
import { redirectTo, RouteComponentProps } from "@reach/router";
import { AppContext } from 'AppContext';
import React, { useContext } from "react";
import styled from 'styled-components';
import PageHeader from "./components/PageHeader";


const NotInOrganizationPage = (_:  RouteComponentProps) => {
  const {user, orgId} = useContext(AppContext);
  //const {addUserInfo} = useUser(orgId);
  if(!user){
    return <div>loading</div>;
}
//alert('stop')

if(user.orgId && user.orgId.length > 0 && user.orgId !== orgId){
  if(window.location.host.includes('localhost:3000')){
    redirectTo(`http://${user.orgId}.localhost:3000`);
  }
  if(window.location.host.includes('propertyservicesexhange')){
    redirectTo(`https://${user.orgId}.propertyservicesexhange.com`);
  }
  if(window.location.host.includes('gosolox')){
    redirectTo(`https://${user.orgId}.gosolox.com`);
  }
}
  
  //addUserInfo(user, orgId );

  return (
    <div>
      <PageHeader />
      <Toolbar />
      <PageBody>
       Thank you for registering. You will be notified when your account is activated.
      </PageBody>
    </div>
  );
};

const PageBody = styled.div`
padding:30px;
  max-width:600px;
  margin:auto;
`;

export default NotInOrganizationPage;
