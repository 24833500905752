import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import {
  CellColumnNumber,



  IdName, PickListField
} from "types/fieldComponentTypes";
import IsRequiredText from "./IsRequiredText";


type Props = {
  field: PickListField;
  value: string ;
  xs: CellColumnNumber;
  sm: CellColumnNumber;
  md: CellColumnNumber;
  disabled: boolean;
  onBlur: (value: boolean) => void;
  handleChange: (jsonPath: string, value: string | null) => void;
  options: IdName[];
  error: string | null;

};

const PickListControl = ({
  field,
  value,
  xs,
  sm,
  md,
  handleChange,
  options,
  error,
  disabled = false,
}: Props) => {
  const [, setState] = useState<string>(value || "");
  // SET IT BLANK INSTEAD OF NULL, BECAUSE OF ERROR OF SWITCHING BEWTEEN
  // UNCONTROLLED TO CONTROLLED.
  useEffect(() => {
    setState(value);
  }, [value]);

  const optionValue = options.find((i) => i.id === value);



  if (!!field.radio) {
    return (
      <Grid item xs={12} sm={sm} md={md}>
        <FormLabel component="legend" disabled={disabled}>
          {field.formLabelOverride || field.label}
        </FormLabel>
        <RadioGroup
          aria-label={field.formLabelOverride || field.label}
          name={field.formLabelOverride || field.label}
          value={value || field.defaultValue || null}
          onChange={(e) => handleChange(field.jsonPath, e.target.value)}
        >
          {options.map(o => (          
          <FormControlLabel
            key={`radio_${o.id}`}
            value={o.id}
            control={<Radio />}
            label={o.name}
            disabled={disabled}
          />

          ))}

        </RadioGroup>
        <IsRequiredText isRequired={field.isRequired} />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={sm} md={md}>
      <Autocomplete
        value={optionValue ||  null}
        options={options}
        getOptionLabel={(idName) => idName.name}
        disabled={disabled}
        
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label={field.label}
            error={!!error}
            helperText={error || (field.isRequired ? 'Required' : null)}
            disabled={disabled}
            onBlur={ e => {
                const value = e.target.value;
              
                const found = options.find(o => o.name.toUpperCase() === value.toUpperCase())
                if(!!found){
                  handleChange(field.jsonPath, !!field.useIdInsteadOfValueForValue ? found.id : found.name);
                }
            }
          }
          />
        )}
        onChange={(_: any, v: string | IdName | null) => {
          if (typeof v === "string" || v === null) {
            setState(v || "");
            handleChange(field.jsonPath, v || "")
            return;
          }
          if (!!v?.id) {
            setState(v?.id);
            handleChange(field.jsonPath,v?.id)
            return;
          }
        }}
        
      />

    </Grid>
  );
};

export default PickListControl;
