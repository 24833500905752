import React from "react";

const UnitLabel = ({
  isLand = false,
  isBuilding = false,
  isHeight = false,
  isPercent = false,
  isCurrencyRate = false,
}) => {
  if (isBuilding) {
    return (
      <>{' '}
        ft<sup>2</sup>
      </>
    );
  }
  if (isHeight) {
    return <>{' '}ft</>;
  }
  if(isPercent) {
    return <>%</>;
  }
  if(isLand) {
  return <>{' '}acres</>
  }
  if(isCurrencyRate) {
    return <>{' '}per ft<sup>2</sup></>;
  }
  return null;
};

export default UnitLabel;
