import UnitLabel from "pages/components/controls/UnitLabel";
import React from "react";
import { Light, Strong } from "./ListingPageStyledComponents";

type Props = {
  isRange: boolean | undefined;
  value: number | undefined;
  to: number | undefined;
  from: number | undefined;
  label: string;
  actualOrEstimate?:string;
};

const BasicRentFixedRangeFieldLabel: React.FC<Props> = ({
  label,
  isRange,
  to,
  from,
  value,
  actualOrEstimate
}) => {

  if (!isRange && !value) {
    return null;
  }
  if (!isRange && !!value) {
    return (
      <p>
        <Light>{label} </Light>
        <Strong>
          ${value.toLocaleString(undefined, {minimumFractionDigits:2})} <UnitLabel isCurrencyRate /> {!!actualOrEstimate && <>({actualOrEstimate})</>}
        </Strong>
      </p>
    );
  }
  if (!to || !from) {
    return null;
  }
  return (
    <p>
      <Light>{label} </Light>
      <Strong>
          ${`${from.toLocaleString(undefined, {minimumFractionDigits:2})}—${to.toLocaleString(undefined, {minimumFractionDigits:2})} ` }<UnitLabel  isCurrencyRate />  {!!actualOrEstimate && <>({actualOrEstimate})</>}
      </Strong>
    </p>
  );
};
export default BasicRentFixedRangeFieldLabel;
