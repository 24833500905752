import { Button, IconButton } from "@material-ui/core";
import { getLetterForOrdinal } from "common/helperFunctions";
import React, { useState } from "react";
import styled from "styled-components";
import { Zone } from "./MultipleZoneMapEditor";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import { getGeoAcres } from "common/mapHelpers";

type MultipleZoneTableProps = {
  zones: Zone[];
  selectedOrdinal: number;
  setSelectedOrdinal(ordinal: number): void;
  addPin(): void;
  addPolygon(): void;
  removePolygon(): void;
  removePin(): void;
};
export default function MultipleZoneTable({
  zones,
  selectedOrdinal,
  setSelectedOrdinal,
  addPin,
  addPolygon,
  removePolygon,
  removePin,
}: MultipleZoneTableProps) {
    

  return (
    <Container>
      {zones.map((z, n) => {
        const hasPin = !!z.marker;
        const hasPolygon = !!z.polygon;
        const polygonSizeText = hasPolygon ? `${getGeoAcres(zones[n].polygon)} acres` : undefined;
        const props = {key:n, ordinal:n, hasPin, hasPolygon, polygonSizeText, selectedOrdinal, setSelectedOrdinal};
        return n === selectedOrdinal ? (
          <SelectedRow  
           zone={z}
            {...props}
            addPin={addPin}
            addPolygon={addPolygon}
            removePolygon={removePolygon}
            removePin={removePin}
          />
        ) : (
          <UnselectedRow
            {...props}
            showEditButton={selectedOrdinal === -1}
            description={z.description}
          />
        );
      })}
    </Container>
  );
}

function SelectedRow({
  zone,
  ordinal,
  setSelectedOrdinal,
  hasPin,
  hasPolygon,
  addPin,
  addPolygon,
  removePolygon,
  removePin,
}: {
  zone: Zone;
  ordinal: number;
  setSelectedOrdinal: (ordinal: number) => void;
  hasPin: boolean;
  hasPolygon: boolean;
  addPin(): void;
  addPolygon(): void;
  removePolygon(): void;
  removePin(): void;
}) {
  const btns = (() => {
    const b = [];
      if (!hasPin) {
        b.push({ onClick:addPin, text: "Add Pin", color: "default" });
      } else if (!hasPolygon) {
        b.push({ onClick: addPolygon, text: " Add Polygon", color: "default"  });
      }
      if (hasPolygon) {
        b.push({ onClick: removePolygon, text: "Remove Polygon", color: "secondary"  });
      }
      if (hasPin) {
        b.push({ onClick: removePin, text: hasPolygon ? "Remove Pin & Polygon" : "Remove Pin", color: "secondary"  });
      }
    return b;
  })() as {onClick: () => void, text: string, color: "default" | "secondary" }[];

  return (
    <Row selected>
      <OrdinalCell selected>{getLetterForOrdinal(ordinal)}</OrdinalCell>
      <ButtonCell>
            {!!btns &&
              btns.map((b, n) => (
                <Button
                  key={`btn_${n}`}
                  variant="outlined"
                  color={b.color}
                  onClick={b.onClick}
                  style={{ marginLeft: 5 }}
                >
                  {b.text}
                </Button>
              ))}</ButtonCell>
      <IconCell>
        <IconButton onClick={() => setSelectedOrdinal(-1)} >
          <DoneIcon />
        </IconButton>
      </IconCell>
    </Row>
  );
}

function UnselectedRow({
  ordinal,
  setSelectedOrdinal,
  showEditButton,
  hasPin,
  hasPolygon,
  polygonSizeText,
  description,
}: {
  ordinal: number;
  setSelectedOrdinal: (ordinal: number) => void;
  showEditButton: boolean;
  hasPin: boolean;
  hasPolygon: boolean;
  polygonSizeText?: string;
  description: string | null;

}) {
  return (
    <Row>
      <OrdinalCell >{getLetterForOrdinal(ordinal)}</OrdinalCell>
      <DescriptionCell>{description}</DescriptionCell>
      <PinPolyCell hasPin={hasPin} hasPolygon={hasPolygon} polygonSizeText={polygonSizeText} />
      <IconCell>
        {showEditButton && (
          <IconButton onClick={() => setSelectedOrdinal(ordinal)}>
            <EditIcon />
          </IconButton>
        )}
      </IconCell>
    </Row>
  );
}

function PinPolyCell({
  hasPin,
  hasPolygon,
  polygonSizeText,
}: {
  hasPin: boolean;
  hasPolygon: boolean;
  polygonSizeText?: string;
}) {
  return (
    <Flex1>
      {hasPin && <>Pin: ✔</>}
      {hasPolygon && (
        <span style={{ paddingLeft: 20 }}>
          Polygon: {polygonSizeText}
        </span>
      )}
    </Flex1>
  );
}
const Container = styled.div`
  padding-bottom: 16px;
`;

const DescriptionCell = styled.div`
  width: 200px;
`;

const Row = styled.div<{ selected?: boolean }>`
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  min-height: 50px;
  ${({ selected }) => selected && `border-bottom: 1px solid #2196F3;`}
`;
const IconCell = styled.div`
  width: 70px;
  padding: 0 16px;
`;
const Flex1 = styled.div`
  flex: 1;
  padding: 0 16px;
`;
const OrdinalCell = styled.div<{ selected?: boolean }>`
  width: 70px;
  padding: 0 16px;
  font-weight: bold;
  ${({ selected }) => selected && `color: #2196F3;`}
`;
const ButtonCell = styled.div`
  flex:1;
`;