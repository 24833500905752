import firebase from "config/firebase";
import { useCallback, useEffect, useState } from "react";
import { User } from "types/AdminTypes";
import { LastSave } from "types/Types";
import { getLastSaveObject } from "./newStoreHelperMethods";

interface ReturnProps {
  docs: DueDiligenceDoc[] | null;
  saveDoc(doc: DueDiligenceDoc): Promise<void>;
  addNewPage(): Promise<string>;
  deletePage(docId: string): Promise<void>;

}

export type DueDiligenceDoc = {
  id: string;
  title: string;
  slug: string;
  synopsis: string;
  file?: string;
  author: string;
  company?: string;
  email?: string;
  phone?: string;
  parentDoc?: string;
  fileName?:string;
  lastSave:LastSave;
  status: 'draft' | 'published' | 'deleted'
};

export default function useDueDiligenceStore(user: User | null): ReturnProps {
  const db = firebase.firestore();
  const [state, setState] = useState<DueDiligenceDoc[] | null>(null);
  const isUserLoggedIn = !!user;

  const getDueDiligenceCollection = useCallback(
    () => db.collection("dueDiligence"),
    [db]
  );
  useEffect(() => {

    const unsubscribe = getDueDiligenceCollection().onSnapshot(
      (querySnapshot) => {
        const queryDocs = querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            } as DueDiligenceDoc)
        );
        setState(queryDocs);
      }
    );

    return () => unsubscribe();
  }, [isUserLoggedIn, getDueDiligenceCollection]);


  function saveDoc(doc: DueDiligenceDoc): Promise<void> {
      
    if (!isUserLoggedIn  || !user) {
      throw new Error("user not logged in");
    }
    const docRef = db.collection("dueDiligence").doc(doc.id);
    const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());
    const updatedDoc = {
      ...doc,
      lastSave: getLastSaveObject(dateSaved, user),
    };

    const batch = db.batch();
    batch.set(docRef, updatedDoc);
    return batch.commit();
  }

  const rtn: ReturnProps = {
    docs: state,
    saveDoc: saveDoc,
    addNewPage: async function () {
      const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());

      if (!isUserLoggedIn || !user) {
        throw new Error("user not logged in");
      }

      const dueDiligenceCollectionRef = db.collection("dueDiligence");
      const docRef = dueDiligenceCollectionRef.doc();
      const pageId = docRef.id;

      //const dateSaved = new firebase.firestore.Timestamp.fromDate(new Date());
      const addDoc: DueDiligenceDoc  = {
        id: pageId,
        title:'New Page',
        synopsis:'',
        author: user?.displayName || '',
        email: user?.email,
        slug:'new-page',
        lastSave: getLastSaveObject(dateSaved, user),
        status: 'draft'
      };

      const batch = db.batch();
      batch.set(docRef, addDoc as any);
      await batch.commit();
      return pageId;
    },
    deletePage: async function (docId: string) {
      const doc = state?.find(d => d.id === docId);
      if(!doc){
        throw new Error("doc not found");
      }
      return saveDoc({...doc, status: 'deleted'})
    }
  };

  return rtn;
}
