import { Divider, ListItemIcon, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Check } from "@material-ui/icons";
import { navigate, useLocation } from "@reach/router";
import { AppContext } from "AppContext";
import { getUserFriendlyName } from "common/newHelperFunctions";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { settings } from "../../config/firebase";

const UserAvatar = () => {
  const { user, handleLogout, setOrganizationType, organizationType} = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { projectId } = settings.firebaseConfig;


  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleLogout2() {
    handleLogout();
    setAnchorEl(null);
  }

  const location = useLocation();
  const getAdminItems = () => 
    [
      <Divider key="d1"/>,
      <MenuItem
      key="ll1"
      onClick={() => navigate(`/admin/lookups`)}
      disabled={location.pathname === "/admin/lookups"}
    >
      Lookups Editor
    </MenuItem>,
      <MenuItem
      key="dde1"
      onClick={() => navigate(`/duediligence/editor`)}
      disabled={location.pathname === "/duediligence/editor"}
    >
      Useful Resources Editor
    </MenuItem>,
    <MenuItem
    key="clog"
    onClick={() => navigate(`/changelog`)}
    disabled={location.pathname === "/changelog"}
  >
    Change Log
  </MenuItem>,
  <SubMenu key="a1">Member Type</SubMenu>,
  <MemberTypeMenuItem
  key="a2"
  onClick={() => {setOrganizationType("contributors");setAnchorEl(null);}}
  label="Contributor"
  isSelected={organizationType === "contributors"}
  />,
  <MemberTypeMenuItem
  key="a3"
  onClick={() => {setOrganizationType("sponsors");setAnchorEl(null);}}
  label="Sponsor"
  isSelected={organizationType === "sponsors"}
  />,
  <MemberTypeMenuItem
  key="a4"
  onClick={() => {setOrganizationType("prospects");setAnchorEl(null);}}
  label="Prospect"
  isSelected={organizationType === "prospects"}
  />,
  // <SubMenu key="Prelaunch">Prelaunch?</SubMenu>,
  // <MemberTypeMenuItem
  // key="b1"
  // onClick={() => {setIsPrelaunch(true)}}
  // label="Prelaunch"
  // isSelected={isPrelaunch}
  // />,
  // <MemberTypeMenuItem
  // key="b2"
  // onClick={() => {setIsPrelaunch(false)}}
  // label="Post Launch"
  // isSelected={!isPrelaunch}
  // />,
  <Divider key="lastd"/>
  ]
  
  
  return (
    <Container>
      <StyledIconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <StyledAvatar
          alt={user.displayName || user.email}
          src={user.photoURL}
        />
      </StyledIconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <UserInfoDiv>{getUserFriendlyName(user)}</UserInfoDiv>
        {user.isUserAdmin && user.displayName ==="Mark Kitz" && (
          <MenuItem
            onClick={() => {
              alert("Go Solo: " + projectId);
              handleClose();
            }}
          >
            About
          </MenuItem>
        )}
        {(user.isUserAdmin || user.roles.indexOf("orgAdmin") > 0) && (
          <MenuItem
            onClick={() => navigate(`/orgAdmin/userAdmin`)}
            disabled={location.pathname === "/orgAdmin/userAdmin"}
          >
            User Admin
          </MenuItem>
        )} 
        {user.isUserAdmin && getAdminItems()}
        
        <MenuItem onClick={handleLogout2}>Logout</MenuItem>
      </Menu>
    </Container>
  );
};


const MemberTypeMenuItem = ({ onClick, label, isSelected }) => 
  (<MenuItem onClick={onClick}>
    <ListItemIcon>{isSelected && <Check fontSize="small" />}</ListItemIcon>
    <Typography variant="inherit" noWrap>
      {label}
    </Typography>
  </MenuItem>);
;

const SubMenu = styled.li`
  padding: 16px 16px 8px;
  text-transform: uppercase;
  font-size: 12px;
  color: #666;
`;

const Container = styled.div`
  display: inline-block;
`;
const StyledAvatar = styled(Avatar)`
  height: 40px !important;
  width: 40px !important;
`;
const StyledIconButton = styled(IconButton)`
  padding: 9px !important;
`;
const UserInfoDiv = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  min-width: 250px;
`;
export default UserAvatar;
