import styled from 'styled-components'

export const Test =   styled.div`
  text-align: center;
  font-size: 18px;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 6px;
  width: 250px;
  margin: 30px auto 0 auto;
  color: #116c39;

  &  h3 {
      font-weight:600;
      font-size:17px;

  }
  & div {
      margin-top: 5px;
      font-size:15px;
  }
`;

export const Heading = styled.h2`
    margin: 0;
    padding: 40px 0 20px 0;
    font-family: Roboto, Helvetica Neue;
    font-size:29px;
    color:#333;
`;

export const SearchForm =  styled.div<{width:number, marginLeft:number}>`
border: 1px solid #ccc;
border-radius: 4px;
min-height: 50px;
background-color: #fff;
width: ${props => props.width || '360'}px;
margin-left: ${props => props.marginLeft || '18'}px;
box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;
padding: 14px 24px 24px 24px;
background-color:#f6f6f6;`