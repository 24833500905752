import { FormControlLabel, Grid, Switch } from "@material-ui/core";
import { DEFAULT_CENTER } from "common/constants";
import { getNumberOfPieces } from "common/newHelperFunctions";
import FieldGroupHeading from "components/controls/FieldGroupHeading";
import MultiplePiecesMapEditor from "components/controls/MultiplePiecesMapEditor";
import SpaceSetsImageControl from "components/controls/SpaceSetsImageControl";
import React from "react";
import { SpacesField } from "types/fieldComponentTypes";
import { Piece, PieceCombination, Pieces, Point, SizeData } from "types/Types";
import CombinationsTable from "./CombinationsTable";
import SpaceRow from "./SpaceRow";

type Props = {
  listingId:string;
  getStorageRef: () => firebase.storage.Reference;
  field: SpacesField;
  value?: Pieces;
  listingCenter: Point;
  isSpaceListing: boolean;
  handleChange: (jsonPath: string, value: Pieces) => void;
};

const PiecesEditor = ({
  listingId,
  getStorageRef,
  field,
  value,
  listingCenter,
  isSpaceListing,
  handleChange,
}: Props) => {
  const defaultPieces: Pieces = { mapInfo: null };
  const [pObject, setPObject] = React.useState<Pieces>(value || defaultPieces);
  const measurementUnit = isSpaceListing ? "ft²" : "acres";

  React.useEffect(() => {
    const numParcel = value?.numberOfParcels || 1;
    const numAncillaryLots = value?.numberOfAncillaryLots || 0;
    const numSpaces = value?.numberOfSpaces || 1;
    const numPieces = value ? getNumberOfPieces(value, isSpaceListing) : 1;
    const array: Piece[] = [...Array(numPieces)].map((_, i) => {
      const current: Piece | undefined =
        value && value.pieces && value.pieces[i];

      const piece: Piece = {
        description: current?.description || "",
        sizeData: current?.sizeData || [{ type: "fixed", size: null, sizeFrom: null, sizeTo: null }],
        mapArea: current?.mapArea || null,
        marker: current?.marker || null,
        polygon: current?.polygon || null,
        ordinal: i,
      };
      return piece;
    });

    const common = {
      image: value?.image || null,
      numberOfPieces: numPieces,
      canPiecesBeCombined: value?.canPiecesBeCombined || false,
      showMap: value?.showMap || false,
      mapInfo: !!value?.showMap
        ? {
            center: value?.mapInfo?.center || listingCenter,
            zoom: value?.mapInfo?.zoom || 17,
          }
        : null,
      pieces: array,
      pieceCombinations: value?.canPiecesBeCombined
        ? value?.pieceCombinations || []
        : [],
    };

    setPObject(
      isSpaceListing
        ? {
            ...common,
            numberOfSpaces: numSpaces,
          }
        : {
            ...common,
            numberOfParcels: numParcel,
            numberOfAncillaryLots: numAncillaryLots,
          }
    );
  }, [value]);

  function updateComboPObject(index: number, value: PieceCombination) {
    const newCombos = [...(pObject.pieceCombinations || [])];
    newCombos[index] = value;
    const newObj = { ...pObject, pieceCombinations: newCombos };
    setPObject(newObj);
    return newObj;
  }
  function getSizeDataForCombo(
    combo: PieceCombination,
    pieces: Piece[]
  ): SizeData[] {
    let newValue = 0;
    for (let i = 0; i < combo.selectedPieces.length; i++) {
      const selectedPieceIndex = combo.selectedPieces[i];
      const piece = pieces[selectedPieceIndex];
      if (
        !piece ||
        !piece.sizeData ||
        piece.sizeData.length !== 1 ||
        piece.sizeData[0].type !== "fixed"
      ) {
        return combo.sizeData || [{ type: "fixed" }];
      }
      newValue += piece.sizeData[0].size || 0;
    }
    return [{ type: "fixed", size: newValue, sizeFrom: null, sizeTo: null }];
  }

  const isAncillaryLots =
    pObject.numberOfParcels === 1 &&
    pObject.numberOfAncillaryLots &&
    pObject.numberOfAncillaryLots > 0;

  const prefix = "lot";

  return (
    <>
      <Grid item md={12}>
        {pObject.pieces &&
          pObject.pieces.map((piece, i) => {
            return (
              <SpaceRow
                key={`space-${i}`}
                value={piece}
                index={i}
                prefix={isSpaceListing? "Space" : isAncillaryLots ? "Ancillary Lot" : "Parcel"}
                onlyShowSizeField={pObject.numberOfPieces === 1}
                handleChange={(p) => {
                  if (pObject.pieces && pObject.pieces[i]) {
                    const newPieces = [...pObject.pieces];
                    newPieces[i] = p;
                    setPObject({ ...pObject, pieces: newPieces });
                  }
                }}
                handleBlur={(p) => {
                  if (!pObject.pieces || !pObject?.pieces?.[i]) {
                    throw new Error("pObject not set");
                  }

                  const newPieces = [...pObject.pieces];
                  newPieces[i] = { ...pObject.pieces[i], ...p };
                  const newObj = { ...pObject, pieces: newPieces };
                  handleChange(field.jsonPath, newObj);
                }}
                isSpaceListing={isSpaceListing}
              />
            );
          })}
      </Grid>
      {!!pObject.canPiecesBeCombined &&
        pObject.numberOfPieces &&
        pObject.numberOfPieces > 1 && (
          <Grid item md={12}>
            <CombinationsTable
              data={pObject.pieceCombinations || []}
              measurementUnit={measurementUnit}
              numberOfPieces={pObject.numberOfPieces || 0}
              addCombo={() => {
                const newCombos = [
                  ...(pObject.pieceCombinations || []),
                  { selectedPieces: [], sizeData: [] },
                ];
                setPObject({ ...pObject, pieceCombinations: newCombos });
              }}
              deleteCombo={(index) => {
                const newCombos = [...(pObject.pieceCombinations || [])];
                newCombos.splice(index, 1);
                setPObject({ ...pObject, pieceCombinations: newCombos });
              }}
              handleChange={(value: PieceCombination, index: number) => {
                updateComboPObject(index, value);
              }}
              handleLetterChange={(value: PieceCombination, index: number) => {
                const newSizeData: SizeData[] = getSizeDataForCombo(
                  value,
                  pObject.pieces || []
                );
                const newCombo: PieceCombination = {
                  ...value,
                  sizeData: newSizeData,
                };

                const newValue = updateComboPObject(index, newCombo);
                handleChange(field.jsonPath, newValue);
              }}
              handleBlur={(value: PieceCombination, index: number) => {
                const newObj = updateComboPObject(index, value);
                handleChange(field.jsonPath, newObj);
              }}
            />
          </Grid>
        )}

      <Grid container>
        {pObject.numberOfPieces && pObject.numberOfPieces > 1 && (
          <Grid item md={12} style={{ paddingBottom: 30 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!pObject.showMap}
                  onChange={() => {
                    // const hasAvailableSpaces = !state.hasAvailableSpaces;
                    const showMap = !!pObject.showMap ? false : true;
                    setPObject({ ...pObject, showMap: showMap });
                    handleChange(field.jsonPath, { ...pObject, showMap });
                  }}
                />
              }
              label="Use a map to show where the spaces are?"
            />
          </Grid>
        )}
        {pObject.showMap && (
          <>
            <FieldGroupHeading>{prefix} Map</FieldGroupHeading>
            <Grid item md={12}>
              <MultiplePiecesMapEditor
                center={pObject.mapInfo?.center || DEFAULT_CENTER}
                zoom={pObject.mapInfo?.zoom || 17}
                pieces={pObject.pieces || []}
                disabled={false}
                updateMap={(center, zoom, zones) => {
                  setPObject({
                    ...pObject,
                    mapInfo: { center, zoom },
                    pieces: zones,
                  });
                  handleChange(field.jsonPath, {
                    ...pObject,
                    mapInfo: { center, zoom },
                    pieces: zones,
                  });
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item md={12}>
        <h4>Site Map</h4>
        <SpaceSetsImageControl
          listingId={listingId}
          imageName={pObject.image}
          getStorageRef={getStorageRef}
          uploadComplete={(image) => {
            const newState = { ...pObject, image: image };
            setPObject(newState);
            handleChange(field.jsonPath, newState);
          }}
          onDelete={() => {
            const { image, ...newState } = pObject;
            setPObject(newState);
            handleChange(field.jsonPath, newState);
          }}
        />
      </Grid>
    </>
  );
};

export default PiecesEditor;
