import { navigate } from "@reach/router";
import PageLink, { ClickLink } from "components/PageLink";
import React, { ReactNode } from "react";
import styled from "styled-components";


type DocSideCategoryProps = {
  title: string;
  links: LinkProps[];
  hideTitle:boolean;
  noMargin?:boolean;
  onClick?():void;

};
export type LinkProps = {
  title: string;
  slug: string;
  isCurrent: boolean;
  index: number | null;
  categoryName:string;
};

export default function DocSideCategory({
  title,
  links,
  onClick,
  hideTitle =false,
  noMargin=false,
}: DocSideCategoryProps) {

  const C = ({children}:{children:ReactNode}) => noMargin ?
   (<div>{children}</div>) : (<Container>{children}</Container>)





  return (
    <C >
      {!hideTitle && <CategoryDiv>{title}</CategoryDiv>}
      {!!onClick && links.map((link) => (
        <ClickLink
          key={link.slug}
          title={link.title}
          //slug={link.slug}
          isCurrent={link.isCurrent}
          showIcon={false}
          //pathPrefix="docs"
          onClick={() => {
            navigate(`/docs/${link.slug}`);
            onClick()
          
          }}
        />
      ))}
      {!onClick && links.map((link) => (
        <PageLink
          key={link.slug}
          title={link.title}
          slug={link.slug}
          isCurrent={link.isCurrent}
          pathPrefix="docs"
        />
      ))}
    </C>
  );
}

const Container = styled.div`
  padding-left: 24px;
  padding-top: 24px;
`;

const CategoryDiv = styled.div`
  font-size: 12px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 14.4px;
  color: #9daab6;
  padding: 7px 24px 7px 16px;
  margin: 2px 0px;
`;

// color: #9DAAB6;
// border: 1px solid transparent;
// margin: 0;
// display: flex;

// position: relative;
// align-items: center;
// border-right: 0;
// text-decoration: none;
// -webkit-box-align: center;
