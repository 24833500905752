import UnitLabel from 'pages/components/controls/UnitLabel';
import React, {useState} from 'react';
import styled from 'styled-components';
import { Light, Strong } from './ListingPageStyledComponents';

type MezzanineFieldLabelProps = {
    size?:number;
    purpose?:string;
}
export default function MezzanineFieldLabel ({size, purpose} : MezzanineFieldLabelProps) {
    return (<p>
        <Light>Mezzanine</Light>
        <Strong>
            {size?.toLocaleString()} <UnitLabel isBuilding /> ({purpose})
        </Strong>
    </p>);
}