import React, { useState } from "react";
import styled from "styled-components";
import FileUploader from "./FileUploader";
import DeleteIcon from "@material-ui/icons/Delete";
import { settings } from "config/firebase";
import { Button, IconButton } from "@material-ui/core";
type SpaceSetsImageControlProps = {
  imageName?: string | null;
  getStorageRef: () => firebase.storage.Reference;
  uploadComplete: (fileName: string) => void;
  listingId: string;
  onDelete: () => void;
};
export default function SpaceSetsImageControl({
  getStorageRef,
  imageName,
  uploadComplete,
  listingId,
  onDelete,
}: SpaceSetsImageControlProps) {
  const { projectId } = settings.firebaseConfig;
  const fullUrl = `https://firebasestorage.googleapis.com/v0/b/${projectId}.appspot.com/o/listings%2F${listingId}%2F${imageName}?alt=media`;

  if (!imageName || imageName === "") {
    return (
      <ImgContainer>
        <FileUploader
          getStorageRef={getStorageRef}
          uploadComplete={uploadComplete}
          disabled={false}
          value={imageName || ""}
          labelOverride="Upload Image"
        />
      </ImgContainer>
    );
  }

  return (
    <div>
      {
        <ImgContainer>

              <Image src={fullUrl} alt="Site Plan" />
              <DeleteButtonDiv>
                <Button
                  aria-label="delete"
                  color="secondary"
                  onClick={() => onDelete()}
                  startIcon={<DeleteIcon />}
                >
                  Delete Image
                </Button>
              </DeleteButtonDiv>

        </ImgContainer>
      }
    </div>
  );
}
const DeleteButtonDiv = styled.div``;
const ImgContainer = styled.div``;
const Image = styled.img`
  max-width: 100%;
  border: 1px solid #ccc;
`;
