import { LinearProgress, Toolbar } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { AppContext } from "AppContext";
import MainHeader from "pages/components/MainHeader";
import React, { ReactNode, useContext, useEffect } from "react";
import useInvite from "store/useInvite";
import useUser from "store/useUser";
import styled from "styled-components";
import { User } from "types/AdminTypes";
import InviteStepOne from "./InviteStepOne";

type InvitePageProps = {
  inviteCode?: string;
} & RouteComponentProps;
export default function InvitePage({ inviteCode = "" }: InvitePageProps) {
  const {
    docStatus: {  queryStatus },
  } = useInvite(inviteCode);
  const { user, orgId } = useContext(AppContext);
  if (inviteCode === "") {
    return <Template>no invite code</Template>;
  }
  console.log(queryStatus)
  if (queryStatus === 'pending') {
    return (
      <Template isLoading>
        retriving invite
      </Template>
    );
  }

  if(!user){
    if( queryStatus === 'notFound'){
      return (
        <Template>
          <h1>Expired Invite Code</h1>
          <p>This invite code has already been used.</p>
          <p>
            If you have already created an account, continute to the{" "}
            <a href="/">site</a>.
          </p>
        </Template>
      );
    }
    return (
      <Template>
        <InviteStepOne inviteCode={inviteCode} />
      </Template>
    );

  }
  if(user.roles.length > 0){
    return (
      <Template>
        <h1>Your account has been created.</h1>
        <a href="/">Enter site</a>
      </Template>
    );
  }
  return (
    <Template isLoading>
      <RoleUpdater user={user} orgId={orgId} inviteDocId={inviteCode} />
    </Template>
  );

}

const Template = ({
  isLoading = false,
  children,
}: {
  isLoading?: boolean;
  children: ReactNode;
}) => {
  return (
    <div>
      <MainHeader noExtra />

      <Toolbar />
      {isLoading && <LinearProgress />}
      <Content>{children}</Content>
    </div>
  );
};

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const RoleUpdater = ({
  user,
  orgId,
  inviteDocId,
}: {
  user: User;
  orgId: string;
  inviteDocId: string;
}) => {
  const {  addUserAndRoles } = useUser(orgId);

  useEffect(
    function () {
      //updateUserRoles.
      addUserAndRoles(
        inviteDocId,
        orgId,
        user,
      ).then(function () {
        //setIsAdded(true);
        window.location.reload();

      });
    },
    [addUserAndRoles, inviteDocId, orgId, user]
  );

  return (
    <div>
    </div>
  );
};
