import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import React from 'react';
import styled from 'styled-components';
import { REGION_NAMES, REGION_SUBTEXT } from "../../../common/constants";

const MapCheckboxControl = ({regions, setRegions}) => {
  
  return ( <FormHolder>
    <EdmontonRegion>
    <StyledFormLabel>Edmonton</StyledFormLabel>
    <div style={{paddingTop:20}}>
    <FormControl>
      
      <FormGroup>
        {["centralEdmonton", "northEastEdmonton", "northWestEdmonton",   "southEastEdmonton", "southWestEdmonton"  ].map(
          region => (
            <FormControlLabel
            key={region}
              control={
                <Checkbox
                  color="primary"
                  checked={regions[region] || false}
                  onChange={() => setRegions(region)}
                  value={REGION_NAMES[region] || false}
                />
              }
              label={REGION_NAMES[region]}
            />
          )
        )}
      </FormGroup>
    </FormControl>
    </div>
    </EdmontonRegion>
    <MetroRegion>
    <StyledFormLabel>Metro Region</StyledFormLabel>
    <div style={{paddingTop:20}}>
    <FormControl>
      
      <FormGroup>
        {[ "northEastMetro", "southMetro", "westMetro"].map(
          region => {
            return (<div key={region}>
            <FormControlLabel
              control={<div>
                <Checkbox
                  color="primary"
                  checked={regions[region] || false}
                  onChange={() => setRegions(region)}
                  value={REGION_NAMES[region] }
                />
                </div>
              }
              label={REGION_NAMES[region]}
            />
            <RegionSubText onClick={() => setRegions(region)}>
              {REGION_SUBTEXT[region]}
            </RegionSubText>
                {/* <RegionAdditionInfoUl>
                <li>tester</li>
                <li>tester</li>
                <li>tester</li>
            </RegionAdditionInfoUl> */}
            </div>
        )}
        )}
     
      </FormGroup>
    </FormControl>
    </div>
    </MetroRegion>
    </FormHolder>)
  }
  const EdmontonRegion = styled.div`
  width:180px;
  padding-top: 20px;
`;
const MetroRegion = styled.div`
  flex:1;
  padding-top: 20px;
`;
const StyledFormLabel = styled.span`
color: #116C39;
font-weight: 600;
margin-bottom: 10px;

`;
const RegionSubText = styled.div`
     font-size: 11pt;
    padding: 0 30px;
    line-height: 1.7;
    color:#666;
    cursor:pointer;
`;
const FormHolder = styled.div`
  
  display:flex;
`;
export default MapCheckboxControl;