import { Box } from "@material-ui/core";
import { getTermsConditionsHelperValues } from "common/newHelperFunctions";
import React, { useState } from "react";
import { TermsConditions } from "types/Types";
import { Line, Title } from "./ListingPageStyledComponents";
import StringFieldLabel from "./StringFieldLabel";
import * as fields from "../../types/fieldDefinitions";
import NumberFieldLabel from "components/preview/NumberFieldLabel";
import BasicRentFixedRangeFieldLabel from "./BasicRentFixedRangeFieldLabel";

export function TermsConditionSection({
  tradingCategory,
  termsConditions,
  isMezzanineIncluded,
}: {
  tradingCategory: string[];
  termsConditions?: TermsConditions;
  isMezzanineIncluded?: boolean;
}) {
  const showTermsConditions: boolean =
    !!termsConditions &&
    (!!termsConditions?.termsConditions ||
      !!termsConditions?.termsRate ||
      !!termsConditions?.termsPrice ||
      !!termsConditions?.additionalRent ||
      (!!termsConditions?.termsBasicRentIsRange &&
        !!termsConditions?.termsBasicRentRangeFrom &&
        !!termsConditions?.termsBasicRentRangeTo) ||
      (!termsConditions?.termsBasicRentIsRange &&
        !!termsConditions?.termsBasicRent));
  const [showSaleControls, showLeaseControls] =
    getTermsConditionsHelperValues(tradingCategory);

  if (!showTermsConditions) {
    return null;
  }
  return (
    <>
      <Box gridColumn="col 1 / span 1">
        <Title>Terms & Conditions</Title>
      </Box>
      <Box gridColumn="col 2 / span 4">
        {showSaleControls && (
          <>
            <NumberFieldLabel
              field={fields.termsPrice}
              value={termsConditions?.termsPrice}
              isCurrency
            />
            <NumberFieldLabel
              field={fields.termsRate}
              value={termsConditions?.termsRate}
              isCurrencyRate
              minimumFractionDigits={2}
            />
            {isMezzanineIncluded && (
              <StringFieldLabel
                field={fields.termsBasicRentBasedOn}
                value={termsConditions?.termsRateBasedOn}
              />
            )}
          </>
        )}
        {showLeaseControls && (
          <>
            <BasicRentFixedRangeFieldLabel
              label="Basic Rent"
              isRange={termsConditions?.termsBasicRentIsRange}
              to={termsConditions?.termsBasicRentRangeTo}
              from={termsConditions?.termsBasicRentRangeFrom}
              value={termsConditions?.termsBasicRent}
              actualOrEstimate={termsConditions?.termsActualOrEstimated}
            />

            <NumberFieldLabel
              field={fields.additionalRent}
              value={termsConditions?.additionalRent}
              isCurrencyRate
              minimumFractionDigits={2}
              suffix={
                termsConditions?.circaYear && `(${termsConditions?.circaYear})`
              }
            />
            {isMezzanineIncluded && (
              <StringFieldLabel
                field={fields.additionalRentBasedOn}
                value={termsConditions?.additionalRentBasedOn}
              />
            )}

            {/* <StringFieldLabel
                field={fields.circaYear}
                value={termsConditions?.circaYear}
              /> */}
          </>
        )}
        <NumberFieldLabel
          field={fields.grossCurrentRent}
          value={termsConditions?.grossCurrentRent}
          isCurrencyRate
          minimumFractionDigits={2}
        />
        <StringFieldLabel
          field={fields.termsConditions}
          value={termsConditions?.termsConditions}
          hideLabel
          isMarkdown
        />
      </Box>
      <Line gridColumn="col 2 / span 4"></Line>
    </>
  );
}
