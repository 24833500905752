import { RouteComponentProps } from "@reach/router"
import React from "react"
import styled from "styled-components"
import Template from "./Template"

export default function TermsPage({}: RouteComponentProps) {
  return (
    <Template>
              <p style={{textAlign:"center"}}>
          <h1>Terms of Use</h1>
        <strong>
          IF YOU DO NOT AGREE WITH THESE TERMS OF USE,
          <br />
          YOU MUST NOT USE THIS WEBSITE.
        </strong>
      </p>
      <h2>1. General</h2>
      <OL>
        <li>
          <strong>Website</strong>. This website (“<strong>Site</strong>”) is
          owned and managed by Go Solo Land Inc. (“<strong>GSL</strong>” or “
          <strong>we</strong>”) and may be used by members of the public (“
          <strong>you</strong>”) in accordance with these Terms of Use (the “{" "}
          <strong>Agreement</strong>”), which govern your use of this Site.
        </li>

        <li>
          <strong>Modification without notice</strong>. This Agreement is
          effective <strong>April 17, 2022</strong>. We may modify this Agreement at
          anytime time without notice by updating this page.{" "}<StrongUpper>
            By using this Site, you are consenting to this Agreement as it may
            be amended at the time you access the Site
          </StrongUpper>. For greater certainty, if you use the Site after the date we say the
          changes are effective, you agree to be bound by the Agreement with all
          the changes we posted.
        </li>

        <li>
          <strong>Acceptance</strong>. We provide you access to this Site
          subject to the terms of this Agreement and&#160;
          <StrongUpper>
            your use of this Website constitutes your unconditional agreement to
            be bound by the terms of this Agreement
          </StrongUpper>
          .
        </li>
        <li>
          <strong>Capacity to contract</strong>. By using this Site, you
          represent that you are of the age of majority in your jurisdiction and
          otherwise have the capacity to enter into this Agreement.
        </li>
        <li>
          <strong>Governing law</strong>. This Agreement shall be construed
          under the laws of Alberta and you agree that it shall be exclusively
          enforced in the courts of Alberta or the Federal Court of Canada. You
          agree that you will consent to any request by GSL that a hearing or
          examination be held by teleconference or at a venue in Alberta.
        </li>
        <li>
          <strong>Data Policies</strong>. Our Privacy and Data Collection Policy
          (the “Privacy Policy”) describe our policies on the collection, use,
          and disclosure of your personal information.{" "}
          <StrongUpper>
            You represent that you accept our Privacy Policy and agree that the
            terms of our Data Policy is reasonable. You warrant that you will
            comply with our Data Policy and you consent to the use and
            disclosure of your Personal Information by GSL in accordance with
            our Privacy Policy.
          </StrongUpper>{" "}
          Please read our Privacy Policy carefully before using our Site.
        </li>
        <li>
          <strong>Entire Agreement</strong>. For greater certainty, this
          Agreement shall be construed in conjunction with the Data Policy but
          shall otherwise supersedes all prior understandings related to the
          subject matter of this Agreement.
        </li>
      </OL>
      <h2>2. Intellectual Property</h2>
      <OL>
        <li>
          <strong>Copyright, etc. in this Site</strong>. Unless otherwise noted,
          all information, including images, text, audio, video, and programs,
          that is part of this Site (collectively, the "
          <strong>Contents</strong>") is subject to intellectual property
          rights, including copyrights and trademarks, owned by or licensed to
          Us or one of our affiliates. The compilation (meaning the collection,
          arrangement, and assembly) of all the Contents on this Site is the
          exclusive property of <strong>GSL</strong>.
        </li>
        <li>
          <strong>All rights reserved</strong>. GSL and its suppliers and
          licensors expressly reserve all intellectual property rights in all
          Contents that appear on this Site. Access to this Site does not confer
          and shall not be considered as conferring upon anyone any license
          under any of our, or any third party's, intellectual property rights.
        </li>
        <li>
          <strong>Trademarks</strong>. GSL names and logos and all related
          tradenames, product names, designs, and slogans are marks that belong
          to GSL or its affiliates. All other marks are the property of their
          respective owners. No license is granted in connection with the
          trademark materials included on this Site.
        </li>
        <li>
          <strong>Licence</strong>. Except as otherwise specifically permitted,
          you may use our Site only for personal, non-commercial purposes. Under
          no circumstances may you use the Site in a manner that exceeds the
          rights granted for your use.
        </li>
        <li>
          <strong>No Assignment</strong>. You agree that you shall not assign
          your obligations under this Agreement to anyone else.
        </li>
        <li>
          <strong>No third-party rights</strong>. This Agreement does not confer
          any third-party beneficiary rights.
        </li>
      </OL>
      <h2>3. Member accounts</h2>
      <OL>
        <li>
          <strong>GeX Membership</strong>.<strong> </strong>GSL operates the
          Greater Edmonton Exchange database, which may be accessed through this
          Site through a paid membership. You may become a member of the GeX
          Database (“GeX Member”) by registering as a member on the Site and
          agreeing to the terms and conditions of such membership.
          <strong> </strong>You may only access your GeX Member account through
          the Site by use of your login name and password. We recommend that you
          choose a password that is unique and not easily guessed, that you
          change your password on a regular basis, and that you log out at the
          end of each session.
        </li>
        <li>
          <strong>Liability for misuse of account and credentials</strong>. You
          are solely responsible and liable for any use and misuse of your login
          credentials and for all activities that occur under your login name
          and password.
        </li>
        <li>
          <strong>Login credentials are confidential</strong>. You must keep
          your login credentials confidential and not disclose them to any
          person or permit any other person to use them, except an authorized
          GSL representative.
        </li>
        <li>
          <strong>Notification of compromised login credentials</strong>. You
          agree to immediately notify us of any unauthorized use of your login
          name or password, or if you know or suspect that your login name or
          password has been lost or stolen, has become known to any other
          person, or has been otherwise compromised.
        </li>
      </OL>
      <h2>4. Database Access by Paid Search</h2>
      <OL>
        <li>
          <strong>Accessing to the GeX Database</strong>. Access to the GeX
          Database on the Site is only available to GeX Members or by paying for
          an individual search of the GeX Database (“Paid Search”). Each search
          of the GeX Database will produce a search result (“Search Result”).
        </li>
        <li>
          <strong>Licence</strong>. When you pay for a Paid Search, GSL grants
          you a limited, non-exclusive, non-transferrable, limited right license
          to use, reproduce, and display each Search Result for private use and
          for the purpose of facilitating your purchase, sale, or lease of real
          estate. You may not distribute or make Search Results or other
          information from the GeX Database available to any other third party.
          Any other commercial use of Search Results is prohibited except with
          prior written permission of GSL. You may access Search Results from
          the GeX Website and you may print or otherwise save individual Search
          Results produced by the GeX Website for private use only.
        </li>
        <li>
          <strong>Database Rights. </strong>The Contents of the Site includes
          the Search Results, and access to the GeX Database through a Paid
          Search is subject to these Site Terms of Use.
        </li>
        <li>
          <strong>Results of Searches</strong>. You must not amend Search
          Results, make derivate works from the Search Results, or alter any
          proprietary notices or trademarks included on such Search Results.
        </li>
        <li>
          <strong>Protection of Information</strong>. You agree that aspects of
          Search Results, including the specific design and structure of the
          Search Results, constitute trade secrets and confidential information
          of GSL. You shall not disclose, provide, or otherwise make available
          such trade secrets or confidential information in any form to any
          third party without the prior written consent of GSL. You shall
          implement reasonable security measures to protect such trade secrets
          and confidential information.
        </li>
      </OL>
      <h2>5. Disclaimer, Limitation of Liability, etc.</h2>
      <OL>
      <li>
        <strong>General information only</strong>.<strong> </strong>The
        Contents on the Site is provided for general information only. It is not
        intended to amount to advice on which you should rely. You should obtain
        professional or specialist advice before taking, or refraining from, any
        action on the basis of the Contents or any other information available
        on or through this Site.
      </li>
      <li>
        <strong>Third-party links</strong>. References on this Site to any
        names, marks, products or services of third parties or hypertext links
        to third party sites or information are provided solely as a convenience
        and do not constitute our endorsement of the third party, information,
        product or service. We are not responsible for the content of any
        third-party sites and we do not make any representations regarding the
        content or accuracy of material on such websites. If you decide to link
        to any such third-party websites, you do so entirely at your own risk.
        Your use of such third-party websites is subject to the terms of use,
        privacy policies, and any other similar policies of such websites.
      </li>
      <li>
        <strong>Third</strong>-<strong>party information</strong>.
        Information on the site for which authorship is attributed to third
        parties, including information provided by GeX Members under Section 3
        of this Terms of Use, is provided solely for convenience and does not
        constitute our endorsement of the third party, information, product or
        service. We are not responsible for the content provided by such third
        parties and we do not make any representations regarding the content or
        accuracy of such third party material.
      </li>
      <li>
        <strong>International use</strong>. We make no promises that
        information on the Site is appropriate or available for use in locations
        outside of Alberta, Canada.
      </li>
      <li>
        <strong>No warranty</strong>.
        <StrongUpper>
          The Contents and any other information available on or through this
          Site are provided "as is," "with all faults," "as available," and
          without warranty, including all implied warranties and conditions of
          merchantability, fitness for a particular purpose, title, and
          non-infringement
        </StrongUpper>
        . We exclude any express or implied warranties, including that the Site
        or any Content is free of defects, virus free, able to operate on an
        uninterrupted basis, or will be corrected. We do not warrant that all or
        any internet browser or platform is supported by the Site. However,
        nothing in this agreement is intended to affect any applicable rights
        you may have under local law.
      </li>
      <li>
        <strong>Disclaimer</strong>. The Content and other information
        available through this site may include errors, omissions, or other
        inaccuracies.
        <StrongUpper>
          You assume the sole risk of making use or relying on the Contents and
          other information available on or through this site.
        </StrongUpper>
        We make no representations or warranties about the suitability,
        completeness, timeliness, reliability, legality, or accuracy of the
        information available through this site for any purpose.
      </li>
      <li>
        <strong>Limitation of Liability</strong>. We shall not be liable to
        you for any lost profits, lost data or other consequential, special,
        indirect, incidental damages or other compensation under any other
        theory of liability arising out your use of this Site or in connection
        with this Agreement, even if we have been advised of the possibility of
        such damages. If your jurisdiction does not allow exclusions of certain
        warranties or limitations on certain damages, these exclusions and
        limitations apply so far as consistent with the law of your
        jurisdiction. <StrongUpper>
          You agree that we are entitled to the maximum limitation permitted if
          any limitation or exclusion of damages or liability is restricted by
          law in the applicable jurisdiction
        </StrongUpper>
        . In no event shall GSL’s total liability to you exceed the total amount
        you paid to GSL in connection with the subject matter of the dispute.
      </li>
      <li>
        <strong>Sanctions compliance</strong>. You represent and warrant
        that
      </li>
      <OLLetter>
      <li>
        you are not located in a jurisdiction that is subject to sanctions
        imposed by the governments of Canada or the United States of America;
      </li>
      <li>
        you are not located in a jurisdiction designated by the United
        States government as a "terrorist supporting" country; and
      </li>
      <li>
        you are not listed on any list of prohibited or restricted parties
        issued by the governments of Canada or the United States of America.
      </li>
      </OLLetter>
      <li>
        <strong>No waiver</strong>. If we refrain from enforcing any portion
        of the Agreement, it shall not be considered a waiver.
      </li>
      </OL>
      <h2>6. Termination</h2>
      <OL>
      <li>
        <strong>Termination at will</strong>. We may terminate or restrict
        your access to any component of the Site immediately, without prior
        notice or liability, for any reason whatsoever, including if you breach
        this Agreement.
      </li>
      <li>
        <strong>Rights cease</strong>. Upon termination, your right to use
        the Service will cease immediately.
      </li>
      </OL>
      <h2>7. Site Security</h2>
      <OL>
      <li>
        <strong>No unauthorized access</strong>. You are prohibited from
        violating or attempting to violate the security of the Site, including:
      </li>
      <OLLetter>
      <li>accessing data not intended for you;</li>
      <li>
         attempting to probe, scan, or test the vulnerability of a system or
        network or to breach security or authentication measures without proper
        authorization;
      </li>
      <li>
        attempting to interfere with service to any user, host or network,
        including by means of submitting a virus to the Site, overloading,
        "flooding", "spamming", "mailbombing" or "crashing";
      </li>
      <li>
         sending unsolicited email, including promotions and/or advertising
        of products or services; or
      </li>
      <li>
         forging any header, including any email or TCP/IP packet header.
      </li>
      </OLLetter>
      <li>
         <strong>No interference</strong>. You agree not to use any device,
        software or routine to interfere or attempt to interfere with the proper
        working of this Site or any activity being conducted on this Site.
      </li>
      <li>
         <strong>No data scraping</strong>. You are not permitted to access
        or copy data from the Site other than by making use of the facilities
        that may be provided by GSL. Content must not be modified, merged with
        other information or published in any form. You agree not to use or
        attempt to use any software or other device or mechanism (including
        browsers, spiders, robots, avatars or intelligent agents) to scrape,
        navigate or search this Site other than the search engine and search
        agents available by GSL on this Site and other than generally available
        third-party web browsers (e.g., Chrome, Firefox, Edge).
      </li>
      </OL>
      <h2>8. INTERPRETATION</h2>
      <OL>
      <li>

           <strong>Syntax</strong>. In this Agreement,{" "}
       
      </li>
        <OLLetter>
      <li>
         words importing male persons include female persons, words importing
        female persons include male persons and words importing either sex
        include corporations;
      </li>
      <li>
         words in the singular include the plural, and words in the plural
        include the singular;
      </li>
      <li>
        when a word or expression is defined, other parts of speech and
        grammatical forms of the same word or expression have corresponding
        meanings;
      </li>
      <li>
        the word “including” has the same meaning as “including without
        limitation” or “including but not limited to” and shall not be construed
        to limit any general statement that it follows to the specific or
        similar items or matters immediately following it; and
      </li>
      <li>
         any reference to legislation will be deemed to include any
        subordinate legislation made pursuant to it and as may be amended from
        time to time or replaced by superseding legislation.
      </li>
      </OLLetter>
      <li>
         <strong>Sections and Subsections</strong>. This Agreement is divided
        into Sections and Subsections.
      </li>
      <li>
        Headings. The headings contained in the Agreement are for
        convenience of reference only and shall not limit or otherwise affect in
        any way the meaning or interpretation of the Agreement.
      </li>
      <li>
        <strong>English Language</strong>. You agree that this Agreement and
        all related documents be drawn up in English.{" "}
        <em>Vous acceptez que le présent contrat ( « Agreement</em>
        <em>
          » ) et les documents qui s’y rattachent soient redigés exclusivement
          en anglais
        </em>
        .
      </li>
      <li>
         <strong>Persons under control of a party</strong>. Any reference to
        a party includes the officers, directors, agents, servants, employees,
        representatives, successors, assigns, subcontractors, consultant and
        professional advisers, or related or affiliate entities of that party
        and all those under their control.
      </li>
      <li>
        <strong>Currency</strong>. Unless otherwise stated, all prices in
        are Canadian Dollars (CAD).
      </li>
      <li>
         <strong>Severable</strong>. If a court of competent jurisdiction
        should find any provision of the Agreement to be invalid or
        unenforceable, such finding shall not affect or impair the validity and
        enforceability of the remaining provisions.
      </li>
      <li>
        <strong>
          <em>Contra proferentem</em>
        </strong>
        . Any rule of construction to the effect that ambiguities are to be
        resolved against the drafting party shall not apply in interpreting the
        Agreement.
      </li>
      <li>Definitions. For the purpose of these Terms:</li>
        <OLLetter>
      <li>
         “<strong>affiliate</strong>” means an entity that controls, is
        controlled by or is under common control with a party, where "control"
        means ownership of 50% or more of the shares, equity interest or other
        securities entitled to vote for election of directors or other managing
        authority.
      </li>
      <li>
         “<strong>device</strong>” means any device that can access the Site
        including a desktop computer, a laptop computer, a mobile phone, or a
        digital tablet
      </li>
      <li>
         “GeX Database” or “Greater Edmonton Exchange Database” means any
        information entered into or produced from GSL’s database whether from
        the Data, data from GSL’s licensors, or other information collected by
        GSL by any means.
      </li>
      <li>
         “<strong>third-party social media service</strong>” means any
        services or content provided by a third-party that may be displayed or
        otherwise made available by the Site.
      </li>
      <li>
         “We”, “Us”, or “Our” means <strong>GSL</strong>.
      </li>
      <li>
         “Site” or “Website” means the publicly available webpages accessible
        through the domain <a href="https://gosolox.com">gosolox.com</a>.
      </li>
      <li>
         “<strong>You”</strong> means the individual accessing or using the
        Website or the legal entity on behalf of which such individual is
        accessing or using the Website.
      </li>
        </OLLetter>
      </OL>
      
    </Template>
  )
}

const OL = styled.ol`
  counter-reset: list;
  margin: 0;

  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: list;
    content: "(" counter(list, numbers) ")";
    position: absolute;
    left: -2em;
  }
`
const OLLetter = styled.ol`
  counter-reset: letters;
  margin: 0;
  & > li {
    list-style: none;
    position: relative;
    line-height: 28px;
  }
  & > li:before {
    counter-increment: letters;
    content: "(" counter(letters, lower-alpha ) ")";
    position: absolute;
    left: -2em;
  }
`
const StrongUpper = styled.strong`
font-variant: small-caps;
`