import { firestore } from "firebase";
import { useCallback, useEffect, useState } from "react";
import { Themes } from "themes";
import { Role } from "types/AdminTypes";
import { LISTING_TYPE } from "types/Types";
import firebase from "../config/firebase";
interface ReturnProps {
  docStatus: StateType;
}

export type InviteDoc = {
  id: string;
  roles: Role[],
  marketSegments: LISTING_TYPE[],
  assistantTo?: string | null,
  orgId: keyof Themes,
  consumed?:firestore.Timestamp,
  email:string,
};

type StateType = {
  inviteDoc?: InviteDoc;
  queryStatus: 'pending' | 'notFound' | 'found';
};


export default function useInvite(inviteId?: string): ReturnProps {
  
  const db = firebase.firestore();
  const [state, setState] = useState<StateType>({ queryStatus: 'pending' });
  const getInviteCollection = useCallback(() => db.collection("invites"), [db]);

  useEffect(() => {
    const unsubscribe = getInviteCollection().onSnapshot((querySnapshot) => {
      const inviteDoc = querySnapshot.docs
        .map(
          (doc) =>
            ({
              ...doc.data(),
            } as InviteDoc)
        )
        .find((d) => d.id === inviteId);
      setState({ inviteDoc, queryStatus: !!inviteDoc? "found" : "notFound" });
    });

    return () => unsubscribe();
  }, [getInviteCollection, inviteId]);

  const rtn: ReturnProps = {
    docStatus: state,
  };
  return rtn;
}
