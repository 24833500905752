import * as firebase from 'firebase/app';

import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore


// const firebaseConfig = {
//   apiKey: "AIzaSyBfYvjp547tdvQfqfBivpy5ZQTVRlEbggQ",
//   authDomain: "go-solo-spark.firebaseapp.com",
//   databaseURL: "https://go-solo-spark.firebaseio.com",
//   projectId: "go-solo-spark",
//   storageBucket: "go-solo-spark.appspot.com",
//   messagingSenderId: "998410474154",
//   appId: "1:998410474154:web:5505d95c0a3e54df"
// };

const firebaseConfig = process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ?
  {
  apiKey: "AIzaSyBfYvjp547tdvQfqfBivpy5ZQTVRlEbggQ",
  authDomain: "go-solo-spark.firebaseapp.com",
  databaseURL: "https://go-solo-spark.firebaseio.com",
  projectId: "go-solo-spark",
  storageBucket: "go-solo-spark.appspot.com",
  messagingSenderId: "998410474154",
  appId: "1:998410474154:web:5505d95c0a3e54df"
} :

process.env.REACT_APP_ENVIRONMENT === "STAGING" ? 
 {
  apiKey: "AIzaSyA49H7mhMJFbFkwtHsMedt5TxtNQmBK2Ak",
  authDomain: "go-solo-spark-2.firebaseapp.com",
  databaseURL: "https://go-solo-spark-2.firebaseio.com",
  projectId: "go-solo-spark-2",
  storageBucket: "go-solo-spark-2.appspot.com",
  messagingSenderId: "161297365017",
  appId: "1:161297365017:web:ee2ce84d7ee6d179ea5288"
} :
 {
  apiKey: "AIzaSyCjF-hxXcX2KttZFmbMx8K740TlCcL6mMc",
  authDomain: "gex-app-prod.firebaseapp.com",
  projectId: "gex-app-prod",
  storageBucket: "gex-app-prod.appspot.com",
  messagingSenderId: "906921977558",
  appId: "1:906921977558:web:00e38d2bc1c21b81f60b77",
  measurementId: "G-S6G0NEBELQ"
};


  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const settings = {firebaseConfig}

  export default firebase;