import Grid from "@material-ui/core/Grid";
import TextFieldMUI from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { CellColumnNumber, TextField } from "types/fieldComponentTypes";


type Props = {
  field: TextField,
  value:string,
  xs: CellColumnNumber,
  sm: CellColumnNumber,
  md: CellColumnNumber,
  disabled: boolean,
  onBlur: (value:string | number | null) => void,
  error: string | null

}


const TextFieldControl = ({ field, value, xs, sm, md, onBlur, disabled, error } : Props) => {
  const [state, setState] = useState(value || "");
  useEffect(() => {
    setState(value || "");
  }, [value]);

  function handleBlur(v: string){
    if(field.inputType === 'number'){
      if(isNaN(v as any)){
        onBlur(null);
        return;
      }
      onBlur(Number(v))
      return;
    }
    onBlur(v);
  }

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <TextFieldMUI
        disabled={disabled}
        label={`${field.formLabelOverride || field.label}`}
        variant="filled"
        fullWidth
        value={state}
        error={!!error}
        onChange={e => setState(e.target.value)}
        onBlur={e => handleBlur(e.target.value)}
        multiline={field.multiline}
        type={field.inputType || 'text'}
        helperText={error || field.helperText || (field.isRequired ? 'Required' : null)}
      />
    </Grid>
  );
};



export default TextFieldControl;
