import React from 'react';
import { Paper} from "@material-ui/core";
import MainHeader from 'pages/components/MainHeader';
import styled from "styled-components";
type TemplateProps =  {
    children?: React.ReactNode;
}

const Template = ({children} : TemplateProps ) => {

    return (
        <Page>
      <FormContainerOuter>
        <Spacer />
        <Paper>
          <MainHeader noExtra />

          <FormContainer>
            {children}
            </FormContainer>
        </Paper>
      </FormContainerOuter>
    </Page>
    );
};
const FormContainerOuter = styled.div`
  margin: 30px auto;
  max-width: 800px;
  padding: 5px;
`;
const FormContainer = styled.div`

  margin: 0px auto;
  padding: 30px 30px 40px 30px;
  min-height: 200px;

  & > h2 {
    font-size: 24px;
    font-family: "Nunito Sans", sans-serif;
  }
`;
const Spacer = styled.div`
  height: 20px;
`;
const Page = styled.div`
min-height: 100vh;
background-color: #f6f7f9;
padding: 30px 16px 200px 16px;
`;
export default Template;