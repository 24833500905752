import { blueGrey } from "@material-ui/core/colors";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
const shell = {
  typography: {
    button: {
      textTransform: "none",
    },
  },
  zIndex: {
    drawer:1050
  }

};



const goSolo: Theme = {
  ...shell,
  palette: {
    primary: green,
  },
  company: {
    id: "goSolo",
    name: "Go Solo Land",
    folder: "themes/go-solo",
    organizationType: "contributors",
    headerLogo: {
      height: 59,
      width: 78,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAApCAYAAAArpDnNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA+BJREFUeNq8V0tME1EUvS0UaGm1LcgnFTAFohWBxoRUioGCGKO4qOyMGNC4MkJg48bfwrjWoIm4l8jGUBI/S4aFhGIwpBgwaUF+ldSEAvKpFEt979HXzpTp8Jtyk5d573XmnnfuPffOFOCQTCL0YzAYNKKLBY08NIyhbYvAI4toDNO5RCK5JuT8BBrP0VgIHtBiMsIA6NIaDe5Z+QFe3zT4A2vhvS/Ob+Sam5YNyclp8HvVT9apMiWU55RDpjITL6sRKwZPElkgvTQsS74V+Ozog3f2j8Ths5uFIE+ScsCVqq2r1z8FPz0+sM/+Cf824nHAI8tjCIU7AoRAmihIF3L+4P0LBLYcfvCrcwkqizQxw52RmsRZTyyM02kpndBjNlKQe2+fckAokJClJiWQEQm1B1b9q8ASEHDiQeMebe75dTKETJOSyMfKiKKlJkDNLS3q/v5+gny5pCqmo51YqeUyztqB8hQyI82RdWhoSG02m+FKSSXkarNh2ju3zdH3qRWwnssAn38Tfs3/Bbd3ncyJIJY3wKhXQY0+kkeFzAOsumMIX6fLBT6fD+RyOWH1hunigKSnJ4FOlwKvemdgYmKNl5HPH4Dbtbrwev2fm06raI5sFAzbdVNd5FSKBCgtPQJ6vQLVihQ0GlnM0I3PcQ/gD8yzGYH0ZXs7aRsjjq2YnjleSMKHWRgMSgJALSFBQvb5GW2Cd2WDszezNEpr1EK99FBG2Kxl1ZCXJyeOt6lrD6xwN6GsqCZtXq/3idvtRrnQQUPFVRj48IlfxggIHyAQ2GplFYVnSQRyUCsqyFKAQZcOWnlu9GNV4SMjmS/U19erLVVbEr/V3UgKb5uMZcfglLIMLpw8T0B2a+wqs6E8NVGg8hwz2Ma6OQA3Shqg7vSlXTlGESIDW0FBAcMG6kN5aqKL2vyLYSBzdg00V9yFo3Ilx5kL5RXn1j07S8qDnecoa2MDkS7rGBmBkuJi0Gv0pNXnq4rgYe39SDtCeWQYBuyDg3t5wVrDnXDQbl80mUzWIypVlsFgIHuyoALumBrDd2Pnrzs6CJiA4QNPRo3FxOibECMjEgVZROejs7Mz2mFf6LqI6nF4t2Ig9YQS2IqTqNVqY30TtGHhhAp9/4Zl3sswvN8AuNPv16+UL8Yup5P35oOw4APqwcoT26QxVANig0l5woPlOBwrfGIyojI/FKAedq+KGxAKHylCMVlJhVqJmHkSAuoT6MaiAtlw63eJBBYTKCTzSYfDEXdGhJUz3oxonvC7R0yZC3Xz4IDdvu0fnNiM6EdL3EMnmsx3xUgMme8IRGU+OjZ2oFf3fwEGAEVq/7vXEYuOAAAAAElFTkSuQmCC",
  },
};
const loren: Theme = {
  ...shell,
  palette: {
    primary: orange,
  },
  company: {
    id: "loren",
    name: "Sample Company",
    folder: "themes/loren-ipsum",
    organizationType: "contributors",
    headerLogo: {
      height: 59,
      width: 227,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAApCAYAAAArpDnNAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAx5JREFUeNq8Vs9rE0EUfrP5UYuoUTAKVSgiEittU4TaiyQi2ItgqYKHXuofYOPF9iQePHoQ68mL1UNvCunRU7e31EvWVr00YEQLKnSbKjaQJru+t9nZbJLd2Tbd7YPJ/NjJfPN975vZZeARuq73YkUlbQ4dw5J0mFrE8s3WlxljstfiY1jmsGzqnceifU3WCoDVM5NBW2jfc44bY9Ej9Ub0MLDjjb8iI9YGRAywmrT6pR9Q/fgOtGIOtK1PAJU/rgqE4gP4E230L9+DUOImNYcQS6FG2AR5wEG0n19g5/0TqBVzth3rABGB1DvbwGxA+maRNymXDSCMDP1UlbdQWXjosBADFtHdgSp/gR2K2YC+8uYgb0hmbQhbXZ5zWYmoCtyDjJqmNxilLaD7U1O96+vr9U7viPuuq0zIqG3s12ewHwNiNLayump0woO33XctAHIC00z5MP9pDhQrrK3VUU/3AYudcZevJgBrAWqVj4CUtUIByuVy3ZqJGwL5xM5rin+/eSvFgYxrgsA85avtPk9aPUcNRi9mZ0vEanVlZXfyueVKq7Wx4vJRnri9FzijTuRjXUdBOjsCUjwB0qlLwE6cA3YyYZ+S5gc2q6rqY7J5T0+PIV8198pVPil+EULnR43FWbzPAPKIlKUDnqfN8fHxWDpl5A7Kz68a9509wsk7EEll3KUVRNjWzmKeJjkQycdZUd667r50BCig5BuqCiqVjQ2jtsc2unlmevqaHWgJ8zRJNu/u7rbkIxbRW0+tSfScDviH5WWw51UUU5lMqYkRljn680B/v8EidOE6REYfWRMIYH5+3jpze4ixJq9invJXhoeTExMTbjtrHZLN18AWP48YJTwyiihH3OZJj929pnm4WLZTM7TZ3CGGnHbbUZDNF2XZ82NjryE5jGX5be5nOAEt8fdT0EAyt3KgQJhsunIVv+WTXMblwBnx86Sa91egQCgf5ankJytJ8Ez2M08ioIUDY+SnzV2B/La55PFcDpyR3zYXAnGb7/aVvR9Ghnz84zJooKWDYpSlj5G8ogQLZNq8qOTzb/YD9F+AAQB6V8a8c+00bwAAAABJRU5ErkJggg==",
  },
};
const gex: Theme = {
  ...shell,
  palette: {
    primary: blue,
  },
  company: {
    id: "gex",
    name: "Greater Edmonton Exchange",
    folder: "themes/gex",
    organizationType: "prospects",
    headerLogo: {
      height: 58,
      width: 238,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAArCAYAAACNWyPFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA1VJREFUeNq8WE1ME0EUfru0hRaEphojgWjRqCQK1oshRsOSeBMjctSLBy6aSHr16MmjBg8eOKAGE0+kTbh3C4lJOTU0BrCNENNGDtqfpWWB/jkz7dTZtrMU2PUlLzs/m/fN9703M5sVgGPlctmDHhLyC8g91WEJ+JZGHqZtQRAe8QK7kc8hT5VPaGxcgQF4gx5enZXCt6QKmYOiZkwUBRDbBBjs7oBuq8hOjSE2Mm5YqgCBeiki2ylYXE/A1+0d+F5wgFIXnJrNboX2Tlut3+ewQuDeRahKXAFBAF4W4HN4E17LEfiZzlWoiiI4+i9z2RXyRWhn+ondPHEEdoOOYSYPaef+JxmWf/zS5qlUgqKahTZ7V1OQUqHUMLam7GMQT01S2lhcjzcA1Fa7m9VLFRTzWinXMvtELqSSk4BkFGULN+66z/IlyWUIIz6Idm7lzy5tEjbi/Pz8BG70dNhgfLCfH0jd0c2LlskesPtK3NjYcKqqWsmJDoieZPV5URAzpcJulOZkKxqLkcnxwT4dJlkoF/K8TdwAtK7s/WOC3BeLRqEVyQpqtmXJQr9VugAJgwRXI5HapK5kqAC4khVL3LzgfSInk0nA7nK5iGTPeIEO9olkgsXaMHfrtAPsp9o1O79qo+TsejE9HZicnJSkUZInePxlmeybpsdIzxl4MHwJbp/rgmso8HWXHbptbbr7yFJ9+lFeaiBYsnqQ885OeD5yFZ54Bkju9CyRSACt2N7e3jAFkdm81Ev2UhpCAFc0wWOoInFVJuJxEpBWaBP7WDvqkWSbU1NT7uGhIY1k7ydGyOpp4FAoBKGVFTiC+SxMR0aSPaUgWLI76KihAHIwCAsLC3AMk9hbxs9KhoPjHFDjAOD74i3yV8jxdTvG+M13MzNYqQENE7aUde7xD1hnFCDcCg30XlozgEs5IMtN72w0N4fcCSc1FMQ7Ozt76IfBUU2s15jNi2mGSzkajZrKpMJmddXQhTcDCersXsNAfPjswaVsGki1rsNGshE54/6IgXnhgfiMzguvlFNsKZvBhLAxqpT1QAwrZV0mRpWyeMgRbUgpi4fM++mHn5kgvv91Kqfi8XjZTCaG3DGWFt7xLy0tuU1nksvl0qaCoFLeYv40HMv+CjAAQj0V7s3Tg7oAAAAASUVORK5CYII=",
  },
};
const test: Theme = {
  ...shell,
  palette: {
    primary: blue,
  },
  company: {
    id: "test",
    name: "Text Site",
    folder: "themes/test",
    organizationType: "sponsors",
    headerLogo: {
      height: 58,
      width: 70,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAsCAYAAAB7aah+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPeSURBVHgBtVdLTxNRFD4zLQIGSdGYoJDYIITEDWwIaEysC1m5MS5cwg+wpAsjCxfGH2BsCCEkLGDrAsGVEBdWV7CigBATahiSVknoSx59ZTrXc25npjPtDK92vuTmPnpzvnvO+e65UwHOCcaYBzuv2jyGnyS1TwuCEIaLggxjC2BbwJZi58catm/YfGcR+NSNtWLWaNddQfIBu0Al+WEe4CAD8CsB0OgCyBRKa1dcDHpuCHyPpwmgCa21qT3CZ7QhGEjoBKPafCUKsPwb4M8Rg2SGgR2810XNsI43D3WyNsxbWvdI9WSUxtsHAO9+lIgIHS3YrtnywEmBoVHBtJbKAdxqKZ0DGxeIqCaNhyt6CPBivkxC2M8IcBqKxeq1v8f6sF8biGCQKhFQ7E2GFFwrgC0O89Vh3bcimpmbk7LZLJ8M37U2tn9i75WMB1EU85qU1od9OtFmOLwQjcX4pLURYKiz2thRQeCe2SEnm72i0OVkPjSETlGkzY0NfdNQR7UhIjnI2nt1nK9e247zji79HU7EBOH7xuamvuF+p7UxUpIdKj0iGPL0mBOhR6FkMgnUCBQ6CmElKHx2osjL1Xla29eHPHzi5ORkCPv0hiF8w13WBlM56/AVWbVXlCNV5o9KHiFwy+fzhC+etRbF1QaABixNXk+5teOF3S2pr58KNK8MAmOhWCw2ontEMv9qcXKl5NW9mwwGOgToxTrX114iOgP9nMjlci3iXZrdiUSgp7tbl7mxQhBIka8eCDBw2xxCuodanrU7mUgkeN/V1bXY29ub5kTBYDDt9/tDKHMfEWlGNSIinnlavmNkbHV1FehgEWyacStgWvYmJybCet0lmePF9WlzylNwFaCzFeDj81JPCIVC8GVp6VTjRoiCwMVQLvAoczzdWzLQ3Nysy/z9kzIJefFpYaF8WswtCQlzHJZlWZqenpa03wKBgKdQKHhFUfRWsfvHxlIrKyv6E7kcMT+Z8/PzDPewl2NjQTIEF4D5hcXToVcjg4ODfF5ZZJFLKsrys6mpqYt/hJg88vtHX4+Pn/Yd4INLQjROVJlzNdUbJiKSOYYvHItG7fbvQj2IVJiqeQX+Qd2IFGXR7hJqXzR1IXK73aSotPbqGnBpEksiyhPqOGx8dR0hIigVz4YKCepNxIpF06tbD1gSqTff9OpCDYqzJSKo5ci4lAIniOjVragQe+AEUb3LkS2RKvNQZGdHW5LACSICvrrrBo/qf490mMuRc0Tax6XqlYMeEbAcqXlyRt4aqBz93NqSsHI7I28NDSjzeDy+DjXiTCKUucTUP7yOEhHcohiEGvEfNk2UkHQs+VwAAAAASUVORK5CYII=",
  },
};
const qualicoproperties: Theme = {
  ...shell,
  palette: {
    primary: blue,
  },
  company: {
    id: "qualicoproperties",
    name: "Qualico Properties",
    folder: "themes/qualicoproperties",
    organizationType: "sponsors",
    markerIsFile: true,
    headerLogo: {
      height: 53,
      width: 159,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAsCAYAAAB7aah+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPeSURBVHgBtVdLTxNRFD4zLQIGSdGYoJDYIITEDWwIaEysC1m5MS5cwg+wpAsjCxfGH2BsCCEkLGDrAsGVEBdWV7CigBATahiSVknoSx59ZTrXc25npjPtDK92vuTmPnpzvnvO+e65UwHOCcaYBzuv2jyGnyS1TwuCEIaLggxjC2BbwJZi58catm/YfGcR+NSNtWLWaNddQfIBu0Al+WEe4CAD8CsB0OgCyBRKa1dcDHpuCHyPpwmgCa21qT3CZ7QhGEjoBKPafCUKsPwb4M8Rg2SGgR2810XNsI43D3WyNsxbWvdI9WSUxtsHAO9+lIgIHS3YrtnywEmBoVHBtJbKAdxqKZ0DGxeIqCaNhyt6CPBivkxC2M8IcBqKxeq1v8f6sF8biGCQKhFQ7E2GFFwrgC0O89Vh3bcimpmbk7LZLJ8M37U2tn9i75WMB1EU85qU1od9OtFmOLwQjcX4pLURYKiz2thRQeCe2SEnm72i0OVkPjSETlGkzY0NfdNQR7UhIjnI2nt1nK9e247zji79HU7EBOH7xuamvuF+p7UxUpIdKj0iGPL0mBOhR6FkMgnUCBQ6CmElKHx2osjL1Xla29eHPHzi5ORkCPv0hiF8w13WBlM56/AVWbVXlCNV5o9KHiFwy+fzhC+etRbF1QaABixNXk+5teOF3S2pr58KNK8MAmOhWCw2ontEMv9qcXKl5NW9mwwGOgToxTrX114iOgP9nMjlci3iXZrdiUSgp7tbl7mxQhBIka8eCDBw2xxCuodanrU7mUgkeN/V1bXY29ub5kTBYDDt9/tDKHMfEWlGNSIinnlavmNkbHV1FehgEWyacStgWvYmJybCet0lmePF9WlzylNwFaCzFeDj81JPCIVC8GVp6VTjRoiCwMVQLvAoczzdWzLQ3Nysy/z9kzIJefFpYaF8WswtCQlzHJZlWZqenpa03wKBgKdQKHhFUfRWsfvHxlIrKyv6E7kcMT+Z8/PzDPewl2NjQTIEF4D5hcXToVcjg4ODfF5ZZJFLKsrys6mpqYt/hJg88vtHX4+Pn/Yd4INLQjROVJlzNdUbJiKSOYYvHItG7fbvQj2IVJiqeQX+Qd2IFGXR7hJqXzR1IXK73aSotPbqGnBpEksiyhPqOGx8dR0hIigVz4YKCepNxIpF06tbD1gSqTff9OpCDYqzJSKo5ci4lAIniOjVragQe+AEUb3LkS2RKvNQZGdHW5LACSICvrrrBo/qf490mMuRc0Tax6XqlYMeEbAcqXlyRt4aqBz93NqSsHI7I28NDSjzeDy+DjXiTCKUucTUP7yOEhHcohiEGvEfNk2UkHQs+VwAAAAASUVORK5CYII=",
  },
};
const rcedm: Theme = {
  ...shell,
  palette: {
    primary: blue,
  },
  company: {
    id: "rcedm",
    name: "RE/MAX Commercial Capital",
    folder: "themes/rcedm",
    organizationType: "sponsors",
    markerIsFile: true,
    headerLogo: {
      height: 55,
      width: 110,
    },
    marker:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAsCAYAAAB7aah+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPeSURBVHgBtVdLTxNRFD4zLQIGSdGYoJDYIITEDWwIaEysC1m5MS5cwg+wpAsjCxfGH2BsCCEkLGDrAsGVEBdWV7CigBATahiSVknoSx59ZTrXc25npjPtDK92vuTmPnpzvnvO+e65UwHOCcaYBzuv2jyGnyS1TwuCEIaLggxjC2BbwJZi58catm/YfGcR+NSNtWLWaNddQfIBu0Al+WEe4CAD8CsB0OgCyBRKa1dcDHpuCHyPpwmgCa21qT3CZ7QhGEjoBKPafCUKsPwb4M8Rg2SGgR2810XNsI43D3WyNsxbWvdI9WSUxtsHAO9+lIgIHS3YrtnywEmBoVHBtJbKAdxqKZ0DGxeIqCaNhyt6CPBivkxC2M8IcBqKxeq1v8f6sF8biGCQKhFQ7E2GFFwrgC0O89Vh3bcimpmbk7LZLJ8M37U2tn9i75WMB1EU85qU1od9OtFmOLwQjcX4pLURYKiz2thRQeCe2SEnm72i0OVkPjSETlGkzY0NfdNQR7UhIjnI2nt1nK9e247zji79HU7EBOH7xuamvuF+p7UxUpIdKj0iGPL0mBOhR6FkMgnUCBQ6CmElKHx2osjL1Xla29eHPHzi5ORkCPv0hiF8w13WBlM56/AVWbVXlCNV5o9KHiFwy+fzhC+etRbF1QaABixNXk+5teOF3S2pr58KNK8MAmOhWCw2ontEMv9qcXKl5NW9mwwGOgToxTrX114iOgP9nMjlci3iXZrdiUSgp7tbl7mxQhBIka8eCDBw2xxCuodanrU7mUgkeN/V1bXY29ub5kTBYDDt9/tDKHMfEWlGNSIinnlavmNkbHV1FehgEWyacStgWvYmJybCet0lmePF9WlzylNwFaCzFeDj81JPCIVC8GVp6VTjRoiCwMVQLvAoczzdWzLQ3Nysy/z9kzIJefFpYaF8WswtCQlzHJZlWZqenpa03wKBgKdQKHhFUfRWsfvHxlIrKyv6E7kcMT+Z8/PzDPewl2NjQTIEF4D5hcXToVcjg4ODfF5ZZJFLKsrys6mpqYt/hJg88vtHX4+Pn/Yd4INLQjROVJlzNdUbJiKSOYYvHItG7fbvQj2IVJiqeQX+Qd2IFGXR7hJqXzR1IXK73aSotPbqGnBpEksiyhPqOGx8dR0hIigVz4YKCepNxIpF06tbD1gSqTff9OpCDYqzJSKo5ci4lAIniOjVragQe+AEUb3LkS2RKvNQZGdHW5LACSICvrrrBo/qf490mMuRc0Tax6XqlYMeEbAcqXlyRt4aqBz93NqSsHI7I28NDSjzeDy+DjXiTCKUucTUP7yOEhHcohiEGvEfNk2UkHQs+VwAAAAASUVORK5CYII=",
  },
};
const themes: Themes = { goSolo, loren, gex, test, qualicoproperties, rcedm };

export default themes;

export interface Theme {
  palette: Palette;
  company: Company;
}
interface Palette {
  primary: import("@material-ui/core").Color;
}
interface Company {
  id: string;
  name: string;
  folder: string;
  headerLogo: Dimensions;
  marker: string;
  organizationType: "contributors" | "prospects" | "sponsors";
  markerIsFile?: boolean;
}
interface Dimensions {
  height: number;
  width: number;
}
export interface Themes {
  goSolo: Theme;
  loren: Theme;
  gex:Theme;
  test:Theme;
  qualicoproperties:Theme;
  rcedm:Theme;
}

// export function isFilterKey(key: string): key is (themeType in themes)  {
//   return FILTER_KEYS.includes(key as FilterKey);
// }


export function getTheme(orgId: string)
{
  return themes[orgId as keyof Themes];
  
}