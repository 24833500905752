import { VersionDoc, Lookups } from "types/Types";
import { JurisdictionLookupsAdminDoc } from "./AdminTypes";

export enum FIELD_TYPE {
  BOOL = "bool",
  BROKERAGE_CONTACT = 'BROKERAGE_CONTACT', 
  CONTACT_INFO_ARRAY = "CONTACT_INFO_ARRAY",
  ANCILLARY_OFFER = "ANCILLARY_OFFER",
  CRANE = "CRANE",
  EDITABLE_PICKLIST = 'editable_pick_list',
  FILE = "file",
  FILE_OR_LINK = "fileOrLink",
  IMAGE = "image",
  INTEGER_PICKER = "integerPicker",
  FIELD_COLLECTION = "field_collection",
  MAP_INFO = "mapInfo",
  MULTIPICK = "multipick",
  NUMERIC_PICKLIST = "numericPicklist",
  PICKLIST = "picklist",
  SIZES = "sizes",
  PIECES = "pieces",
  SPACE_SETS="space_sets",
  LOT_SETS="lot_sets",
  TEXT = "text",
  TRAFFIC_FLOW_INFO = "TRAFFIC_FLOW_INFO",
  
  VIDEO = "video",
}
export enum INPUT_TYPE {
  NUMBER = "number",
  
}
type GetOptionsParams = 
{versionDoc: VersionDoc, lookups:Lookups, jurisdictionLookups:JurisdictionLookupsAdminDoc}

export type Field = {
  id: string;
  label: string;
  formLabelOverride?:string;
  type: FIELD_TYPE;
  jsonPath: string;
  sm?: number;
  md?: number;
  lg?: number;
  multiline?: boolean;
  isRequired?:boolean;
  helperText?:string;
};
export type PickListField = Field & {
  getOptions?(props:GetOptionsParams): IdName[];
  options?: IdName[];
  radio?: boolean;
  defaultValue?:string;
  useIdInsteadOfValueForValue?:boolean;
};
export type NumericPickListField = Field & {
  getOptions?(props:GetOptionsParams): number[];
  options?: number[];
  defaultValue?:number;
};
export type SpacesField = Field;
export type EditablePickListField = Field & {
  getOptions?(props:GetOptionsParams): IdName[];
  options?: IdName[];
  radio?: boolean;
  defaultValue?:string;
};

export type BrokerageContactField = Field & {
  jsonPathBrokerageContactNumber:string;
};

export type BoolField = Field & {
  defaultValue?:boolean;
  trueLabel?: string;
  radio?: boolean;
  falseLabel?: string;
};
export type ContactInfoField = Field;
export type CraneField = Field & {
  craneNumber: 1|2|3;
};
export type FileField = Field;
export type SizesField = Field & {hideToBeDetermined?:boolean};
export type SpaceSetsField = Field & {spaceType: "SET" | "SPACE"};
export type GoogleMapField = Field;
export type ImageField = Field;
export type IntegerPickerField = Field & {
  min: number;
  max: number;
}
export type MapField = Field & {
  fields: (TextField | BoolField | PickListField)[];
};
export type MultiPickField<T = string> = Field & {
  getOptions?(props:GetOptionsParams): T[];
  getOptionLabel?(option: T): string;
  options?: T[];
};
export type LookupMultiPickField = Field & {
  getOptions?(props:GetOptionsParams): string[];
  getOptionLabel?(option: string): string;
  isLookup:true;
}
export type TextField = Field & {
  inputType?: INPUT_TYPE;
};
export type TrafficFlowInfoField = Field;
export type VideoField = Field;
export interface IdName {
  id: string;
  name: string;
}
export type CellColumnNumber = boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;