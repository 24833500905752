import UnitLabel from "pages/components/controls/UnitLabel";
import React from "react";
import { TextField } from "types/fieldComponentTypes";
import { Light, Strong } from "./ListingPageStyledComponents";

type Props = {
  value: number | undefined;
  field: TextField;
  isBuilding?: boolean;
  isHeight?:boolean;
  isLand?:boolean;
  isPercent?:boolean;
  isCurrency?:boolean;
  isCurrencyRate?:boolean;
  minimumFractionDigits?:number;
  suffix?:string;
  
};

export default function FieldLabel({ field, value, isLand, isBuilding, isHeight, isPercent, isCurrency, isCurrencyRate, minimumFractionDigits, suffix }: Props) {
  if (!value) {
    return null;
  }

  return (
    <p>
      <Light>{field.label} </Light>
      <Strong>
        {(isCurrency || isCurrencyRate) && <>$</> }{minimumFractionDigits ? value.toLocaleString(undefined, {minimumFractionDigits}) : value.toLocaleString()}<UnitLabel isLand={isLand} isCurrencyRate={isCurrencyRate} isBuilding={isBuilding} isHeight={isHeight} isPercent={isPercent} /> {!!suffix && <>{suffix}</>} 
      </Strong>
    </p>
  );
}
