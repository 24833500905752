import { AppBar, Toolbar, useScrollTrigger } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Themes } from "themes";
import LogoControl from "./controls/LogoControl";

type HeaderBuilderProps = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
  /** The Listing page can override the logo to who the listing owner is. */
};
export default function HeaderBuilder(props: {
  children: React.ReactElement;
  listOwnerOrgId?: keyof Themes;
  noLogo?:boolean;
}) {
  const {children, listOwnerOrgId,  noLogo} = props;
  return (
    <ElevationScroll {...props}>
      <StyledAppBar>
        <Toolbar>
          {!noLogo && (
            <LogoControl listOwnerOrgId={listOwnerOrgId} />
          )}
          {children}
        </Toolbar>
      </StyledAppBar>
    </ElevationScroll>
  );
}

function ElevationScroll(props: HeaderBuilderProps) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const StyledAppBar = styled(AppBar)`
  background-color: #fff !important;
  border-bottom: 1px solid #ebebeb;
  //z-index: 1201 !important;
`;
