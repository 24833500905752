import { useEffect, useState } from "react";
import { Role, User } from "types/AdminTypes";
import firebase from "../config/firebase";
import { InviteDoc } from "./useInvite";

export default function useUser(orgId: string) {
  const [state, setState] = useState<{
    user: User | null;
    userDataNeedsUpdating?: boolean;
  }>({ user: null });

  const { user, userDataNeedsUpdating } = state;

  useEffect(() => {
    const db = firebase.firestore();
    const unsubscribes: Array<() => void> = [];
    firebase.auth().onAuthStateChanged((fbUser) => {
      if (hasUserChanged(fbUser, state.user)) {
        if (!!fbUser) {
          db.collection("users")
            .doc(fbUser.uid)
            .get()
            .then((doc) => {
              const userDoc = doc.data();

              if (!!userDoc) {
                console.log("INSIDE IF", userDoc);
                const udnu =
                  fbUser.email !== userDoc.email ||
                  fbUser.displayName !== userDoc.displayName;
                const verified = userDoc.verified;
                
                setState({
                  user: getUserObject(
                    fbUser,
                    userDoc.orgId,
                    userDoc.marketSegments,
                    !!userDoc.isAdmin,
                    userDoc.roles,
                    verified
                  ),
                  userDataNeedsUpdating: udnu,
                });
              } else {
                setState({ user: getNonRegisteredUserObject(fbUser) });
              }
            });
        } else {
          setState({ user: null });
        }
      }
    });

    return () => unsubscribes.forEach((s) => s());
  }, [orgId, user, userDataNeedsUpdating, state.user]);

  async function addUserAndRoles(
    inviteCode: string,
    orgId:string,
    user: User,
  ) {
    const db = firebase.firestore();
    const docRef = db.collection("users").doc(user.uid);
    const inviteRef = db.collection("invites").doc(inviteCode);
    const inviteDocSnap = await inviteRef.get();
    const inviteDocData = inviteDocSnap.data() as InviteDoc;
    if (inviteDocData.orgId !== orgId || !!inviteDocData.consumed) {

      throw new Error("wrong org or invite already consummed");
    }
    //const { displayName, email, photoURL } = user;

    const { roles, marketSegments,  assistantTo} = inviteDocData;

    const newUser:User = {...user, orgId, roles, marketSegments,  assistantTo: assistantTo|| null};
    // const newUser:User = {
    //   displayName,
    //   email,
    //   orgId,
    //   marketSegments,
    //   assistantTo,
    //   roles,
    // };
    const batch = db.batch();
    batch.set(docRef, newUser);
    batch.set(inviteRef, {
      ...inviteDocData,
      consumed: firebase.firestore.Timestamp.fromDate(new Date()),
    });
    return batch.commit();
  }

  async function updateUserInfo(user: User) {
    const db = firebase.firestore();
    const docRef = db.collection("users").doc(user.uid);
    const userDocSnap = await docRef.get();
    const userDoc = userDocSnap.data();
    const newUser = {
      ...userDoc,
      displayName: user.displayName,
      email: user.email,
    } as User;
    if (!userDoc) {
      throw new Error("User Doc not found");
    }
    const batch = db.batch();
    batch.set(docRef, newUser);
    return batch.commit();
  }
  async function addNew(user:User) {

    const db = firebase.firestore();
    const dateSaved = firebase.firestore.Timestamp.fromDate(new Date());

    if (!user) {
      throw new Error("user not logged in");
    }

    const docRef = db.collection("users").doc(user.uid);

    console.log("save user", user)

    const batch = db.batch();
    batch.set(docRef, user);
    await batch.commit();
    setState({ user, userDataNeedsUpdating: false });
    return user.uid;
  }
  // async function addUserInfo(user: User, orgId: keyof Themes) {
  //   const db = firebase.firestore();
  //   const docRef = db.collection("users").doc(user.uid);
  //   // const userDocSnap = await docRef.get();
  //   // const userDoc = userDocSnap.data();
  //   const { displayName, email, photoURL } = user;
  //   const newUser = {
  //     displayName,
  //     email,
  //     roles: [],
  //     marketSegments: [],
  //     orgId,
  //   };

  //   const batch = db.batch();
  //   batch.set(docRef, newUser);
  //   return batch.commit();
  // }

  const rtnObj: {
    user: User | null;
    setUserNull: () => void;
    userInfoNeedsUpdate: boolean;
    updateUserInfo: (user: User) => Promise<unknown>;
    addUserAndRoles:(inviteCode:string, orgId:string, user:User ) => Promise<void>;
    addNew:(user:User) => Promise<string>;
    //addUserInfo: (user: User, orgId: keyof Themes) => Promise<unknown>
  } = {
    user: state.user,
    setUserNull: () => setState({ user: null }),
    userInfoNeedsUpdate: !!state.userDataNeedsUpdating,
    updateUserInfo,
    addUserAndRoles,
    addNew
    //addUserInfo,
  };
  return rtnObj;
}

function hasUserChanged(fbUser: firebase.User | null, user: User | null) {
  if (!user) {
    return true;
  }
  if (!fbUser) {
    return true;
  }
  if (fbUser.uid !== user.uid) {
    return true;
  }
  return false;
}
function getNonRegisteredUserObject(fbUser: firebase.User): User {
  const { uid, displayName, photoURL, email } = fbUser;
  return {
    uid,
    displayName,
    photoURL,
    email: email || "",
    orgId: null,
    marketSegments: [],
    isUserAdmin: false,
    roles: [],
  };
}

function getUserObject(
  fbUser: firebase.User,
  storedOrgId: string,
  marketSegments: string[] = [],
  isUserAdmin: boolean,
  roles: Role[] = [],
  verified: boolean = false
): User | null {
  const { uid, displayName, email, photoURL  } = fbUser;
  return {
    uid,
    displayName,
    email: email || "",
    photoURL,
    orgId: storedOrgId,
    marketSegments,
    isUserAdmin,
    roles,
    verified
  };
}
