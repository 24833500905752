import React, { ReactElement } from "react";
import { Light, Strong } from "./ListingPageStyledComponents";
import {
  TextField,
  MultiPickField,
  PickListField,
} from "types/fieldComponentTypes";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
type Props = {
  value: string | string[] | undefined;
  field?: TextField | MultiPickField | PickListField;
  label?: string;
  showValueBelowLabel?: boolean;
  isMarkdown?: boolean;
  hideLabel?: boolean;
};

export default function StringFieldLabel({
  field,
  label,
  value,
  showValueBelowLabel = false,
  isMarkdown = false,
  hideLabel = false,
}: Props) {
  if (!value) {
    return null;
  }
  let renderValue: string | ReactElement;
  if (value instanceof Array) {
    if (value.length === 0) {
      return null;
    }
    renderValue = value.join(", ");
  } else if (isMarkdown) {
    renderValue = <ReactMarkdown source={value} plugins={[gfm]} />;
  } else {
    renderValue = value;
  }
  if (hideLabel) {
    return <MarkDownSection>{renderValue}</MarkDownSection>;
  }

  if (showValueBelowLabel) {
    return (<>
      <ValueBelowP>
        <Light>{field?.label || label || ""}</Light>
        
      </ValueBelowP>
      <ValueBelowDiv>{renderValue}</ValueBelowDiv>
      </>
    );
  }

  return (
    <p>
      <Light>{field?.label || label || ""} </Light>
      <Strong>{renderValue}</Strong>
    </p>
  );
}

const ValueBelowDiv = styled.p`
  margin-top: 0px;
`;

const ValueBelowP = styled.p`
margin-bottom: 8px;
`;

const MarkDownSection = styled.div`

  & p {
    line-height: 25px;
  }
`;
