import { withTheme } from '@material-ui/core/styles';
import React from "react";
import styled from "styled-components";
type Props = {
  selectedIndex: number;
  setSelectedIndex(n:number): void;
  showType: boolean; 
}

const StepSearchNavBar : React.FC<Props> = ({selectedIndex, setSelectedIndex, showType}) => {


  function buttonClick(position: number) {
    setSelectedIndex(position);
  }
  const buttons = showType ?  ["MARKET SEGMENT", "SIZE", "LOCATION", "REPORT CATEGORY"] : ["MARKET SEGMENT", "SIZE", "LOCATION"];

  return (
    <Container>
      <Ul>
        {buttons.map((label, n) => (
          <Li key={`btn_${n}`}  isSelected={selectedIndex === n} onClick={() => buttonClick(n)}>
            {label}
          </Li>
        ))}
      </Ul>
      <Stick stickPosition={selectedIndex * 150} />
    </Container>
  );
};

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

type LiType = {
  isSelected:boolean;
  theme:any;
}

const Li = withTheme(styled.li<LiType>`
  float: left;
  width: 150px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${props => (props.isSelected ? props.theme.palette.primary[600] : "#aaa")};
  display: table-cell;
  vertical-align: middle;
  height: 120px;
  padding-top: 45px;
  cursor: pointer;
  user-select: none;
`);

const Container = styled.div`
  height: 120px;
  border-bottom: 1px solid #d9d9d9;
  border-top-left-radius: 10px;
  padding-left: 50px;
`;
const Stick = withTheme(styled.div<{stickPosition:number, theme:any}>`
  position: relative;
  left: ${position => position.stickPosition}px;
  width: 150px;
  height: 5px;
  background-color: ${props => props.theme.palette.primary[600]};
  transition: all 0.3s ease;
  top: 115px;
`);

export default StepSearchNavBar;
