import React from "react";
import { TextField } from "@material-ui/core";
import styled from "styled-components";

const   InputField = ({
    label,
    isRequired,
    onChange,
    isError,
    isWhereApplicable,
    value,
    disabled,
    multiline,
  }: InputFieldProps) => (
    <TFContainer>
      <TextField
        variant="filled"
        label={label}
        size="small"
        disabled={disabled}
        onChange={onChange}
        fullWidth
        error={isError}
        multiline={multiline}
        rows={multiline ? 3 : 1}
        value={value}
        helperText={
          isError
            ? "Enter value"
            : isRequired
            ? "Required"
            : isWhereApplicable
            ? "Required (where applicable)"
            : null
        }
      />
    </TFContainer>
  );

  type InputFieldProps = {
    label: string;
    //value:string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isRequired?: boolean;
    isError?: boolean;
    isWhereApplicable?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    value: string;
  };

  
  const TFContainer = styled.div`
  margin: 25px 0;
  `;
  export default InputField;