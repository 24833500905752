import React from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { ListingInfo, LISTING_TYPE, LISTING_STATUSES } from "types/Types";
import ImgViewer300 from "../../components/controls/ImgViewer300";
import ListItemDetails from "../../components/lists/ListItemDetails";

const RowListItem = ({
  land,
  onClick,
  onHover,
  isSelected,
  isPublic,
  isRealtor,
  showEditButton
}: {
  land: ListingInfo & { listingType: LISTING_TYPE; status: LISTING_STATUSES };
  isSelected: boolean;
  onClick: (id: string | null) => void;
  onHover: (id: string | null) => void;
  isPublic: boolean;
  isRealtor: boolean;
  showEditButton: boolean;
}) => {
  const primaryImg =
    land.images &&
    land.images.length > 0 &&
    land.images.find((i: any) => i.isPrimary);


  return (
    <Box
      isSelected={isSelected}
      isArchived={land.status === LISTING_STATUSES.ARCHIVED}
      onClick={() => onClick(isSelected ? null : land.id)}
      onMouseEnter={() => onHover(land.id)}
      onMouseLeave={() => onHover(null)}
    >
      {!!primaryImg ? (
        <ImgViewer300
          listingId={land.id}
          fullImageName={primaryImg.fullImageName}
          onClick={onClick}
        />
      ) : (
        <NoImage />
      )}
      <ListItemDetails listingItemDetail={land} isMap={false} />
      {isSelected && (
        <ButtonBox>
          {land.status === LISTING_STATUSES.PUBLISHED && (
            <OpenButton
              href={`/${isPublic ? "pland" : isRealtor? "rland" : "land"}/${land.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              OPEN
            </OpenButton>
          )}

          {showEditButton && (
            <EditButton href={`/editor/${land.id}`} target="_blank">
              EDIT
            </EditButton>
          )}
        </ButtonBox>
      )}
    </Box>
  );
};
interface BoxProps {
  isSelected: boolean;
  isArchived: boolean;
}

const Box = styled.div<BoxProps>`
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding: 12px;
  //border-radius: 5px;
  position: relative;
  cursor: pointer;
  ${(props) => props.isArchived && "opacity: 0.5;"}
  
  ${(props) => props.isSelected && "box-shadow: 0px 0px 5px 0px #ccc; border:2px solid dodgerblue;"}
  &:hover {
    background-color: #efefef;
  }
`;

const ButtonBox = styled.div`
  position: absolute;
  top: 20px;

  left: 20px;
`;

const NoImage = styled.div`
  width: 300px;
  height: 200px;
  background-color: #eee;
`;

const EditButton = withTheme(styled.a`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.palette.primary[800]};
  color: ${(props) => {
    return props.theme.palette.primary[500];
  }};
  width: 60px;
  text-align: center;
  padding: 7px 0;
  border-radius: 3px;
  box-shadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)";
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  margin: 0 5px;
  :hover {
    background-color: ${(props) => props.theme.palette.primary[100]};
  }
`);

const OpenButton = withTheme(styled.a`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  width: 80px;
  text-align: center;
  padding: 8px 0;
  text-decoration: none;
  border-radius: 3px;
  box-shadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)";
  display: inline-block;
  margin: 0 5px;
  font-size: 12px;
`);
export default RowListItem;
