import { RouteComponentProps } from "@reach/router";
import React from "react";
import styled from "styled-components";
import PageHeader from "./components/PageHeader";

const RegisterPage = ( props: RouteComponentProps   )  => {
  return (
    <>
      <PageHeader />
      <Wrapper>
        <MainImage src="/parcelSizeDiagram.png" />
        <TextHeader>GoSolo Land is in closed beta</TextHeader>
        <Line />
        <ContactInfo>
          To request access, contact Samuel Zion at{' '} <a href="mailto:samuel@gosololand.com.">samuel@gosololand.com</a>.
        </ContactInfo>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 1020px;
  margin: auto;
  padding-top: 40px;
  font-family: Montserrat;
`;

const Line = styled.div`
  border-bottom: 1px solid rgb(235, 235, 235);
  padding-top: 20px;
`;

const MainImage = styled.img`
  height: 175;
  padding: 0px 80px;
`;

const TextHeader = styled.h1`
  margin-top: 0;
  font-weight: 600;
  display: inline-block;
  line-height: 175px;
  vertical-align: top;
  margin: 0;
`;

const ContactInfo = styled.p`
  padding-top: 20px;
  text-align: center;
`;

export default RegisterPage;
