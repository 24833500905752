import { Toolbar } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import styled from "styled-components";
import firebase from "../../config/firebase";
import PageHeader from "../components/PageHeader";




const RegistrationLoginPage = ({redirect = "/"}: {redirect?:string} & RouteComponentProps  ) => {

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: redirect,
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, 
      firebase.auth.EmailAuthProvider.PROVIDER_ID]
  };
  return (
    <>
      <PageHeader  />
      <Toolbar />
      <div style={{ marginTop: 100 }}>
        <Container>
          <h3>Registration</h3>
          <p>Please select a sign-in method to begin the registration process.</p>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          />
          </Container>
      </div>
    </>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;
  & > p {
    margin-bottom: 40px;
  }
`;



export default RegistrationLoginPage;
