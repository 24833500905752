import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { CraneField } from "types/fieldComponentTypes";
import { Crane, Hook } from "types/Types";

type Props = {
  field: CraneField;
  value?: Crane;
  onBlur: (value: Crane) => void;
};

const CraneFieldControl: React.FC<Props> = ({
  field,
  value,
  onBlur,
}) => {
  const [crane, setCrane] = React.useState<Crane>(
    !!value && value.type ? value : { type: "interior" }
  );
  const numberOfHooks = crane?.numberOfHooks || 1;

  function handleCraneChange(key: keyof Crane, value: Crane[keyof Crane]) {
    setCrane({ ...crane, [key]: value });
  }
  function handleHookChange(key: keyof Hook, craneKey: keyof Crane, v: number) {
    if (["hook1", "hook2", "hook3"].includes(craneKey)) {
      const hook = { ...(crane.hook1 || {}), [key]: v };
      setCrane({ ...crane, [craneKey]: hook });
    }
  }

  function saveCrane() {
    onBlur(crane);
  }

  return (
    <>
      <Grid item md={12}>
        <h5 style={{ margin: 0 }}>Crane {field.craneNumber}</h5>
      </Grid>
      <Grid item md={3}>
        <TextField
          label="Type"
          select
          variant="filled"
          fullWidth
          value={crane.type}
          onChange={(e) =>
            handleCraneChange(
              "type",
              e.target.value === "interior" ? "interior" : "exterior"
            )
          }
          onBlur={saveCrane}
        >
          <MenuItem value={"interior"}>Interior</MenuItem>
          <MenuItem value={"exterior"}>Exterior</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={3}>
        <TextField
          label="Length (ft)"
          type="number"
          variant="filled"
          fullWidth
          value={crane?.length || ""}
          onChange={(e) =>
            handleCraneChange("length", parseFloat(e.target.value))
          }
          onBlur={saveCrane}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          label="Width (ft)"
          type="number"
          variant="filled"
          fullWidth
          value={crane?.width || ""}
          onChange={(e) => handleCraneChange("width", parseInt(e.target.value))}
          onBlur={saveCrane}
        />
      </Grid>
      <Grid item md={3}>
        <TextField
          label={"Number of Hooks"}
          select
          variant="filled"
          fullWidth
          value={numberOfHooks}
          onChange={(e) =>
            handleCraneChange("numberOfHooks", parseInt(e.target.value, 10))
          }
          onBlur={saveCrane}
        >
          <MenuItem value={1}>{1}</MenuItem>
          <MenuItem value={2}>{2}</MenuItem>
          <MenuItem value={3}>{3}</MenuItem>
        </TextField>
      </Grid>
      <CraneHook
        hookKey="hook1"
        hookNumber={1}
        hook={crane.hook1 || {}}
        handleHookChange={handleHookChange}
        saveCrane={saveCrane}
      />
      {numberOfHooks >= 2 && (
        <CraneHook
          hookKey="hook2"
          hookNumber={2}
          hook={crane.hook2 || {}}
          handleHookChange={handleHookChange}
          saveCrane={saveCrane}
        />
      )}
      {numberOfHooks >= 3 && (
        <CraneHook
          hookKey="hook3"
          hookNumber={3}
          hook={crane.hook3 || {}}
          handleHookChange={handleHookChange}
          saveCrane={saveCrane}
        />
      )}
    </>
  );
};
export default CraneFieldControl;


type CraneHookProps = {
  hookKey: "hook1" | "hook2" | "hook3";
  hookNumber: 1 | 2 | 3;
  hook: Hook;
  handleHookChange: (key: keyof Hook, craneKey: keyof Crane, v: number) => void;
  saveCrane: () => void;
};

function CraneHook({
  hookKey,
  hookNumber,
  hook,
  handleHookChange,
  saveCrane,
}: CraneHookProps) {
  return (
    <React.Fragment key={`hook_${hookKey}`}>
      <Grid item md={6}>
        <TextField
          label={`Hook ${hookNumber}: Clear Height (ft)`}
          type="number"
          variant="filled"
          fullWidth
          value={hook?.height || ""}
          onChange={(e) =>
            handleHookChange("height", hookKey, parseInt(e.target.value, 10))
          }
          onBlur={saveCrane}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          label={`Hook ${hookNumber}: Capacity (tons)`}
          type="number"
          variant="filled"
          fullWidth
          value={hook?.capacity || ""}
          onChange={(e) =>
            handleHookChange("capacity", hookKey, parseInt(e.target.value, 10))
          }
          onBlur={saveCrane}
        />
      </Grid>
    </React.Fragment>
  );
}
